import React from "react";
import styled from "styled-components/macro";
import { useApi } from "../../../api/apiContext";
import { themeColor, themeMinWidth } from "../../../app/theme";
import { truncate } from "../../../lib/stringUtilities";
import Icon from "../../Icon/Icon";

export type UserItemProps = {};

const Container = styled.div`
  padding: 24px;
  display: none;
  flex-direction: row;
  ${themeMinWidth("small")} {
    display: flex;
  }
`;
const UserIcon = styled.div`
  color: ${themeColor("teal")};
`;
const NameAndType = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;
const Name = styled.div`
  font-size: 18px;
  margin-top: -2px;
  line-height: 27px;
  white-space: nowrap;
  color: ${themeColor("darkGrayText4")};
`;
const Type = styled.div`
  margin-top: -3px;
  font-size: 10px;
  font-weight: 500;
  line-height: 14.1px;
  text-transform: capitalize;
  color: ${themeColor("black", { opacity: 0.4 })};
`;
const UserItem: React.FC<UserItemProps> = () => {
  const api = useApi();
  const { data: { data: me } = {}, isLoading: isLoadingMe } = api.useGetMe();
  return (
    <Container>
      {isLoadingMe ? (
        <></>
      ) : (
        <>
          <UserIcon>
            <Icon name="profile" width="32" height="32" />
          </UserIcon>
          <NameAndType>
            <Name>{truncate(me?.fullName, 25)}</Name>
            <Type>{me?.userKind}</Type>
          </NameAndType>
        </>
      )}
    </Container>
  );
};

export default UserItem;
