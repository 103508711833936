import React from "react";
import styled from "styled-components/macro";
import { OrganizationMemberV1 } from "../../../../api/queries/organizationMembers";
import { themeColor } from "../../../../app/theme";
import TextLink from "../../../../components/TextLink/TextLink";
import Referrals from "../../../../lib/models/referrals";

const Container = styled.div`
  &.n-a {
    color: ${themeColor("darkGrayText8")};
  }
`;
export type InsuranceStatusBoxProps = {
  organizationMember: OrganizationMemberV1;
};

const InsuranceStatusBox: React.FC<InsuranceStatusBoxProps> = ({
  organizationMember,
}) => {
  const referral = organizationMember.referral;
  if (!referral) return <Container className="n-a">N/A</Container>;

  const { insuranceStatus, insuranceRequestUrl, partialReferral } = referral;
  const teenName =
    organizationMember.firstName + " " + organizationMember.lastName;
  const parentEmail = partialReferral?.guardianEmail;
  const parentFirstName = partialReferral?.guardianFirstName;

  if (insuranceStatus === "pending" || insuranceStatus === "requested") {
    const staffInsuranceFormUrl = Referrals.staffInsuranceFormUrl({
      insuranceRequestUrl,
      organizationMemberId: organizationMember.id,
    });
    const parentMailTo = Referrals.parentInsuranceFormMailto({
      parentEmail: parentEmail ?? "",
      teenName,
      insuranceFormUrl: insuranceRequestUrl,
      parentFirstName,
    });
    if (!staffInsuranceFormUrl) {
      console.error("staffInsuranceFormUrl missing", { staffInsuranceFormUrl });
      return null;
    }

    return (
      <Container className={`insurance-status-${insuranceStatus}`}>
        This student needs insurance info. Please{" "}
        <TextLink href={parentMailTo}>reach out to their guardian</TextLink> or{" "}
        <TextLink href={staffInsuranceFormUrl}>
          enter their insurance information yourself.
        </TextLink>{" "}
      </Container>
    );
  }
  if (insuranceStatus === "no_insurance") {
    return (
      <Container className="no-insurance">
        This student has no insurance.
      </Container>
    );
  }
  if (insuranceStatus === "not_applicable") {
    return <Container className="n-a">N/A</Container>;
  }

  return <Container>This student has insurance info on file.</Container>;
};

export default InsuranceStatusBox;
