import { useState } from "react";
import styled, { css } from "styled-components/macro";
import { RequestError } from "../../../api/apiClient";
import { useApi } from "../../../api/apiContext";
import { Organization } from "../../../api/queries/organizations";
import { OrganizationSite } from "../../../api/queries/organizationSites";
import {
  OrganizationStaffMember,
  OrganizationStaffMemberPostArguments,
  usePatchOrganizationStaffMember,
  usePostOrganizationStaffMember,
} from "../../../api/queries/organizationStaffMembers";
import queryClient from "../../../api/queryClient";
import { themeMinWidth } from "../../../app/theme";
import { CenteredLoading } from "../../Loading/Loading";
import BaseModal, { BaseModalProps } from "../BaseModal/BaseModal";
import StaffUserModalAccess from "./StaffUserModalAccess";
import StaffUserModalForm from "./StaffUserModalForm";
import { trackEvent } from "client/amplitudeHelper";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 16px 24px;
  width: 100%;
  max-height: calc(100vh - 40px);
  overflow-y: scroll;
  ${themeMinWidth("small")} {
    width: 600px;
  }
`;
const ModalHeader = styled.div`
  font-weight: 600;
  font-size: 20px;
`;
const HidingContainer = styled.div<{ visible: boolean }>`
  ${(props) =>
    props.visible
      ? ""
      : css`
          display: none;
        `}
`;

type ShowModalArgs =
  | {
      mode: "edit";
      organizationStaffMember: OrganizationStaffMember;
    }
  | { mode: "new"; organizationStaffMember?: undefined };
export const useStaffUserModal = () => {
  const [state, setState] = useState<{
    showing: boolean;
    organizationStaffMember?: OrganizationStaffMember;
    mode: Mode;
  }>({
    showing: false,
    organizationStaffMember: undefined,
    mode: "new",
  });

  return {
    showModal: ({ mode, organizationStaffMember }: ShowModalArgs) =>
      setState((oldState) => ({
        ...oldState,
        showing: true,
        mode,
        organizationStaffMember,
      })),
    hideModal: () => setState((oldState) => ({ ...oldState, showing: false })),
    ...state,
  };
};

type ModalContext = ReturnType<typeof useStaffUserModal>;
type StaffUserModalInsertProps = {
  modalContext: ModalContext;
};
export const StaffUserModalInsert: React.FC<StaffUserModalInsertProps> = ({
  modalContext: { showing, hideModal, mode, organizationStaffMember },
}) => {
  if (!showing) {
    return null;
  }
  return (
    <StaffUserModal
      onClose={hideModal}
      mode={mode}
      organizationStaffMember={organizationStaffMember}
    />
  );
};

export type StaffUserModalWithDataProps = {
  orgSites?: OrganizationSite[];
  myOrg?: Organization;
  isSubmitting: boolean;
  submissionError: RequestError | null;
  createOrganizationStaffMember: ReturnType<
    typeof usePostOrganizationStaffMember
  >["mutate"];
  updateOrganizationStaffMember: ReturnType<
    typeof usePatchOrganizationStaffMember
  >["mutate"];
  mode: Mode;
  organizationStaffMember?: OrganizationStaffMember;
} & BaseModalProps;

export const StaffUserModalWithData: React.FC<StaffUserModalWithDataProps> = ({
  orgSites,
  organizationStaffMember,
  myOrg,
  isSubmitting,
  submissionError,
  createOrganizationStaffMember,
  updateOrganizationStaffMember,
  mode,
  ...baseModalProps
}) => {
  const [stage, setStage] = useState<"userForm" | "siteAccess">("userForm");
  const [staffAttributes, setStaffAttributes] = useState<
    Pick<
      OrganizationStaffMemberPostArguments,
      "firstName" | "lastName" | "email" | "title"
    >
  >();
  if (!orgSites || !myOrg) {
    return (
      <BaseModal>
        <Container>
          <CenteredLoading size="medium" />
        </Container>
      </BaseModal>
    );
  }
  return (
    <BaseModal {...baseModalProps}>
      <Container>
        <ModalHeader>
          {mode === "new" ? "Invite new user" : "Edit User"}
        </ModalHeader>
        <HidingContainer visible={isSubmitting}>
          <CenteredLoading size="medium" />
        </HidingContainer>
        <HidingContainer visible={stage === "userForm" && !isSubmitting}>
          <StaffUserModalForm
            onCancel={() => {
              baseModalProps.onClose?.();
              trackEvent("SCD:staffUserModalCancelClicked", {
                mode,
              });
            }}
            onNext={(newStaffAttributes) => {
              trackEvent("SCD:staffUserModalNextClicked", {
                mode,
              });
              setStage("siteAccess");
              setStaffAttributes(newStaffAttributes);
            }}
            submissionError={submissionError}
            initialValues={{
              firstName: organizationStaffMember?.firstName ?? "",
              lastName: organizationStaffMember?.lastName ?? "",
              title: organizationStaffMember?.title ?? "",
              email: organizationStaffMember?.email ?? "",
            }}
          />
        </HidingContainer>
        <HidingContainer visible={stage === "siteAccess" && !isSubmitting}>
          <StaffUserModalAccess
            mode={mode}
            onBack={() => {
              setStage("userForm");
              trackEvent("SCD:staffUserModalBackClicked", {
                mode,
              });
            }}
            onSubmit={(siteIds) => {
              if (!staffAttributes) {
                throw new Error("Cannot submit without staff attributes");
              }
              trackEvent("SCD:staffUserModalSubmitClicked", {
                mode,
              });
              if (mode === "new") {
                createOrganizationStaffMember(
                  {
                    ...staffAttributes,
                    organizationSiteIds: siteIds,
                    organizationApiKey: myOrg.apiKey,
                  },
                  { onError: () => setStage("userForm") }
                );
              } else {
                updateOrganizationStaffMember(
                  {
                    ...staffAttributes,
                    organizationSiteIds: siteIds,
                    id: organizationStaffMember?.id ?? "-1",
                  },
                  { onError: () => setStage("userForm") }
                );
              }
            }}
            orgSites={orgSites ?? []}
            initialSelection={organizationStaffMember?.organizationSites?.map(
              (site) => site.id
            )}
          />
        </HidingContainer>
      </Container>
    </BaseModal>
  );
};

type Mode = "new" | "edit";
export type StaffUserModalProps = {
  mode: Mode;
  organizationStaffMember?: OrganizationStaffMember;
} & BaseModalProps;

const StaffUserModal: React.FC<StaffUserModalProps> = ({
  mode,
  organizationStaffMember,
  ...baseModalProps
}) => {
  const api = useApi();
  const {
    mutate: createOrganizationStaffMember,
    isLoading: isCreating,
    error: creationError,
  } = api.usePostOrganizationStaffMember({
    options: {
      onSuccess: () => {
        // Reload any lists of organization staff members
        queryClient.invalidateQueries("organizationStaffMembers");
        baseModalProps.onClose?.();
      },
    },
  });
  const {
    mutate: updateOrganizationStaffMember,
    isLoading: isUpdating,
    error: updateError,
  } = api.usePatchOrganizationStaffMember({
    options: {
      onSuccess: () => {
        // Reload any lists of organization staff members
        queryClient.invalidateQueries("organizationStaffMembers");
        baseModalProps.onClose?.();
      },
    },
  });
  const { data: { data: myOrg } = {} } = api.useGetMyOrg();
  const { data: { data: orgSites } = {} } = api.useGetOrganizationSites({
    organizationApiKey: myOrg?.apiKey ?? "",
    options: {
      enabled: !!myOrg,
    },
  });

  return (
    <StaffUserModalWithData
      myOrg={myOrg}
      orgSites={orgSites}
      isSubmitting={mode === "new" ? isCreating : isUpdating}
      submissionError={mode === "new" ? creationError : updateError}
      mode={mode}
      organizationStaffMember={organizationStaffMember}
      createOrganizationStaffMember={createOrganizationStaffMember}
      updateOrganizationStaffMember={updateOrganizationStaffMember}
      {...baseModalProps}
    />
  );
};

export default StaffUserModal;
