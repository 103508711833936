import React from "react";
import ParentWelcomePacketLayout from "../../layouts/ParentWelcomePacketLayout/ParentWelcomePacketLayout";
import { Container, FormSection, H1, H2, HeaderSection } from "./elements";
import en from "./ParentWelcomePacket.en.json";
import es from "./ParentWelcomePacket.es.json";
import { useDaybreakTranslation } from "../../hooks/useDaybreakTranslation";

export type ParentWelcomePacketCompletedProps = {};

const ParentWelcomePacketCompleted: React.FC<ParentWelcomePacketCompletedProps> = () => {
  const { t } = useDaybreakTranslation("pwpForm", {
    en,
    es,
  });
  return (
    <ParentWelcomePacketLayout>
      <Container>
        <HeaderSection>
          <H1>{t("consentTitle")}</H1>
        </HeaderSection>
        <FormSection>
          <H2>{t("completed.submittedTitle")}</H2>
          <p>{t("completed.thankYouDescription")}</p>
        </FormSection>
      </Container>
    </ParentWelcomePacketLayout>
  );
};

export default ParentWelcomePacketCompleted;
