import React, { useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useApi } from "../../../api/apiContext";
import { UserFeature } from "../../../api/queries/userFeatures";
import { useOrgApiKeyParam } from "../../../app/Routes/ScreenerRoutes";
import MultipartQuiz from "../../../components/MultipartQuiz/MultipartQuiz";
import { useMandateOrganization } from "../../../hooks/screener/useMandateOrganization";
import ScreenerLayout from "../../../layouts/ScreenerLayout/ScreenerLayout";
import guestUserTokenStorage from "../../../lib/guestUserTokenStorage";
import { languagePreferenceStorage } from "../../../lib/localStorageManagers";
import {
  isClosedScreenerStatus,
  ClosedScreenerStatus,
} from "../../../api/queries/userFeatures";
import { trackPage, trackEvent } from "client/amplitudeHelper";

export type ScreenerAssessmentPageWithDataProps = {
  questionIndex: number;
  featureApiKey: string;
  goToQuestion: (index: number) => void;
  onQuizEnded: () => void;
  onClosedError: (status: ClosedScreenerStatus) => void;
};
export const ScreenerAssessmentPageWithData: React.FC<ScreenerAssessmentPageWithDataProps> = ({
  questionIndex,
  featureApiKey,
  goToQuestion,
  onQuizEnded,
  onClosedError,
}) => {
  return (
    <ScreenerLayout currentWizardStep="Survey">
      <MultipartQuiz
        questionIndex={questionIndex}
        featureApiKey={featureApiKey}
        goToQuestion={goToQuestion}
        onQuizEnded={onQuizEnded}
        onClosedError={onClosedError}
      />
    </ScreenerLayout>
  );
};
export type ScreenerAssessmentPageProps = {};
const ScreenerAssessmentPage: React.FC<ScreenerAssessmentPageProps> = () => {
  const history = useHistory();
  const organizationApiKey = useOrgApiKeyParam();
  useMandateOrganization(organizationApiKey);
  const queryClient = useQueryClient();

  // Grab the user's user_features, which will give us a feature api key we can
  // use to get the right assessment for this user.
  const { data: userFeatures, isLoading } = useApi().useGetUserFeatures(
    "me",
    false,
    {
      useErrorBoundary: true,
    }
  );

  // Get the 0-based question index from the url
  const { questionIndex: questionIndexString } = useParams<{
    questionIndex?: string;
  }>();
  const questionIndex = questionIndexString
    ? parseInt(questionIndexString, 10)
    : 0;

  const goToQuestion = useCallback(
    (index: number) => {
      console.log(`going to question ${index}`);
      history.push(
        `/organization/${organizationApiKey}/screener/assessment/${index}`
      );
    },
    [history, organizationApiKey]
  );

  const onQuizEnded = useCallback(() => {
    // Log the user out when we're done with the quiz.
    guestUserTokenStorage.clear();

    // Clear all clientside api caches when we're done with the quiz.
    queryClient.clear();

    history.push(`/organization/${organizationApiKey}/screener/thank-you`);
  }, [history, organizationApiKey, queryClient]);

  const onClosedError = useCallback(
    (statusError) => {
      history.push(
        `/organization/${organizationApiKey}/screener/status/${statusError}`
      );
    },
    [history, organizationApiKey]
  );

  useEffect(() => {
    trackPage("universalScreenerScreenerAssessmentPage", {
      questionIndex: questionIndex,
    });
  }, [questionIndex]);

  // If we're not logged in as a guest, bounce us back to the eligibility page
  if (!guestUserTokenStorage.get()) {
    return <Redirect to={`/organization/${organizationApiKey}/screener`} />;
  }

  if (isLoading) {
    return <ScreenerLayout currentWizardStep="Survey" loading />;
  }
  if (!userFeatures) {
    throw new Error("Unable to load userFeatures");
  }

  // Find the feature that tells us this user's screener feature api key
  const screenerFeatures = userFeatures?.filter((userFeature) =>
    userFeature.apiKey.startsWith("universal_screener")
  );

  let screenerUserFeature: UserFeature | undefined;
  if (screenerFeatures.length > 1) {
    // might have both a spanish and english screener available, check what
    // the submitted language preference is. Default to 'en' if no preference
    // set
    const preferredLanguage = languagePreferenceStorage.get() || "en";
    if (preferredLanguage === "es") {
      screenerUserFeature = screenerFeatures.find((userFeature) =>
        userFeature.apiKey.includes("spanish")
      );
    } else {
      screenerUserFeature = screenerFeatures.find(
        (userFeature) => !userFeature.apiKey.includes("spanish")
      );
    }
  }
  if (!screenerUserFeature) {
    screenerUserFeature = screenerFeatures[0];
  }

  if (isClosedScreenerStatus(screenerUserFeature?.scheduledStatus)) {
    const scheduledStatus = screenerUserFeature?.scheduledStatus;
    trackEvent("screenerNotOpen", {
      featureInfo: screenerUserFeature,
    });
    history.push(
      `/organization/${organizationApiKey}/screener/status/${scheduledStatus}`
    );
  }

  return (
    <ScreenerAssessmentPageWithData
      questionIndex={questionIndex}
      featureApiKey={screenerUserFeature.apiKey}
      goToQuestion={goToQuestion}
      onQuizEnded={onQuizEnded}
      onClosedError={onClosedError}
    />
  );
};

export default ScreenerAssessmentPage;
