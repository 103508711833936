import React, { Suspense } from "react";
import DirectReferralSelfCareInstruction from "../DirectReferralSelfCareInstruction/DirectReferralSelfCareInstruction";
import { Container } from "components/DesktopNav/elements";
import { CenteredLoading } from "components/Loading/Loading";

const DirectSelfCareInstructionPage: React.FC = () => {
  return (
    <Suspense
      fallback={
        <Container>
          <CenteredLoading></CenteredLoading>{" "}
        </Container>
      }
    >
      <DirectReferralSelfCareInstruction />
    </Suspense>
  );
};

export default DirectSelfCareInstructionPage;
