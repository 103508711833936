import styled from "styled-components/macro";
import { themeColor } from "../../../app/theme";
import TextLink from "../../../components/TextLink/TextLink";

const EmptyTableContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  font-weight: 500;
  color: ${themeColor("darkGrayText7")};
  padding: 48px 24px 24px 24px;
  a {
    font-weight: 400;
  }
`;
const EmptyTableContent = styled.div`
  max-width: 300px;
  text-align: center;
`;
type EmptyTableProps = { message: string; ctaText: string; onCTA: () => void };
const EmptyTable: React.FC<EmptyTableProps> = ({ message, ctaText, onCTA }) => {
  return (
    <EmptyTableContainer>
      <EmptyTableContent>{message}</EmptyTableContent>
      <TextLink onClick={onCTA}>{ctaText}</TextLink>
    </EmptyTableContainer>
  );
};
export default EmptyTable;
