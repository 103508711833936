import { SortingState } from "@tanstack/react-table";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { OrganizationMemberV1 } from "../../../api/queries/organizationMembers";
import { themeColor } from "../../../app/theme";
import TealButton from "../../../components/buttons/TealButton/TealButton";
import TableV2, { createTableV2 } from "../../../components/TableV2/TableV2";
import OrganizationMembers from "../../../lib/models/organizationMembers";
import { useHasOrganizationFeature } from "../../../hooks/useHasOrganizationFeature";

const Container = styled.div`
  .table-element {
    min-width: 1000px;
  }
`;
const EmptyTableContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${themeColor("darkGrayText7")};
  padding: 24px;
  font-weight: 500;
`;
const ReferButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const table = createTableV2<OrganizationMemberV1>();
export type ReferredStudentsListTableProps = {
  organizationMembers: OrganizationMemberV1[];
  setPageIndex: (pageIndex: number) => void;
  setPageSort: (sort: SortingState[0]) => void;
  pageIndex: number;
  maxPageIndex: number;
};
const ReferredStudentsListTable: React.FC<ReferredStudentsListTableProps> = ({
  organizationMembers,
  setPageIndex,
  setPageSort,
  pageIndex,
  maxPageIndex,
}) => {
  const history = useHistory();
  const { active: appointmentsAvailable } = useHasOrganizationFeature(
    "show_appointments_in_ssd"
  );
  const columns = useMemo(
    () => [
      table.createDataColumn("firstName", {
        id: "first_name",
        header: "Student name",
        cell: (cell) =>
          cell.row.original?.firstName + " " + cell.row.original?.lastName,
      }),
      table.createDataColumn("careProvider", {
        id: "Clinician",
        enableSorting: false,
        cell: (row) => {
          return row.getValue()?.fullName ?? null;
        },
      }),
      table.createDataColumn("latestReferralStatus", {
        id: "Referral Status",
        enableSorting: false,
        cell: (cell) => {
          const orgMember = cell.row.original;
          const journeyStatus = orgMember
            ? OrganizationMembers.journeyStatusDisplayString(
                OrganizationMembers.getJourneyStatus(orgMember) ?? "Pending"
              )
            : "";
          return <div>{journeyStatus}</div>;
        },
      }),

      ...(appointmentsAvailable
        ? [
            table.createDataColumn("nextSessionDatetime", {
              id: "Next appointment",
              enableSorting: false,
              cell: (cell) => {
                const realSessionTimeString = cell.getValue();
                const intakeSessionTimeString =
                  cell.row.original?.intake?.startTime;

                // If there's no normal upcoming session *and* no intake session, then
                // show a blank "next appointment"
                if (!realSessionTimeString && !intakeSessionTimeString) {
                  return null;
                }

                // Otherwise, get the latest of the two.
                const realSessionTime = realSessionTimeString
                  ? DateTime.fromISO(realSessionTimeString)
                  : DateTime.fromSeconds(0);
                const intakeSessionTime = intakeSessionTimeString
                  ? DateTime.fromSeconds(intakeSessionTimeString)
                  : DateTime.fromSeconds(0);
                const latestTime = DateTime.max(
                  intakeSessionTime,
                  realSessionTime
                );

                // If the latest appointment is in the past, don't show it.
                if (latestTime < DateTime.now()) {
                  return null;
                }

                // Format it nicely
                return latestTime.toLocaleString({
                  month: "short",
                  day: "numeric",
                  ...DateTime.TIME_SIMPLE,
                });
              },
            }),
          ]
        : []),
      table.createDisplayColumn({
        id: "view more button",
        header: () => {
          return <></>;
        },
        cell: ({ row }) => {
          return (
            <ReferButton>
              <TealButton
                onClick={() => {
                  history.push(`/school/students/${row.original?.id}`);
                }}
              >
                View more
              </TealButton>
            </ReferButton>
          );
        },
      }),
    ],
    [history, appointmentsAvailable]
  );
  return (
    <Container>
      <TableV2
        table={table}
        columns={columns}
        data={organizationMembers}
        emptyContent={<EmptyTableContent>No Students Found</EmptyTableContent>}
        updateData={({ sorting, currentPageIndex }) => {
          if (currentPageIndex != null) {
            setPageIndex(currentPageIndex);
          }
          if (sorting != null) {
            setPageSort(sorting[0]);
          }
        }}
        currentPageIndex={pageIndex}
        maxPageIndex={maxPageIndex}
      />
    </Container>
  );
};

export default ReferredStudentsListTable;
