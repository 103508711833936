import React, { useState } from "react";
import styled from "styled-components/macro";
import { theme, themeColor } from "../../../app/theme";
import Icon from "../../Icon/Icon";

export type NewMessageBarProps = { sendMessage: (newMessage: string) => void };

const Container = styled.div`
  display: flex;
  gap: 24px;
  padding: 14px 24px;

  position: absolute;
  bottom: 0;
  background-color: white;
  right: 0;
  left: 0;
`;

// This grow-wrap trick allows the textarea to expand vertically as the user
// types.  See https://stackoverflow.com/a/64792984/240427 for details.
const TextBoxGrowWrap = styled.div`
  display: grid;
  flex-grow: 2;
`;
// Any styling for the text area must go in here so that it's also applied to
// the hidden TextBoxGrower element.
const commonStyles = `
  font-family: ${theme.fonts.poppins};
  border-radius: 11px;
  background-color: ${theme.colors.backgroundGray};
  border: 1px solid #e2e4e4;
  padding: 12px 16px;
  font-size: 14px;
  max-height: 160px;
  overflow: hidden;

  grid-area: 1 / 1 / 2 / 2;
`;
const TextBoxGrower = styled.div`
  white-space: pre-wrap;
  visibility: hidden;
  ${commonStyles}
`;
const TextBox = styled.textarea`
  resize: none;
  :focus-visible {
    outline: 2px solid ${themeColor("teal")};
  }
  ${commonStyles}
`;
const SendButton = styled.div`
  border-radius: 50%;
  background-color: ${themeColor("orange5")};
  min-width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  transition: all ${(props) => props.theme.animations.defaultHover};
  cursor: pointer;
  .icon {
    color: white;
    top: -2px;
    position: relative;
  }
  :hover {
    opacity: 0.9;
  }
  :active {
    opacity: 1;
  }
`;
const NewMessageBar: React.FC<NewMessageBarProps> = ({ sendMessage }) => {
  const [newMessage, setNewMessage] = useState<string>("");
  const onSend = () => {
    sendMessage(newMessage);
    setNewMessage("");
  };

  return (
    <Container>
      <TextBoxGrowWrap>
        <TextBox
          placeholder="Type here"
          rows={1}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        ></TextBox>
        <TextBoxGrower>{newMessage}</TextBoxGrower>
      </TextBoxGrowWrap>
      <SendButton onClick={onSend}>
        <Icon name="paperPlane"></Icon>
      </SendButton>
    </Container>
  );
};

export default NewMessageBar;
