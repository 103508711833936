import styled from "styled-components";
import { themeMinWidth } from "../../../app/theme";
import TealButton from "../../../components/buttons/TealButton/TealButton";

export const Header = styled.div`
  margin-bottom: 16px;
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    display: -webkit-box;
  }
`;
export const Side = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Left = styled(Side)``;
export const Right = styled(Side)``;

export const Logo = styled.img`
  cursor: pointer;
  ${themeMinWidth("small")} {
    display: none;
  }
`;
export const LogoType = styled.img`
  display: none;
  cursor: pointer;
  ${themeMinWidth("small")} {
    display: block;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  justify-content: space-between;
`;

export const LanguageButton: React.FC<{
  language: string;
  currentLanguage: string;
  onClick: () => void;
}> = ({ language, currentLanguage, onClick }) => (
  <TealButton
    onClick={onClick}
    variant="secondary"
    style={{
      backgroundColor: "#FFFFFF",
      color: currentLanguage === language ? "#0A6E82" : "#9e9e9e",
      borderColor: currentLanguage === language ? "#0A6E82" : "#9e9e9e",
    }}
  >
    {language === "en" ? "English" : "Español"}
  </TealButton>
);
