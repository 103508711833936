import { Field, FormikProps } from "formik";
import get from "lodash/get";
import { ReactElement } from "react";
import { Input, Layout } from "../elements";

export type TextFieldProps = {
  label: string | ReactElement;
  error?: boolean;
  formik?: FormikProps<any>;
  basicFormik?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TextField: React.FC<TextFieldProps> = ({
  formik,
  label,
  error,
  basicFormik = false,
  ...inputProps
}) => {
  if (formik) {
    const key = inputProps.name ?? inputProps.id ?? "";
    return (
      <TextField
        label={label}
        value={get(formik.values, key) ?? ""}
        name={key}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!(formik.errors[key] && formik.touched[key])}
        {...inputProps}
        disabled={inputProps.disabled || formik.isSubmitting}
      />
    );
  }

  const id = inputProps.id ?? inputProps.name;

  if (basicFormik) {
    return (
      <Layout label={label} error={error} {...inputProps}>
        <Field {...inputProps} id={id} error={error} as={Input} />
      </Layout>
    );
  }

  return (
    <Layout id={id} error={error} label={label} {...inputProps}>
      <Input error={error} {...inputProps} />
    </Layout>
  );
};

export default TextField;
