import React, { useMemo } from "react";
import toast from "react-hot-toast";
import styled from "styled-components/macro";
import { useApi } from "../../../../api/apiContext";
import {
  OrganizationStaffMember,
  usePostOrganizationStaffMemberInvite,
} from "../../../../api/queries/organizationStaffMembers";
import queryClient from "../../../../api/queryClient";
import Badge from "../../../../components/Badge/Badge";
import Icon from "../../../../components/Icon/Icon";
import Initial from "../../../../components/Initial/Initial";
import {
  ConfirmStaffDeletionModalInsert,
  useConfirmStaffDeletionModal,
} from "../../../../components/Modals/ConfirmStaffDeletionModal/ConfirmStaffDeletionModal";
import TableV2, { createTableV2 } from "../../../../components/TableV2/TableV2";
import TextLink from "../../../../components/TextLink/TextLink";
import {
  ActionCell,
  DeleteIcon,
  DeleteIconPlaceHolder,
  EmailCell,
  NameCell,
} from "./tableElements";

const InviteStatusCell = styled.div`
  text-transform: capitalize;
`;
const table = createTableV2<OrganizationStaffMember>();

export type PendingUsersTableWithDataProps = {
  staffMembers?: OrganizationStaffMember[];
  resendInvite: ReturnType<
    typeof usePostOrganizationStaffMemberInvite
  >["mutateAsync"];
};
export const PendingUsersTableWithData: React.FC<PendingUsersTableWithDataProps> = ({
  staffMembers,
  resendInvite,
}) => {
  const confirmStaffDeletionModalContext = useConfirmStaffDeletionModal();

  const columns = useMemo(
    () => [
      table.createDataColumn("firstName", {
        enableSorting: false,
        id: "firstName",
        cell: ({ row }) => {
          const fullName =
            row.original?.firstName + " " + row.original?.lastName;
          return (
            <NameCell>
              <Initial>{fullName}</Initial>
              {fullName}
            </NameCell>
          );
        },
      }),
      table.createDataColumn("email", {
        enableSorting: false,
        id: "email",
        cell: ({ getValue }) => {
          return <EmailCell>{getValue()}</EmailCell>;
        },
      }),
      table.createDataColumn("lastInvite", {
        id: "lastInvite",
        cell: ({ getValue }) => {
          const { state, isExpired } = getValue() ?? {};
          let displayState = "";
          if (isExpired) {
            displayState = "invite expired";
          } else if (state === "issued") {
            displayState = "pending";
          } else if (state === "consumed") {
            displayState = "invite used";
          } else if (state === "invalidated") {
            displayState = "invite invalidated";
          }
          return (
            <InviteStatusCell>
              <Badge
                type="lightGray"
                text={displayState}
                size="medium"
                inline
              />
            </InviteStatusCell>
          );
        },
      }),
      table.createDisplayColumn({
        id: "actions",
        cell: ({ row: { original } }) => {
          const canDelete = original?.managementMode === "manual";
          return (
            <ActionCell>
              <TextLink
                onClick={() => {
                  if (!original) {
                    return;
                  }
                  // TODO: some sort of user feedback here
                  const resendPromise = resendInvite(
                    {
                      organizationStaffMemberId: original?.id,
                    },
                    {
                      onSuccess: () => {
                        queryClient.invalidateQueries(
                          "organizationStaffMembers"
                        );
                      },
                    }
                  );
                  toast.promise(resendPromise, {
                    loading: `Sending invite to ${original.email}`,
                    success: `Sent invite to ${original.email}`,
                    error: `Failed to send invite to ${original.email}!`,
                  });
                }}
              >
                Resend invite
              </TextLink>
              {canDelete ? (
                <DeleteIcon
                  onClick={() =>
                    original &&
                    confirmStaffDeletionModalContext.showModal({
                      organizationStaffMember: original,
                    })
                  }
                >
                  <Icon name="trashCan" />
                </DeleteIcon>
              ) : (
                <DeleteIconPlaceHolder />
              )}
            </ActionCell>
          );
        },
      }),
    ],
    [confirmStaffDeletionModalContext, resendInvite]
  );
  if (!staffMembers) {
    return (
      <TableV2
        data={[] as OrganizationStaffMember[]}
        table={table}
        columns={columns}
        hideHeader
        loading
        updateData={() => {}}
      />
    );
  }

  return (
    <>
      <ConfirmStaffDeletionModalInsert
        modalContext={confirmStaffDeletionModalContext}
      />
      <TableV2
        data={staffMembers}
        table={table}
        columns={columns}
        hideHeader
        updateData={() => {}}
      />
    </>
  );
};

export type PendingUsersTableProps = {};

const PendingUsersTable: React.FC<PendingUsersTableProps> = () => {
  const api = useApi();
  const { data: { data: myOrg } = {} } = api.useGetMyOrg({
    options: { useErrorBoundary: true },
  });
  const {
    data: { data: staffMembers } = {},
  } = api.useGetOrganizationStaffMembers({
    organizationApiKey: myOrg?.apiKey ?? "",
    hasAuthed: false,
    options: { enabled: !!myOrg, useErrorBoundary: true },
  });
  const {
    mutateAsync: resendInvite,
  } = api.usePostOrganizationStaffMemberInvite({});
  return (
    <PendingUsersTableWithData
      staffMembers={staffMembers}
      resendInvite={resendInvite}
    />
  );
};

export default PendingUsersTable;
