import { Field, FormikProps } from "formik";
import get from "lodash/get";
import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import downArrow from "../../../images/icons/downArrow.svg";
import es from "./SelectField.es.json";
import en from "./SelectField.en.json";
import { useDaybreakTranslation } from "../../../hooks/useDaybreakTranslation";
import { Layout, errorStyles, inputStyles } from "../elements";

const Select = styled.select<{ error?: boolean }>`
  ${inputStyles}
  ${errorStyles}
  padding: 16px 14px;
  display: block;

  // The only way to style the "down" arrow in a select input is to remove it
  // and replace it with an image.  This is a bummer, but even something as
  // simple as adding a bit of padding requires this:
  appearance: none;
  background: url(${downArrow}) no-repeat;
  background-position: right 12px top 50%;
`;
export type SelectFieldProps = {
  label: string | ReactElement;
  error?: boolean;
  formik?: FormikProps<any>;
  basicFormik?: boolean;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

const SelectField: React.FC<SelectFieldProps> = ({
  label,
  error,
  children,
  formik,
  basicFormik = false,
  ...inputProps
}) => {
  if (formik) {
    const key = inputProps.name ?? "";
    return (
      <SelectField
        label={label}
        value={get(formik.values, key) ?? ""}
        name={key}
        children={children}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!(formik.errors[key] && formik.touched[key])}
        disabled={inputProps.disabled || formik.isSubmitting}
        {...inputProps}
      />
    );
  }

  if (basicFormik) {
    return (
      <Layout label={label} error={error} {...inputProps}>
        <Field error={error} {...inputProps} as={Select}>
          {children}
        </Field>
      </Layout>
    );
  }

  return (
    <Layout label={label} error={error} {...inputProps}>
      <Select error={error} {...inputProps}>
        {children}
      </Select>
    </Layout>
  );
};

export const selectOptions = (
  options: [string, string][],
  name: string,
  props?: React.OptionHTMLAttributes<HTMLOptionElement>
) => (
  <>
    {options.map(([value, label], idx) => (
      <option key={`${name}-${value}-${idx}`} value={value} {...props}>
        {label}
      </option>
    ))}
  </>
);

export const SelectStateField = (props: Omit<SelectFieldProps, "children">) => {
  const { t } = useDaybreakTranslation("contactCard", { en, es });
  return (
    <SelectField basicFormik {...props}>
      <option value="" selected disabled hidden>
        {t("Select your state")}
      </option>
      {selectOptions(
        [
          ["AL", "Alabama"],
          ["AK", "Alaska"],
          ["AZ", "Arizona"],
          ["AR", "Arkansas"],
          ["CA", "California"],
          ["CO", "Colorado"],
          ["CT", "Connecticut"],
          ["DE", "Delaware"],
          ["FL", "Florida"],
          ["GA", "Georgia"],
          ["HI", "Hawaii"],
          ["ID", "Idaho"],
          ["IL", "Illinois"],
          ["IN", "Indiana"],
          ["IA", "Iowa"],
          ["KS", "Kansas"],
          ["KY", "Kentucky"],
          ["LA", "Louisiana"],
          ["ME", "Maine"],
          ["MD", "Maryland"],
          ["MA", "Massachusetts"],
          ["MI", "Michigan"],
          ["MN", "Minnesota"],
          ["MS", "Mississippi"],
          ["MO", "Missouri"],
          ["MT", "Montana"],
          ["NE", "Nebraska"],
          ["NV", "Nevada"],
          ["NH", "New Hampshire"],
          ["NJ", "New Jersey"],
          ["NM", "New Mexico"],
          ["NY", "New York"],
          ["NC", "North Carolina"],
          ["ND", "North Dakota"],
          ["OH", "Ohio"],
          ["OK", "Oklahoma"],
          ["OR", "Oregon"],
          ["PA", "Pennsylvania"],
          ["RI", "Rhode Island"],
          ["SC", "South Carolina"],
          ["SD", "South Dakota"],
          ["TN", "Tennessee"],
          ["TX", "Texas"],
          ["UT", "Utah"],
          ["VT", "Vermont"],
          ["VA", "Virginia"],
          ["WA", "Washington"],
          ["WV", "West Virginia"],
          ["WI", "Wisconsin"],
          ["WY", "Wyoming"],
        ],
        "state"
      )}
    </SelectField>
  );
};

export default SelectField;
