import React from "react";
import SchoolCounselorLayout from "../../../layouts/SchoolCounselorLayout/SchoolCounselorLayout";
import { useInsuranceReturnHandler } from "./useInsuranceReturnHandler";

export type ReturnFromInsurancePageProps = {};

const ReturnFromInsurancePage: React.FC<ReturnFromInsurancePageProps> = () => {
  useInsuranceReturnHandler({ landingPage: "/schools" });
  return <SchoolCounselorLayout loading />;
};

export default ReturnFromInsurancePage;
