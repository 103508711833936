import { createUseApiQuery, PageMetaTypeV1 } from "./queryHelpers";

type ChatTokenResponse = { token: string };
export const useGetChatToken = createUseApiQuery<
  ChatTokenResponse,
  {},
  PageMetaTypeV1
>({
  queryBuilder: () => ({
    url: "/chat/v1/token",
    cacheKey: ["token"],
  }),
});

type ChatNicknameResponse = { id: string; nickname: string }[];
export const useGetChatNicknames = createUseApiQuery<
  ChatNicknameResponse,
  {},
  PageMetaTypeV1
>({
  queryBuilder: () => ({
    url: "/chat/v1/nicknames",
    cacheKey: ["nicknames"],
  }),
});
