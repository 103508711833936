type TokenStorageShape = {
  token: string;
  surveyResponseId?: string;
};

const GUEST_USER_TOKEN_KEY = "guest_user_token";
const storeGuestUserToken = (dataToStore: TokenStorageShape) => {
  localStorage.setItem(GUEST_USER_TOKEN_KEY, JSON.stringify(dataToStore));
};
const getGuestUserToken = (): TokenStorageShape | null => {
  const stringFromStorage = localStorage.getItem(GUEST_USER_TOKEN_KEY);
  if (!stringFromStorage) {
    return null;
  }
  try {
    return JSON.parse(stringFromStorage);
  } catch (error) {
    if (error instanceof SyntaxError) {
      clearGuestUserToken();
      return null;
    } else {
      throw error;
    }
  }
};
const clearGuestUserToken = () => {
  localStorage.removeItem(GUEST_USER_TOKEN_KEY);
};

// This is so you can easily clear your guest token when testing with
// `window.clearGuestUserToken()`.  Please use the exported function below for
// normal usage.
(window as any).clearGuestUserToken = clearGuestUserToken;

const guestUserTokenStorage = {
  get: getGuestUserToken,
  set: storeGuestUserToken,
  clear: clearGuestUserToken,
};

export default guestUserTokenStorage;
