import React from "react";
import styled from "styled-components/macro";
import { themeColor } from "../../../app/theme";
import UserItem from "../UserItem/UserItem";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${themeColor("borderGray6")};
  margin-left: 16px;
  margin-right: 16px;
`;
const Title = styled.div`
  display: flex;
  align-self: center;
  font-size: 24px;
  font-weight: 600;
`;

export type TitleNavProps = { title: string };
const TitleNav: React.FC<TitleNavProps> = ({ title }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <UserItem />
    </Container>
  );
};

export default TitleNav;
