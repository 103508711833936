import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { themeColor } from "../../../app/theme";
import Icon from "../../../components/Icon/Icon";
import { schoolSupportEmail } from "../../../constants";
import ScreenerLayout from "../../../layouts/ScreenerLayout/ScreenerLayout";
import { trackPage } from "client/amplitudeHelper";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 16px 60px 0;
`;
const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${themeColor("orange5", { opacity: 0.1 })};
  width: 85px;
  height: 85px;
  border-radius: 8px;
  .icon {
    width: 43px;
    height: 39px;
    color: ${themeColor("orange5")};
  }
`;
const H1 = styled.h1`
  margin-top: 32px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;
const Details = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  max-width: 436px;
  text-align: center;
`;
export type OrgNotFoundPageProps = {};

const OrgNotFoundPage: React.FC<OrgNotFoundPageProps> = () => {
  useEffect(() => {
    trackPage("universalScreenerOrgNotFoundPage");
  }, []);
  return (
    <ScreenerLayout>
      <Container>
        <IconBox>
          <Icon name="alert" />
        </IconBox>
        <H1>The page you're looking for was not found.</H1>
        <Details>
          Please double check the URL you entered into your browser. If you
          think the URL should be correct, please email us at{" "}
          <a href={"mailto:" + schoolSupportEmail}>{schoolSupportEmail}</a>
        </Details>
      </Container>
    </ScreenerLayout>
  );
};

export default OrgNotFoundPage;
