import { useState } from "react";
import { useTranslation } from "react-i18next";

const languages = ["en", "es"] as const;
type Language = typeof languages[number];
export const useDaybreakTranslation = (
  key: string,
  resources: {
    [language in Language]: Record<string, Record<string, string> | string>;
  }
) => {
  const translation = useTranslation([key]);
  const [loaded, setLoaded] = useState(false);
  if (loaded) {
    return translation;
  }
  languages.forEach((language) => {
    translation.i18n.addResourceBundle(
      language,
      key,
      resources[language],
      true,
      true
    );
  });
  setLoaded(true);
  return translation;
};
