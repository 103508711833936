import { createUseApiQuery } from "./queryHelpers";
import { SurveyMonkeySurvey } from "./surveys";

export type Form = {
  id: string;
  type: string;
  kind: string;
  featureData: {
    milestoneId: string;
    userFormId: string;
    isTest: boolean;
  };
  surveyMonkeyName: string;
  surveyMonkeyId: string;
  surveyMonkeyFormData: SurveyMonkeySurvey;
};

export const getFormByFeatureQueryKey = (featureApiKey: string) => [
  "forms",
  "by_feature",
  featureApiKey,
];

export const useGetFormByFeature = createUseApiQuery<
  Form,
  { featureApiKey: string }
>({
  queryBuilder: ({ featureApiKey }) => ({
    url: `/v4/forms/by_feature/${featureApiKey}`,
    cacheKey: getFormByFeatureQueryKey(featureApiKey),

    // The backend gets the *next* form for this user,
    // so this endpoint should always be expected to be different
    // unless you know something specific about your usage situation
    defaultQueryOptions: { cacheTime: 0 },
  }),
  auth: false,
});
