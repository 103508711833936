import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";

export const Container = styled.div`
  padding: 32px 16px;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${themeMinWidth("small")} {
    font-size: 18px;
  }
  .new-appointment-button {
    padding-left: 8px;
    padding-right: 8px;
  }
`;
export const HeaderText = styled.div`
  font-size: 18px;
  color: ${themeColor("darkGrayText4")};
  font-weight: 500;
`;
export const StatsRow = styled.div`
  display: grid;
  margin-top: 16px;
  grid-template-columns: repeat(2, auto);
  gap: 16px;
  ${themeMinWidth("small")} {
    gap: 18px;
    grid-template-columns: repeat(4, auto);
  }
`;
export const TableSection = styled.div`
  margin-top: 32px;
`;
export const TableHeader = styled.div`
  margin-bottom: 16px;
  font-weight: 500;
  color: ${themeColor("darkGrayText4")};
  margin-left: 24px;
`;

export const EmptySessionsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px;
  font-size: 14px;
  font-weight: 500;
  color: ${themeColor("darkGrayText7")};
  gap: 24px;
  flex-grow: 1;
`;
export const EmptySessionsImage = styled.img`
  width: 50px;
`;

export const UtilizedTooltipContainer = styled.span`
  position: relative;
  top: 2px;
  margin-left: 4px;
  .icon {
    opacity: 0.6;
    width: 14px;
    height: 14px;
  }
  ${themeMinWidth("small")} {
    top: 1px;
    .icon {
      width: 16px;
      height: 16px;
    }
  }
`;
export const ChargedText = styled.div`
  display: flex;
`;
export const YearString = styled.div`
  display: inline;
  color: ${themeColor("darkGrayText8", { opacity: 0.75 })};
  margin-left: 4px;
`;
