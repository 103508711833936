import React, { createContext, useContext } from "react";

/**
 * STOP!  Are you thinking of adding some bit of state here?  Ask yourself:
 *
 * 1. Is this state local to a specific component?  Put it there (via useState)
 * instead.
 * 2. If the state is needed in more than one component, is there a common
 * ancestor component you could put this state in instead?  If so, put it there
 * and use a react context to pass it to the descendent components.
 * 3. Is this data that came from the api?  Use ReactQuery and lean on RQ's
 * built-in cache - that's what it's for.  RQ has a lot of advanced features -
 * odds are your use case is handled somewhere in those docs!  :)
 * 4. If you really must, you can put your non-local state here.
 *
 * The assumption is that for the small fraction of non-local state that's not a
 * good fit for ReactQuery, we can use react contexts like this to manage it.
 * If this file is getting unwieldy, that means that assumption is breaking and
 * we need to consider a state management solution like Redux.
 */

// As of 2022-04-07 we have no more global bits of UI state.  If no new ones
// arise in 4-6 months, rip this out.
type UIStateStoreType = {
  // Put your state getters + setters here...
};
const uiStateStoreContext = createContext<UIStateStoreType>(
  {} as UIStateStoreType
);
export const useUIStateStore = () => useContext(uiStateStoreContext);

export type UIStateStoreProps = {};
const UIStateStore: React.FC<UIStateStoreProps> = ({ children }) => {
  // Put your state getters + setters here...

  return (
    <uiStateStoreContext.Provider
      value={
        {
          // Put your state getters + setters here...
        }
      }
    >
      {children}
    </uiStateStoreContext.Provider>
  );
};

export default UIStateStore;
