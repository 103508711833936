import { DateTime } from "luxon";
import * as Yup from "yup";
import { guardianRelationships } from "../../api/queries/parentWelcomePackets";
import { languageOptions } from "../../constants";

const pwpSchema = Yup.object().shape({
  patientFirstName: Yup.string()
    .nullable()
    .required("Patient first name is required."),
  patientLastName: Yup.string()
    .nullable()
    .required("Patient last name is required."),
  patientBirthDate: Yup.string()
    .nullable()
    .required("Patient birth date is required.")
    .test(
      "birthDateIsReasonable",
      (_value) => `Birth date is too far in the past.`,
      (value) => {
        const birthDate = DateTime.fromISO(value ?? "");
        return Math.abs(birthDate.diffNow("years").years) < 75;
      }
    ),
  patientPreferredLanguage: Yup.string()
    .nullable()
    .oneOf(Object.keys(languageOptions))
    .required("Patient preferred language is required."),
  patientPreferredLanguageOther: Yup.string().nullable(),
  guardianRelationship: Yup.string()
    .nullable()
    .oneOf(Object.keys(guardianRelationships))
    .required("Guardian relationship is required."),
  guardianFirstName: Yup.string()
    .nullable()
    .required("Guardian first name is required."),
  guardianLastName: Yup.string()
    .nullable()
    .required("Guardian last name is required."),
  guardianPhoneNumber: Yup.string()
    .nullable()
    .required("Guardian phone number is required."),
  guardianEmail: Yup.string()
    .nullable()
    .email("Guardian email must be a valid email address.")
    .required("Guardian email is required."),
  guardianPreferredLanguage: Yup.string()
    .nullable()
    .oneOf(Object.keys(languageOptions))
    .required("Guardian preferred language is required."),
  guardianPreferredLanguageOther: Yup.string().nullable(),
  agreementTosPp: Yup.boolean()
    .nullable()
    .oneOf([true], "You must agree to the terms of use and privacy policy."),
  agreementIc: Yup.boolean()
    .nullable()
    .oneOf([true], "You must agree to the informed consent."),
  agreementAobHipaa: Yup.boolean()
    .nullable()
    .oneOf(
      [true],
      "You must agree to the assignment of benefits authorization for payers."
    ),
  agreementHipaaRoi: Yup.boolean()
    .nullable()
    .oneOf([true], "You must agree to the release of information."),
  agreementSignature: Yup.string()
    .nullable()
    .required("Signature is required."),
});

export default pwpSchema;
