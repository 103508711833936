import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../../../api/apiContext";
import { Session } from "../../../api/queries/sessions";
import StatCard from "../../../components/Cards/StatCard/StatCard";
import Icon from "../../../components/Icon/Icon";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import AppointmentBox from "./AppointmentBox";
import ClinicalProgressBox from "../../../components/ClinicalProgressBox/ClinicalProgressBox";
import {
  AppointmentBoxContainer,
  AppointmentsSection,
  ChargedText,
  ClinicalProgressSection,
  Container,
  Disclaimer,
  DisclaimerLink,
  EmptyAppointmentText,
  EmptySection,
  EmptySectionButton,
  EmptySectionImage,
  EmptySectionText,
  MoreLink,
  SectionHeader,
  SectionTitle,
  UtilizedTooltipContainer,
} from "./elements";
import clipboardImage from "../../../images/clipboard.svg";
import graphImage from "../../../images/graph.svg";
import TealButton from "../../../components/buttons/TealButton/TealButton";
import { Milestone, MilestoneKind } from "../../../api/queries/milestones";
import Sessions from "../../../lib/models/sessions";
import UtilizedTooltip from "../../../components/Tooltip/UtilizedTooltip/UtilizedTooltip";
import { User } from "../../../api/queries/users";
import { useSelectedTeen } from "../../../hooks/useSelectedTeen";
import { careCoordinatorEmail } from "../../../constants";
import { trackPage } from "client/amplitudeHelper";

export type DashboardHomePageWithDataProps = {
  teen: User;
  me: User;
  recentSession?: Session;
  upcomingSession?: Session;
  sessionStats: {
    charged: number;
    upcoming: number;
    attended: number;
    missed: number;
  };
  clinicalMilestonesByKind: Record<MilestoneKind, Milestone[]>;
};
export const DashboardHomePageWithData: React.FC<DashboardHomePageWithDataProps> = ({
  teen,
  recentSession,
  upcomingSession,
  sessionStats,
  clinicalMilestonesByKind,
  me,
}) => {
  const counselorEmail = teen.counselorEmail || careCoordinatorEmail;
  const parentCheckinMailto = `mailto:${counselorEmail}?subject=Scheduling a parent check-in`;
  const reminderMailto = `mailto:${counselorEmail}?subject=Clinical progress forms`;

  const hasSessions = upcomingSession || recentSession;
  const hasClinicalProgress = Object.values(clinicalMilestonesByKind).find(
    (milestones) => milestones.length > 0
  );
  const isCounselor = me.userKind === "counselor";
  const isParent = me.userKind === "parent";
  return (
    <DashboardLayout
      topNav={{
        type: isCounselor ? "counselorTopNav" : "childSelectorTopNav",
      }}
      showPatientName={isCounselor}
    >
      <Container>
        <SectionHeader>
          <SectionTitle>Clinical Progress</SectionTitle>
          <MoreLink>
            <Link
              to={
                isCounselor
                  ? `/clinician/${teen.id}/clinical-progress`
                  : `/${teen.id}/clinical-progress`
              }
            >
              See all progress
              <Icon name="leftArrow" flip />
            </Link>
          </MoreLink>
        </SectionHeader>
        {hasClinicalProgress ? (
          <>
            <ClinicalProgressSection>
              <ClinicalProgressBox
                title="Anxiety level"
                levelText="anxiety"
                milestones={clinicalMilestonesByKind["GadSurvey"]}
                kind="GadSurvey"
                reminderMailto={reminderMailto}
                isParent={isParent}
                teen={teen}
              />
              <ClinicalProgressBox
                title="Depression disorder"
                levelText="depression"
                milestones={clinicalMilestonesByKind["PhqSurvey"]}
                kind="PhqSurvey"
                reminderMailto={reminderMailto}
                isParent={isParent}
                teen={teen}
              />
              <ClinicalProgressBox
                title="Overall wellbeing"
                levelText="overall wellbeing"
                milestones={clinicalMilestonesByKind["WhoSurvey"]}
                kind="WhoSurvey"
                reminderMailto={reminderMailto}
                isParent={isParent}
                teen={teen}
              />
            </ClinicalProgressSection>
            {!isParent ? null : (
              <Disclaimer>
                Results are preliminary should be discussed live with your
                teen's therapist in a parent check-in.
                <DisclaimerLink href={parentCheckinMailto}>
                  Schedule a parent check-in
                </DisclaimerLink>
              </Disclaimer>
            )}
          </>
        ) : (
          <EmptySection>
            <EmptySectionImage src={graphImage} />
            <EmptySectionText>
              No clinical progress forms have been completed
            </EmptySectionText>
            <EmptySectionButton>
              {!isParent ? null : (
                <TealButton
                  onClick={() => (window.location.href = reminderMailto)}
                >
                  Schedule a session
                </TealButton>
              )}
            </EmptySectionButton>
          </EmptySection>
        )}
        <SectionHeader>
          <SectionTitle>Appointments</SectionTitle>
          <MoreLink>
            <Link
              to={
                isCounselor
                  ? `/clinician/${teen.id}/appointments`
                  : `/${teen.id}/appointments`
              }
            >
              See all appointments
              <Icon name="leftArrow" flip />
            </Link>
          </MoreLink>
        </SectionHeader>
        {hasSessions ? (
          <AppointmentsSection>
            <StatCard icon="bill" stat={sessionStats.charged.toString()}>
              <ChargedText>
                <div>Utilized</div>
                <UtilizedTooltipContainer>
                  <UtilizedTooltip />
                </UtilizedTooltipContainer>
              </ChargedText>
            </StatCard>
            <StatCard icon="clock2" stat={sessionStats.upcoming.toString()}>
              Upcoming
            </StatCard>
            <StatCard
              icon="checkCircle"
              stat={sessionStats.attended.toString()}
            >
              Attended
            </StatCard>
            <StatCard icon="userDelete" stat={sessionStats.missed.toString()}>
              Missed
            </StatCard>
            <AppointmentBoxContainer>
              <AppointmentBox
                title="Recent"
                session={recentSession}
                empty={<div>No recent sessions</div>}
                showPaidStatus
              />
            </AppointmentBoxContainer>
            <AppointmentBoxContainer>
              <AppointmentBox
                title="Upcoming"
                session={upcomingSession}
                empty={
                  <>
                    <EmptyAppointmentText>
                      No upcoming sessions
                    </EmptyAppointmentText>
                    {!isParent ? null : (
                      <TealButton
                        onClick={() => {
                          window.location.href = parentCheckinMailto;
                        }}
                      >
                        Schedule Appointment
                      </TealButton>
                    )}
                  </>
                }
                showPaidStatus={false}
              />
            </AppointmentBoxContainer>
          </AppointmentsSection>
        ) : (
          <EmptySection>
            <EmptySectionImage src={clipboardImage} />
            <EmptySectionText>No upcoming or recent sessions</EmptySectionText>
            <EmptySectionButton>
              {!isParent ? null : (
                <TealButton
                  onClick={() => (window.location.href = parentCheckinMailto)}
                >
                  Schedule a session
                </TealButton>
              )}
            </EmptySectionButton>
          </EmptySection>
        )}
      </Container>
    </DashboardLayout>
  );
};

export type DashboardHomePageProps = {};

const DashboardHomePage: React.FC<DashboardHomePageProps> = () => {
  const api = useApi();
  const { data: { data: me } = {}, isLoading: isLoadingMe } = api.useGetMe();
  const { teen } = useSelectedTeen();
  useEffect(() => {
    trackPage("dashboardOverview");
  }, []);

  const {
    data: { data: sessions } = {},
    isLoading: isLoadingSessions,
  } = api.useGetSessions({
    userId: teen?.id,
    options: { enabled: !!teen },
  });

  const {
    data: milestones,
    isLoading: isLoadingMilestones,
  } = api.useGetMilestones(
    {
      userId: teen?.id,
      kind: ["GadSurvey", "WhoSurvey", "PhqSurvey"],
      state: "completed",
    },
    { enabled: !!teen }
  );

  if (
    !teen ||
    isLoadingSessions ||
    !sessions ||
    isLoadingMilestones ||
    !milestones ||
    isLoadingMe ||
    !me
  ) {
    return <DashboardLayout loading />;
  }

  // TODO: move this logic to a separate file - we'll need it for the
  // clinical progress page.
  const milestonesByKind: Record<MilestoneKind, Milestone[]> = {
    GadSurvey: [],
    PhqSurvey: [],
    WhoSurvey: [],
  };
  milestones.forEach((milestone) => {
    milestonesByKind[milestone.kind].push(milestone);
  });

  // TODO: move this logic to a separate file - we'll need it for the
  // appointments page.
  const { recent, upcoming } = Sessions.partitionByUpcoming(sessions);

  const mostRecentSession = recent.reduce(
    (latest, session) =>
      session.startTime > latest.startTime ? session : latest,
    recent[0]
  );

  const earliestUpcomingSession = upcoming.reduce(
    (earliest, session) =>
      session.startTime > earliest.startTime ? session : earliest,
    upcoming[0]
  );

  return (
    <DashboardHomePageWithData
      teen={teen}
      recentSession={mostRecentSession}
      upcomingSession={earliestUpcomingSession}
      clinicalMilestonesByKind={milestonesByKind}
      sessionStats={Sessions.calculateStats(sessions)}
      me={me}
    />
  );
};

export default DashboardHomePage;
