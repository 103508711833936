import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import React, { useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components/macro";
import "@reach/combobox/styles.css";
import { useApi } from "../../../api/apiContext";
import { themeColor, themeMinWidth } from "../../../app/theme";
import Icon from "../../Icon/Icon";
import { GetOrganizationMembersParams } from "../../../api/queries/organizationMembers";
import debounce from "lodash/debounce";
import { trackEvent } from "client/amplitudeHelper";

const Container = styled.div`
  // margin-bottom: 48px;
  width: 100%;

  padding: 40px 12px 60px 12px;
  ${themeMinWidth("small")} {
    padding: 40px;
  }
`;
const Header = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 32px;
  color: black;
`;
const popupBorderRadius = 8;
const Popup = styled(ComboboxPopover)`
  z-index: 2;
  border-radius: ${popupBorderRadius}px;
  border: 1px solid ${themeColor("borderGray6")};
  margin-top: 4px;
`;
const InputAndIcon = styled.div`
  position: relative;
  display: flex;
  .icon {
    color: ${themeColor("darkGrayText6")};
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 19px;
  }
`;
const SearchInput = styled(ComboboxInput)`
  border: 1px solid ${themeColor("borderGray6")};
  border-radius: 8px;
  padding: 16px;
  padding-left: 48px;
  color: black;
  font-size: 14px;
  display: flex;
  flex-grow: 1;

  ${themeMinWidth("small")} {
    width: 552px;
  }
`;
const HeaderRow = styled.div`
  color: ${themeColor("darkGrayText6")};
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin: 20px 16px 24px 16px;
  margin-bottom: 8px;
`;

const ResultItem = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  border-bottom: 1px solid ${themeColor("borderGray6")};
  padding-bottom: 16px;
`;
const OptionRow = styled(ComboboxOption)`
  display: flex;
  font-size: 16px;
  text-overflow: ellipsis;
  ${themeMinWidth("small")} {
    font-size: 16px;
  }

  // Doesn't seem to be a good way to override the library's padding
  padding: 12px 16px 0 16px !important;
  &:last-child {
    border-bottom-left-radius: ${popupBorderRadius}px;
    border-bottom-right-radius: ${popupBorderRadius}px;
    ${ResultItem} {
      border-bottom: none;
    }
  }
`;
const ResultField = styled.div`
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
`;
const ResultName = styled(ResultField)``;
const ResultEmail = styled(ResultField)`
  color: ${themeColor("darkGrayText6")};
`;
const InfoRow = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  border-top: 1px solid ${themeColor("borderGray6")};
  padding-bottom: 16px;
  cursor: pointer;
  padding: 12px 16px 12px 16px;
  color: ${themeColor("teal6")};
`;
const NoResultsText = styled.div`
  padding: 40px;
  font-size: 16px;
  display: flex;
`;

export type SearchModeProps = {
  onSelect: (organizationMemberId: string) => void;
  title: string;
  searchParameters?: GetOrganizationMembersParams;
  goToAdd?: () => void;
};

const SearchMode: React.FC<SearchModeProps> = ({
  title,
  onSelect,
  searchParameters,
  goToAdd,
}) => {
  const api = useApi();
  const [displayedSearchQuery, setDisplayedSearchQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    ref.current?.focus();
  }, []);

  const {
    data: { data: organizationMembers } = {},
  } = api.useGetOrganizationMembers({
    q: searchQuery,
    perPage: 7,
    page: 1,
    options: {
      keepPreviousData: true,
    },
    ...searchParameters,
  });

  useEffect(() => {
    if (searchQuery.trim()) {
      trackEvent("SCD:SearchModeModal:search", {
        searchQuery,
      });
    }
  }, [searchQuery]);

  const setSearchQueryDebounced = useMemo(() => {
    return debounce(setSearchQuery, 400);
  }, [setSearchQuery]);

  useEffect(() => {
    setSearchQueryDebounced(displayedSearchQuery);
  }, [displayedSearchQuery, setSearchQueryDebounced]);

  return (
    <Container>
      <Header>{title}</Header>
      <Combobox aria-label="Cities" onSelect={(item) => onSelect(item)}>
        <InputAndIcon>
          <Icon name="search" />
          <SearchInput
            className="city-search-input"
            placeholder="Search using student name or email"
            onChange={(event) => {
              setDisplayedSearchQuery(event.target.value);
            }}
            autocomplete={false}
            ref={ref}
          />
        </InputAndIcon>
        {organizationMembers && (
          <Popup className="shadow-popup">
            {organizationMembers.length > 0 ? (
              <ComboboxList>
                <HeaderRow>
                  <div>Student name</div>
                  <div>Email</div>
                </HeaderRow>
                {organizationMembers.map((organizationMember) => {
                  const str = `${organizationMember.firstName}, ${organizationMember.lastName}, ${organizationMember.studentEmail}`;
                  return (
                    <OptionRow key={str} value={organizationMember.id}>
                      <ResultItem>
                        <ResultName>{`${organizationMember.firstName} ${organizationMember.lastName}`}</ResultName>
                        <ResultEmail>
                          {organizationMember.studentEmail}
                        </ResultEmail>
                      </ResultItem>
                    </OptionRow>
                  );
                })}
              </ComboboxList>
            ) : (
              <NoResultsText>No results found</NoResultsText>
            )}
            {goToAdd && <InfoRow onClick={goToAdd}>Add a student</InfoRow>}
          </Popup>
        )}
      </Combobox>
    </Container>
  );
};

export default SearchMode;
