import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { useApiClient } from "../apiClient";
import { convertSerializedType, withApiErrorHandling } from "./queryHelpers";
import { User } from "./users";

type LoginVariables = {
  auth0AccessToken: string;
  auth0IdToken: string;
  inviteToken?: string;
};

export type OnboardingMilestoneKind = string;
export type LoginResponseData_OnboardingMilestone = {
  id: string;
  kind: OnboardingMilestoneKind;
};
export type LoginResponseData_Onboarding = {
  current_milestone_id: number;
  current_milestone_kind: OnboardingMilestoneKind;
  onboarding_milestones: {
    [key: string]: LoginResponseData_OnboardingMilestone;
  };
};
export type LoginResponseData_User = {
  id?: string;
  credential_id?: string;
  analytics_type: string;
  is_test?: boolean;
  psychiatrist: boolean;
};
export type LoginResponseData = {
  onboarding: LoginResponseData_Onboarding;
  user: LoginResponseData_User;
  data: User;
};

export const usePostLoginV3 = (
  options: UseMutationOptions<
    LoginResponseData,
    AxiosError,
    LoginVariables
  > = {}
) => {
  const apiClient = useApiClient({ auth: false });
  return useMutation<LoginResponseData, AxiosError, LoginVariables>(
    async ({ auth0AccessToken, auth0IdToken, inviteToken }) => {
      return withApiErrorHandling(async () => {
        const loginResponse = (
          await apiClient.post(
            "/v3/login",
            { invite_token: inviteToken },
            {
              headers: {
                Authorization: "Bearer " + auth0AccessToken,
                idToken: auth0IdToken,
              },
            }
          )
        )?.data;
        loginResponse.data = convertSerializedType(loginResponse.data);
        return loginResponse;
      });
    },
    options
  );
};
