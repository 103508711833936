import styled, { css } from "styled-components";

export const LooksLikeLink = styled.p<{ disabled?: boolean }>`
  text-decoration: underline;
  ${({ disabled }) =>
    disabled
      ? css`
          color: var(--Night, #000);
          cursor: default;
        `
      : css`
          color: blue;
          cursor: pointer;
        `};
`;
