import React from "react";
import styled from "styled-components/macro";
import { themeMinWidth } from "../../../app/theme";
import Icon from "../../Icon/Icon";

export type QuizFrameProps = {
  onBack?: () => void;
  currentQuestion: number;
  totalQuestions?: number;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const TopNav = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 40px 1fr 40px;
  margin-bottom: 16px;

  ${themeMinWidth("small")} {
    margin-top: 24px;
  }
`;
const Left = styled.div`
  padding-left: 17px;
  ${themeMinWidth("small")} {
    padding-left: 0;
  }
`;
const Center = styled.div`
  text-align: center;
`;
const BackButton = styled.div`
  cursor: pointer;
  transition: all ${(props) => props.theme.animations.defaultHover};
  :hover {
    opacity: 0.5;
  }
`;
const QuestionContainer = styled.div``;
const QuestionCount = styled.div`
  color: #8b8b8b;
`;

const QuizFrame: React.FC<QuizFrameProps> = ({
  currentQuestion,
  totalQuestions,
  onBack,
  children,
}) => {
  const showQuestionCount = !!totalQuestions;
  return (
    <Container>
      <TopNav>
        <Left>
          {onBack && (
            <BackButton onClick={onBack}>
              <Icon name="leftArrow" />
            </BackButton>
          )}
        </Left>
        <Center>
          {showQuestionCount ? (
            <QuestionCount>
              Question {currentQuestion} of {totalQuestions} total
            </QuestionCount>
          ) : (
            <></>
          )}
        </Center>
      </TopNav>
      <QuestionContainer>{children}</QuestionContainer>
    </Container>
  );
};

export default QuizFrame;
