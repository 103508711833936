import React, { useState } from "react";
import styled from "styled-components/macro";
import { themeMinWidth } from "../../../app/theme";
import { CenteredLoading } from "../../Loading/Loading";

const Container = styled.div`
  flex-grow: 1;
  ${themeMinWidth("small")} {
    padding: 8px;
    min-width: 800px;
  }
  height: calc(100vh - 64px);
`;
const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 16px;
  &.loading {
    display: none;
  }
`;

export type PWPInsuranceModalProps = {
  insuranceLink: string;
};

const PWPInsuranceModal: React.FC<PWPInsuranceModalProps> = ({
  insuranceLink,
}) => {
  const [iFrameLoaded, setIFrameLoaded] = useState(false);
  return (
    <Container>
      {iFrameLoaded ? null : <CenteredLoading />}
      <IFrame
        src={insuranceLink}
        onLoad={() => {
          console.log("loaded");
          setIFrameLoaded(true);
        }}
        className={iFrameLoaded ? "loaded" : "loading"}
      ></IFrame>
    </Container>
  );
};

export default PWPInsuranceModal;
