import { useQuery, UseQueryOptions } from "react-query";
import { RequestError, useApiClient } from "../apiClient";
import { withApiErrorHandling } from "./queryHelpers";

export type Invite = {
  email: string;
  state: "issued" | "consumed" | "invalidated";
  isExpired: boolean;
};

export const useGetInvite = (
  token?: string,
  options: UseQueryOptions<Invite, RequestError> = {}
) => {
  const queryKey = ["invite", token];
  const apiClient = useApiClient({ auth: false });
  return useQuery<Invite, RequestError>(
    queryKey,
    async () => {
      return withApiErrorHandling(async () => {
        const response = await apiClient.get(`/v1/invites/${token}`);
        return response.data;
      });
    },
    { retry: false, ...options }
  );
};
