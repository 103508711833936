import React from "react";
import styled from "styled-components/macro";
import { themeMinWidth } from "../../app/theme";
import { CenteredLoading } from "../../components/Loading/Loading";
import Wizard from "../../components/Wizard/Wizard";
import CenteredLayout from "../CenteredLayout/CenteredLayout";

export type OnboardingLayoutProps = {
  showHeader?: boolean;
  wizardSteps?: string[];
  currentWizardStep?: string;
};

const Box = styled.div`
  // prevent margin collapse
  overflow: auto;
  width: 100%;
  ${themeMinWidth("small")} {
    width: 704px;
  }
`;
const WizardContainer = styled.div`
  padding: 24px 17px;

  ${themeMinWidth("small")} {
    border-bottom: 1px solid #e4e4e4;
  }
`;

export type OnboardingWizardProps = {
  wizardSteps: string[];
  currentWizardStep?: string;
};

const OnboardingWizard: React.FC<OnboardingWizardProps> = ({
  wizardSteps,
  currentWizardStep,
}) => {
  const lastFilledStepIndex = currentWizardStep
    ? wizardSteps.indexOf(currentWizardStep) + 1
    : 0;
  return (
    <WizardContainer>
      <Wizard steps={wizardSteps} lastFilledStepIndex={lastFilledStepIndex} />
    </WizardContainer>
  );
};

const Container = styled.div`
  padding: 40px;
  min-height: 400px;
`;
export const OnboardingLoadingPage: React.FC = () => (
  <OnboardingLayout>
    <Container>
      <CenteredLoading />
    </Container>
  </OnboardingLayout>
);

const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({
  children,
  showHeader = true,
  wizardSteps,
  currentWizardStep,
}) => {
  return (
    <CenteredLayout headerStyle={showHeader ? "onboarding" : "none"}>
      <Box>
        {wizardSteps && (
          <OnboardingWizard {...{ wizardSteps, currentWizardStep }} />
        )}
        {children}
      </Box>
    </CenteredLayout>
  );
};

export default OnboardingLayout;
