import { Invite } from "./invites";
import { OrganizationSite } from "./organizationSites";
import {
  createUseApiMutation,
  createUseApiQuery,
  PageMetaTypeV1,
} from "./queryHelpers";

export type OrganizationStaffMember = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  createdAt: string;
  managementMode: "efile" | "manual";
  lastInvite?: Invite;
  organizationSites?: OrganizationSite[];
};
export const useGetOrganizationStaffMember = createUseApiQuery<
  OrganizationStaffMember,
  { id: string },
  PageMetaTypeV1
>({
  queryBuilder: ({ id }) => {
    return {
      cacheKey: ["organizationStaffMembers", id],
      url: `/v4/organization_staff_members/${id}`,
    };
  },
});

export const useGetMyStaff = (
  {
    options,
  }: {
    options: Parameters<typeof useGetOrganizationStaffMember>[0]["options"];
  } = { options: {} }
) => useGetOrganizationStaffMember({ id: "me", options });

export const useGetOrganizationStaffMembers = createUseApiQuery<
  OrganizationStaffMember[],
  { hasAuthed?: boolean; organizationApiKey: string },
  PageMetaTypeV1
>({
  queryBuilder: ({ hasAuthed, organizationApiKey }) => {
    return {
      cacheKey: ["organizationStaffMembers", { hasAuthed, organizationApiKey }],
      url: `/v4/organization_staff_members`,
      config: { params: { has_authed: hasAuthed, organizationApiKey } },
    };
  },
});

export const useDeleteOrganizationStaffMember = createUseApiMutation<
  OrganizationStaffMember,
  { id: string }
>({
  requestBuilder: ({ id }) => {
    return { method: "delete", url: `/v4/organization_staff_members/${id}` };
  },
});

export type OrganizationStaffMemberPostArguments = {
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  organizationSiteIds: string[];
  organizationApiKey: string;
};
export const usePostOrganizationStaffMember = createUseApiMutation<
  OrganizationStaffMember,
  OrganizationStaffMemberPostArguments
>({
  requestBuilder: ({
    firstName,
    lastName,
    email,
    title,
    organizationSiteIds,
    organizationApiKey,
  }) => ({
    method: "post",
    url: "/v4/organization_staff_members",
    data: {
      organization_staff_member: {
        first_name: firstName,
        last_name: lastName,
        email,
        title,
        organization_site_ids: organizationSiteIds,
        organization_api_key: organizationApiKey,
      },
    },
  }),
});

export const usePostOrganizationStaffMemberInvite = createUseApiMutation<
  {},
  { organizationStaffMemberId: string }
>({
  requestBuilder: ({ organizationStaffMemberId }) => ({
    method: "post",
    url: `/v4/organization_staff_members/${organizationStaffMemberId}/invites`,
  }),
});

export type OrganizationStaffMemberPatchArguments = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  organizationSiteIds: string[];
};
export const usePatchOrganizationStaffMember = createUseApiMutation<
  OrganizationStaffMember,
  OrganizationStaffMemberPatchArguments
>({
  requestBuilder: ({
    id,
    firstName,
    lastName,
    email,
    title,
    organizationSiteIds,
  }) => ({
    method: "patch",
    url: `/v4/organization_staff_members/${id}`,
    data: {
      organization_staff_member: {
        first_name: firstName,
        last_name: lastName,
        email,
        title,
        organization_site_ids: organizationSiteIds,
      },
    },
  }),
});
