import { useCallback, useEffect, useMemo, useState } from "react";
import SendBird, {
  BaseMessageInstance,
  GroupChannel,
  MessageCollection,
} from "sendbird";
import { useSendBirdStore } from "../app/SendBirdStore";
import SendBirdClient from "../lib/sendBirdClient";
import { trackEvent } from "client/amplitudeHelper";

/**
 * You don't want to mess around with sendbird's messaging paradigm - you just
 * want a list of messages, in a channel, that's kept up to date with new
 * messages.  This hook provides that for you.
 *
 * useMessageView creates a view into a channel's messages.  The length of that
 * view is determined by sendbird's MessageCollection concept, which
 * useMessageView is really just a wrapper around.
 *
 * Usage:
 *
 * const { messages, sendMessage } = useMessageView(someChannel);
 * return messages.map((message) => <div>{message.whatever}</div>)
 *
 * Note: this hook may only be used in components nested underneath a
 * <SendBirdStore> component, since it relies on the react context provided by
 * that store component.
 *
 * @param channel The channel whose messages you wish to show.
 * @returns A list of messages that will be kept up-to-date, and a function to
 * send new messages.
 */
export const useMessageView = (channel: GroupChannel) => {
  const { sendBird } = useSendBirdStore();

  const sendBirdClient = useMemo(() => {
    return sendBird && new SendBirdClient(sendBird);
  }, [sendBird]);

  // messageList is an ordered list of messages you can display in a chat view.
  const [messageList, _setMessageList] = useState<
    SendBird.BaseMessageInstance[]
  >([]);

  const [
    messageCollection,
    setMessageCollection,
  ] = useState<MessageCollection>();

  // The message collection doesn't always update its list of messages promptly.
  // As such, whenever we receive new messages from some source (eg
  // .loadPrevious()), we should use this function to set an authoritative
  // sorted and deduplicated message list.
  const setOrderedMessageList = useCallback(
    (newMessages: BaseMessageInstance[]) => {
      const { pendingMessages, succeededMessages } = messageCollection ?? {
        pendingMessages: [],
        succeededMessages: [],
      };
      const allMessages = [
        ...pendingMessages,
        ...succeededMessages,
        ...newMessages,
      ];
      allMessages.sort((m1, m2) => m1.messageId - m2.messageId);
      const messageIdSet = new Set(
        allMessages.map((message) => message.messageId)
      );
      const newAllMessages: BaseMessageInstance[] = [];
      allMessages.forEach((message) => {
        if (messageIdSet.delete(message.messageId)) {
          newAllMessages.push(message);
        }
      });
      _setMessageList(newAllMessages);
    },
    [messageCollection]
  );

  useEffect(() => {
    (async () => {
      if (!sendBirdClient) {
        return;
      }

      const messageCollection = await sendBirdClient.createMessageCollection({
        channel,
        onCollectionUpdate: () => {
          // TODO: include pending and failed messages.
          setOrderedMessageList([...messageCollection.succeededMessages]);
        },
      });
      setMessageCollection(messageCollection);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, sendBirdClient]);

  const sendMessage = async (newMessage: string) => {
    if (!sendBirdClient) {
      return;
    }

    trackEvent("sentMessage");

    await sendBirdClient.sendMessage({
      channel,
      message: newMessage,
    });
  };
  const loadOlderMessages = async () => {
    if (messageCollection?.hasPrevious) {
      const newMessages = await messageCollection?.loadPrevious();
      if (newMessages) {
        setOrderedMessageList([...newMessages, ...messageList]);
      }
    }
  };

  return {
    messages: messageList,
    sendMessage,
    loadOlderMessages,
  };
};
