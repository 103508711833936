import SendBird, {
  BaseMessageInstance,
  GroupChannel,
  MessageCollection,
} from "sendbird";
import { SendBirdInstance } from "sendbird";

/**
 * SendBirdClient is a wrapper around the SendBird lib.  Most functions here are
 * asynchronous, so if you don't want to have to mess with a bunch of
 * useEffect()s yourself, consider using/building a custom hook around
 * SendBirdClient (like useMessageView) instead of using SendBirdClient
 * directly.
 *
 * Aside from the static methods, usage is like:
 *
 * const client = new SendBirdClient(sendBirdInstance);
 * const channels = await client.findChannels(...);
 */
class SendBirdClient {
  /**
   * Connects to sendbird and returns a SendBird instance.
   *
   * @param config {
   *  sendBirdUserId: the sendbird user id for the current user you're "logging
   *    in" to sendbird as.
   *  accessToken: a sendbird access token (generally retrieved from our api)
   *    for the user you're "logging in" to sendbird as.
   * }
   * @returns An instance of the SendBird library, as returned by `new
   *    SendBird()`.
   */
  static async createSendBird({
    sendBirdUserId,
    accessToken,
  }: {
    sendBirdUserId: string;
    accessToken: string;
  }) {
    const sendBirdInstance = new SendBird({
      appId: process.env.REACT_APP_SENDBIRD_APP_ID || "",
      localCacheEnabled: true,
    });

    await sendBirdInstance.connect(sendBirdUserId, accessToken);
    return sendBirdInstance;
  }

  sendBird: SendBirdInstance;
  constructor(sendBird: SendBirdInstance) {
    this.sendBird = sendBird;
  }

  /**
   * Returns all group channels the current user is in containing at least one
   * of the given sendbird users.
   *
   * @param {
   *  sendBirdUserIds: The list of sendbird user ids for whom you want to find channels.
   *  limit: The max number of channels to return (default 100)
   * }
   * @returns
   */
  async findChannels({
    sendBirdUserIds,
    limit = 100,
  }: {
    sendBirdUserIds: string[];
    limit?: number;
  }) {
    const channelQuery = this.sendBird.GroupChannel.createMyGroupChannelListQuery();
    channelQuery.includeEmpty = true;
    channelQuery.limit = limit;
    channelQuery.order = "latest_last_message";
    channelQuery.userIdsIncludeFilter = sendBirdUserIds;
    channelQuery.userIdsIncludeFilterQueryType = "OR";
    return await channelQuery.next();
  }

  /**
   * Returns a new sendbird MessageCollection.  See the sendbird docs for
   * details, but this acts as a sort of "view" onto a set of messages.  You can
   * get the messages currently in that view with collection.succeededMessages,
   * .pendingMessages, and .failedMessages.  The length of the messages in the
   * view is fixed, but you can sort of "scroll" it forward and backward with
   * collection.loadPrevious and .loadNext.
   *
   * Use the onCollectionUpdate to trigger a rerender in components displaying
   * this view.
   *
   * @param {
   *  channel: the channel to create a message collection for.
   *  onCollectionUpdate: a function to be called whenever the contents of the
   *    message collection change, and therefore clients displaying this collection
   *    should update their UIs.
   * }
   * @returns
   */
  async createMessageCollection({
    channel,
    onCollectionUpdate,
  }: {
    channel: GroupChannel;
    onCollectionUpdate?: () => void;
  }) {
    var messageFilter = new this.sendBird.MessageFilter();

    // Keep track of whether we've fully set up this collection so that we don't
    // accidentally do certain things within the callbacks we configure on the
    // collection before the collection is fully set up.
    let initialized = false;

    const startingPoint = Date.now();
    const messageCollectionFetchLimit = 100;
    const messageCollection: MessageCollection = channel
      .createMessageCollection()
      .setFilter(messageFilter)
      .setStartingPoint(startingPoint)
      .setLimit(messageCollectionFetchLimit)
      .build();

    // For the most part, the logic here is "whenever anything happens, rerender
    // the canonical list of messages (as stored in
    // messageCollection.succeededMessages, .pendingMessages, and
    // .failedMessages).  That canonical list won't necessarily trigger a
    // rerender of whatever component is using this function, so whenever
    // there's a potential change in the messages, we call this same callback to
    // make sure components have the opportunity to rerender.
    const triggerCallback = () => {
      // Don't trigger the callback if we haven't finished initializing the
      // collection.  If we do, Sendbird complains.
      initialized && onCollectionUpdate && onCollectionUpdate();
    };

    messageCollection
      .initialize(
        this.sendBird.MessageCollection.MessageCollectionInitPolicy
          .CACHE_AND_REPLACE_BY_API
      )
      // We need to listen to these two events so that we can trigger rerenders
      // on first load.
      .onCacheResult(function (_err, _messages) {
        triggerCallback();
      })
      .onApiResult(function (_err, _messages) {
        triggerCallback();
      });

    // We listen to these events to tirgger rerenders on later, newer messages.
    const messageCollectionHandler = {
      onMessagesAdded: function () {
        triggerCallback();
      },
      onMessagesUpdated: function () {
        triggerCallback();
      },
      onMessagesDeleted: function () {
        triggerCallback();
      },
      onChannelUpdated: function () {
        // We don't care about this event, but it's required.
      },
      onChannelDeleted: function () {
        // We don't care about this event, but it's required.
      },
      onHugeGapDetected: function () {
        // We don't care about this event, but it's required.
      },
    };
    messageCollection.setMessageCollectionHandler(messageCollectionHandler);

    // Actually fetch the most recent messages
    await messageCollection.loadPrevious();

    initialized = true;

    return messageCollection;
  }

  /**
   * Send a message to the given channel.  Returns a promise that resolves once
   * the message is sent.  Note that the message may *not* be sent succesffully!
   * Rather, it's just in the "pending" state as far as sendbird is concerned.
   * Check the sendbird sdk docs for more info on the message lifecycle.
   *
   * @param {
   *  channel: the channel to send the message to.
   *  message: the string message to send.
   * }
   * @returns A promise resolving to the message object.
   */
  sendMessage({
    channel,
    message: newMessage,
  }: {
    channel: GroupChannel;
    message: string;
  }) {
    const params = new this.sendBird.UserMessageParams();
    params.message = newMessage;
    return new Promise<BaseMessageInstance>((resolve, reject) => {
      channel.sendUserMessage(params, (message, err) => {
        err ? reject(err) : resolve(message);
      });
    });
  }
}

export default SendBirdClient;
