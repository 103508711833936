import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { useApiClient } from "../apiClient";
import { convertSerializedType } from "./queryHelpers";

const screener_statuses = [
  "screener_not_scheduled",
  "screener_closed_recently",
  "screener_opening_soon",
  "screener_open",
] as const;
const closed_statuses = [
  "screener_not_scheduled",
  "screener_closed_recently",
  "screener_opening_soon",
] as const;
const open_statuses = ["screener_open"] as const;

export type ScheduledScreenerStatus = typeof screener_statuses[number];
export type ClosedScreenerStatus = typeof closed_statuses[number];
export type OpenScreenerStatus = typeof open_statuses[number];

export const isScreenerStatus = (
  value?: any
): value is ScheduledScreenerStatus => screener_statuses.includes(value);
export const isClosedScreenerStatus = (
  value?: any
): value is ClosedScreenerStatus => closed_statuses.includes(value);
export const isOpenScreenerStatus = (
  value?: any
): value is OpenScreenerStatus => open_statuses[0] === value;

export type UserFeature = {
  id: number;
  apiKey: string;
  name: string;
  scheduledStatus?: ScheduledScreenerStatus;
};

/**
 * @param userId user id to get user_features for (or the string "me" for the current user)
 * @param auth This endpoint supports either guest user access or a
 *             fully-logged-in user.  Specify true for logged-in access or false for guest
 *             user access.
 * @param options Options to pass on to useQuery
 * @returns useQuery response object
 */
export const useGetUserFeatures = (
  userId = "me",
  auth: boolean = false,
  options: UseQueryOptions<UserFeature[], AxiosError> = {}
) => {
  const queryKey = ["user_features", userId];
  const apiClient = useApiClient({ auth });
  return useQuery<UserFeature[], AxiosError>(
    queryKey,
    async () => {
      const response = await apiClient.get("/v1/user_features", {
        params: { user_id: userId },
      });
      return response.data.data?.map(convertSerializedType);
    },
    options
  );
};
