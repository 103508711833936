import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import { useApi } from "../../api/apiContext";
import { ApplicationFeature } from "../../api/queries/features";
import { User, UserKind } from "../../api/queries/users";
import { useHasOrganizationFeature } from "../../hooks/useHasOrganizationFeature";
import { useSelectedTeen } from "../../hooks/useSelectedTeen";
import logoTypeImage from "../../images/daybreak-logo-type.svg";
import { getDashboardNavItems } from "../../lib/navPaths";
import { truncate } from "../../lib/stringUtilities";
import Icon from "../Icon/Icon";
import {
  Bottom,
  Container,
  GearIcon,
  Item,
  ItemIconContainer,
  ItemName,
  LogoType,
  LogoutIcon,
  LogoutItem,
  Name,
  NameAndType,
  NavItems,
  SettingsLink,
  SettingsTitle,
  Top,
  UserIcon,
  UserItem,
} from "./elements";
import { reset } from "client/amplitudeHelper";

const logoPath: Record<UserKind, string> = {
  counselor: "/clinician/patients",
  parent: "/dashboard",
  organization_staff_member: "/school/home",
  teen: "/",
  psychiatrist: "/",
};
export type DesktopNavWithDataProps = {
  // Used for testing only.  The active nav item is normally determined
  // automatically by NavLink.
  forceActivePath?: string;
  me?: User;
  logout: () => void;
  viewedUser?: User;
  userManagementEnabled: boolean;
  applicationFeatures: ApplicationFeature[] | undefined;
};
export const DesktopNavWithData: React.FC<DesktopNavWithDataProps> = ({
  forceActivePath,
  me,
  logout,
  viewedUser,
  userManagementEnabled,
  applicationFeatures,
}) => {
  const history = useHistory();

  const navOptions = {
    currentUser: me,
    routeMatch: useRouteMatch(),
    viewedUser,
    applicationFeatures,
  };

  return (
    <Container>
      <Top>
        <LogoType
          src={logoTypeImage}
          alt="Daybreak logo"
          onClick={() => history.push(logoPath[me?.userKind ?? "parent"])}
        />
        <NavItems>
          {getDashboardNavItems(navOptions).map((navPath) => {
            return (
              <NavLink
                to={navPath.destinationPath(navOptions)}
                key={navPath.destinationPath(navOptions)}
                activeClassName="active"
                className={[
                  "navLink",
                  forceActivePath === navPath.destinationPath(navOptions)
                    ? "active"
                    : "",
                ].join(" ")}
              >
                <Item>
                  <ItemIconContainer>
                    <Icon name={navPath.icon} />
                  </ItemIconContainer>
                  <ItemName>{navPath.displayString}</ItemName>
                </Item>
              </NavLink>
            );
          })}
        </NavItems>
      </Top>
      <Bottom>
        {me?.userKind === "organization_staff_member" ? (
          <UserItem>
            <UserIcon>
              <Icon name="user" />
            </UserIcon>
            <NameAndType>
              <Name>{truncate(me?.fullName, 25)}</Name>
            </NameAndType>
          </UserItem>
        ) : null}
        {userManagementEnabled ? (
          <NavLink
            to="/school/settings"
            className={[
              "navLink",
              forceActivePath?.startsWith("/school/settings") ? "active" : "",
            ].join(" ")}
          >
            <SettingsLink>
              <GearIcon>
                <Icon name="gear" />
              </GearIcon>
              <SettingsTitle>Settings</SettingsTitle>
            </SettingsLink>
          </NavLink>
        ) : null}
        <LogoutItem
          onClick={() => {
            reset();
            logout();
          }}
        >
          <LogoutIcon>
            <Icon name="exit" />
          </LogoutIcon>
          Log out
        </LogoutItem>
      </Bottom>
    </Container>
  );
};

export type DesktopNavProps = {};
const DesktopNav: React.FC<DesktopNavProps> = () => {
  const api = useApi();
  const { logout } = useAuth0();
  const { data: { data: me } = {} } = api.useGetMe();
  const { teen: viewedUser } = useSelectedTeen({ redirectIfNone: false });
  const {
    data: { features: applicationFeatures } = {},
  } = api.useGetApplicationFeatures();

  const { active: userManagementEnabled } = useHasOrganizationFeature(
    "organization_manual_user_management"
  );
  return (
    <DesktopNavWithData
      {...{
        me,
        logout,
        viewedUser,
        userManagementEnabled,
        applicationFeatures,
      }}
    />
  );
};

export default DesktopNav;
