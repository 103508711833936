// TODO: Once react-table v8 is officially released, switch to using that.
import {
  ColumnDef,
  createTable,
  getCoreRowModel,
  getSortedRowModel,
  Overwrite,
  ReactTableGenerics,
  SortingState,
  Table,
  useTableInstance,
} from "@tanstack/react-table";
import React, { ReactElement, useEffect, useState } from "react";
import Icon from "../Icon/Icon";
import { CenteredLoading } from "../Loading/Loading";
import Pagination, { PaginationProps } from "../Pagination/Pagination";
import {
  Container,
  EmptyContent,
  LoadingTD,
  LoadingTR,
  PaginationContainer,
  TableElement,
  TableShadowLine,
  TableViewPort,
  TableViewPortAndShadow,
  TBody,
  TD,
  TH,
  THContent,
  THead,
  TR,
} from "./elements";
import { trackEvent } from "client/amplitudeHelper";

// ---------------------------------------------------------------------------
// This is some typescript nonsense.  We have a bunch of places throughout the
// config for TableV2 where we interact with the data provided in the
// `data` prop.  This TS nonsense lets us assert that the same data is used
// everywhere for a given data table.  If you give an array of User objects as
// your `data` prop, everywhere else it's referenced will be forced to treat it
// as an array of Users, not an array of Sessions or Strings or nulls.
//
// It may not have been worth figuring this out, but I got nerd-sniped at 10pm
// and now here we are.

// This is the type that the data row type must inherit from.  All data rows
// must at least be objects with string keys.
type DataRowParent = Record<string, any>;

// This is the normal React.FC return type, but with the DataRow generics passed
// through.
type returnType<DataRow extends DataRowParent> = ReturnType<
  React.FC<TableV2Props<DataRow>>
>;
// This is the normal React.FC props type, but with the DataRow generics passed
// through.
type propType<DataRow extends DataRowParent> = Parameters<
  React.FC<TableV2Props<DataRow>>
>[0];

// This is the same as the `React.FC`, and can (in theory) be used
// interchangeably with it, but it includes a single generic passed to the props
// and return type.  It defaults that generic to DataRowParent.  This is so that
// you don't *have* to specify the data row type, but if you use a specific data
// row type, it'll narrow the data row type to whatever you actually used
// automatically.
type CustomReactFC = <DataRow extends DataRowParent = DataRowParent>(
  props: propType<DataRow>
) => returnType<DataRow>;
// ---------------------------------------------------------------------------

/**
 * The type for the extra metadata we can attach to columns for our table beyond
 * just what react-table supports.
 */
type ColumnMetaType = {
  headerClassName?: string;
};

export type TableV2Props<DataRow extends DataRowParent = DataRowParent> = {
  updateData: ({
    sorting,
  }: {
    sorting?: SortingState;
    currentPageIndex?: number;
  }) => void;

  /**
   * An array of objects to display in this table.  This data should be sorted,
   * filtered, and paginated already.
   * */
  data: DataRow[];

  /**
   * An element to put in place of the table if no data is provided to the data
   * prop.
   */
  emptyContent?: ReactElement;

  /**
   * A ReactTable table element.  A good way to create one of these is:
   * @example
   * ```
   * const table = createTableV2<OrganizationMember>();
   * ```
   * (replacing OrganizationMember with your row type)
   */
  table: Table<
    Overwrite<ReactTableGenerics, { Row: DataRow; ColumnMeta: ColumnMetaType }>
  >;

  /**
   * An array of ReactTable columns as created by several calls to
   * `table.createDataColumn()`.  Note that we support one non-standard column
   * field: `meta.headerClassName`.  A string supplied to that field will be
   * appended to the class names on the THContent elements.
   */
  columns: ColumnDef<
    Overwrite<ReactTableGenerics, { Row: DataRow; ColumnMeta: ColumnMetaType }>
  >[];

  /**
   * An optional array of ReactTable sort elements to set the initial state of
   * this table's sorting.
   * @example
   * ```
   * initialSort={[{ id: "name", desc: true }]}
   * ```
   */
  initialSort?: SortingState;

  /**
   * The current page to show in the pagination widget.  If `undefined`, the
   * pagination widget will not display.
   */
  currentPageIndex?: number;

  /**
   * The maximum number of pages in the remote data source.  Used to limit the paging behavior
   */
  maxPageIndex?: number;

  /**
   * If you want to put this table into a temporary loading state, this binary
   * will show a spinner and a loading message.  You'll need to clear this
   * yourself: the loading state will take precidence over any data you provide
   * in the `data` field.
   */
  loading?: boolean;

  /**
   * Set if you want to hide the header row
   */
  hideHeader?: boolean;

  /**
   * Set if you'd like to override the default pagination props.  Takes in the
   * same arguments as the Pagination component.
   * */
  paginationProps?: Partial<PaginationProps>;
};

/**
 * This is a wrapper around react-table's `createTable` function.  We need to
 * set the columnMetaType on the created table and using this helper prevents
 * every user of TableV2 from having to call setColumnMetaType.
 * @returns
 */
export const createTableV2 = <RowType,>() => {
  return createTable()
    .setRowType<RowType>()
    .setColumnMetaType<ColumnMetaType>();
};

/**
 * A flexible, sortable, paginatable table component.
 *
 * This class is built on ReactTable (v8) and exposes a lot of ReactTable APIs.
 * Refer to the ReactTable documentation - it's pretty good!
 *
 * This component expects to be given an array of data that's already
 * sorted/paginated/etc.  When a user changes the sorting/pagination settings
 * (eg by clicking on a sortable header), the updateData function provided by
 * the parent component will be called.  It's up to the parent component to
 * update the data being passed to this table to reflect the new page or sort
 * order.
 *
 * @example
 * ```
 * const data = [
 *  {name: 'metallica', founded: 1981},
 *  {name: 'beatles', founded: 1960},
 *  {name: 'enya', founded: 1961},
 * const table = createTableV2<typeof data[0]>();
 * const columns = [
 *   table.createDataColumn("name", {}),
 *   table.createDataColumn("founded", {}),
 * ]
 *
 * <TableV2 data={data} table={table} columns={columns} updateData={() => {}}
 * ```
 */
const TableV2: CustomReactFC = ({
  data,
  updateData,
  table,
  columns,
  initialSort,
  emptyContent,
  currentPageIndex,
  maxPageIndex,
  loading,
  hideHeader,
  paginationProps,
}) => {
  const [sorting, setSorting] = useState<SortingState>(initialSort ?? []);
  useEffect(() => {
    updateData({ sorting });
  }, [sorting, updateData]);

  const instance = useTableInstance(table, {
    data,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
  });
  return (
    <Container>
      <TableViewPortAndShadow>
        <TableShadowLine />
        <TableViewPort>
          <TableElement className="table-element">
            {hideHeader ? null : (
              <THead>
                {instance.getHeaderGroups().map((headerGroup) => (
                  <TR key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const sort = header.column.getIsSorted();
                      return (
                        <TH key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder ? null : (
                            <THContent
                              {...{
                                className: [
                                  header.column.getCanSort() ? "sortable" : "",
                                  sort ? "sort-active" : "sort-inactive",
                                  header.column.columnDef.meta
                                    ?.headerClassName ?? "",
                                ].join(" "),
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {header.renderHeader()}
                              {header.column.getCanSort() ? (
                                <>
                                  {sort === "asc" && (
                                    <Icon name="downArrow" flip />
                                  )}
                                  {(sort === "desc" || !sort) && (
                                    <Icon name="downArrow" />
                                  )}
                                </>
                              ) : null}
                            </THContent>
                          )}
                        </TH>
                      );
                    })}
                  </TR>
                ))}
              </THead>
            )}
            <TBody skipLastBorder={hideHeader}>
              {loading ? (
                <LoadingTR>
                  <LoadingTD colSpan={100}>
                    <CenteredLoading size="medium" />
                  </LoadingTD>
                </LoadingTR>
              ) : (
                instance.getRowModel().rows.map((row) => (
                  <TR key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <TD key={cell.id}>{cell.renderCell()}</TD>
                    ))}
                  </TR>
                ))
              )}
            </TBody>
          </TableElement>
        </TableViewPort>
      </TableViewPortAndShadow>
      {data.length > 0 && currentPageIndex != null && maxPageIndex != null ? (
        <PaginationContainer>
          <Pagination
            showStepNext
            currentPageIndex={currentPageIndex}
            maxPageIndex={maxPageIndex}
            changePage={(newPageIndex) => {
              updateData({ currentPageIndex: newPageIndex });
              trackEvent("TableV2:pageChange", {
                changedTo: newPageIndex,
              });
            }}
            {...(paginationProps ?? {})}
          />
        </PaginationContainer>
      ) : null}
      {data.length === 0 ? <EmptyContent>{emptyContent}</EmptyContent> : null}
    </Container>
  );
};

export default TableV2;
