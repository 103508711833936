import { useMemo } from "react";
import { useApi } from "../api/apiContext";
import {
  FeatureApiKey,
  OrganizationFeature,
} from "../api/queries/organizationFeatures";

export const useHasOrganizationFeature = (featureApiKey: FeatureApiKey) => {
  const api = useApi();
  const { data: { data: myOrg } = {} } = api.useGetOrganization({
    apiKey: "me",
  });
  const { data: { data: orgFeatures } = {} } = api.useGetOrganizationFeatures({
    organizationApiKey: myOrg?.apiKey ?? "",
    options: { enabled: !!myOrg },
  });
  const orgApiKeys = useMemo(
    () =>
      orgFeatures?.reduce((map, orgFeature) => {
        map[orgFeature.featureApiKey] = orgFeature;
        return map;
      }, {} as Record<string, OrganizationFeature>) ?? {},
    [orgFeatures]
  );

  return {
    active: orgApiKeys[featureApiKey]?.active ?? false,
    loading: !orgFeatures,
  };
};
