import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { useApi } from "../../../api/apiContext";
import { User } from "../../../api/queries/users";
import { theme, themeColor, themeMinWidth } from "../../../app/theme";
import { useSelectedTeen } from "../../../hooks/useSelectedTeen";
import { truncate } from "../../../lib/stringUtilities";
import Icon from "../../Icon/Icon";
import Initial from "../../Initial/Initial";

// This component:
// - When there are 2 or more children
//   - Shows up on mobile + desktop
// - When there are 1 or fewer children
//   - On mobile: hidden entirely
//   - On desktop: hides the children, but keeps the top bar + profile section
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${themeColor("borderGray6")};
  margin-left: 16px;
  margin-right: 16px;
  &.onlyOneChild {
    display: none;
  }
  ${themeMinWidth("small")} {
    margin-left: 24px;
    margin-right: 0;
    &.onlyOneChild {
      display: flex;
    }
  }
`;
const ChildItems = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
`;
const ChildInitial = styled.div`
  margin-right: 5px;
`;
const ChildName = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${themeColor("darkGrayText4")};
  ${themeMinWidth("small")} {
    font-size: 18px;
  }
  .selected & {
    color: ${themeColor("teal6")};
    font-weight: 500;
  }
`;
const ChildItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  align-items: center;
  cursor: pointer;
  margin-right: 24px;
  padding: 18px 0;
  .onlyOneChild & {
    display: none;
  }
  ${themeMinWidth("small")} {
    padding: 28px 0;
  }
  &.selected {
    border-bottom: 3px solid ${themeColor("teal")};
    padding-bottom: 15px;
    ${themeMinWidth("small")} {
      padding-bottom: 25px;
    }
  }
  transition: filter ${(props) => props.theme.animations.defaultHover};
  :hover {
    filter: brightness(95%);
  }
  ${themeMinWidth("small")} {
    margin-right: 40px;
  }
`;
const UserItem = styled.div`
  padding: 24px;
  display: none;
  flex-direction: row;
  ${themeMinWidth("small")} {
    display: flex;
  }
`;
const UserIcon = styled.div`
  color: ${themeColor("teal")};
`;
const NameAndType = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;
const Name = styled.div`
  font-size: 18px;
  margin-top: -2px;
  line-height: 27px;
  white-space: nowrap;
  color: ${themeColor("darkGrayText4")};
`;
const Type = styled.div`
  margin-top: -3px;
  font-size: 10px;
  font-weight: 500;
  line-height: 14.1px;
  text-transform: capitalize;
  color: ${themeColor("black", { opacity: 0.4 })};
`;

const findFirstName = (user: User) =>
  user.firstName || (user.fullName ? user.fullName.split(" ")[0] : undefined);

// The Nth child will have the Nth color for their initial.
const initialColors = [
  theme.colors.green,
  theme.colors.teal4,
  theme.colors.red + "dd",
  theme.colors.orange5 + "dd",
  theme.colors.borderGray6,
];
export type ChildSwitcherWithDataProps = {
  childrenUsers: User[];
  selectedChildId?: string;
  me: User;
};
export const ChildSwitcherWithData: React.FC<ChildSwitcherWithDataProps> = ({
  selectedChildId,
  childrenUsers,
  me,
}) => {
  const history = useHistory();
  return (
    <Container className={childrenUsers.length <= 1 ? "onlyOneChild" : ""}>
      <ChildItems>
        {childrenUsers.map((child, i) => (
          <ChildItem
            onClick={() => history.push(`/dashboard/${child.id}`)}
            key={child.id}
            className={child.id === selectedChildId ? "selected" : ""}
          >
            <ChildInitial>
              <Initial
                size="small"
                color={
                  initialColors[i] || initialColors[initialColors.length - 1]
                }
              >
                {findFirstName(child)} ?? i
              </Initial>
            </ChildInitial>
            <ChildName>
              {truncate(findFirstName(child) ?? "teen", 20)}
            </ChildName>
          </ChildItem>
        ))}
      </ChildItems>
      <UserItem>
        <UserIcon>
          <Icon name="profile" width="32" height="32" />
        </UserIcon>
        <NameAndType>
          <Name>{truncate(me?.fullName, 25)}</Name>
          <Type>{me?.userKind}</Type>
        </NameAndType>
      </UserItem>
    </Container>
  );
};

export type ChildSwitcherProps = {};
const ChildSwitcher: React.FC<ChildSwitcherProps> = () => {
  const api = useApi();
  const {
    data: { data: users } = {},
    isLoading: isLoadingUsers,
  } = api.useGetUsers({});
  const { data: { data: me } = {}, isLoading: isLoadingMe } = api.useGetMe();
  const { teen: selectedChild } = useSelectedTeen();

  if (isLoadingUsers || isLoadingMe || !me || !users) {
    return <></>;
  }
  const childrenUsers = users.filter((user) => user.id !== me.id);

  return (
    <ChildSwitcherWithData
      {...{
        selectedChildId: selectedChild?.id,
        childrenUsers,
        me,
      }}
    />
  );
};

export default ChildSwitcher;
