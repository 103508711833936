import React, { useState } from "react";
import IdleTimer from "react-idle-timer";
import { useQueryClient } from "react-query";
import guestUserTokenStorage from "../../lib/guestUserTokenStorage";
import InactivityModal from "../Modals/InactivityModal/InactivityModal";

export type InactivityTimeoutProps = {};

// After this many milliseconds of no mouse movement, key presses, etc, we'll
// pop up a modal and log the user out.
const timeoutMs = 30 * 60 * 1000;

const InactivityTimeout: React.FC<InactivityTimeoutProps> = () => {
  const [modalShowing, setModalShowing] = useState(false);
  const queryClient = useQueryClient();
  const goIdle = () => {
    guestUserTokenStorage.clear();

    // Clear all clientside api caches when we're done with the quiz.
    queryClient.clear();

    setModalShowing(true);
  };
  return (
    <>
      {modalShowing ? <InactivityModal /> : ""}
      <IdleTimer onIdle={goIdle} timeout={timeoutMs} stopOnIdle />
    </>
  );
};

export default InactivityTimeout;
