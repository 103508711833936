import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import { themeColor } from "../../../app/theme";
import Icon from "../../Icon/Icon";
import UserItem from "../UserItem/UserItem";

const Container = styled.div<{ noMargin?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${themeColor("borderGray6")};
  margin-left: ${(props) => (props.noMargin ? 0 : "16px")};
  margin-right: ${(props) => (props.noMargin ? 0 : "16px")};
  min-height: 64px;
`;

const BackLink = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${themeColor("teal")};
  font-weight: 500;
  margin-left: 6px;
  transition: all ${(props) => props.theme.animations.defaultHover};
  cursor: pointer;
  :hover {
    opacity: 0.5;
    & .icon {
      transition: transform 0.25s ease-out;
      transform: translateX(-6px);
    }
  }
`;
export type BackNavProps = {
  backText: string;
  backTarget: string;
  includeUser?: boolean;
  noMargin?: boolean;
};

const BackNav: React.FC<BackNavProps> = ({
  backText,
  backTarget,
  includeUser = true,
  noMargin = false,
}) => {
  const history = useHistory();
  return (
    <Container noMargin={noMargin}>
      <BackLink
        onClick={() => {
          history.push(backTarget);
        }}
      >
        <Icon name="leftArrow2" /> {backText}
      </BackLink>
      {includeUser && <UserItem />}
    </Container>
  );
};

export default BackNav;
