import { trackEvent } from "client/amplitudeHelper";
import { useEffect } from "react";

declare global {
  interface Window {
    intercomDisabled?: boolean;
  }
}

// If `boot` is true, intercom will be shown on the page this hook is called on.
// If false, it will not.  If this hook is called multiple times on a page, any
// invocation with {boot: false} will take precidence and intercom will not be
// shown.
const useIntercom = ({ boot } = { boot: true }) => {
  const Intercom: any = (window as any).Intercom;

  useEffect(() => {
    const intercomId = process.env.REACT_APP_INTERCOM_ID;
    if (!intercomId) {
      if (process.env.NODE_ENV !== "test") {
        console.error("intercomId not set");
      }
      return;
    }
    if (!Intercom) {
      console.error("intercom not loaded; possibly an ad-blocker.");
      return;
    }

    // If we disable intercom once, keep track of that so we don't let
    // subsequent attempts to boot it succeed.
    if (!boot) {
      window.intercomDisabled = true;
    }

    // If `boot` is false, actively call Intercom("shutdown").  This will hide
    // the intercom widget, even if we've already booted it elsewhere on the
    // current page.
    if (boot && !window.intercomDisabled) {
      Intercom("boot", {
        app_id: intercomId,
      });
    } else {
      Intercom("shutdown", {
        app_id: intercomId,
      });
    }
  }, [Intercom, boot]);

  const show = () => {
    trackEvent("showingIntercomWidget");
    Intercom("show");
  };

  return {
    update: () => Intercom("update"),
    hide: () => Intercom("hide"),
    show,
  };
};
export default useIntercom;
