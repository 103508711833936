import React from "react";
import styled from "styled-components/macro";
import { useApi } from "../../../api/apiContext";
import { OrganizationMemberV1 } from "../../../api/queries/organizationMembers";
import { themeColor, themeMinWidth } from "../../../app/theme";
import TealButton from "../../buttons/TealButton/TealButton";
import TextField from "../../Forms/TextField/TextField";
import { CenteredLoading } from "../../Loading/Loading";
import { useHasOrganizationFeature } from "../../../hooks/useHasOrganizationFeature";
import { Organization } from "../../../api/queries/organizations";
import { orgMemberVisitOnsiteUrl } from "./VisitOnsiteUrl";

const Container = styled.div`
  padding: 24px;
  flex-grow: 1;
  ${themeMinWidth("small")} {
    min-width: 600px;
  }
`;
const LoadingContainer = styled.div`
  display: flex;
  min-height: 400px;
`;
const Header = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: black;
`;
const SectionHeader = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  color: ${themeColor("darkGrayText6")};
  font-size: 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid ${themeColor("borderGray7")};
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  gap: 16px;
`;

export type FormModeWithDataProps = {
  organizationMember: OrganizationMemberV1;
  goToSearch: () => void;
  finishModal: (organizationMemberId: string) => void;
  onsiteReferralsLinkActive: boolean;
  myStaffUserId: string;
  myOrg: Organization;
};
export const FormModeWithData: React.FC<FormModeWithDataProps> = ({
  organizationMember,
  goToSearch,
  finishModal,
  onsiteReferralsLinkActive,
  myStaffUserId,
  myOrg,
}) => {
  // Note: we're currently hardcoding these all to "Disabled" until we add
  // support for adding non-efile students.
  return (
    <Container>
      <Header>Refer a student</Header>
      <SectionHeader>Student info</SectionHeader>
      <Section>
        <TextField
          value={
            organizationMember.firstName + " " + organizationMember.lastName
          }
          label="Student Full name"
          readOnly
          disabled
        />
        <TextField
          value={organizationMember.studentEmail}
          label="Student Email address"
          readOnly
          disabled
        />
      </Section>
      <SectionHeader>School info</SectionHeader>
      <Section>
        <TextField
          value={organizationMember.schoolName}
          label="School name"
          readOnly
          disabled
        />
        <TextField
          value={organizationMember.gradeLevel}
          label="Student Grade"
          disabled
          readOnly
        />
      </Section>
      <ButtonRow>
        <TealButton onClick={goToSearch}>Back</TealButton>
        {onsiteReferralsLinkActive && (
          <TealButton
            onClick={() => {
              orgMemberVisitOnsiteUrl(
                {
                  ...organizationMember,
                },
                myOrg.apiKey,
                myStaffUserId
              );
            }}
            variant="secondary"
          >
            Onsite Referral
          </TealButton>
        )}
        <TealButton onClick={finishModal}>Continue</TealButton>
      </ButtonRow>
    </Container>
  );
};
type FormModeProps = {
  organizationMemberId: string | null;
  goToSearch: () => void;
  finishModal: (organizationMemberId: string) => void;
};
export const FormMode: React.FC<FormModeProps> = ({
  organizationMemberId,
  goToSearch,
  finishModal,
}) => {
  if (!organizationMemberId) {
    throw Error(
      "We do not yet support referring students who are not in the eligibility file."
    );
  }
  const api = useApi();
  const {
    data: { data: organizationMember } = {},
  } = api.useGetOrganizationMember({ externalId: organizationMemberId });

  const { data: { data: myOrg } = {} } = api.useGetOrganization({
    apiKey: "me",
  });

  const {
    active: onsiteReferralsLinkActive,
    loading: onsiteReferralsLinkLoading,
  } = useHasOrganizationFeature("organization_onsite_referral_form_link");

  const { data: meData } = api.useGetMe();

  if (!organizationMember || !myOrg || !meData || onsiteReferralsLinkLoading) {
    return (
      <Container>
        <LoadingContainer>
          <CenteredLoading />
        </LoadingContainer>
      </Container>
    );
  }

  return (
    <FormModeWithData
      organizationMember={organizationMember}
      goToSearch={goToSearch}
      finishModal={finishModal}
      onsiteReferralsLinkActive={onsiteReferralsLinkActive}
      myStaffUserId={meData.data.id}
      myOrg={myOrg}
    />
  );
};

export default FormMode;
