import { Field, FieldAttributes, FormikProps } from "formik";
import get from "lodash/get";
import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { FormFieldContainer, errorStyles } from "../elements";

const RadioFieldContainer = styled(FormFieldContainer)`
  flex-grow: 1;
  gap: 12px;
  ${errorStyles}
`;

const Input = styled.input`
  height: 20px;
  width: 20px;
  :not(:checked) {
    opacity: 0.2;
  }
`;
const Label = styled.label<{ disabled?: boolean }>`
  padding-left: 4px;
  cursor: ${({ disabled }) => (disabled ? "normal" : "pointer")};
`;
const Option = styled.div`
  display: flex;
`;

type RadioButtonProp = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
};

export type RadioButtonGroupProps = {
  error?: boolean;
  disabled?: boolean;
  commonRadioButtonProps: Partial<RadioButtonProp> & { name: string };
  radioButtonProps: RadioButtonProp[];
  formik?: FormikProps<any>;
  basicFormik?: boolean;
};

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  error,
  disabled,
  radioButtonProps,
  commonRadioButtonProps,
  formik,
  basicFormik = false,
}) => {
  if (formik) {
    const key = commonRadioButtonProps.name ?? "";
    return (
      <RadioButtonGroup
        error={!!(formik.errors[key] && formik.touched[key])}
        disabled={disabled || formik.isSubmitting}
        radioButtonProps={radioButtonProps.map((radioButtonProp) => ({
          ...radioButtonProp,
          checked: get(formik.values, key) === radioButtonProp.value,
        }))}
        commonRadioButtonProps={{
          ...commonRadioButtonProps,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
      />
    );
  }

  if (basicFormik) {
    return (
      <RadioFieldContainer error={error} disabled={disabled}>
        {radioButtonProps.map(({ label, ...individualButtonProps }) => {
          const props = {
            ...commonRadioButtonProps,
            ...individualButtonProps,
            disabled,
          };
          const id = props.id ?? props.name + " " + props.value?.toString();
          return (
            <Option key={id}>
              <Field type="radio" id={id} {...props} as={Input} />
              <Label htmlFor={id} disabled={disabled}>
                {label}
              </Label>
            </Option>
          );
        })}
      </RadioFieldContainer>
    );
  }

  return (
    <RadioFieldContainer error={error} disabled={disabled}>
      {radioButtonProps.map(({ label, ...individualButtonProps }) => {
        const props = {
          ...commonRadioButtonProps,
          ...individualButtonProps,
          disabled,
        };
        const id = props.id ?? props.name + " " + props.value?.toString();
        return (
          <Option key={id}>
            <Input type="radio" id={id} {...props} />
            <Label htmlFor={id} disabled={disabled}>
              {label}
            </Label>
          </Option>
        );
      })}
    </RadioFieldContainer>
  );
};

export type SimpleRadioButtonGroupProps = {
  label: string | ReactElement;
  error?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const SimpleRadioButtonGroup: React.FC<SimpleRadioButtonGroupProps> = ({
  error,
  label,
  children,
  disabled,
}) => {
  return (
    <FormFieldContainer error={error} disabled={disabled}>
      <Label>{label}</Label>
      {children}
    </FormFieldContainer>
  );
};

export type RadioButtonsProps = {
  buttons: [string, string][];
  field_name: string;
} & FieldAttributes<unknown>;

export const RadioButtons: React.FC<RadioButtonsProps> = ({
  buttons,
  field_name,
  ...props
}) => (
  <>
    {buttons.map(([value, label], idx) => (
      <Option key={`${field_name}-${value}-${idx}`}>
        <Label>
          <Field type="radio" value={value} {...props} />
          {label}
        </Label>
      </Option>
    ))}
  </>
);

export default RadioButtonGroup;
