import { AcuityConversionMessageData } from "../components/AcuityScheduler/AcuityScheduler";

// This is a generic class for storing typed data in localStorage.  Don't export
// it.  Instead, create an instance of it and export *that*.  That way, we don't
// have localstorage keys spread out throughout the codebase and it'll be easier
// to make sure all users of a given localstorage value are using the same key.
class LocalStorageManager<ValueType = any> {
  key: string;
  constructor(key: string) {
    this.key = key;
  }

  get(): ValueType | null {
    const stringValue = localStorage.getItem(this.key);
    if (!stringValue) {
      return null;
    }

    try {
      const parsedValue = JSON.parse(stringValue);

      // There's no good way to be sure that parsedValue matches ValueType
      // without a complicated vaidation layer, so let's just assume that if it
      // parsed cleanly, it's the right type.
      return parsedValue as ValueType;
    } catch (error) {
      if (error instanceof SyntaxError) {
        // If whatever's in localstorage doesn't parse as json, remove it.
        this.clear();
        return null;
      } else {
        throw error;
      }
    }
  }

  set(value: ValueType): void {
    localStorage.setItem(this.key, JSON.stringify(value));
  }

  clear(): void {
    localStorage.removeItem(this.key);
  }
}

export const scheduleDataStorage = new LocalStorageManager<AcuityConversionMessageData>(
  "onboardingScheduleData"
);

export const languagePreferenceStorage = new LocalStorageManager<string>(
  "screenerLanguagePreference"
);
(window as any).clearLanguagePreference = () =>
  languagePreferenceStorage.clear();
