import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import ScreenerLayout from "../../layouts/ScreenerLayout/ScreenerLayout";
import { reset } from "client/amplitudeHelper";

export type LogoutPageProps = {};

const LogoutPage: React.FC<LogoutPageProps> = () => {
  const { logout } = useAuth0();
  useEffect(() => {
    console.log("logging out");
    reset();
    logout();
  }, [logout]);

  return <ScreenerLayout loading />;
};

export default LogoutPage;
