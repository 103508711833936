import styled from "styled-components/macro";
import { themeColor } from "../../../../app/theme";

export const DeleteIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${themeColor("darkGrayText7")};
  transition: all ${(props) => props.theme.animations.defaultHover};
  :hover {
    color: ${themeColor("blackText")};
    cursor: pointer;
  }
  :active {
    color: black;
  }
`;
export const DeleteIconPlaceHolder = styled.div`
  width: 40px;
  height: 40px;
`;
export const NameCell = styled.div`
  display: flex;
  flex-direction: row;
  gap: 35px;
  align-items: center;
  font-weight: 500;
  color: ${themeColor("darkGrayText3")};
  margin-left: -18px;
`;
export const EmailCell = styled.div`
  color: ${themeColor("darkGrayText6")};
`;
export const ActionCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;
