import React, { createContext, useContext } from "react";
import {
  useGetSurvey,
  useGetSurveyResponse,
  useGetSurveyV2,
  usePatchSurveyResponse,
  usePostSurveyResponse,
  usePostSurveyResponseV2,
} from "./queries/surveys";
import { useGetUserFeatures } from "./queries/userFeatures";
import { usePostLoginV3 } from "./queries/login";
import { useGetPatients } from "./queries/patients";
import { useGetApplicationFeatures } from "./queries/features";
import {
  usePutMilestoneComplete,
  useGetMilestones,
  usePutMilestoneV2Complete,
} from "./queries/milestones";
import { useGetInvite } from "./queries/invites";
import { useGetUsers, useGetMe, useGetUser } from "./queries/users";
import { useGetSessions } from "./queries/sessions";
import { useGetChatToken, useGetChatNicknames } from "./queries/chats";
import {
  usePostClaimOrganizationMember,
  useGetOrganizationMembers,
  useGetOrganizationMember,
  useGetOrganizationMemberV4,
  usePostOrganizationMember,
} from "./queries/organizationMembers";
import { usePostIntakes, usePostIntakesViaReferral } from "./queries/intakes";
import { useGetMyOrg, useGetOrganization } from "./queries/organizations";
import {
  useGetOrganizationStaffMember,
  useGetMyStaff,
  useGetOrganizationStaffMembers,
  useDeleteOrganizationStaffMember,
  usePostOrganizationStaffMember,
  usePostOrganizationStaffMemberInvite,
  usePatchOrganizationStaffMember,
} from "./queries/organizationStaffMembers";
import {
  usePostReferrals,
  usePatchReferrals,
  useDeleteReferrals,
  useGetReferrals,
} from "./queries/referrals";
import { useGetOrganizationFeatures } from "./queries/organizationFeatures";
import { useGetOrganizationSites } from "./queries/organizationSites";
import {
  useGetPartialReferral,
  usePostPartialReferral,
} from "./queries/partialReferrals";
import { useGetFormByFeature } from "./queries/forms";
import { usePostAnsweredFormByFeature } from "./queries/form_responses";
import {
  useGetParentWelcomePacket,
  usePostParentWelcomePacket,
} from "./queries/parentWelcomePackets";
import { useGetSearchSelectOrganizations } from "./queries/searchSelectOrganizations";
import { usePostOrganizationMemberReferral } from "./queries/organizationMembers/referrals";
import {
  useGetWelcomePacketBySfid,
  usePatchWelcomePacketBySfid,
} from "./queries/welcomePacket";
import { usePostDirectReferral } from "./queries/directReferral";

/**
 * How to add a new api query hook:
 * https://www.notion.so/daybreakhealth/Access-api-data-with-a-query-hook-1677db549b834b50a6b568c4d15325cd
 */

// Don't use this directly; use the api context (`useApi().useGetSurvey`). That
// way we can swap this out with a dummy version of the api in tests that
// require it.
const api = {
  useGetSurvey,
  usePostSurveyResponse,
  usePatchSurveyResponse,
  usePostLoginV3,
  useGetApplicationFeatures,
  usePutMilestoneComplete,
  useGetSurveyResponse,
  useGetPatients,
  useGetInvite,
  useGetUsers,
  useGetMe,
  useGetUser,
  useGetSessions,
  useGetMilestones,
  useGetUserFeatures,
  usePostClaimOrganizationMember,
  useGetOrganization,
  useGetMyOrg,
  useGetOrganizationMembers,
  useGetOrganizationMember,
  useGetOrganizationMemberV4,
  usePostOrganizationMember,
  usePostOrganizationMemberReferral,
  useGetSurveyV2,
  usePostSurveyResponseV2,
  usePutMilestoneV2Complete,
  useGetChatToken,
  useGetChatNicknames,
  usePostReferrals,
  usePatchReferrals,
  useDeleteReferrals,
  useGetReferrals,
  usePostIntakes,
  usePostIntakesViaReferral,
  useGetOrganizationFeatures,
  useGetOrganizationStaffMembers,
  useGetOrganizationStaffMember,
  useDeleteOrganizationStaffMember,
  usePostOrganizationStaffMember,
  usePatchOrganizationStaffMember,
  usePostOrganizationStaffMemberInvite,
  useGetOrganizationSites,
  useGetMyStaff,
  usePostPartialReferral,
  useGetPartialReferral,
  useGetFormByFeature,
  usePostAnsweredFormByFeature,
  useGetParentWelcomePacket,
  useGetWelcomePacketBySfid,
  usePostParentWelcomePacket,
  usePatchWelcomePacketBySfid,
  useGetSearchSelectOrganizations,
  usePostDirectReferral,
};
type apiType = typeof api;

export const ApiContext = createContext<apiType>(api);
export const ApiProvider: React.FC = ({ children }) => (
  <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
);

// -------- API Decorator --------
// Use the API Decorator to mock out our api hooks in storybook:
//
// export const SomeStory = Template.bind({});
// SomeStory.decorators = [
//   ApiDecorator({
//     useGetSurvey: () => ({ isLoading: false, data: mockSurvey }),
//   }),
// ];
//
// Then within that story, calls to `useApi()` will return the object you passed
// to ApiDecorator instead.
type MockApiInput = { [P in keyof apiType]?: any };
export const ApiDecorator = (mockApi: MockApiInput) => (Story: any) => {
  return (
    // Cast mockApi to apiType and pretend the mockApi has all the right keys.
    // This lets you mock out just the apis you actually care about. If you
    // forget to mock one out, you'll get an error, but that's fine since this
    // decorator is only used in storybook.
    <ApiContext.Provider value={mockApi as apiType}>
      <Story />
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);
