import React from "react";
import { useDaybreakTranslation } from "../../../hooks/useDaybreakTranslation";
import { Trans } from "react-i18next";
import en from "./DirectReferralSelfCareInstruction.en.json";
import es from "./DirectReferralSelfCareInstruction.es.json";
import TextLink from "components/TextLink/TextLink";
import {
  ContentContainer,
  MainContent,
  Title,
  ContentBox,
  Heading,
  Description,
} from "./elements";
import DirectReferralHeader from "../DirectReferralHeader/DirectReferralHeader";

const DirectSelfCareInstruction: React.FC = () => {
  const { t } = useDaybreakTranslation("selfCareInstructions", { en, es });
  return (
    <>
      <DirectReferralHeader />
      <ContentContainer>
        <MainContent>
          <Title>{t("selfCareInstructions.title")}</Title>
          <ContentBox>
            <Heading>{t("selfCareInstructions.heading")}</Heading>
            <Description>
              <Trans
                components={{
                  p: <p />,
                  b: <b />,
                  i: <i />,
                  textlink: (
                    <TextLink href="https://www.daybreakhealth.com/faq" />
                  ),
                }}
              >
                {t("selfCareInstructions.description")}
              </Trans>
            </Description>
          </ContentBox>
        </MainContent>
      </ContentContainer>
    </>
  );
};

export default DirectSelfCareInstruction;
