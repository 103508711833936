import { createUseApiMutation } from "./queryHelpers";

export type PostDirectReferralVariables = {
  schoolName: string;
  patientIsSelf: string;
  patientFirstName: string;
  patientLastName: string;
  patientDOB: Date | null;
  patientBirthGender: string;
  patientSchoolEmail: string;
  patientPersonalEmail: string;
  patientStudentId: string;
  patientPhoneNum: string;
  patientLanguagePreference: string;
  parentLanguagePreference: string;
  parentFirstName: string;
  parentLastName: string;
  parentPhoneNum: string;
  parentEmail: string;
  relationshipToPatient: string;
  parentContactConsent: boolean;
  organizationSlug: string;
};

export type PostDirectReferralResponse = {
  url: string;
};

export const usePostDirectReferral = createUseApiMutation<
  PostDirectReferralResponse,
  PostDirectReferralVariables
>({
  requestBuilder: (mutationFunctionArguments) => ({
    method: "post",
    url: "/direct_referral/create",
    data: {
      ...mutationFunctionArguments,
    },
  }),
  auth: false,
  defaultMutationOptions: {
    retry: false,
    onSuccess: (_variables, _context) => {},
  },
});
