import {
  OrganizationMember,
  OrganizationMemberV1,
} from "../../api/queries/organizationMembers";
import { Referral } from "../../api/queries/referrals";

export type JourneyStatus =
  | Referral["referralJourneyStatus"]
  | OrganizationMember["clientJourneyStatus"];

// Gets the current journey status to display for the current org member (using
// either the org member's own status or the org member's referral's status as
// appropriate)
const getJourneyStatus = (orgMember: OrganizationMemberV1) =>
  orgMember.clientJourneyStatus === "Client_Referral"
    ? orgMember.referral?.referralJourneyStatus
    : orgMember.clientJourneyStatus;

// Gets a displayable string for any given journey status
const journeyStatusDisplayString = (journeyStatus: JourneyStatus) =>
  journeyStatus.replaceAll("_", " ");
const OrganizationMembers = {
  getJourneyStatus,
  journeyStatusDisplayString,
};
export default OrganizationMembers;
