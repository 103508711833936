export const supportEmail = "carecoordinator@daybreakhealth.com";
export const schoolSupportEmail = "schoolsupport@daybreakhealth.com";
export const careCoordinatorEmail = "carecoordinator@daybreakhealth.com";

export const languages = {
  english: "English",
  american_sign_language: "American Sign Language",
  armenian: "Armenian",
  farsi: "Farsi",
  korean: "Korean",
  mandarin: "Mandarin",
  spanish: "Spanish",
  tagalog: "Tagalog",
  vietnamese: "Vietnamese",
  cantonese: "Cantonese",
} as const;

export const languageOptions = {
  ...languages,
  prefer_not_to_answer: "Prefer not to answer",
  other: "Other (please specify)",
};
