import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import Header from "../../components/Header/Header";
import { themeColor, themeMinWidth } from "../../app/theme";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";
import SimpleHeader from "../../components/SimpleHeader/SimpleHeader";

const HeaderContainer = styled.div`
  display: flex;
`;
const LayoutContainer = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background-color: white;
  ${themeMinWidth("small")} {
    background-colior: ${themeColor("backgroundGray")};
  }
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const CenteredContent = styled.div`
  min-height: 500px;
  min-width: 200px;
  width: 100%;
  ${themeMinWidth("small")} {
    width: auto;
  }
`;
const GlobalStyle = createGlobalStyle`
  html, body, #root, .app {
    height: 100%;
  }
`;
const ContentBox = styled.div`
  background-color: white;
  ${themeMinWidth("small")} {
    margin-top: 43px;
    border-radius: 16px;
    border: 1px solid #e4e4e4;
    margin-bottom: 16px;
    overflow: hidden;
  }
`;

// Animates the contents by sliding in from the right when mounting and sliding
// out to the left when unmounting.
//
// If `window.goingBack` is set, the animation is reversed and goingBack is
// cleared. Note that this is a big pile of hacks and is probably a bit brittle.
// That said, the hacks are mostly contained within this component and the
// failure mode is "all screen transitions slide the same way", so it's not too
// bad. If it causes problems, delete the .goingBack stuff.
declare global {
  interface Window {
    goingBack?: true;
  }
}
const AnimatedTransition: React.FC = ({ children }) => {
  const location = useLocation();
  const animationDirection = window.goingBack ? -1 : 1;
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <motion.div
        variants={{
          initial: () => {
            delete window.goingBack;
            return { x: animationDirection * 50, opacity: 0 };
          },
          animate: { x: 0, opacity: 1 },
          exit: () => {
            const innerAnnimationDirection = window.goingBack ? -1 : 1;
            return { x: innerAnnimationDirection * -50, opacity: 0 };
          },
        }}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ type: "spring", duration: 0.3, bounce: 0 }}
        key={location.pathname}
        aria-live="polite"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

const headerStyles = {
  onboarding: Header,
  simple: SimpleHeader,
  none: undefined,
};

export type CenteredLayoutProps = {
  headerStyle: keyof typeof headerStyles;
};
const CenteredLayout: React.FC<CenteredLayoutProps> = ({
  children,
  headerStyle,
}) => {
  const HeaderComponent = headerStyles[headerStyle];
  return (
    <LayoutContainer>
      <GlobalStyle />
      {HeaderComponent && (
        <HeaderContainer>
          <HeaderComponent />
        </HeaderContainer>
      )}
      <ContentContainer>
        <CenteredContent>
          <AnimatedTransition>
            <ContentBox>{children}</ContentBox>
          </AnimatedTransition>
        </CenteredContent>
      </ContentContainer>
    </LayoutContainer>
  );
};

export default CenteredLayout;
