import React, { ReactElement, useState } from "react";
import styled, { css } from "styled-components";
import {
  PresignedUrl,
  buildUploadsResource,
} from "../../../api/queries/uploads";
import { Field, useFormikContext } from "formik";
import Icon from "../../Icon/Icon";
import axios from "axios";
import { disabledStyles, errorStyles } from "../elements";
import { useApiClient } from "../../../api/apiClient";

// https://uploadcare.com/blog/how-to-upload-file-in-react/

const InputArea = styled.label<{ disabled?: boolean; error?: boolean }>`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px dashed;
  background: var(--Dusk-95, #ebf2f5);
  gap: 15px;
  ${errorStyles}
  ${disabledStyles}
  ${(props) => {
    if (props.error) {
      return css`
        cursor: pointer;
      `;
    } else if (props.disabled) {
      return css`
        opacity: 0.5;
        cursor: default;
      `;
    } else {
      return css`
        cursor: pointer;
      `;
    }
  }}
`;

const Label = styled.div`
  text-align: center;
`;

const Preview = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const RemoveButton = styled.button<{ disabled?: boolean; error?: boolean }>`
  display: flex;
  height: 40px;
  padding: 20px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid;
  background-color: transparent;
  ${errorStyles}
  ${disabledStyles}
  ${(props) => {
    if (!props.error) {
      return css`
        color: var(--Day-teal, #0a6e82);
        border-color: var(--Day-teal, #0a6e82);
      `;
    }
  }}
`;

type FileInputProps = {
  name: string;
  label: string | ReactElement;
  error?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: React.MouseEventHandler;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onClick">;

/**
 * This is the lowest-level component for uploading files, and not recommended for direct use.
 * It provides the actual HTML elements that make up the UI. It does not provide any server interactions.
 *
 * @param name Standard form element name
 * @param label Standard label for the input
 * @param onChange Standard onChange callback for the input (called when a file is selected)
 * @params inputProps Standard React/HTML input element props (disabled, etc)
 */
const FileInput = ({
  name,
  label,
  onChange,
  onClick,
  ...inputProps
}: FileInputProps): JSX.Element => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
    e.target.files && e.target.files[0] && setFile(e.target.files[0]);
  };

  const handleRemove = (e: React.MouseEvent) => {
    e.type = "remove";
    e.stopPropagation();

    onClick && onClick(e);

    const parent = e.currentTarget.closest("label");
    const input = parent?.querySelector("input");
    if (input) {
      input.value = "";
      setFile(null);
    }
  };

  return (
    <>
      <InputArea
        error={inputProps.error}
        disabled={inputProps.disabled}
        onClick={file ? () => {} : onClick}
      >
        {!file && <Icon name="add" />}
        <Label>{label}</Label>
        <input
          id={name}
          name={name}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept="image/jpeg"
          {...inputProps}
        />
        {file && (
          <>
            <Preview src={URL.createObjectURL(file)} alt={file.name} />
            <RemoveButton onClick={handleRemove}>Remove</RemoveButton>
          </>
        )}
      </InputArea>
    </>
  );
};

type UploadInputProps = {
  onSuccess?: (upload: PresignedUrl) => void;
} & FileInputProps;

/**
 * This is the "standard" component for uploading files, and is recommended for usage outside of Formik.
 * It wraps the underlying FileInput component with bits to manage fetching the presigned URL from Rails,
 * and for uploading the file to the presigned URL.
 *
 * @param name Standard form element name. The S3 location will be a combination of this and a UUID.
 * @param label Standard label for the input
 * @param onChange Standard onChange callback for the input (called when a file is selected)
 * @params inputProps Standard React/HTML input element props (disabled, etc)
 */
const UploadInput = ({
  name,
  label,
  onChange,
  onSuccess,
  ...inputProps
}: UploadInputProps): JSX.Element => {
  const apiClient = useApiClient({ auth: false });
  const { init, create } = buildUploadsResource(apiClient);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);

    const file = e.target.files?.[0];
    if (file) {
      const response = await init({
        params: { filename: `${name}_${file.name}` },
      });
      await axios.put(response.data.url, file);
      await create({
        key: response.data.key,
        filename: file.name,
        url: response.data.url,
      });
      onSuccess &&
        onSuccess({
          key: response.data.key,
          filename: file.name,
          url: response.data.url,
        });
    }
  };

  return (
    <>
      <FileInput
        name={name}
        label={label}
        onChange={handleFileChange}
        {...inputProps}
      />
    </>
  );
};

export type UploadFieldProps = UploadInputProps;

/**
 * This is the Formik layer for these components; it wraps the UploadInput component so that it can be easily used
 * as part of a Formik form. The uploaded file's key will be stored in a hidden Formik input field, so that when the
 * Formik form is submitted, that key is the value for the named attribute.
 *
 * Note that (helpfully) other aspects of uploading the file (such as the file itself) will *not* show up in the Formik data
 * @param name Standard Formik field name
 * @param label Standard label for the input
 * @param onChange Standard onChange callback for the input (called when a file is selected)
 * @param onSuccess "Resource" callback for when the file is successfully uploaded
 * @param inputProps Standard React/HTML input element props (disabled, etc)
 */
const UploadField = ({
  name,
  label,
  onChange,
  onSuccess,
  ...inputProps
}: UploadInputProps): JSX.Element => {
  const { setFieldTouched, setFieldValue } = useFormikContext();

  return (
    <>
      <UploadInput
        name={name}
        label={label}
        onChange={(e) => {
          setFieldTouched(name, true);
          onChange && onChange(e);
        }}
        onSuccess={(upload) => {
          setFieldTouched(name, true);
          setFieldValue(name, upload.key);
          onSuccess && onSuccess(upload);
        }}
        {...inputProps}
      />
      <Field id={name} type="hidden" />
    </>
  );
};

export default UploadField;
