import React, { useEffect } from "react";
import { useApi } from "../../../api/apiContext";
import {
  Counselor,
  Session,
  SessionStatus,
} from "../../../api/queries/sessions";
import StatCard from "../../../components/Cards/StatCard/StatCard";
import DashboardTable, {
  TableDataConfig,
} from "../../../components/DashboardTable/DashboardTable";
import Icon from "../../../components/Icon/Icon";
import TealButton from "../../../components/buttons/TealButton/TealButton";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import Sessions from "../../../lib/models/sessions";
import {
  ChargedText,
  Container,
  EmptySessionsImage,
  EmptySessionsSection,
  Header,
  HeaderText,
  StatsRow,
  TableHeader,
  TableSection,
  UtilizedTooltipContainer,
  YearString,
} from "./elements";
import clipboardImage from "../../../images/clipboard.svg";
import AppointmentBadge from "../../../components/AppointmentBadge/AppointmentBadge";
import UtilizedTooltip from "../../../components/Tooltip/UtilizedTooltip/UtilizedTooltip";
import { User } from "../../../api/queries/users";
import { useSelectedTeen } from "../../../hooks/useSelectedTeen";
import { careCoordinatorEmail } from "../../../constants";
import { trackPage } from "client/amplitudeHelper";

export type AppointmentsPageWithDataProps = {
  teen: User;
  me: User;
  upcomingSessions: Session[];
  recentSessions: Session[];
  sessionStats: {
    charged: number;
    upcoming: number;
    attended: number;
    missed: number;
  };
};

const appointmentsTableConfig: TableDataConfig<Session> = {
  startTime: {
    headerDisplay: "Date and Time",
    renderCell: (time: number) => {
      // Formats an integer number of seconds since the epoch to a string like:
      // "Sep 4, 3:52 PM"
      const timeString = new Date(time * 1000).toLocaleTimeString(undefined, {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
      const yearString = new Date(time * 1000).getFullYear();
      return (
        <>
          {timeString} <YearString>({yearString})</YearString>
        </>
      );
    },
  },
  types: {
    headerDisplay: "Session Type",
    renderCell: (types: string[]) => {
      return (
        <div
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {Sessions.mapType(types)}
        </div>
      );
    },
  },
  counselors: {
    headerDisplay: "Counselor",
    renderCell: (counselor: Counselor[]) => <>{counselor[0]?.fullName}</>,
  },
  status: {
    headerDisplay: "Status",
    renderCell: (status: SessionStatus) => (
      <div style={{ textTransform: "capitalize", display: "flex" }}>
        <AppointmentBadge status={status} />
      </div>
    ),
  },
  billing: {
    headerDisplay: "Billing",
    renderCell: (_, session) => {
      return Sessions.isCharged(session) ? <>Utilized</> : null;
    },
  },
};

export const AppointmentsPageWithData: React.FC<AppointmentsPageWithDataProps> = ({
  upcomingSessions,
  recentSessions,
  sessionStats,
  teen,
  me,
}) => {
  const counselorEmail = teen.counselorEmail || careCoordinatorEmail;
  const mailto = `mailto:${counselorEmail}?subject=Scheduling an appointment`;
  const isCounselor = me.userKind === "counselor";
  const isParent = me.userKind === "parent";

  // Sort both lists "newest first"
  recentSessions = [...recentSessions].sort(
    (a, b) => b.startTime - a.startTime
  );
  upcomingSessions = [...upcomingSessions].sort(
    (a, b) => b.startTime - a.startTime
  );
  return (
    <DashboardLayout
      topNav={{
        type: isCounselor ? "counselorTopNav" : "childSelectorTopNav",
      }}
      showPatientName={isCounselor}
    >
      <Container>
        <Header>
          <HeaderText>Appointments</HeaderText>
          {!isParent ? null : (
            <TealButton
              onClick={() => (window.location.href = mailto)}
              className="new-appointment-button"
            >
              <Icon name="addCircle"></Icon>New appointment
            </TealButton>
          )}
        </Header>
        <StatsRow>
          <StatCard icon="bill" stat={sessionStats.charged.toString()}>
            <ChargedText>
              <div>Utilized</div>
              <UtilizedTooltipContainer>
                <UtilizedTooltip />
              </UtilizedTooltipContainer>
            </ChargedText>
          </StatCard>
          <StatCard icon="clock2" stat={sessionStats.upcoming.toString()}>
            Upcoming
          </StatCard>
          <StatCard icon="checkCircle" stat={sessionStats.attended.toString()}>
            Attended
          </StatCard>
          <StatCard icon="userDelete" stat={sessionStats.missed.toString()}>
            Missed
          </StatCard>
        </StatsRow>
        <TableSection>
          <TableHeader>Upcoming</TableHeader>
          <DashboardTable
            config={appointmentsTableConfig}
            mobileFields={{
              boxHeaderFields: ["startTime", "status"],
              boxIcon: (_rowData: Session) => {
                return "spiral";
              },
              titleField: "types",
              bodyFields: ["counselors"],
            }}
            data={upcomingSessions}
            emptyContent={
              <EmptySessionsSection>
                <EmptySessionsImage src={clipboardImage} />
                No upcoming sessions
                {!isParent ? null : (
                  <TealButton onClick={() => (window.location.href = mailto)}>
                    Schedule a session
                  </TealButton>
                )}
              </EmptySessionsSection>
            }
          />
        </TableSection>
        <TableSection>
          <TableHeader>Recent</TableHeader>
          <DashboardTable
            config={appointmentsTableConfig}
            data={recentSessions}
            mobileFields={{
              boxHeaderFields: ["startTime", "status"],
              boxIcon: (_rowData: Session) => {
                return "spiral";
              },
              titleField: "types",
              bodyFields: ["counselors", "billing"],
            }}
            emptyContent={
              <EmptySessionsSection>
                <EmptySessionsImage src={clipboardImage} />
                No recent sessions
              </EmptySessionsSection>
            }
          />
        </TableSection>
      </Container>
    </DashboardLayout>
  );
};

export type AppointmentsPageProps = {};

const AppointmentsPage: React.FC<AppointmentsPageProps> = () => {
  useEffect(() => {
    trackPage("dashboardAppointments");
  }, []);

  const api = useApi();
  const { teen } = useSelectedTeen();
  const { data: { data: me } = {}, isLoading: isLoadingMe } = api.useGetMe();

  const {
    data: { data: sessions } = {},
    isLoading: isLoadingSessions,
  } = api.useGetSessions({ userId: teen?.id });

  if (!teen || !sessions || isLoadingSessions || isLoadingMe || !me) {
    return <DashboardLayout loading />;
  }
  const { recent, upcoming } = Sessions.partitionByUpcoming(sessions);

  return (
    <AppointmentsPageWithData
      me={me}
      teen={teen}
      upcomingSessions={upcoming}
      recentSessions={recent}
      sessionStats={Sessions.calculateStats(sessions)}
    />
  );
};

export default AppointmentsPage;
