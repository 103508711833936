import { LatestReferralStatuses } from "../../api/queries/organizationMembers";
import { Referral } from "../../api/queries/referrals";
import { Bugsnag } from "../../app/ErrorReporting";

// 0: no status (all steps incomplete)
// 1: "Intake Scheduled"
// 2: "Intake Complete"
// 3: "Match in Progress"
// 4: "Match Completed"
// 5: "Care in Progress"
// 6: "Discharge"
const statusToStepMap: Record<LatestReferralStatuses, number> = {
  referred: 0,
  scheduling: 0,
  cancelled: 0,
  abandoned: 0,
  scheduled: 1,
  rescheduled: 1,
  completed: 2,
  no_show: 2,
  matched: 4,
  in_progress: 3,
  accepted_for_services: 5,
  discharged: 6,
};
const statusToDisplayName: Record<LatestReferralStatuses, string> = {
  referred: "Referred",
  scheduling: "Scheduling",
  cancelled: "Cancelled",
  abandoned: "Abandoned",
  scheduled: "Intake Scheduled",
  rescheduled: "Intake Scheduled",
  completed: "Intake Completed",
  no_show: "Missed Appointment",
  matched: "Match Completed",
  in_progress: "Match in Progress",
  accepted_for_services: "Care in Progress",
  discharged: "Discharged",
};
const referralJourneyStatusToDisplayName = (
  journeyStatus: Referral["referralJourneyStatus"]
): string => journeyStatus.replaceAll("_", " ");

/**
 * This gives you the url for a staff to enter insurance information for a given
 * student.  It includes redirect-back-to-ssd params, so don't use it for
 * anything parent-facing.
 * @param param0
 */
const staffInsuranceFormUrl = ({
  insuranceRequestUrl,
  organizationMemberId,
}: {
  insuranceRequestUrl: string | null;
  organizationMemberId: string;
}) => {
  try {
    if (insuranceRequestUrl === null) {
      return null;
    }
    // NOTE: the url here needs to be a full url (scheme and
    // everything).  `foobar.com` will not cut it.
    const insuranceUrl = new URL(insuranceRequestUrl);
    const params = new URLSearchParams(insuranceUrl.search);
    params.set("source", "SSD");

    // Note: we're passing the org id through the referralId param.  We
    // should get SF to change the param name to organizationMemberId.
    params.set("referralId", organizationMemberId || "");
    params.set(
      "returnTo",
      window.location.origin + "/school/referrals/returnFromInsurance"
    );
    insuranceUrl.search = params.toString();
    return insuranceUrl.toString();
  } catch (error) {
    // Handle parsing errors if a bad url was passed in.  Usual cause: you gave
    // `new URL()` a relative URL like `/foo/bar` instead of an absolute one
    // like `http://example.com/foo/bar`.
    if (
      error instanceof TypeError &&
      error.message === "Failed to construct 'URL': Invalid URL"
    ) {
      console.error("Failed to parse url:", insuranceRequestUrl);
      Bugsnag.notify(error);
      return null;
    } else {
      // Don't swallow other errors.
      throw error;
    }
  }
};

// This generates the contents of a mailto link aimed at staff members emailing
// parents to encourage them to fill out the referral scheduling form.
const parentIntakeFormMailto = ({
  parentEmail,
  parentFirstName,
  teenName,
  parentFormUrl,
}: {
  parentEmail: string;
  parentFirstName?: string;
  teenName: string;
  parentFormUrl: string;
}) => {
  const subject = "Intake Scheduling Needed";

  // No, we can't make this an html email with embedded <a> element around the
  // insurance form url.  Mailto links only support plaintext emails.  :(
  const body = `
Hello${parentFirstName ? " " + parentFirstName : ""},
In order for ${teenName} to start teletherapy, we need to schedule an intake appointment with them.  So, please use the following URL to set that up.  It shouldn’t take more than 5 minutes and will allow ${teenName} to get started.

${encodeURIComponent(parentFormUrl)}

Thank you for your help,

  `
    .trim()
    .replaceAll("\n", "%0D%0A");

  return `mailto:${parentEmail}?subject=${subject}&body=${body}`;
};

// This generates the contents of a mailto link aimed at staff members emailing
// parents to encourage them to fill out the insurance information form.
const parentInsuranceFormMailto = ({
  parentEmail,
  parentFirstName,
  teenName,
  insuranceFormUrl,
}: {
  parentEmail: string;
  parentFirstName?: string;
  teenName: string;
  insuranceFormUrl: string;
}) => {
  const subject = "Insurance Information Needed";

  // No, we can't make this an html email with embedded <a> element around the
  // insurance form url.  Mailto links only support plaintext emails.  :(
  const body = `
Hello${parentFirstName ? " " + parentFirstName : ""},

In order for ${teenName} to start teletherapy, we need to get your health insurance information. So, please grab your insurance card and enter your information at the following URL. It shouldn’t take more than 5 minutes and will allow ${teenName} to get started.

${encodeURIComponent(insuranceFormUrl)}

Thank you for your help,

  `
    .trim()
    .replaceAll("\n", "%0D%0A");

  return `mailto:${parentEmail}?subject=${subject}&body=${body}`;
};

const Referrals = {
  statusToStepMap,
  statusToDisplayName,
  staffInsuranceFormUrl,
  parentInsuranceFormMailto,
  parentIntakeFormMailto,
  referralJourneyStatusToDisplayName,
};
export default Referrals;
