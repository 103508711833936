import React from "react";
import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";
import connectionImage from "../../../images/connection.png";
import computerImage from "../../../images/computer.png";
import { useHistory } from "react-router-dom";
import Icon from "../../Icon/Icon";

export type ChoiceModeProps = {
  goToSearch: () => void;
  goToAdd: () => void;
  directReferDisabled: boolean;
};

const Container = styled.div`
  padding: 24px 24px 44px 24px;
`;
const Header = styled.div`
  color: black;
  font-weight: 600;
  font-size: 20px;
`;
const Buttons = styled.div`
  display: flex;
  margin-top: 27px;
  flex-direction: column;
  gap: 24px;
  ${themeMinWidth("small")} {
    flex-direction: row;
    gap: 17px;
  }
`;
const Button = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 24px 28px 24px;
  border: 1px solid ${themeColor("borderGray6")};
  border-radius: 12px;
  transition: all ${(props) => props.theme.animations.defaultHover};
  cursor: pointer;
  background-color: white;
  :hover {
    filter: brightness(95%);
  }
  :active {
    filter: brightness(85%);
  }
`;
const WideButton = styled(Button)`
  padding: 12px 28px;
  margin-top: 24px;
  ${themeMinWidth("small")} {
    margin-top: 17px;
  }
`;
const ButtonHeader = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 600;
  > svg {
    display: inline-block;
    vertical-align: bottom;
    color: ${themeColor("teal6")};
  }
`;
const ButtonText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${themeColor("darkGrayText7")};
  max-width: 230px;
  margin-top: 8px;
`;
const IKnowWhoImg = styled.img`
  margin-top: 16px;
  margin-bottom: -7px;
  width: 171px;
  height: 117px;
`;
const TryingToFigureImg = styled.img`
  width: 156px;
  height: 156px;
  margin-top: -12px;
  margin-bottom: -18px;
`;
const ChoiceMode: React.FC<ChoiceModeProps> = ({
  goToSearch,
  goToAdd,
  directReferDisabled,
}) => {
  const history = useHistory();
  return (
    <Container>
      <Header>Refer a student</Header>
      <Buttons>
        <Button onClick={goToSearch}>
          <IKnowWhoImg src={connectionImage} />
          <ButtonHeader>I know who I want to refer</ButtonHeader>
          <ButtonText>
            Find a student you looking for or add a new student
          </ButtonText>
        </Button>
        <Button onClick={() => history.push("/school/students")}>
          <TryingToFigureImg src={computerImage} />
          <ButtonHeader>I’m trying to figure out who to refer</ButtonHeader>
          <ButtonText>
            Browse a students list to find someone to refer
          </ButtonText>
        </Button>
      </Buttons>
      {!directReferDisabled && (
        <WideButton onClick={goToAdd}>
          <ButtonHeader>
            <Icon name={"addCircle"} /> Create and refer a student record not
            (yet) in Daybreak
          </ButtonHeader>
        </WideButton>
      )}
    </Container>
  );
};

export default ChoiceMode;
