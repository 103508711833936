import React, { useEffect, useState } from "react";
import Icon from "../../Icon/Icon";
import { Overlay, ModalContainer, ModalBox, Close } from "./elements";

export type BaseModalProps = {
  closeModal?: () => void;
  onClose?: () => void;
  onOpen?: () => void;
};

const BaseModal: React.FC<BaseModalProps> = ({ closeModal, children }) => {
  useEffect(() => {
    const onKeydown = (event: KeyboardEvent) => {
      if (event.code === "Escape") {
        closeModal && closeModal();
      }
    };
    document.addEventListener("keydown", onKeydown, false);

    return () => {
      document.removeEventListener("keydown", onKeydown, false);
    };
  }, [closeModal]);

  return (
    <>
      <Overlay onClick={closeModal ? closeModal : () => {}} />
      <ModalContainer>
        <ModalBox>
          {closeModal ? (
            <Close onClick={closeModal}>
              <Icon name="close" />
            </Close>
          ) : null}
          {children}
        </ModalBox>
      </ModalContainer>
    </>
  );
};

export const useModal = ({
  onClose,
  onOpen,
}: {
  onClose?: () => void;
  onOpen?: () => void;
} = {}) => {
  const [modalShowing, setModalShowing] = useState<boolean>(false);

  const modal = {
    modalShowing,
    openModal: () => {
      onOpen && onOpen();
      setModalShowing(true);
    },
    closeModal: () => {
      onClose && onClose();
      setModalShowing(false);
    },
    renderModal: (element: JSX.Element) => (modalShowing ? element : <></>),
  };

  const asModal = (element: JSX.Element) =>
    modal.renderModal(
      <BaseModal closeModal={modal.closeModal}>{element}</BaseModal>
    );

  return {
    ...modal,
    asModal: asModal,
  };
};

export default BaseModal;
