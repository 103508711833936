import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { Session } from "../../../api/queries/sessions";
import { themeColor, themeMinWidth } from "../../../app/theme";
import AppointmentBadge from "../../../components/AppointmentBadge/AppointmentBadge";
import CardBox from "../../../components/Cards/CardBox/CardBox";
import Icon, { ValidIconNames } from "../../../components/Icon/Icon";
import Sessions from "../../../lib/models/sessions";

const HeaderContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  ${themeMinWidth("small")} {
    padding: 16px 24px;
  }
`;
const HeaderTitle = styled.div`
  font-size: 14px;
  color: ${themeColor("darkGrayText7")};
  font-weight: 500;
  ${themeMinWidth("small")} {
    font-size: 16px;
  }
`;
const Body = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px 16px;
  ${themeMinWidth("small")} {
    padding: 24px;
  }
`;
const IconBox = styled.div`
  background-color: ${themeColor("orange5", { opacity: 0.1 })};
  width: 50px;
  height: 50px;
  color: ${themeColor("orange5")};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  .icon {
    width: 30px;
    height: 30px;
  }
  ${themeMinWidth("small")} {
    width: 116px;
    height: 116px;
    border-radius: 16px;
    .icon {
      width: 64px;
      height: 64px;
    }
  }
`;
const Details = styled.div`
  margin-left: 24px;
`;
const AppointmentTitle = styled.div`
  font-weight: 500;
  ${themeMinWidth("small")} {
    font-size: 18px;
  }
`;
const AppointmentTimeLine = styled.div`
  color: ${themeColor("darkGrayText7")};
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-top: 16px;
  .icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  ${themeMinWidth("small")} {
    font-size: 16px;
    .icon {
      width: 24px;
      height: 24px;
    }
  }
`;
const AppointmentTime = styled.div``;
const PaidStatusLine = styled.div`
  color: ${themeColor("darkGrayText7")};
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-top: 8px;
  .icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  ${themeMinWidth("small")} {
    font-size: 16px;
    margin-top: 16px;
    .icon {
      width: 24px;
      height: 24px;
    }
  }
`;
const PaidStatus = styled.div`
  margin-left: 8px;
  color: ${themeColor("green")};
`;

const EmptyBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;
// Show a decent icon for the given session type, then default to the spiral
// icon.
const sessionIcons: [RegExp, ValidIconNames][] = [
  [/Psychotherapy/i, "spiral"],
  [/Psychiatry/i, "spiral"],
  [/Behavioral/i, "hat"],
  [/Family/i, "family"],
  [/.*/i, "spiral"],
];
export type AppointmentBoxProps = {
  title: string;
  session?: Session;
  empty: ReactElement;
  showPaidStatus: boolean;
};
const AppointmentBox: React.FC<AppointmentBoxProps> = ({
  title,
  session,
  empty,
  showPaidStatus,
}) => {
  const [_, icon] = sessionIcons.find(([pattern, _]) =>
    pattern.exec(session?.types[0] || "")
  ) ?? [null, "spiral"];

  const startTimeString = session
    ? new Date(session?.startTime * 1000).toLocaleTimeString(undefined, {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      })
    : "";

  return (
    <CardBox
      header={
        <HeaderContent>
          <HeaderTitle>{title}</HeaderTitle>
          {session?.status && session?.status !== "upcoming" && (
            <AppointmentBadge status={session?.status} />
          )}
        </HeaderContent>
      }
    >
      {session ? (
        <Body>
          <IconBox>
            <Icon name={icon} />
          </IconBox>
          <Details>
            <AppointmentTitle>
              {Sessions.mapType(session.types)}
            </AppointmentTitle>
            <AppointmentTimeLine>
              <Icon name="clock2" />
              <AppointmentTime>{startTimeString}</AppointmentTime>
            </AppointmentTimeLine>
            {showPaidStatus && Sessions.isCharged(session) && (
              <PaidStatusLine>
                <Icon name="creditCard" />
                Paid status: <PaidStatus>Utilized</PaidStatus>
              </PaidStatusLine>
            )}
          </Details>
        </Body>
      ) : (
        <EmptyBody>{empty}</EmptyBody>
      )}
    </CardBox>
  );
};

export default AppointmentBox;
