import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { themeColor } from "../../../app/theme";

export type CardBoxProps = { header?: ReactElement };

const Container = styled.div`
  border: 1px solid ${themeColor("borderGray6")};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const Header = styled.div`
  border-bottom: 1px solid ${themeColor("borderGray6")};
`;
const CardBox: React.FC<CardBoxProps> = ({ children, header }) => {
  return (
    <Container>
      {header && <Header>{header}</Header>}
      {children}
    </Container>
  );
};

export default CardBox;
