import styled from "styled-components/macro";
import { hexOpacity, themeColor, themeMinWidth } from "../../../app/theme";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
export const MessageList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f4;
  flex-grow: 1;

  // The pixel value here has to be manually set to the total of "all the other
  // stuff that takes up vertical space above and below this element."  It kinda
  // sucks, but I don't know a better way to make this element take up all
  // remaining vertical space.
  height: calc(100vh - 260px);

  overflow: scroll;
  gap: 40px;
  padding: 0 14px 100px 14px;
`;
export const MessageGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const Message = styled.div<{ isMe: boolean }>`
  display: flex;
  justify-content: flex-end;
  flex-direction: ${({ isMe }) => (isMe ? "row" : "row-reverse")};
  gap: 11px;
  margin-${({ isMe }) => (isMe ? "left" : "right")}: 20%;
`;
export const MessageBubble = styled.div<{ isMe: boolean }>`
  display: flex;
  align-items: baseline;
  gap: 12px;
  padding: 12px 16px;
  color: ${({ isMe, theme }) => (isMe ? "white" : theme.colors.blackText)};
  flex-direction: ${({ isMe }) => (isMe ? "row" : "row-reverse")};
  background-color: ${({ isMe, theme }) =>
    isMe ? theme.colors.orange5 : "white"};
  border-radius: 30px;
  border: ${({ isMe, theme }) =>
    isMe ? "none" : `1px solid ${theme.colors.borderGray6 + hexOpacity(0.7)}`};
`;
export const MessageTimeStamp = styled.div`
  min-width: 50px;
  font-size: 12px;
  opacity: 0.6;
`;
export const MessageText = styled.div`
  overflow-wrap: anywhere;

  // Deprecated, but necessary until safari supports overflow-wrap.
  word-break: break-word;
`;
export const MessageIcon = styled.div`
  align-self: flex-end;
  min-width: 40px;
`;
export const DateBreak = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: 24px;
`;
export const DateBreakBubble = styled.div`
  background-color: ${themeColor("black", { opacity: 0.06 })};
  padding: 8px 16px;
  border-radius: 41px;
  font-size: 12px;
`;
export const ChannelHeader = styled.div`
  display: flex;
  padding: 11px 24px;
  border-bottom: 1px solid ${themeColor("borderGray6")};
`;
export const UserName = styled.div`
  margin-top: 3px;
  margin-left: 16px;
  font-weight: 600;
  font-size: 16px;
`;
export const BackButton = styled.div`
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  ${themeMinWidth("small")} {
    display: none;
  }
`;
