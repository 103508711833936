import { Field, FormikProps } from "formik";
import get from "lodash/get";
import React, { LabelHTMLAttributes, ReactElement } from "react";
import styled from "styled-components/macro";
import { theme } from "../../../app/theme";

const Checkbox = styled.input`
  width: 20px;
  min-width: 20px;
  height: 20px;
  cursor: pointer;
  :not(:checked) {
    opacity: 0.5;
  }
`;
const CheckboxContainer = styled.div`
  display: flex;
`;
const CheckboxLabel = styled.label<{ disabled?: boolean; error?: boolean }>`
  margin-left: 12px;
  cursor: ${({ disabled }) => (disabled ? "normal" : "pointer")};
  color: ${({ disabled }) =>
    disabled ? theme.colors.darkGrayText8 : "inherit"};
  color: ${({ error }) => (error ? "red" : "inherit")};
`;

type CheckboxInputProps = Omit<CheckboxFieldProps, "formik" | "basicFormik">;
type CheckboxLabelProps = LabelHTMLAttributes<HTMLLabelElement>;

export const CheckboxInput = ({
  id,
  label,
  ...inputProps
}: CheckboxInputProps): JSX.Element => (
  <CheckboxContainer>
    <Checkbox type="checkbox" id={id} {...inputProps} />
    <CheckboxLabel htmlFor={id} {...(inputProps as CheckboxLabelProps)}>
      {label}
    </CheckboxLabel>
  </CheckboxContainer>
);

export type CheckboxFieldProps = {
  label: string | ReactElement;
  error?: boolean;
  formik?: FormikProps<any>;
  basicFormik?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const CheckboxField: React.FC<CheckboxFieldProps> = ({
  label,
  error,
  formik,
  basicFormik = false,
  ...inputProps
}) => {
  if (formik) {
    const key = inputProps.name ?? inputProps.id ?? "";
    return (
      <CheckboxField
        label={label}
        error={!!(formik.errors[key] && formik.touched[key])}
        checked={get(formik.values, key) ?? ""}
        name={key}
        {...inputProps}
        onChange={(props) => {
          inputProps.onChange && inputProps.onChange(props);
          formik.handleChange(props);
        }}
        onBlur={formik.handleBlur}
        disabled={inputProps.disabled || formik.isSubmitting}
        /* @dgsan 2023-12-14
         * This (value true) not being present leads to null and undefined values
         * turning into arrays, which in turn locks up
         * the UI.
         */
        value="true"
      />
    );
  }
  const id = inputProps.id ?? inputProps.name;

  if (basicFormik) {
    return (
      <CheckboxContainer>
        <Field type="checkbox" {...inputProps} id={id} as={Checkbox} />
        <CheckboxLabel
          htmlFor={id}
          disabled={inputProps.disabled}
          error={error}
        >
          {label}
        </CheckboxLabel>
      </CheckboxContainer>
    );
  }

  return <CheckboxInput id={id} error={error} label={label} {...inputProps} />;
};

export default CheckboxField;
