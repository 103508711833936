import { createUseApiQuery, PageMetaTypeV1 } from "./queryHelpers";

export type OrganizationSite = {
  id: string;
  type: string;
  name: string;
};

export const useGetOrganizationSites = createUseApiQuery<
  OrganizationSite[],
  { organizationApiKey: string },
  PageMetaTypeV1
>({
  queryBuilder: ({ organizationApiKey }) => ({
    url: `/v4/organization_sites`,
    cacheKey: ["organizationSites", { organizationApiKey }],
    config: {
      params: {
        organization_api_key: organizationApiKey,
      },
    },
  }),
});
