import { Redirect } from "react-router-dom";
import GeneralErrorPage from "../../pages/GeneralErrorPage/GeneralErrorPage";
import ReferralFormPage from "../../pages/School/ReferralFormPage/ReferralFormPage";
import ReferredStudentsListPage from "../../pages/School/ReferredStudentsListPage/ReferredStudentsListPage";
import ReturnFromInsurancePage from "../../pages/School/ReturnFromInsurancePage/ReturnFromInsurancePage";
import SchoolHomePage from "../../pages/School/SchoolHomePage/SchoolHomePage";
import SchoolSchedulingPage from "../../pages/School/SchoolSchedulingPage/SchoolSchedulingPage";
import SettingsUserDetailsPage from "../../pages/School/SchoolSettings/SettingsUserDetailsPage/SettingsUserDetailsPage";
import SettingsUsersPage from "../../pages/School/SchoolSettings/SettingsUsersPage/SettingsUsersPage";
import StudentDetailsPage from "../../pages/School/StudentDetailsPage/StudentDetailsPage";
import StudentListPage from "../../pages/School/StudentListPage/StudentListPage";
import { RouteWith } from "./RouteWith";

const SchoolStaffDashboardRoutes = () => {
  return [
    <RouteWith
      auth
      path="/school/referrals/returnFromInsurance"
      key="return-from-insurance"
      featureFlag="School Counselor Dashboard"
      featureFlagFailureComponent={<GeneralErrorPage errorKey="ssdDisabled" />}
    >
      <ReturnFromInsurancePage />
    </RouteWith>,
    <RouteWith
      auth
      path="/school/home"
      key="school-home"
      featureFlag="School Counselor Dashboard"
      featureFlagFailureComponent={<GeneralErrorPage errorKey="ssdDisabled" />}
    >
      <SchoolHomePage />
    </RouteWith>,
    <RouteWith
      auth
      path="/school/referred-students-list"
      key="/school/referred-students-list"
      featureFlag="School Counselor Dashboard"
      featureFlagFailureComponent={<GeneralErrorPage errorKey="ssdDisabled" />}
    >
      <ReferredStudentsListPage />
    </RouteWith>,
    <RouteWith
      auth
      path="/school/students/:orgMemberId"
      key="student-details"
      featureFlag="School Counselor Dashboard"
      featureFlagFailureComponent={<GeneralErrorPage errorKey="ssdDisabled" />}
    >
      <StudentDetailsPage />
    </RouteWith>,
    <RouteWith
      auth
      path="/school/students"
      key="student-list"
      featureFlag="School Counselor Dashboard"
      featureFlagFailureComponent={<GeneralErrorPage errorKey="ssdDisabled" />}
    >
      <StudentListPage />
    </RouteWith>,
    <RouteWith
      auth
      path="/school/scheduling/referral-form/:organizationMemberId"
      key="student-list"
      featureFlag="School Counselor Dashboard"
      featureFlagFailureComponent={<GeneralErrorPage errorKey="ssdDisabled" />}
    >
      <ReferralFormPage />
    </RouteWith>,
    <RouteWith
      auth
      path="/school/scheduling/:organizationMemberId"
      key="school-scheduling"
      featureFlag="School Counselor Dashboard"
      featureFlagFailureComponent={<GeneralErrorPage errorKey="ssdDisabled" />}
    >
      <SchoolSchedulingPage />
    </RouteWith>,
    <RouteWith
      auth
      path="/school/settings/users/:organizationStaffMemberId"
      key="school-settings-users-show"
      featureFlag="School Counselor Dashboard"
      featureFlagFailureComponent={<GeneralErrorPage errorKey="ssdDisabled" />}
    >
      <SettingsUserDetailsPage />
    </RouteWith>,
    <RouteWith
      auth
      path="/school/settings/users"
      key="school-settings-users"
      featureFlag="School Counselor Dashboard"
      featureFlagFailureComponent={<GeneralErrorPage errorKey="ssdDisabled" />}
    >
      <SettingsUsersPage />
    </RouteWith>,
    <RouteWith
      auth
      path="/school/settings"
      key="school-settings-users"
      featureFlag="School Counselor Dashboard"
      featureFlagFailureComponent={<GeneralErrorPage errorKey="ssdDisabled" />}
    >
      <Redirect to="/school/settings/users" />
    </RouteWith>,
    <RouteWith
      auth
      path="/school"
      key="school-fallback"
      featureFlag="School Counselor Dashboard"
      featureFlagFailureComponent={<GeneralErrorPage errorKey="ssdDisabled" />}
    >
      <Redirect to="/school/home" />
    </RouteWith>,
  ];
};

export default SchoolStaffDashboardRoutes;
