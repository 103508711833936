import { UserKind } from "../../api/queries/users";

const userKindDisplayStrings: Record<UserKind, string> = {
  counselor: "clinician",
  organization_staff_member: "school counselor",
  teen: "teen",
  parent: "parent",
  psychiatrist: "psychiatrist",
};
const userKindDisplayString = (userKind: UserKind | undefined) =>
  userKind ? userKindDisplayStrings[userKind] : "";
const Users = { userKindDisplayString };
export default Users;
