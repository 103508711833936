import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import styled from "styled-components/macro";
import { DaybreakErrorV4 } from "../../api/apiClient";
import { themeColor, themeMinWidth } from "../../app/theme";
import OrangeButton from "../buttons/OrangeButton/OrangeButton";
import Icon from "../Icon/Icon";
import useIntercom from "../../hooks/useIntercom";
import CenteredLayout from "../../layouts/CenteredLayout/CenteredLayout";

const ErrorBox = styled.div`
  width: 100%;
  padding: 32px 32px 80px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  ${themeMinWidth("small")} {
    padding: 50px;
    padding-bottom: 80px;
    width: 704px;
  }
`;
const ErrorSquare = styled.div`
  width: 85px;
  height: 85px;
  background-color: ${themeColor("orange5", { opacity: 0.1 })};
  color: ${themeColor("orange5")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  .icon {
    width: 43px;
    height: 43px;
  }
`;
const ErrorTitle = styled.div`
  margin-top: 32px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;
const ErrorBody = styled.div`
  margin-top: 32px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  ${themeMinWidth("small")} {
    width: 500px;
    padding: 0;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 40px;
`;

const errors = {
  getOrganizationMemberError: (error: DaybreakErrorV4) => ({
    title: <>You do not have access to this student, sorry!</>,
    body: (
      <>
        <p>
          The student belongs to {error.errorData?.studentSiteName}, which you
          do not have access to.
        </p>
        <p>
          If you beleive this to be an error, please let your Daybreak contact
          know
        </p>
      </>
    ),
  }),
  getNoReferralError: (error: DaybreakErrorV4) => ({
    title: error.error,
    body: (
      <>
        <p>
          We could not find a referral to schedule. If you reached this page via
          link, it may be stale.
        </p>
        <p>
          If you are trying to schedule a just-filled referral, please contact
          your Daybreak contact for assistance.
        </p>
      </>
    ),
  }),
  getMissingDataError: (error: DaybreakErrorV4) => ({
    title: error.error,
    body: (
      <>
        <p>Data we require to scheduled the referral is missing.</p>
        <p>
          If you are trying to schedule a just-filled referral, please contact
          your Daybreak contact for assistance.
        </p>
      </>
    ),
  }),
} as const;
export type InformativeErrorProps = {
  errorKey: keyof typeof errors;
  error: DaybreakErrorV4;
};

const InformativeError: React.FC<InformativeErrorProps> = ({
  errorKey,
  error,
}) => {
  const { isAuthenticated, logout } = useAuth0();
  const errorData = errors[errorKey](error);

  useIntercom({ boot: true });
  return (
    <CenteredLayout headerStyle="simple">
      <ErrorBox>
        <ErrorSquare>
          <Icon name="alert" />
        </ErrorSquare>
        <ErrorTitle>{errorData["title"]}</ErrorTitle>
        <ErrorBody>{errorData["body"]}</ErrorBody>
        {isAuthenticated ? (
          <ButtonContainer>
            <OrangeButton onClick={logout}>Logout</OrangeButton>
          </ButtonContainer>
        ) : null}
      </ErrorBox>
    </CenteredLayout>
  );
};

export default InformativeError;
