import debounce from "lodash/debounce";
import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import Icon from "../../../components/Icon/Icon";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
`;

const SearchInput = styled.input`
  line-height: 32px;
  font-size: 20px;
  outline: none;
  border: none;
  margin-left: 10px;
  flex: 1;
`;

const BackArrow = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type SearchBarProps = {
  showingFilteredResults: boolean;
  onSearch: (q: string) => void;
  onReset: () => void;
  initialQuery: string;
};

const StudentListPage: React.FC<SearchBarProps> = ({
  showingFilteredResults,
  onSearch,
  onReset,
  initialQuery,
}) => {
  const [queryString, setQueryString] = useState(initialQuery);

  const handleSearchReset = () => {
    onReset();
  };
  const onSearchDebounced = useMemo(() => {
    return debounce(onSearch, 400);
  }, [onSearch]);

  return (
    <Container>
      {showingFilteredResults ? (
        <BackArrow onClick={handleSearchReset}>
          <Icon name="leftArrow2" />
        </BackArrow>
      ) : (
        <Icon name="search" />
      )}
      <SearchInput
        type="text"
        placeholder="Search"
        value={queryString}
        onChange={(e) => {
          setQueryString(e.target.value);
          onSearchDebounced(e.target.value);
        }}
      />
    </Container>
  );
};

export default StudentListPage;
