import React, { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import { useOrgApiKeyParam } from "../../../app/Routes/ScreenerRoutes";
import { themeColor, themeMinWidth } from "../../../app/theme";
import Icon from "../../../components/Icon/Icon";
import OrangeButton from "../../../components/buttons/OrangeButton/OrangeButton";
import { useMandateOrganization } from "../../../hooks/screener/useMandateOrganization";
import ScreenerLayout from "../../../layouts/ScreenerLayout/ScreenerLayout";
import { languagePreferenceStorage } from "../../../lib/localStorageManagers";
import { trackPage, reset } from "client/amplitudeHelper";

export type PostSurveyPageProps = {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 16px 60px 16px;
  ${themeMinWidth("small")} {
    padding: 50px 48px 60px 48px;
  }
`;
const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${themeColor("orange5", { opacity: 0.1 })};
  width: 85px;
  height: 85px;
  border-radius: 8px;
  .icon {
    width: 61px;
    height: 62px;
    color: ${themeColor("orange5")};
  }
`;
const H1 = styled.h1`
  margin-top: 32px;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;
const Details = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  max-width: 436px;
  text-align: center;
`;
const Buttons = styled.div`
  margin-top: 48px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  ${themeMinWidth("small")} {
    flex-direction: row;
  }
`;
const PostSurveyPage: React.FC<PostSurveyPageProps> = () => {
  const history = useHistory();
  const organizationApiKey = useOrgApiKeyParam();
  useMandateOrganization(organizationApiKey);
  useEffect(() => {
    trackPage("universalScreenerPostSurveyPage", []);

    // Tell analytics that this user is done and track subsequent events as a new user
    reset();
  });

  const preferredLanguage = languagePreferenceStorage.get();

  return (
    <ScreenerLayout currentWizardStep="Thank you">
      <Container>
        <IconBox>
          <Icon name="satisfaction" />
        </IconBox>
        {preferredLanguage === "es" && (
          <>
            <H1>Gracias</H1>
            <Details>
              Llegaste al final de la encuesta sobre salud socioemocional y
              mental. ¡Gracias por tomarte el tiempo de ayudar a tu escuela a
              mejorar su apoyo socioemocional! Comunícate con tu consejero
              escolar si tienes alguna pregunta.
            </Details>
            <Buttons>
              <OrangeButton
                onClick={() =>
                  history.push(`/organization/${organizationApiKey}/screener`)
                }
              >
                Bien, de acuerdo
              </OrangeButton>
            </Buttons>
          </>
        )}
        {preferredLanguage !== "es" && (
          <>
            <H1>Thank You</H1>
            <Details>
              You’ve reached the end of the Social, Emotional & Mental Health
              Survey. Thanks for taking the time to help your school improve
              social and emotional supports!
            </Details>
            <Buttons>
              <OrangeButton
                onClick={() =>
                  history.push(`/organization/${organizationApiKey}/screener`)
                }
              >
                OK, got it
              </OrangeButton>
            </Buttons>
          </>
        )}
      </Container>
    </ScreenerLayout>
  );
};

export default PostSurveyPage;
