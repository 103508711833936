import TealButton from "../../../components/buttons/TealButton/TealButton";
import styled from "styled-components";
import { TFunction } from "i18next";

type Callback = () => void;

export type YesNoButtonsProps = {
  t: TFunction;
  onYes: Callback;
  onNo: Callback;
};

const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

const YesNoButtons = ({ t, onYes, onNo }: YesNoButtonsProps): JSX.Element => {
  return (
    <>
      <ButtonRow>
        <div>
          <TealButton onClick={onYes}>{t("Yes")}</TealButton>
        </div>
        <div>
          <TealButton onClick={onNo}>{t("No")}</TealButton>
        </div>
      </ButtonRow>
    </>
  );
};

export default YesNoButtons;
