import { cloneDeep, debounce, isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import {
  RequestError,
  isAxiosError,
  isDaybreakErrorV4,
} from "../../api/apiClient";
import { useApi } from "../../api/apiContext";
import {
  ParentWelcomePacket,
  ParentWelcomePacketPostArguments,
  PostParentWelcomePacketParameters,
} from "../../api/queries/parentWelcomePackets";
import ParentWelcomePacketLayout from "../../layouts/ParentWelcomePacketLayout/ParentWelcomePacketLayout";
import ParentWelcomePacketCompleted from "./ParentWelcomePacketCompleted";
import { ParentWelcomePacketFormWithData } from "./ParentWelcomePacketFormWithData";
import { Error } from "../../components/Toaster/Alert/Error";
import en from "./ParentWelcomePacket.en.json";
import es from "./ParentWelcomePacket.es.json";
import { useDaybreakTranslation } from "../../hooks/useDaybreakTranslation";
import { TFunction } from "i18next";
import { trackEvent, identifyAnonymousUser } from "client/amplitudeHelper";

export type ParentWelcomePacketFormProps = {
  organizationApiSlug: string;
  consentBlocking: boolean;
};

// @dgsan 2020-10-20 We do not want to trigger
// saves while these fields are being edited
// as it may cause an attempt to match a
// client referral with incomplete/incorrect
// data on the back-end.
const bannedSaveFields: readonly string[] = [
  "patientLastName",
  "patientBirthDate",
  "guardianLastName",
  "guardianPhoneNumber",
  "guardianEmail",
];

const retriableErrors: readonly string[] = [
  "requestTimeoutException",
  "lockError",
] as const;

const isExpected404 = (error: RequestError | null): boolean => {
  if (error === null) {
    return false;
  }
  return isAxiosError(error) && error.response?.status === 404;
};

const lockedPageToast = (
  t: TFunction,
  setDismissed: (arg: boolean) => void
) => {
  trackEvent("PWP:ParentWelcomePacketError:Alert");
  toast(
    (theToast) => (
      <Error
        header={t("errorToastHeader")}
        message={t("errorToastMessage")}
        primary={{
          action: () => {
            trackEvent("PWP:ParentWelcomePacketError:Alert:User:Reload");
            window.location.reload();
          },
          label: t("errorToastPrimary"),
        }}
        secondary={{
          action: () => {
            trackEvent("PWP:ParentWelcomePacketError:Alert:User:Dismiss");
            setDismissed(true);
            toast.dismiss(theToast.id);
          },
          label: t("errorToastSecondary"),
        }}
      ></Error>
    ),
    { id: "finalPWPError", duration: Infinity }
  );
};

/* Per Nikta on 2023-12-12
 * Don't save agreement checkboxes
 * unless also signing at the same time.
 * (Or already signed.)
 */
const filterSaveValues = (
  current: ParentWelcomePacket | undefined,
  values: ParentWelcomePacketPostArguments
): ParentWelcomePacketPostArguments => {
  const {
    agreementTosPp: _0,
    agreementIc: _1,
    agreementAobHipaa: _2,
    agreementHipaaRoi: _3,
    agreementSignature: _4,
    ...rest
  } = values;

  if (current?.completedDate || values?.complete) {
    return values;
  } else {
    return rest;
  }
};

const ParentWelcomePacketForm: React.FC<ParentWelcomePacketFormProps> = ({
  organizationApiSlug,
  consentBlocking,
}) => {
  const { useGetParentWelcomePacket, usePostParentWelcomePacket } = useApi();

  const {
    data: { data: parentWelcomePacket } = {},
    isLoading: isLoadingPWP,
    error: getParentWelcomePacketError,
  } = useGetParentWelcomePacket({
    orgApiKey: organizationApiSlug,
    options: {
      retry: (failureCount, error) => {
        if (isExpected404(error)) {
          // 404s from this endpoint indicate that the current user has no
          // existing PWP, which is not an error condition.
          return false;
        }
        return failureCount < 3;
      },
      refetchIntervalInBackground: false,
    },
  });
  const {
    data: { data: updatedParentWelcomePacket } = {},
    error: postParentWelcomePacketError,
    mutate: postParentWelcomePacket,
    isLoading: isSavingPWP,
  } = usePostParentWelcomePacket({
    options: {
      retry: (failureCount, error) => {
        if (
          isDaybreakErrorV4(error) &&
          retriableErrors.includes(error.errorType)
        ) {
          // Retry posts w/ specific errors once.
          trackEvent("PWP:ERROR:POST:retriable", {
            error,
            failureCount,
          });
          return failureCount < 1;
        } else {
          trackEvent("PWP:ERROR:POST:unretriable", error);
          return false;
        }
      },
    },
  });

  useEffect(() => {
    if (isSavingPWP) {
      toast.loading("Saving...", { id: "saving_toast", position: "top-right" });
    } else if (!isSavingPWP && updatedParentWelcomePacket) {
      toast.success("Saved!", {
        id: "saving_toast",
        duration: 1000,
        position: "top-right",
      });
    } else if (!isSavingPWP) {
      toast.error("Couldn't save!", {
        id: "saving_toast",
        duration: 1500,
        position: "top-right",
      });
    }
  }, [isSavingPWP, updatedParentWelcomePacket]);

  const maybeSavePWP = useMemo(() => {
    let lastSaved: PostParentWelcomePacketParameters[0] | undefined = undefined;
    let saving = false;
    return debounce(
      (
        activeField: string = "",
        values: PostParentWelcomePacketParameters[0],
        current: ParentWelcomePacket | undefined,
        options: PostParentWelcomePacketParameters[1] = {},
        force: boolean = false
      ) => {
        if (bannedSaveFields.includes(activeField)) {
          console.log("Banned Save: " + activeField);
          trackEvent("PWP:saveBlockedByCriticalField");
          return;
        }
        // Don't save unless the values have changed.
        if (force || !lastSaved || !isEqual(lastSaved, values)) {
          if (saving) {
            // If we're already saving, don't save again.
            return;
          }
          lastSaved = cloneDeep(values);

          // Prevent storing the `completeValue` for the purposes of
          // diff-checking because most places we call maybeSavePWP don't set
          // it, and so those places will always trigger a new save (because
          // lastSaved with 'complete' != new values without 'complete').
          if (!current?.completedDate) {
            delete lastSaved.complete;
          }
          values.flowVersion = 1;
          saving = true;
          trackEvent("PWP:saveStart");
          postParentWelcomePacket(filterSaveValues(current, values), {
            ...options,
            onSettled: (...args) => {
              saving = false;
              options.onSettled?.(...args);
              trackEvent("PWP:saveComplete");
            },
          });
        }
      },
      1000
    );
  }, [postParentWelcomePacket]);

  // When we're mid-save, the updatedParentWelcomePacket becomes undefined for a
  // second.  To prevent weird flickering in the form UI, we keep track of the
  // last updatedParentWelcomePacket and use that as the source of truth for the
  // form.
  const [
    lastUpdatedParentWelcomePacket,
    setLastUpdatedParentWelcomePacket,
  ] = useState<ParentWelcomePacket | undefined>();
  useEffect(() => {
    if (updatedParentWelcomePacket) {
      setLastUpdatedParentWelcomePacket(updatedParentWelcomePacket);
    }
  }, [updatedParentWelcomePacket]);

  const stableParentWelcomePacket = useMemo(
    () =>
      updatedParentWelcomePacket ??
      lastUpdatedParentWelcomePacket ??
      parentWelcomePacket,
    [
      lastUpdatedParentWelcomePacket,
      parentWelcomePacket,
      updatedParentWelcomePacket,
    ]
  );

  const stablePWPCompleted = stableParentWelcomePacket?.packetComplete ?? false;
  useEffect(() => {
    if (stablePWPCompleted) {
      trackEvent("PWP:pwpCompletedPage");
    }
  }, [stablePWPCompleted]);
  useEffect(() => {
    if (isLoadingPWP) {
      trackEvent("PWP:loadingPWP");
    }
  }, [isLoadingPWP]);

  // Provide Identify information to Amplitude that can be cross referenced later
  // for debugging (by looking at parent email).
  useEffect(() => {
    if (stableParentWelcomePacket) {
      identifyAnonymousUser({
        firstName: stableParentWelcomePacket.guardianFirstName,
        lastName: stableParentWelcomePacket.guardianLastName,
        email: stableParentWelcomePacket.guardianEmail,
        phone: stableParentWelcomePacket.guardianPhoneNumber,
      });

      if (stableParentWelcomePacket.linkedAndConfigured) {
        trackEvent("PWP:IndentifiedLinkedAndConfiguredWelcomePacket", {
          stableParentWelcomePacket: {
            ...stableParentWelcomePacket,
          },
        });
      } else {
        trackEvent("PWP:IndentifiedNonLinkedAndConfiguredWelcomePacket", {
          stableParentWelcomePacket: {
            ...stableParentWelcomePacket,
          },
        });
      }
    } else {
      trackEvent("PWP:UnidentifiedWelcomePacket", {
        stableParentWelcomePacket: stableParentWelcomePacket,
      });
    }
  }, [stableParentWelcomePacket]);

  // Track GET errors
  // Note that retries and handled errors do not trigger this.
  useEffect(() => {
    if (getParentWelcomePacketError) {
      trackEvent("PWP:ERROR:GET:getParentWelcomePacketError", {
        error: getParentWelcomePacketError,
        welcomePacket: stableParentWelcomePacket,
      });
    }
  }, [getParentWelcomePacketError, stableParentWelcomePacket]);

  // Track POST errors
  // Note that retries and handled errors do not trigger this.
  useEffect(() => {
    if (postParentWelcomePacketError) {
      trackEvent("PWP:ERROR:POST:postParentWelcomePacketError", {
        error: postParentWelcomePacketError,
        welcomePacket: stableParentWelcomePacket,
      });
    }
  }, [postParentWelcomePacketError, stableParentWelcomePacket]);

  // Per Kevin this can be used seperately and doesn't
  // need to be passed around. Not in the Toast
  // piece because it would make the component over
  // connected to the PWP translations. (Not that
  // in love with this, but solving cleanly is out
  // of scope.)
  const { t, i18n } = useDaybreakTranslation("pwpForm", {
    en,
    es,
  });
  const language = i18n.language;
  // To make the Toast popup behave sanely
  // it seems we need some state.
  const [dismissed, setDismissed] = useState(false);
  const [erred, setErred] = useState(false);
  // The first fetch w/o a session or postedPWP
  // should fail. So skip locking on first GET 404.
  // There is probably a better way to handle this...
  // When there are errors, use Toast to display an infinite
  // time toast box with reload button.
  useEffect(() => {
    // This is throwaway. The t function doesn't change,
    // but it's behavior does change when language changes...
    if (
      !erred &&
      !postParentWelcomePacketError &&
      getParentWelcomePacketError &&
      isExpected404(getParentWelcomePacketError)
    ) {
      trackEvent("PWP:initialGet404");
      return;
    } else if (
      !dismissed &&
      (getParentWelcomePacketError || postParentWelcomePacketError)
    ) {
      setErred(true);
      lockedPageToast(t, setDismissed);
    }
  }, [
    getParentWelcomePacketError,
    postParentWelcomePacketError,
    language,
    erred,
    dismissed,
    t,
  ]);

  if (isLoadingPWP) {
    return <ParentWelcomePacketLayout loading />;
  }

  if (stablePWPCompleted) {
    return <ParentWelcomePacketCompleted />;
  }

  return (
    <ParentWelcomePacketFormWithData
      organizationApiKey={organizationApiSlug}
      parentWelcomePacket={stableParentWelcomePacket}
      postParentWelcomePacketError={postParentWelcomePacketError ?? undefined}
      isSavingPWP={isSavingPWP}
      maybeSavePWP={maybeSavePWP}
      consentBlocking={consentBlocking}
    />
  );
};

export default ParentWelcomePacketForm;
