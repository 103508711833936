import React from "react";
import styled from "styled-components/macro";
import logoImage from "../../images/daybreak-logo.svg";
import logoTypeImage from "../../images/daybreak-logo-type.svg";
import { themeColor, themeMinWidth } from "../../app/theme";
import OrangeButton from "../buttons/OrangeButton/OrangeButton";
import { useAuth0 } from "@auth0/auth0-react";
import Icon from "../Icon/Icon";
import { Link, useHistory } from "react-router-dom";
import { trackEvent, reset } from "client/amplitudeHelper";

export type HeaderProps = {};

const Container = styled.div`
  height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid #dfdfdf;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  ${themeMinWidth("small")} {
    height: 94px;
  }
`;
const Side = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Left = styled(Side)``;
const Right = styled(Side)``;
const LoginLink = styled.div`
  a,
  a:visited {
    color: ${themeColor("orange5")};
    transition: all ${(props) => props.theme.animations.defaultHover};
  }
  a:hover {
    color: ${themeColor("teal")};
  }
`;
const CallButton = styled(OrangeButton)`
  margin-left: 24px;
  height: 44px;
  width: 148px;
  font-size: 13px;
  font-weight: 600px;
  line-height: 13px;
`;

const Logo = styled.img`
  cursor: pointer;
  ${themeMinWidth("small")} {
    display: none;
  }
`;
const LogoType = styled.img`
  display: none;
  cursor: pointer;
  ${themeMinWidth("small")} {
    display: block;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  transition: all ${(props) => props.theme.animations.defaultHover};
  :hover {
    opacity: 0.5;
  }
`;

export type LoggedInHeaderProps = { logout: () => void };
export const LoggedInHeader: React.FC<LoggedInHeaderProps> = ({ logout }) => {
  const history = useHistory();
  return (
    <Container>
      <Left>
        <Logo
          src={logoImage}
          alt="Daybreak logo"
          onClick={() => history.push("/")}
        />
        <LogoType
          src={logoTypeImage}
          alt="Daybreak logo"
          onClick={() => history.push("/")}
        />
      </Left>
      <Right>
        <IconWrapper
          onClick={() => {
            reset();
            logout();
          }}
        >
          <Icon name="exit" />
        </IconWrapper>
      </Right>
    </Container>
  );
};

export type GuestHeaderProps = {};
export const GuestHeader: React.FC<GuestHeaderProps> = () => {
  const history = useHistory();
  const onScheduleClick = () => {
    trackEvent("scheduleCallButton");
    history.push("/onboarding/guest-schedule-appointment");
  };
  return (
    <Container>
      <Left>
        <Logo
          src={logoImage}
          alt="Daybreak logo"
          onClick={() => history.push("/")}
        />
        <LogoType
          src={logoTypeImage}
          alt="Daybreak logo"
          onClick={() => history.push("/")}
        />
      </Left>
      <Right>
        <LoginLink>
          <Link to={"/login-redirect"}>Log in</Link>
        </LoginLink>
        <CallButton variant="secondary" onClick={onScheduleClick}>
          Book Free Intro Call
        </CallButton>
      </Right>
    </Container>
  );
};

const Header: React.FC<HeaderProps> = () => {
  const { isAuthenticated, logout } = useAuth0();
  return isAuthenticated ? <LoggedInHeader logout={logout} /> : <GuestHeader />;
};

export default Header;
