import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../app/theme";
import ChildSwitcher from "../../components/TopNav/ChildSwitcher/ChildSwitcher";
import DesktopNav from "../../components/DesktopNav/DesktopNav";
import { CenteredLoading } from "../../components/Loading/Loading";
import MobileNav from "../../components/MobileNav/MobileNav";
import useIntercom from "../../hooks/useIntercom";
import TitleNav from "../../components/TopNav/TitleNav/TitleNav";
import BackNav from "../../components/TopNav/BackNav/BackNav";
import PatientNameBar from "../../components/PatientNameBar/PatientNameBar";

const GlobalStyle = createGlobalStyle`
  html, body, #root, .App {
    height: 100%;
  }
`;
const desktopNavWidth = 258;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${themeMinWidth("small")} {
    flex-direction: row;
  }
`;
const DesktopNavContainer = styled.div`
  width: ${desktopNavWidth}px;
  min-width: ${desktopNavWidth}px;
  display: none;
  background-color: ${themeColor("backgroundGray")};
  height: 100%;
  position: fixed;

  ${themeMinWidth("small")} {
    display: flex;
  }
`;
const MobileNavContainer = styled.div`
  display: flex;
  min-height: 71px;
  background-color: ${themeColor("backgroundGray")};
  ${themeMinWidth("small")} {
    display: none;
  }
`;
const ChildSwitcherAndContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${themeMinWidth("small")} {
    margin-left: ${desktopNavWidth}px;
  }
`;

const ChildSwitcherContainer = styled.div``;
const Content = styled.div`
  padding-bottom: 24px;
`;
const LoadingContainer = styled.div`
  display: flex;
  padding-top: 80px;
`;

const PatientNameContainer = styled.div`
  margin-top: 32px;
  margin-left: 16px;
`;

type ChildSelectorTopNav = {
  type: "childSelectorTopNav";
};
type CounselorTopNav = {
  type: "counselorTopNav";
};
type TitleTopNav = {
  type: "titleTopNav";
  title: string;
};
type TopNavConfig = ChildSelectorTopNav | CounselorTopNav | TitleTopNav;
type TopNavProps = { config: TopNavConfig };
const TopNav: React.FC<TopNavProps> = ({ config }) => {
  switch (config.type) {
    case "childSelectorTopNav":
      return <ChildSwitcher />;
    case "titleTopNav":
      return <TitleNav title={config.title} />;
    case "counselorTopNav":
      return (
        <BackNav
          backText="Back to Patients list"
          backTarget="/clinician/patients"
        />
      );
  }
};

export type DashboardLayoutProps = {
  loading?: boolean;
  topNav?: TopNavConfig;
  showPatientName?: boolean;
};
const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  loading,
  topNav,
  showPatientName,
}) => {
  useIntercom({ boot: false }); // Disable intercom
  const content = loading ? (
    <LoadingContainer>
      <CenteredLoading />
    </LoadingContainer>
  ) : (
    children
  );
  return (
    <Container>
      <GlobalStyle />
      <DesktopNavContainer>
        <DesktopNav />
      </DesktopNavContainer>
      <MobileNavContainer>
        <MobileNav />
      </MobileNavContainer>
      <ChildSwitcherAndContent>
        <ChildSwitcherContainer>
          {loading ? (
            <></>
          ) : (
            <TopNav config={topNav || { type: "counselorTopNav" }}></TopNav>
          )}
        </ChildSwitcherContainer>
        {showPatientName ? (
          <PatientNameContainer>
            <PatientNameBar />
          </PatientNameContainer>
        ) : (
          <></>
        )}
        <Content>{content}</Content>
      </ChildSwitcherAndContent>
    </Container>
  );
};

export default DashboardLayout;
