import { trackEvent } from "client/amplitudeHelper";
import { OrganizationMemberV1 } from "api/queries/organizationMembers";

type AddFormProps = {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  uniqueStudentId: string;
  studentEmail: string;
  gender: string;
  otherGender?: string | undefined;
  gradeLevel: string;
  organizationSiteId?: string;
  organizationSiteName?: string;
};
const urlString = process.env.REACT_APP_ONSITE_REFERRAL_URL;

const urlStringError = () => {
  console.error("REACT_APP_ONSITE_REFERRAL_URL is not set");
  trackEvent("SCD:ERROR:navigateToOnsiteReferralForm", {
    missing_url: "REACT_APP_ONSITE_REFERRAL_URL",
  });
};

const navigate = (url: string) => {
  trackEvent("SCD:navigateToOnsiteReferralForm");
  window.open(url, "_blank");
};

const visitOnsiteUrl = (
  values: AddFormProps,
  apiKey: string,
  myStaffId: string
) => {
  if (!urlString) {
    urlStringError();
  } else {
    const url = new URL(urlString);
    const baseVals = {
      language: "en_US",
      did: apiKey,
      fn: values.firstName,
      ln: values.lastName,
      sid: values.uniqueStudentId,
      rsid: myStaffId,
    };
    const searchVals =
      typeof values.organizationSiteName === "string"
        ? {
            ...baseVals,
            sn: values.organizationSiteName,
          }
        : baseVals;
    url.search = new URLSearchParams(searchVals).toString();
    navigate(url.toString());
  }
};

export const orgMemberVisitOnsiteUrl = (
  member: OrganizationMemberV1,
  apiKey: string,
  myStaffId: string
) => {
  if (!urlString) {
    urlStringError();
  } else {
    const url = new URL(urlString);
    const baseVals = {
      language: "en_US",
      did: apiKey,
      rsid: myStaffId,
      fn: member.firstName,
      ln: member.lastName,
      rid: member.id,
      sid: member.uniqueStudentId,
    };
    const vals =
      typeof member.schoolName === "string"
        ? {
            ...baseVals,
            sn: member.schoolName,
          }
        : baseVals;
    url.search = new URLSearchParams(vals).toString();
    navigate(url.toString());
  }
};

export default visitOnsiteUrl;
