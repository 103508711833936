import React from "react";
import Badge from "../Badge/Badge";

const directionColor = {
  Stable: "lightOrange",
  Worsened: "lightRed",
  Improved: "lightGreen",
} as const;

export type TrendBadgeProps = {
  oldScore: number;
  newScore: number;
  lowIsGood: boolean;
};

const TrendBadge: React.FC<TrendBadgeProps> = ({
  oldScore,
  newScore,
  lowIsGood,
}) => {
  let direction: "Improved" | "Worsened" | "Stable";
  if (oldScore && newScore) {
    if (oldScore > newScore) {
      direction = lowIsGood ? "Improved" : "Worsened";
    } else if (oldScore < newScore) {
      direction = lowIsGood ? "Worsened" : "Improved";
    } else {
      direction = "Stable";
    }
  } else {
    direction = "Stable";
  }

  return (
    <Badge type={directionColor[direction]} text={direction} size="small" />
  );
};

export default TrendBadge;
