import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import { RequestError, useApiClient } from "../apiClient";
import { withApiErrorHandling } from "./queryHelpers";

// TODO: generate these from an api specification, rather than just declaring
// them like this and hoping the data coming from the api is always in this
// shape.
export type SurveyMonkeyChoice = {
  text: string;
  id: string;
};
export type SurveyMonkeyAnswers = {
  choices: SurveyMonkeyChoice[];
};
export type SurveyMonkeyQuestion = {
  id: string;
  nickname: string;
  family: "presentation" | "single_choice";
  headings: { heading: string }[];
  answers: SurveyMonkeyAnswers;
  quiz_options: {
    feedback: {
      correct_text: string;
    };
  };
};
export type SurveyMonkeyPage = {
  id: string;
  title: string;
  questions: SurveyMonkeyQuestion[];
};
export type SurveyFeatureData = {
  milestone_id: string;
  user_form_id: string;
  is_test?: boolean;
};
export type SurveyMonkeySurvey = {
  id: string;
  pages: SurveyMonkeyPage[];
  feature_data?: SurveyFeatureData;
};

export type SurveyMonkeyResponse = {
  id?: string;
  response_status?: "partial" | "completed";
  pages: SurveyMonkeyResponse_Page[];
  is_test?: boolean;
};
export type SurveyMonkeyResponse_Page = {
  id: string;
  questions: SurveyMonkeyResponse_Questions[];
};
export type SurveyMonkeyResponse_Questions = {
  id: string;
  answers: SurveyMonkeyResponse_Answer[];
};
export type SurveyMonkeyResponse_Answer = {
  score?: number;
  choice_id: string;
};

export const getSurveyV2QueryKey = (surveyFormFeatureApiKey: string) => [
  "survey",
  surveyFormFeatureApiKey,
];
export const useGetSurveyV2 = (
  surveyFormFeatureApiKey: string,
  options: UseQueryOptions<SurveyMonkeySurvey, RequestError> = {}
) => {
  const queryKey = getSurveyV2QueryKey(surveyFormFeatureApiKey);
  const apiClient = useApiClient({ auth: false });
  return useQuery<SurveyMonkeySurvey, RequestError>(
    queryKey,
    async () => {
      return withApiErrorHandling(async () => {
        const response = await apiClient.get(
          `/v2/features/${surveyFormFeatureApiKey}/form`
        );

        // This api is a thin proxy to surveymonkey, and so the responses don't
        // conform to our usual response shapes.  Hence not using
        // convertSerializedType.
        return response.data;
      });
    },
    options
  );
};

type PostSurveyResponseV2Variables = {
  surveyResponse: SurveyMonkeyResponse;
};
export const usePostSurveyResponseV2 = (
  featureApiKey: string,
  options: UseMutationOptions<
    SurveyMonkeyResponse,
    AxiosError,
    PostSurveyResponseV2Variables
  > = {}
) => {
  const apiClient = useApiClient({ auth: false });
  return useMutation<
    SurveyMonkeyResponse,
    AxiosError,
    PostSurveyResponseV2Variables
  >(async ({ surveyResponse }) => {
    return withApiErrorHandling(async () => {
      return (
        await apiClient.post(
          `v2/features/${featureApiKey}/form/responses`,
          surveyResponse
        )
      ).data;
    });
  }, options);
};

/********************** Deprecated ************************/
// Queries below are deprecated in favor of those above

export const useGetSurvey = (surveyFormFeatureName: string) => {
  const queryKey = ["survey", surveyFormFeatureName];
  const apiClient = useApiClient({ auth: false });
  return useQuery<SurveyMonkeySurvey, AxiosError>(queryKey, async () => {
    const response = await apiClient.get(
      `/v1/features/${surveyFormFeatureName}/form`
    );
    return response.data;
  });
};

type PostSurveyResponseVariables = {
  surveyResponse: SurveyMonkeyResponse;
};
export const usePostSurveyResponse = (
  featureName: string,
  options: UseMutationOptions<
    SurveyMonkeyResponse,
    AxiosError,
    PostSurveyResponseVariables
  > = {}
) => {
  const apiClient = useApiClient({ auth: false });
  return useMutation<
    SurveyMonkeyResponse,
    AxiosError,
    PostSurveyResponseVariables
  >(async ({ surveyResponse }) => {
    return (
      await apiClient.post(
        `v1/features/${featureName}/form/responses`,
        surveyResponse
      )
    ).data;
  }, options);
};

type PatchSurveyResponseVariables = {
  surveyResponse: SurveyMonkeyResponse;
  surveyResponseId: string;
};
export const usePatchSurveyResponse = (
  featureName: string,
  options: UseMutationOptions<
    SurveyMonkeyResponse,
    AxiosError,
    PatchSurveyResponseVariables
  > = {}
) => {
  const apiClient = useApiClient({ auth: false });
  return useMutation<
    SurveyMonkeyResponse,
    AxiosError,
    PatchSurveyResponseVariables
  >(async ({ surveyResponseId, surveyResponse }) => {
    return (
      await apiClient.patch(
        `v1/features/${featureName}/form/responses/${surveyResponseId}`,
        surveyResponse
      )
    ).data;
  }, options);
};

export const useGetSurveyResponse = (
  featureName: string,
  options: UseQueryOptions<SurveyMonkeyResponse, AxiosError> = {}
) => {
  const apiClient = useApiClient();
  const queryKey = ["surveyResponse", featureName];
  return useQuery<SurveyMonkeyResponse, AxiosError>(
    queryKey,
    async () => {
      return (await apiClient.get(`v1/features/${featureName}/form/response`))
        .data;
    },
    options
  );
};
