import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";
import TealButton from "../../buttons/TealButton/TealButton";

const ErrorH2 = styled.h2`
  color: ${themeColor("red")};
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  min-width: 250px;
  button {
    display: flex;
    flex-grow: 1;
    max-width: 200px;
  }
  ${themeMinWidth("small")} {
    button {
      flex-grow: 0;
    }
  }
`;

export type ErrorProps = {
  header: string;
  message: string;
  primary: {
    action: () => void;
    label: string;
  };
  secondary: {
    action: () => void;
    label: string;
  };
};

export const Error: React.FC<ErrorProps> = ({
  header,
  message,
  secondary,
  primary,
}) => {
  // If wrapped
  // with <> it inherits
  // undesirable CSS (flex).
  return (
    <div>
      <ErrorH2>{header}</ErrorH2>
      <p>{message}</p>
      <ButtonArea>
        <TealButton variant="secondary" onClick={secondary.action}>
          {secondary.label}
        </TealButton>{" "}
        <TealButton onClick={primary.action}>{primary.label}</TealButton>
      </ButtonArea>
    </div>
  );
};
