import React, { useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";
import styled from "styled-components/macro";
import {
  isDaybreakError,
  isDaybreakErrorV4,
  RequestError,
} from "../../api/apiClient";
import { useApi } from "../../api/apiContext";
import { CenteredLoading } from "../Loading/Loading";
import QuizPart from "../QuizPart/QuizPart";
import {
  isClosedScreenerStatus,
  ClosedScreenerStatus,
} from "../../api/queries/userFeatures";
import { getFormByFeatureQueryKey } from "../../api/queries/forms";
import { trackEvent } from "client/amplitudeHelper";

export type MultipartQuizProps = {
  questionIndex: number;
  featureApiKey: string;
  goToQuestion: (index: number) => void;
  onQuizEnded: () => void;
  onClosedError?: (status: ClosedScreenerStatus) => void;
};

const LoadingContainer = styled.div`
  display: flex;
  padding: 80px;
`;

const isQuizOverError = (error: RequestError | null) =>
  error && isDaybreakErrorV4(error) && error.errorType === "feature_complete";

export const isClosedScreenerStatusError = (error: RequestError | null) =>
  error && isDaybreakErrorV4(error) && isClosedScreenerStatus(error.errorType);

// This component understands multi-phase quizes, and can render a specific
// question within them.  It does *not* know about page routing.
const MultipartQuiz: React.FC<MultipartQuizProps> = ({
  featureApiKey,
  questionIndex,
  goToQuestion,
  onQuizEnded,
  onClosedError,
}) => {
  const { useGetFormByFeature } = useApi();

  const {
    data: { data: form } = {},
    error: getFormError,
    isLoading: isLoadingForm,
  } = useGetFormByFeature({
    featureApiKey: featureApiKey,
    options: {
      // Keep surveys cached for 5 minutes.  We *will* need to explicitly bust
      // this cache because the result of this survey query changes whenever we
      // complete a quiz part.
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      useErrorBoundary: (error) => {
        if (isQuizOverError(error)) {
          // This is fine - there are just no more parts to this quiz and we
          // should move on to the next thing.  Don't throw an exception -
          // instead just let the `error` field we return from this hook be
          // populated.
          return false;
        }
        // When a screener isn't open we want to handle directly
        if (onClosedError && isClosedScreenerStatusError(error)) {
          return false;
        }
        // Otherwise, throw an exception and let our error boundaries catch it.
        return true;
      },
      retry: (failureCount, error) => {
        if (isQuizOverError(error)) {
          return false;
        }
        if (isClosedScreenerStatusError(error)) {
          return false;
        }
        return failureCount < 3;
      },
    },
  });

  const {
    mutate: completeMilestone,
    isLoading: completingMilestone,
  } = useApi().usePutMilestoneV2Complete();

  useEffect(() => {
    if (isQuizOverError(getFormError)) {
      onQuizEnded();
    }
    if (isClosedScreenerStatusError(getFormError)) {
      trackEvent("screenerNotOpen", {
        errorInfo: getFormError,
      });
      if (
        getFormError &&
        isDaybreakError(getFormError) &&
        isClosedScreenerStatus(getFormError.error_type)
      ) {
        const error_type = getFormError.error_type;
        if (onClosedError && error_type) {
          onClosedError(error_type);
        }
      }
    }
  }, [getFormError, onQuizEnded, onClosedError]);

  const queryClient = useQueryClient();

  const onAnswer = useCallback(
    (partFinished: boolean) => {
      console.log(`onAnswer called for ${questionIndex}`);
      if (partFinished) {
        // If we've finished this part of the survey, mark it complete and go back
        // to question 0 (where we expect to fetch the next survey if one is
        // available).

        if (!form || !form.featureData) {
          throw new Error(
            `No milestone available for survey with id '${form?.id}'`
          );
        }

        const milestoneId = form.featureData.milestoneId;

        completeMilestone(
          { milestoneId },
          {
            onSuccess: () => {
              console.log(
                `milestone completed called for question ${questionIndex}, milestone ${milestoneId}`
              );

              // Clear out the last survey so it doesn't show for a second while
              // we load question 0 on the next survey.
              const queryKey = getFormByFeatureQueryKey(featureApiKey);
              queryClient.removeQueries(queryKey);
              console.log(
                `Cache cleared after completing milestone ${milestoneId}`
              );
              goToQuestion(0);
            },
          }
        );
      } else {
        // Otherwise, just move on to the next question.
        goToQuestion(questionIndex + 1);
      }
    },
    [
      completeMilestone,
      featureApiKey,
      goToQuestion,
      queryClient,
      questionIndex,
      form,
    ]
  );

  const onBack = useCallback(() => {
    goToQuestion(questionIndex > 0 ? questionIndex - 1 : questionIndex);
  }, [goToQuestion, questionIndex]);

  const onSaveError = useCallback(
    (error) => {
      if (isDaybreakError(error)) {
        if (error.error_type === "local_store_expired") {
          console.warn(
            "Local store expired - returning to beginning of quiz part."
          );
          goToQuestion(0);
        }
        if (isClosedScreenerStatusError(error)) {
          trackEvent("screenerNotOpen", {
            errorInfo: error,
          });
          if (
            error &&
            isDaybreakError(error) &&
            isClosedScreenerStatus(error.error_type)
          ) {
            const error_type = error.error_type;
            if (onClosedError && error_type) {
              onClosedError(error_type);
            }
          }
        }
      }
    },
    [goToQuestion, onClosedError]
  );

  // Show the loading spinner if we're loading the survey or if the whole quiz
  // is over (in which case we're already in the process of redirecting to the
  // next page).
  if (
    !form ||
    isLoadingForm ||
    completingMilestone ||
    isQuizOverError(getFormError)
  ) {
    return (
      <LoadingContainer>
        <CenteredLoading />
      </LoadingContainer>
    );
  }

  return (
    <QuizPart
      {...{
        questionIndex,
        form,
        onAnswer,
        onBack,
        onSaveError,
        featureApiKey,
      }}
    />
  );
};

export default MultipartQuiz;
