import React from "react";
import styled from "styled-components";
import { theme } from "../../app/theme";

const Circle = styled.div`
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-transform: capitalize;
`;
const sizes = {
  small: 32,
  medium: 38,
  large: 48,
};
export type InitialProps = {
  color?: string;
  size?: "small" | "medium" | "large";
};
const Initial: React.FC<InitialProps> = ({
  children,
  color = theme.colors.teal,
  size = "large",
}) => {
  const text = children?.toString() ?? "";
  return (
    <Circle
      className="initial-circle"
      style={{
        backgroundColor: color,
        width: sizes[size],
        minWidth: sizes[size],
        height: sizes[size],
      }}
    >
      {text[0]}
    </Circle>
  );
};

export default Initial;
