import { SortingState } from "@tanstack/react-table";
import React, { useMemo } from "react";
import styled from "styled-components/macro";
import { OrganizationMemberV1 } from "../../../api/queries/organizationMembers";
import { themeColor, themeMinWidth } from "../../../app/theme";
import TealButton from "../../../components/buttons/TealButton/TealButton";
import TableV2, { createTableV2 } from "../../../components/TableV2/TableV2";
import TextLink from "../../../components/TextLink/TextLink";
import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";
import { DateTime } from "luxon";
import Referrals from "../../../lib/models/referrals";
import Loading from "../../../components/Loading/Loading";
import { trackEvent } from "client/amplitudeHelper";

const Container = styled.div`
  .table-element {
    width: 600px;
    ${themeMinWidth("small")} {
      width: 100%;
    }
  }
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 32px;
`;
const LinkContainer = styled.div`
  display: flex;
  align-items: center;
`;
const PendingContainer = styled.div`
  min-width: 165px;
  justify-content: center;
  display: flex;
  opacity: 0.7;
`;
const LoadingContainer = styled.div`
  position: relative;
  top: -3px;
  margin-right: 16px;
`;
const PendingInsuranceText = styled.div`
  color: ${themeColor("darkGrayText7")};
`;
export type NeedsInsuranceTableProps = {
  data: OrganizationMemberV1[];
  setPageIndex: (pageIndex: number) => void;
  pageIndex: number;
  maxPageIndex: number;
  setPageSort: (sort: SortingState[0]) => void;
};

const table = createTableV2<OrganizationMemberV1>();
const NeedsInsuranceTable: React.FC<NeedsInsuranceTableProps> = ({
  data,
  setPageIndex,
  pageIndex,
  maxPageIndex,
  setPageSort,
}) => {
  const columns = useMemo(
    () => [
      table.createDataColumn("firstName", {
        id: "first_name",
        header: "Student Name",
        cell: (cell) =>
          cell.row.original?.firstName + " " + cell.row.original?.lastName,
      }),
      table.createDataColumn("referral", {
        id: "referred_at",
        header: "Days Since Referral",
        cell: ({ row }) => {
          const referredAt = row.original?.referral?.referredAt;
          if (!referredAt) return <></>;

          const timeAgo = DateTime.fromSeconds(referredAt).diffNow("days");
          return Math.abs(timeAgo.days).toFixed();
        },
      }),
      table.createDisplayColumn({
        id: "insuranceRequestUrl",
        header: () => {
          return <></>;
        },
        cell: ({ row }) => {
          const orgMember = row.original;
          const referral = orgMember?.referral;
          const partialReferral = referral?.partialReferral;
          const insuranceUrlRaw = referral?.insuranceRequestUrl;
          if (!insuranceUrlRaw)
            return (
              <Buttons>
                <PendingContainer>
                  <LoadingContainer>
                    <Loading size="mini" widthOverride={16} />
                  </LoadingContainer>
                  <PendingInsuranceText>Pending</PendingInsuranceText>
                </PendingContainer>
              </Buttons>
            );

          const insuranceUrlString = Referrals.staffInsuranceFormUrl({
            insuranceRequestUrl: insuranceUrlRaw,
            organizationMemberId: orgMember?.id ?? "",
          });

          const copyUrl = () => {
            // This url shouldn't have any extra params because it's meant to be
            // sent to parents.
            if (!insuranceUrlRaw) return;
            copy(insuranceUrlRaw);
            toast.success("Url copied to clipboard");

            trackEvent("SCD:copiedParentLinkToSalesforceInsuranceForm", {
              insuranceUrlRaw,
              referralExternalId: referral?.externalId,
              orgMemberId: orgMember?.id,
            });
          };
          const teenName = orgMember?.firstName + " " + orgMember?.lastName;
          const parentEmail = partialReferral?.guardianEmail;
          const parentFirstName = partialReferral?.guardianFirstName;

          const mailTo = () => {
            if (!insuranceUrlRaw) return;
            const mailToLink = Referrals.parentInsuranceFormMailto({
              parentEmail: parentEmail ?? "",
              parentFirstName,
              teenName,
              insuranceFormUrl: insuranceUrlRaw,
            });
            trackEvent(
              "SCD:activedParentMailToForSalesforceInsuranceForm",
              {
                mailToLink,
                referralExternalId: referral?.externalId,
                orgMemberId: orgMember?.id,
              },
              () => {
                window.location.assign(mailToLink);
              }
            );
          };

          const visitForm = () => {
            if (!insuranceUrlString) return;
            trackEvent(
              "SCD:clickedStaffLinkToSalesforceInsuranceForm",
              {
                insuranceUrlString,
                referralExternalId: referral?.externalId,
                orgMemberId: orgMember?.id,
              },
              () => {
                window.location.href = insuranceUrlString;
              }
            );
          };

          return (
            <Buttons>
              <LinkContainer>
                <TextLink onClick={mailTo}>Email Parent</TextLink>
              </LinkContainer>
              <LinkContainer>
                <TextLink onClick={copyUrl}>Copy Form URL</TextLink>
              </LinkContainer>
              <TealButton onClick={visitForm}>Add Insurance Info</TealButton>
            </Buttons>
          );
        },
      }),
    ],
    []
  );

  return (
    <Container>
      <TableV2
        table={table}
        columns={columns}
        data={data}
        updateData={({ sorting, currentPageIndex }) => {
          if (currentPageIndex != null) {
            setPageIndex(currentPageIndex);
          }
          if (sorting != null) {
            setPageSort(sorting[0]);
          }
        }}
        currentPageIndex={pageIndex}
        maxPageIndex={maxPageIndex}
        paginationProps={{
          showStepNext: true,
          showJumpToLimit: maxPageIndex > 2,
        }}
      />
    </Container>
  );
};

export default NeedsInsuranceTable;
