import React from "react";
import styled from "styled-components/macro";
import { themeColor, themeFont } from "../../app/theme";
import CenteredLayout from "../../layouts/CenteredLayout/CenteredLayout";

export type DisabledPageProps = {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;
const Header = styled.div`
  font-size: 32px;
  color: ${themeColor("teal")};
  font-weight: 500;
`;
const Body = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-family: ${themeFont("helveticaNeue")};
  max-width: 400px;
  text-align: center;
  line-height: 140%;
`;

const DisabledPage: React.FC<DisabledPageProps> = () => {
  return (
    <CenteredLayout headerStyle="onboarding">
      <Container>
        <Header>Missing Page</Header>
        <Body>
          We're sorry, but the page you're looking for is missing. Try heading
          back to{" "}
          <a href="https://www.daybreakhealth.com">DaybreakHealth.com</a>.
        </Body>
      </Container>
    </CenteredLayout>
  );
};

export default DisabledPage;
