import { Trans } from "react-i18next";
import {
  StandardCheckboxField,
  StandardTextField,
} from "../../../components/Forms/Field/StandardField";
import {
  H2,
  LegalSection,
  LegalText,
  SignatureCheckboxes,
  SignatureDescription,
  SignatureTextbox,
  SubmitButtonSectionV2,
} from "../elements";
import TextLink from "../../../components/TextLink/TextLink";
import { TFunction } from "i18next";
import { FormikErrors, FormikTouched } from "formik";
import { FormEvent } from "react";
import Loading from "../../../components/Loading/Loading";
import TealButton from "../../../components/buttons/TealButton/TealButton";
import { trackEvent } from "client/amplitudeHelper";

const PrivacyPolicyCheckboxLabel = ({ t }: { t: TFunction }) => (
  <>
    <Trans
      components={{
        textLink: (
          <TextLink href="https://www.daybreakhealth.com/daybreak-privacy-policy">
            {t("labelTOSPP")}
          </TextLink>
        ),
      }}
    >
      {t("labelAgreeTOSPP", {})}
    </Trans>
  </>
);

export type ConsentAndSignatureProps = {
  t: TFunction;
  agreementText: string;
  errors: FormikErrors<unknown>;
  touched: FormikTouched<unknown>;
  isSubmitting: boolean;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
};

const ConsentAndSignature = ({
  t,
  agreementText,
  isSubmitting,
  handleSubmit,
}: ConsentAndSignatureProps): JSX.Element => {
  return (
    <LegalSection>
      <H2>{t("authorizationPrivacyTitle")}</H2>
      <LegalText
        onScroll={(e) => {
          if (
            e.currentTarget.scrollTop + e.currentTarget.clientHeight ===
            e.currentTarget.scrollHeight
          ) {
            trackEvent("PWP:scrollInformedConsent");
          }
        }}
      >
        {agreementText}
      </LegalText>
      <SignatureCheckboxes>
        <StandardCheckboxField
          name="agreementTosPp"
          label={<PrivacyPolicyCheckboxLabel t={t} />}
          tracking="PWP"
        />
        <StandardCheckboxField
          name="agreementIc"
          label={t("labelAgreeInformedConsent")}
          tracking="PWP"
        />
        <StandardCheckboxField
          name="agreementAobHipaa"
          label={t("labelAgreeAOBPayers")}
          tracking="PWP"
        />
        <StandardCheckboxField
          name="agreementHipaaRoi"
          label={t("labelAgreeHIPAASchools")}
          tracking="PWP"
        />
      </SignatureCheckboxes>
      <p>{t("textForMultiPartyConsent")}</p>
      <SignatureDescription>
        {t("signatureFromParentDescription")}
      </SignatureDescription>
      <SignatureTextbox>
        <StandardTextField
          name="agreementSignature"
          label={t("labelEnterFullName")}
          tracking="PWP"
        />
      </SignatureTextbox>
      <SubmitButtonSectionV2>
        {isSubmitting ? (
          <Loading size="small" />
        ) : (
          <TealButton
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {t(isSubmitting ? "signButtonSubmitting" : "signButton")}
          </TealButton>
        )}
      </SubmitButtonSectionV2>
    </LegalSection>
  );
};

export default ConsentAndSignature;
