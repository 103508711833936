import React, { useEffect } from "react";
import { RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import { CenteredLoading } from "../../components/Loading/Loading";
import styled from "styled-components/macro";
import OnboardingLayout from "../../layouts/OnboardingLayout/OnboardingLayout";
import {
  AUTH0_AUDIENCE,
  AUTH0_REDIRECT_URI,
} from "../../api/DaybreakAuthProvider";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useApi } from "../../api/apiContext";
import { trackEvent } from "client/amplitudeHelper";

const Container = styled.div`
  padding: 40px;
  min-height: 400px;
`;
export type AuthRedirectPageProps = { kind: "signup" | "login" };
const AuthRedirectPage: React.FC<AuthRedirectPageProps> = (
  { kind } = { kind: "signup" }
) => {
  useEffect(() => {
    trackEvent(kind);
  }, [kind]);

  const { isLoading: auth0IsLoading, loginWithRedirect } = useAuth0();
  const token = useQueryParams().get("token") ?? undefined;

  // If we have an invite token in the url and we're signing up, let's grab that
  // invite from the api and use the email address attached to it to prepopulate
  // auth0.
  const {
    data: invite,
    isLoading: inviteIsLoading,
    error: inviteError,
  } = useApi().useGetInvite(token, {
    enabled: !!token && kind === "signup",
  });
  if (inviteError) {
    // The invite should have already been checked for validity on a previous
    // screen, so something must have gone quite wrong.
    throw inviteError;
  }

  if (!auth0IsLoading && !inviteIsLoading) {
    const redirectArgs: RedirectLoginOptions = {
      audience: AUTH0_AUDIENCE,
      screen_hint: kind,
      redirectUri: AUTH0_REDIRECT_URI,
      appState: {
        kind,
        inviteToken: token,
      },
      redirectMethod: "replace",
    };

    // Prepopulate the email field if we're signing up via an invite token
    if (invite) {
      redirectArgs.login_hint = invite.email;
    }

    loginWithRedirect(redirectArgs);
  }

  return (
    <OnboardingLayout>
      <Container>
        <CenteredLoading />
      </Container>
    </OnboardingLayout>
  );
};

export default AuthRedirectPage;
