import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useApiClient } from "../apiClient";
import { OrganizationMember } from "./organizationMembers";
import { PartialReferral } from "./partialReferrals";
import {
  convertSerializedType,
  createUseApiMutation,
  createUseApiQuery,
  optionalParams,
  V4PaginationParams,
  withApiErrorHandling,
} from "./queryHelpers";
import { FlatIntake } from "./intakes";

type PostReferralsVariables = {
  organizationMemberId: string;
  status?: ReferralV1["status"];
};
// This is the shape of the referral records returned by the v4 serializers in
// the backend.
export type ReferralV1 = {
  id: string;
  status:
    | "referral_queued"
    | "referral_in_progress"
    | "referral_completed"
    | "prereferral"
    | "waiting_on_pwp"
    | null;
  insuranceStatus:
    | "not_applicable"
    | "pending"
    | "requested"
    | "processing"
    | "received"
    | "verified"
    | "no_insurance";
};
type PostReferralsResponse = {
  data: ReferralV1;
};
export const usePostReferrals = (
  options: UseMutationOptions<
    PostReferralsResponse,
    AxiosError,
    PostReferralsVariables
  > = {}
) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<PostReferralsResponse, AxiosError, PostReferralsVariables>(
    async ({ organizationMemberId, status }) => {
      return withApiErrorHandling(async () => {
        const response = await apiClient.post(`referrals/v1`, {
          organizationMemberId,
          status,
        });
        return convertSerializedType(response.data);
      });
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("organizationMembers");
        options.onSuccess && options.onSuccess(data, variables, context);
      },
      ...options,
    }
  );
};

type DeleteReferralsVariables = {
  referralId: string;
};
type DeleteReferralsResponse = {};
export const useDeleteReferrals = (
  options: UseMutationOptions<
    DeleteReferralsResponse,
    AxiosError,
    DeleteReferralsVariables
  > = {}
) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<
    DeleteReferralsResponse,
    AxiosError,
    DeleteReferralsVariables
  >(
    async ({ referralId }) => {
      return withApiErrorHandling(async () => {
        const response = await apiClient.delete(`referrals/v1/${referralId}`);
        return convertSerializedType(response.data);
      });
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("organizationMembers");
        options.onSuccess && options.onSuccess(data, variables, context);
      },
      ...options,
    }
  );
};

export type ReferralsPatchArguments = {
  id: string;
  status?: ReferralV1["status"];
  referredAt?: number;
  insuranceStatus?: ReferralV1["insuranceStatus"];
};
export const usePatchReferrals = createUseApiMutation<
  ReferralV1,
  ReferralsPatchArguments
>({
  requestBuilder: ({ id, status, referredAt, insuranceStatus }) => ({
    method: "patch",
    url: `/referrals/v1/${id}`,
    data: {
      referral: optionalParams({
        status,
        referredAt,
        insuranceStatus,
      }),
    },
  }),
});

// This is the shape of the referral records returned by the v4 serializers in
// the backend.
export type Referral = ReferralV1 & {
  createdAt: string;
  insuranceRequestUrl: string | null;
  status:
    | "referral_queued"
    | "referral_in_progress"
    | "referral_completed"
    | null;
  insuranceStatus:
    | "not_applicable"
    | "pending"
    | "requested"
    | "processing"
    | "received"
    | "verified"
    | "no_insurance";
  referredAt: string;
  organizationMember?: OrganizationMember;
  partialReferral?: PartialReferral;
  referralJourneyStatus:
    | "Pending"
    | "Closed"
    | "Closed:_Client_not_Responsive"
    | "Counseling_Declined"
    | "Counseling_Not_Recommended"
    | "Daybreak_Working"
    | "Discharged"
    | "Insurance_Info_Needed"
    | "Intake_Missed"
    | "Match_in_Progress"
    | "Ongoing_Care"
    | "Waiting_for_Intake"
    | "Scheduling";
  externalId: string;
  intake?: FlatIntake;
};
export type UseGetReferralsArguments = {
  filter?: {
    needs_scheduling?: boolean;
    organization_member_external_id?: string;
    referral_completed?: boolean;
  };
  sort: string[];
  include?: (
    | "partial_referral"
    | "discharge"
    | "intake"
    | "organization_member"
    | "submitter"
  )[];
} & V4PaginationParams;
export const useGetReferrals = createUseApiQuery<
  Referral[],
  UseGetReferralsArguments
>({
  queryBuilder: ({ filter, page, perPage, sort, include }) => {
    // ToDo: Better support referral status filtering.
    // (e.g. for "waiting on PWP," "prereferral", mix n match, etc.)
    const params = {
      filter: optionalParams({
        needs_scheduling: filter?.needs_scheduling,
        organization_member_external_id:
          filter?.organization_member_external_id,
        referral_completed: filter?.referral_completed,
      }),
      page,
      per_page: perPage,
      include: include?.join(","),
      sort: sort?.join(","),
    };
    return {
      url: `v4/referrals`,
      cacheKey: ["v4/referrals", params],
      config: { params },
    };
  },
});
