import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { handleResponseData, withApiErrorHandling } from "./queryHelpers";
import { RequestError, useApiClient } from "../apiClient";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "react-query";
import { decamelizeKeys } from "humps";

/**
 * Every "resourceful" API function fundamentally takes in variable data,
 * and responds (asyncronously) with s JSON hash of that resource's type.
 */
type ApiFunction<T> = (...args: any[]) => Promise<AxiosResponse<T>>;

/**
 * A "resource" consists of the standard CRUD operations -
 * - Index (query)
 * - Init (alias for 'new')
 * - Create
 * - Get (show)
 * - Update
 * - Upsert
 * - Delete
 * Each of these is a function, hence `resourceFunctions` -
 * the functions that make up a resource.
 *
 * Note that at this time, only init (new) and create are implemented.
 *
 * @params T - A type that describes the schema of the resource
 */
type resourceFunctions<T> = {
  // index
  init: (config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>; // Aliased b/c `new` is a reserved word
  create: (data: T, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
  // upsert?
  get: (id: string, config?: AxiosRequestConfig) => Promise<AxiosResponse>;
  update: (
    id: string,
    data: T,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse>;
  // delete

  // This is a Typescript feature called "index type", and it's what allows
  // traversing a resourceFunctions object and do stuff (like wrap each in more middleware).
  [key: string]: ApiFunction<T>;
};

/**
 * Given a path (and, because we're not in a React context yet) an Axios client,
 * we can build the functions for the resource represented by that path.
 *
 * Note that *this* function is the most basic layer; it's missing our standard error
 * handling, deserializion, and other middleware.
 *
 * Generally not recommended to use, but sometimes needs must.
 *
 * @params T - A type that describes the schema of the resource
 * @params base - The base URL path to the resource; for daybreakhealth.com/api/v1/users,
 *   the base would be `/api/v1/users`. Relative to the Axios client's configured host.
 * @params apiClient - An Axios client, configured how you want :)
 *
 * @returns resourceFunctions<T> - A set of API functions that can be used to interact with the resource.
 */
const buildResourceFunctions = <T>(
  base: string,
  apiClient: AxiosInstance
): resourceFunctions<T> => {
  return {
    // index: (config) => apiClient.get(base, config),
    init: (config) => apiClient.get(`${base}/new`, config),
    create: (data, config) =>
      apiClient.post(base, decamelizeKeys(data), config),
    get: (id, config) => apiClient.get(`${base}/${id}`, config),
    // upsert: (data, config) => apiClient.put(base, data, config),
    update: (id, data, config) =>
      apiClient.patch(`${base}/${id}`, decamelizeKeys(data), config),
    // delete: (id, config) => apiClient.delete(`${base}/${id}`, config),
  };
};

/**
 * Wraps an object of functions with error handling and deserialization.
 * Built to not require changes if the underlying `resourceFunctions` change.
 *
 * Update this with more middleware as needed.
 *
 * TODO: Axios already has stuff for middleware - we can probably move these to that.
 *
 * @params T - A type that describes the schema of the resource
 * @params funcs - A set of API functions
 *
 * @returns resourceFunctions<T> - A set of API functions, now wrapped
 *   in error handling and deserialization.
 */
const buildHandledFunctions = <T>(
  funcs: resourceFunctions<T>
): resourceFunctions<T> =>
  Object.keys(funcs).reduce((acc, key) => {
    const wrapped: ApiFunction<T> = (...args: any[]) =>
      withApiErrorHandling(() =>
        funcs[key](...args).then((response) =>
          handleResponseData(response.data)
        )
      );

    acc[key] = wrapped;

    return acc;
  }, {} as any);

/**
 * This is the recommend layer to use if you're outside of a React context,
 * or when hooks are otherwise not desirable.
 *
 * It's a simple composition of buildResourceFunctions and buildHandledFunctions,
 * so that you get back a set of resource API functions with our common middleware.
 *
 * @params ResourceType - A type that describes the schema of the resource
 * @params apiClient - An Axios client, configured how you want :)
 * @params base - The base URL path to the resource; for daybreakhealth.com/api/v1/users,
 *   the base would be `/api/v1/users`. Relative to the Axios client's configured host.
 *
 * @returns resourceFunctions<ResourceType> - A set of API functions that can be used
 *  to interact with the resource, wrapped in error handling and deserialization.
 */
export const buildResource = <ResourceType>(
  apiClient: AxiosInstance,
  base: string
) => {
  return buildHandledFunctions<ResourceType>(
    buildResourceFunctions<ResourceType>(base, apiClient)
  );
};

/**
 * Wraps the resource functions in React Query hooks.
 * This is the recommended layer to use, and usage is *very* straightforward:
 *
 * type MyResource = { ... }
 * const useMyResourceHooks = useResourceHooks<MyResource>('/my-resource');
 *
 * const { useInit, useCreate } = useMyResourceHooks();
 *
 * const { data: newMyResource, isLoading } = useInit({ ...initialValues });
 *
 * const { mutate: createMyResource } = useCreate();
 * createMyResource({ ...values });
 *
 * And now you're back on the happy path, and the same path as the QueryHelpers API code :)
 *
 * This is specifically built to *limit* what you can do
 * (eg, init can only receive attributes for the resource,
 * and not additional parameters) to add things like (more) type safety
 * that is otherwise lacking.
 *
 * TODO: Implement passing through options to the Axios requests. (Right now, there's no need,
 *   so it's hard to tell what *should* be built, and how)
 *
 * @params ResourceType - A type that describes the schema of the resource
 * @params base - The base URL path to the resource; for daybreakhealth.com/api/v1/users,
 *  the base would be `/api/v1/users`. Relative to the Axios client's configured host.
 * @params auth - Whether or not to use the authenticated Axios client
 */
const useResourceHooks = <ResourceType>(base: string, auth = true) => {
  const apiClient = useApiClient({ auth });

  const resource = buildResource<ResourceType>(apiClient, base);

  return {
    useInit: (
      data?: Partial<ResourceType>,
      options?: UseQueryOptions<
        { data: ResourceType; included?: any },
        RequestError,
        ResourceType
      >
    ) =>
      useQuery({
        queryKey: data ? `${base}:${JSON.stringify(data)}` : base,
        queryFn: async () => resource.init({ params: data }),
        ...options,
      }),
    useCreate: (
      options?: UseMutationOptions<
        { data: ResourceType },
        RequestError,
        ResourceType
      >
    ) =>
      useMutation({
        mutationFn: async (data) => resource.create(data),
        ...options,
      }),
    useGet: (id: string) =>
      useQuery<{ data: ResourceType; included?: any }, RequestError>(
        `${base}:${id}`,
        async () => resource.get(id)
      ),
    useUpdate: (
      id: string,
      options?: UseMutationOptions<
        { data: ResourceType },
        RequestError,
        ResourceType
      >
    ) =>
      useMutation({
        mutationFn: async (data) => resource.update(id, data),
        ...options,
      }),
  };
};

export default useResourceHooks;
