import { DateTime } from "luxon";
import React from "react";
import styled from "styled-components/macro";
import { Referral } from "../../../../api/queries/referrals";
import { themeColor } from "../../../../app/theme";
import Referrals from "../../../../lib/models/referrals";

export type ReferralHistoryBoxProps = { referrals: Referral[] };

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const ReferralLine = styled.div`
  display: flex;
  gap: 8px;
`;
const Date = styled.div`
  color: ${themeColor("darkGrayText5")};
`;
const Status = styled.div`
  color: ${themeColor("darkGrayText6")};
`;

const formatTime = (utcString: string) =>
  DateTime.fromISO(utcString).toLocaleString({
    month: "long",
    day: "numeric",
    year: "numeric",
  });
const ReferralHistoryBox: React.FC<ReferralHistoryBoxProps> = ({
  referrals,
}) => {
  return (
    <Container>
      {referrals.map((referral) => (
        <ReferralLine key={referral.id}>
          <Date>{formatTime(referral.referredAt)}</Date>
          <Status>
            {Referrals.referralJourneyStatusToDisplayName(
              referral.referralJourneyStatus
            )}
          </Status>
        </ReferralLine>
      ))}
    </Container>
  );
};

export default ReferralHistoryBox;
