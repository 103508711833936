import { SortingState } from "@tanstack/react-table";
import React, { useMemo } from "react";
import styled from "styled-components/macro";
import { OrganizationMemberV1 } from "../../../api/queries/organizationMembers";
import { themeMinWidth } from "../../../app/theme";
import TealButton from "../../../components/buttons/TealButton/TealButton";
import TableV2, { createTableV2 } from "../../../components/TableV2/TableV2";
import EmptyTable from "./EmptyTable";

const ReferralButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 32px;
`;
const Container = styled.div`
  .table-element {
    width: 600px;
    ${themeMinWidth("small")} {
      width: 100%;
    }
  }
`;
const queuedTable = createTableV2<OrganizationMemberV1>();

export type QueuedTableProps = {
  data: OrganizationMemberV1[];
  setPageIndex: (pageIndex: number) => void;
  pageIndex: number;
  maxPageIndex: number;
  setPageSort: (sort: SortingState[0]) => void;
  referStudent: (organizationMemberId: string) => void;
  removeFromQueue: (referralId: string) => void;
  openAddToQueueModal: () => void;
};
const QueuedTable: React.FC<QueuedTableProps> = ({
  data,
  setPageIndex,
  pageIndex,
  maxPageIndex,
  setPageSort,
  referStudent,
  removeFromQueue,
  openAddToQueueModal,
}) => {
  const queuedColumns = useMemo(
    () => [
      queuedTable.createDataColumn("firstName", {
        id: "first_name",
        header: "Student Name",
        cell: (cell) =>
          cell.row.original?.firstName + " " + cell.row.original?.lastName,
      }),
      queuedTable.createDataColumn("gradeLevel", {
        id: "grade",
        enableSorting: false,
        header: "Grade",
        cell: (cell) => cell.getValue(),
      }),
      queuedTable.createDisplayColumn({
        id: "refer button",
        header: () => {
          return <></>;
        },
        cell: ({ row }) => {
          const referralId = row.original?.referral?.id;
          return (
            <ReferralButtons>
              <TealButton
                onClick={() => row.original && referStudent(row?.original?.id)}
              >
                Refer
              </TealButton>
              <TealButton
                onClick={
                  referralId &&
                  (() => {
                    removeFromQueue(referralId);
                  })
                }
              >
                Remove from Queue
              </TealButton>
            </ReferralButtons>
          );
        },
      }),
    ],
    [referStudent, removeFromQueue]
  );
  return (
    <Container>
      <TableV2
        table={queuedTable}
        columns={queuedColumns}
        data={data}
        emptyContent={
          <EmptyTable
            message="No students have been added to the queue yet."
            ctaText="Add a student to queue"
            onCTA={openAddToQueueModal}
          />
        }
        updateData={({ sorting, currentPageIndex }) => {
          if (currentPageIndex != null) {
            setPageIndex(currentPageIndex);
          }
          if (sorting != null) {
            setPageSort(sorting[0]);
          }
        }}
        currentPageIndex={pageIndex}
        maxPageIndex={maxPageIndex}
        paginationProps={{
          showStepNext: true,
          showJumpToLimit: maxPageIndex > 2,
        }}
      />
    </Container>
  );
};

export default QueuedTable;
