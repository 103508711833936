import qs from "qs";

export type PrefillOptions = {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  studentEmail?: string;
  studentDOB?: string; // MM/DD/YYYY
  studentUniqueId?: string;
  referringStaffEmail?: string;
  referringStaffFirstAndLastName?: string;
  referringStaffFirstName?: string;
  referringStaffLastName?: string;
  referringStaffPhone?: string;
  referringStaffTitle?: string;
  schoolDistrictName?: string;
  referralExternalId?: string;
  organizationId?: string;
  organizationMemberId?: string;
  organizationStaffMemberId?: string;
};

const AcuityFieldMappings: {
  [k: string]: Record<string, string | null>;
} = {
  production: {
    studentEmail: "field:11967453",
    studentDOB: "field:11967454",
    studentUniqueId: "field:11967456",
    referringStaffEmail: "field:11967458",
    referringStaffFirstAndLastName: "field:10212480",
    referringStaffFirstName: "field:11967461",
    referringStaffLastName: "field:11967465",
    referringStaffPhone: "field:11967467",
    referringStaffTitle: "field:11967460",
    schoolDistrictName: "field:11967471",
    referralExternalId: "field:11967439",
    organizationId: "field:11973566",
    organizationMemberId: "field:11973563",
    organizationStaffMemberId: "field:11973565",
  },
  production_staff_basic: {
    referralExternalId: "field:12445496",
  },
  // for now development environments will using staging mappings
  staging: {
    studentEmail: "field:11956455",
    studentDOB: "field:11956456",
    studentUniqueId: "field:11956458",
    referringStaffEmail: "field:11956459",
    referringStaffFirstAndLastName: null,
    referringStaffFirstName: "field:11956462",
    referringStaffLastName: "field:11956469",
    referringStaffPhone: "field:11956470",
    referringStaffTitle: "field:11956460",
    schoolDistrictName: "field:12022406",
    referralExternalId: "field:11956665",
    organizationId: "field:11968089",
    organizationMemberId: "field:11968085",
    organizationStaffMemberId: "field:11968088",
  },
  staging_staff_basic: { referralExternalId: "field:12445500" },
};

// TODO: maybe find a way around this. I've tried to put most
// Acuity params like this into env vars. But storybook + chromatic
// doesn't read REACT_APP_... env vars and thus breaks the
// chromatic visual UI tests. To keep those going, I'm adding
// a default acuity owner, so the contents of the acuity iframe
// will always be _something_ and not 'page not found'
const DEFAULT_ACUITY_OWNER = "22405346";

export default function urlBuilder(
  appointmentType: string,
  prefillOptions: PrefillOptions,
  isBasic: boolean
): string {
  const params = convertPrefillFieldsToParms(prefillOptions, isBasic);
  params.owner = process.env.REACT_APP_ACUITY_OWNER_ID || DEFAULT_ACUITY_OWNER;
  params.appointmentType = appointmentType;
  const queryString = qs.stringify(params);
  return `https://app.acuityscheduling.com/schedule.php?${queryString}`;
}

// For human readability, we call Acuity form fields things like 'Referring School Staff Member - Title'
// and 'Student - Date of birth (MM/DD/YYYY)', but in Acuity these form fields have integer ids that
// Acuity to refer to the for the sake of prefilling form inputs. This function turns human-friendly
// keys/names in something that can be put into a query string for an Acuity URL to prefill fields.
function convertPrefillFieldsToParms(
  prefillOptions: PrefillOptions,
  isBasic: boolean
): Record<string, string> {
  // get the right mappings for this environment
  const isProd = process.env.REACT_APP_ACUITY_MAPPING === "production";
  const fieldMappingsKey = isProd
    ? isBasic
      ? "production_staff_basic"
      : "production"
    : isBasic
    ? "staging_staff_basic"
    : "staging";
  const fieldMappings = AcuityFieldMappings[fieldMappingsKey];

  const result: Record<string, string> = {};
  for (const [key, value] of Object.entries(prefillOptions)) {
    const acuityName = fieldMappings[key] || key;
    result[acuityName] = value || "";
  }
  return result;
}
