import { trackEvent } from "client/amplitudeHelper";
import { ErrorMessage, useFormikContext } from "formik";
import get from "lodash/get";
import React, { ReactElement } from "react";
import CheckboxField from "../CheckboxField/CheckboxField";
import DateField from "../DateField/DateField";
import UploadField from "../FileUpload/FileUpload";
import { SimpleRadioButtonGroup } from "../RadioButtonGroup/RadioButtonGroup";
import SelectField, { SelectStateField } from "../SelectField/SelectField";
import TextAreaField from "../TextAreaField/TextAreaField";
import TextField from "../TextField/TextField";
import { ErrorText, Info, Spacer, StyledField } from "./elements";

type RenderChildrenProps = {
  name: string;
  label: string | ReactElement | null;
  error: boolean;
  touch: boolean;
};

const useTracking = (
  prefix: string | undefined,
  name: string,
  callback: (args: unknown) => unknown
) => {
  if (!prefix) return {};

  const track = (e: React.ChangeEvent | React.MouseEvent) => {
    trackEvent(`${prefix}:${e.type}:${name}`);
    callback(e);
  };

  return {
    onChange: track,
    onFocus: track,
    onBlur: track,
    onClick: track,
  };
};

export type StandardFieldProps = {
  name: string;
  label: string | ReactElement | null;
  field:
    | typeof CheckboxField
    | typeof TextField
    | typeof TextAreaField
    | typeof SelectField
    | typeof SimpleRadioButtonGroup
    | typeof DateField
    | typeof UploadField;
  info?: string | ReactElement;
  tracking?: string;
  layout?: (field: ReactElement | null) => JSX.Element;
  renderChildren?: (props: RenderChildrenProps) => JSX.Element;
} & React.InputHTMLAttributes<
  HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
>;

const StandardField = ({
  name,
  label,
  field,
  info,
  tracking,
  layout,
  renderChildren,
  ...props
}: StandardFieldProps): JSX.Element => {
  const { errors, touched, isSubmitting, handleChange } = useFormikContext();
  const touch = !!get(touched, name, null);
  const error = touch && !!get(errors, name, null);

  const renderedChildren = renderChildren
    ? renderChildren({
        name,
        label,
        error,
        touch,
      })
    : props.children;

  const trackingHooks = useTracking(tracking, name, handleChange);

  const renderedField = field({
    name,
    error,
    label: label ?? "",
    children: renderedChildren,
    basicFormik: true,
    disabled: isSubmitting,
    ...trackingHooks,
    ...props,
  });

  const renderedLayout = layout ? layout(renderedField) : renderedField;

  return (
    <StyledField>
      {renderedLayout}
      {/* We want to ensure that the error messages always take up space,
          so that the form doesn't (generally) move around when they appear */}
      <Spacer>
        <ErrorMessage name={name} component={ErrorText} />
      </Spacer>
      {info && <Info>{info}</Info>}
    </StyledField>
  );
};

export type InputProps = Omit<StandardFieldProps, "field">;

export const StandardTextField = (props: InputProps) => (
  <StandardField field={TextField} {...props} />
);

export const StandardTextArea = (props: InputProps) => (
  <StandardField field={TextAreaField} {...props} />
);

export const StandardCheckboxField = (props: InputProps) => (
  <StandardField field={CheckboxField} {...props} />
);

export const StandardRadioButtonGroup = (props: InputProps) => (
  <StandardField field={SimpleRadioButtonGroup} {...props} />
);

export const StandardSelectField = (props: InputProps) => (
  <StandardField field={SelectField} {...props} />
);

export const StandardSelectStateField = (props: InputProps) => (
  <StandardField field={SelectStateField} {...props} />
);

export const StandardUploadField = (props: InputProps) => (
  <StandardField field={UploadField} {...props} />
);

export const StandardDateField = (props: InputProps) => (
  <StandardField field={DateField} {...props} />
);

export default StandardField;
