import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../app/theme";

export const HeaderContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
export const HeaderTitle = styled.div`
  font-size: 14px;
  color: ${themeColor("darkGrayText7")};
  font-weight: 500;
`;
export const HeaderSeeMore = styled.div`
  font-size: 12px;
  color: ${themeColor("teal")};
  display: flex;
  align-items: center;
  font-weight: 500;
  .icon {
    margin-left: 3px;
    height: 7px;
  }
  ${themeMinWidth("small")} {
    font-size: 14px;
    .icon {
      height: 8px;
    }
  }
`;
export const Body = styled.div`
  padding: 24px 16px 24px 16px;
  ${themeMinWidth("small")} {
    padding: 24px 16px 38px 16px;
  }
`;
export const Period = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${themeColor("darkGrayText7")};
  margin-bottom: 4px;
`;
export const LevelSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;
export const LevelCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
`;
export const LevelLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${themeColor("darkGrayText2")};
  text-transform: capitalize;
  ${themeMinWidth("small")} {
    font-size: 18px;
  }
`;
export const Direction = styled.div`
  display: flex;
  font-size: 12px;
  color: ${themeColor("darkGrayText7")};
  gap: 8px;
`;
export const EmptyBody = styled(Body)`
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const EmptyBodyImage = styled.img`
  width: 44px;
  height: 44px;
`;
export const EmptyBodyReminderLink = styled.a`
  color: ${themeColor("teal")};
  margin-left: 8px;
`;
