import React, { useState } from "react";
import RadioButtonGroup from "../../../components/Forms/RadioButtonGroup/RadioButtonGroup";
import SchoolCounselorLayout from "../../../layouts/SchoolCounselorLayout/SchoolCounselorLayout";
import { useApi } from "../../../api/apiContext";
import analytics from "../../../lib/analytics";
import { useHasOrganizationFeature } from "../../../hooks/useHasOrganizationFeature";
import { languages } from "../../../constants";
import { Container, Form, FormRow, Header } from "./elements";
import { PartialReferralForm } from "./PartialReferralForm";
import { useParams } from "react-router-dom";
import FamilyTherapyReferralForm from "./FamilyTherapyForm";

export const languageOptions = {
  ...languages,
  prefer_not_to_answer: "Prefer not to answer",
  other: "Other (please specify)",
};

export type ReferralFormPageProps = {};

const ReferralFormPage: React.FC<ReferralFormPageProps> = () => {
  analytics.usePage("SCD:PartialReferralPage");

  const { organizationMemberId } = useParams<{
    organizationMemberId?: string;
  }>();

  const [therapyKind, setTherapyKind] = useState<string>("");

  const api = useApi();

  const { data: { data: myOrg } = {} } = api.useGetMyOrg();

  const {
    data: { data: organizationMember } = {},
  } = api.useGetOrganizationMember({
    externalId: organizationMemberId ?? "",
    options: { enabled: !!organizationMemberId },
  });

  const {
    active: ssdLegacyInsuranceCollectionActive,
    loading: loadingSsdLegacyInsuranceCollectionActive,
  } = useHasOrganizationFeature("ssd_legacy_insurance_collection");

  const {
    active: familyTherapyAvailable,
    loading: loadingFamilyTherapyAvailable,
  } = useHasOrganizationFeature("family_therapy");

  const {
    active: consentToContactFeatureActive,
    loading: loadingConsentToContactFeatureActive,
  } = useHasOrganizationFeature("consent_to_contact_flow");

  const {
    mutate: createPartialReferral,
    error: createPartialReferralError,
  } = api.usePostPartialReferral({ options: { retry: false } });

  // TODO: API Error Handling
  const {
    mutate: createOrganizationMemberReferral,
  } = api.usePostOrganizationMemberReferral({ options: { retry: false } });

  if (
    !myOrg ||
    !organizationMemberId ||
    !organizationMember ||
    loadingFamilyTherapyAvailable ||
    loadingConsentToContactFeatureActive ||
    loadingSsdLegacyInsuranceCollectionActive
  ) {
    return <SchoolCounselorLayout loading />;
  }

  return (
    <SchoolCounselorLayout>
      <Container>
        <Header>Student Referral Information</Header>
        {familyTherapyAvailable && (
          <Form>
            <FormRow>
              <div>Referral Type:</div>
              <RadioButtonGroup
                radioButtonProps={[
                  {
                    value: "individual_therapy",
                    label: "Teletherapy (10-19)",
                    checked: therapyKind === "individual_therapy",
                  },
                  {
                    value: "family_therapy",
                    label: "Elementary Teletherapy (Family, 5-12)",
                    checked: therapyKind === "family_therapy",
                  },
                ]}
                commonRadioButtonProps={{
                  name: "form_kind",
                  onChange: (event) =>
                    setTherapyKind(event.currentTarget.value),
                }}
              />
            </FormRow>
            <br />
          </Form>
        )}
        {(!familyTherapyAvailable || therapyKind === "individual_therapy") && (
          <PartialReferralForm
            createPartialReferral={createPartialReferral}
            organizationMember={organizationMember}
            creationError={createPartialReferralError ?? undefined}
            myOrg={myOrg}
            collectInsuranceInfo={ssdLegacyInsuranceCollectionActive}
            allowScheduleNow={!consentToContactFeatureActive}
          />
        )}
        {therapyKind === "family_therapy" && (
          <FamilyTherapyReferralForm
            createReferral={createOrganizationMemberReferral}
            organizationMember={organizationMember}
            collectInsuranceInfo={ssdLegacyInsuranceCollectionActive}
            allowScheduleNow={!consentToContactFeatureActive}
          />
        )}
      </Container>
    </SchoolCounselorLayout>
  );
};

export default ReferralFormPage;
