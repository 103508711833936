import React, { ReactElement } from "react";
import CardBox from "../Cards/CardBox/CardBox";
import Icon, { ValidIconNames } from "../Icon/Icon";
import {
  CardBody,
  CardDetailRow,
  CardDetailRowKey,
  CardDetails,
  CardHeader,
  CardHeaderField,
  CardIconContainer,
  CardTitle,
  EmptyContentDesktop,
  EmptyContentMobile,
  HeadTR,
  MobileBoxes,
  Table,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from "./elements";

/**
 * This is the format for configuring the table's headers, rows, etc.  It should
 * be an object whose keys are keys in your data rows - ie if your data is an
 * array of objects like {a: 1, b: 2, c:3}, then the table config should
 * have 'a', 'b', and 'c' as keys.
 */
export type TableDataConfig<DataRow extends DataRowParent> = {
  [key: string]: {
    // The string to display for the column header for this field
    headerDisplay: string;

    // A function whose output will be put into the table cell for this field
    renderCell: (rawDataValue: any, dataRow: DataRow) => ReactElement | null;
  };
};

/**
 * This is the config for how this table should be drawn on mobile, where each
 * row is displayed as a vertically-stacked card box.
 */
export type MobileFields<DataRow extends DataRowParent = DataRowParent> = {
  // The field names that go in the card box's header.  You probably want 1 or 2
  // fields here.
  boxHeaderFields: string[];

  // A function returning the icon that should show up for a given row.
  boxIcon?: (rowData: DataRow) => ValidIconNames;

  // The field name that should be rendered as the big, bolded title text for a
  // given row.
  titleField?: string;

  // A list of field names that will be rendered in the body of the card box.
  bodyFields: string[];
};

type EmptyContentProps = { emptyContent: ReactElement };
const EmptyContent: React.FC<EmptyContentProps> = ({ emptyContent }) => {
  return (
    <>
      <EmptyContentMobile>
        <CardBox>{emptyContent}</CardBox>
      </EmptyContentMobile>
      <EmptyContentDesktop>{emptyContent}</EmptyContentDesktop>
    </>
  );
};

export type DashboardTableProps<
  DataRow extends DataRowParent = DataRowParent
> = {
  config: TableDataConfig<DataRow>;
  data: DataRow[];
  mobileFields: MobileFields<DataRow>;
  emptyContent?: ReactElement;
};

// ---------------------------------------------------------------------------
// This is some typescript nonsense.  We have a bunch of places throughout the
// config for DashboardTable where we interact with the data provided in the
// `data` prop.  This TS nonsense lets us assert that the same data is used
// everywhere for a given data table.  If you give an array of User objects as
// your `data` prop, everywhere else it's referenced will be forced to treat it
// as an array of Users, not an array of Sessions or Strings or nulls.
//
// It may not have been worth figuring this out, but I got nerd-sniped at 10pm
// and now here we are.

// This is the type that the data row type must inherit from.  All data rows
// must at least be objects with string keys.
type DataRowParent = Record<string, any>;

// This is the normal React.FC return type, but with the DataRow generics passed
// through.
type returnType<DataRow extends DataRowParent> = ReturnType<
  React.FC<DashboardTableProps<DataRow>>
>;
// This is the normal React.FC props type, but with the DataRow generics passed
// through.
type propType<DataRow extends DataRowParent> = Parameters<
  React.FC<DashboardTableProps<DataRow>>
>[0];

// This is the same as the `React.FC`, and can (in theory) be used
// interchangeably with it, but it includes a single generic passed to the props
// and return type.  It defaults that generic to DataRowParent.  This is so that
// you don't *have* to specify the data row type, but if you use a specific data
// row type, it'll narrow the data row type to whatever you actually used
// automatically.
type CustomReactFC = <DataRow extends DataRowParent = DataRowParent>(
  props: propType<DataRow>
) => returnType<DataRow>;
// ---------------------------------------------------------------------------

const DashboardTable: CustomReactFC = ({
  config,
  data,
  mobileFields,
  emptyContent,
}) => {
  const hasContent = data.length > 0;
  return (
    <>
      <Table>
        <THead>
          <HeadTR>
            {Object.values(config).map((configValue, i) => (
              <TH key={configValue.headerDisplay || i}>
                {configValue.headerDisplay}
              </TH>
            ))}
          </HeadTR>
        </THead>
        <TBody>
          {data.map((dataRow, row) => (
            <TR key={row}>
              {Object.entries(config).map(([dataKey, configValue]) => {
                return (
                  <TD key={dataKey}>
                    {configValue.renderCell(dataRow[dataKey], dataRow)}
                  </TD>
                );
              })}
            </TR>
          ))}
        </TBody>
      </Table>
      <MobileBoxes>
        {data.map((dataRow, i) => {
          return (
            <CardBox
              key={i}
              header={
                <CardHeader>
                  {mobileFields.boxHeaderFields.map((boxHeaderField) => {
                    return (
                      <CardHeaderField key={boxHeaderField}>
                        {config[boxHeaderField].renderCell(
                          dataRow[boxHeaderField],
                          dataRow
                        )}
                      </CardHeaderField>
                    );
                  })}
                </CardHeader>
              }
            >
              <CardBody>
                {mobileFields.boxIcon ? (
                  <CardIconContainer>
                    <Icon name={mobileFields.boxIcon(dataRow)} />
                  </CardIconContainer>
                ) : null}
                <CardDetails>
                  {mobileFields.titleField ? (
                    <CardTitle>
                      {config[mobileFields.titleField].renderCell(
                        dataRow[mobileFields.titleField],
                        dataRow
                      )}
                    </CardTitle>
                  ) : null}
                  {mobileFields.bodyFields.map((field) => {
                    const renderedValue = config[field].renderCell(
                      dataRow[field],
                      dataRow
                    );
                    const keyText = config[field].headerDisplay;
                    return renderedValue != null ? (
                      <CardDetailRow key={field}>
                        {keyText ? (
                          <CardDetailRowKey>{keyText}:</CardDetailRowKey>
                        ) : (
                          <></>
                        )}
                        {renderedValue}
                      </CardDetailRow>
                    ) : (
                      <></>
                    );
                  })}
                </CardDetails>
              </CardBody>
            </CardBox>
          );
        })}
      </MobileBoxes>
      {!hasContent && emptyContent && (
        <EmptyContent emptyContent={emptyContent} />
      )}
    </>
  );
};

export default DashboardTable;
