import React from "react";
import styled from "styled-components/macro";
import { themeMinWidth } from "../../app/theme";
import logoImage from "../../images/daybreak-logo.svg";
import logoTypeImage from "../../images/daybreak-logo-type.svg";
import { useHistory } from "react-router-dom";

export type SimpleHeaderProps = {};

const Container = styled.div`
  height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-bottom: 1px solid #dfdfdf;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  ${themeMinWidth("small")} {
    height: 94px;
  }
`;
const Logo = styled.img`
  cursor: pointer;
  ${themeMinWidth("small")} {
    display: none;
  }
`;
const LogoType = styled.img`
  display: none;
  cursor: pointer;
  ${themeMinWidth("small")} {
    display: block;
  }
`;
const SimpleHeader: React.FC<SimpleHeaderProps> = () => {
  const history = useHistory();
  return (
    <Container>
      <Logo
        src={logoImage}
        alt="Daybreak logo"
        onClick={() => history.push("/")}
      />
      <LogoType
        src={logoTypeImage}
        alt="Daybreak logo"
        onClick={() => history.push("/")}
      />
    </Container>
  );
};

export default SimpleHeader;
