import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import styled from "styled-components/macro";
import { themeColor } from "../../../app/theme";
import Icon from "../../Icon/Icon";
import logoTypeImage from "../../../images/daybreak-logo-type.svg";
import { useApi } from "../../../api/apiContext";
import { truncate } from "../../../lib/stringUtilities";
import { useAuth0 } from "@auth0/auth0-react";
import { User } from "../../../api/queries/users";
import { useSelectedTeen } from "../../../hooks/useSelectedTeen";
import { getDashboardNavItems } from "../../../lib/navPaths";
import Users from "../../../lib/models/users";
import { useHasOrganizationFeature } from "../../../hooks/useHasOrganizationFeature";
import { ApplicationFeature } from "../../../api/queries/features";
import { reset } from "client/amplitudeHelper";

export type MobileNavMenuProps = {
  onClose: () => void;
};

const Container = styled.div`
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${themeColor("backgroundGray")};
  z-index: 1;
`;
const ItemWithButtonState = styled.div`
  transition: all ${(props) => props.theme.animations.defaultHover};
  :hover {
    background-color: ${themeColor("orange1")};
  }
  &:active {
    background-color: ${themeColor("orange5", { opacity: 0.4 })};
    color: ${themeColor("blackText", { opacity: 0.8 })};
  }
  .active & {
    background-color: ${themeColor("orange5")};
    color: white;
  }
`;
const Item = styled(ItemWithButtonState)`
  height: 50px;
  color: ${themeColor("blackText")};
  font-size: 16px;
  line-height: 24px;
  border-radius: 12px;
  padding: 12px 16px;
  box-sizing: border-box;
  display: flex;
  align-content: center;
`;
const LogoType = styled.img`
  cursor: pointer;
  height: 39px;
  width: 174px;
`;
const LogoutIcon = styled.div`
  color: ${themeColor("teal")};
  margin-right: 16px;
  transition: transform 0.25s ease-out;
`;
const LogoutItem = styled.div`
  display: flex;
  margin-bottom: 24px;
  padding: 12px 38px;
  transition: all ${(props) => props.theme.animations.defaultHover};
  cursor: pointer;
  :hover {
    opacity: 0.5;
    & ${LogoutIcon} {
      transform: translateX(5px);
    }
  }
`;
const Top = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
`;
const NavLinks = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
`;
const ItemIconContainer = styled.div`
  margin-right: 16px;
  color: ${themeColor("teal")};
  .active & {
    color: white;
  }
`;
const ItemName = styled.div``;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
`;
const UserItem = styled.div`
  padding: 24px;
  padding-left: 32px;
  display: flex;
  flex-direction: row;
`;
const SettingsLink = styled(ItemWithButtonState)`
  display: flex;
  flex-direction: row;
  padding: 10px 18px;
  align-items: center;
  border-radius: 8px;
  margin: 0 18px;
  .active & {
    color: white;
    background-color: ${themeColor("orange5")};
    .icon {
      color: white;
    }
  }
`;
const GearIcon = styled.div`
  display: flex;
  margin-right: 14px;
  color: #9da9aa;
  .icon {
    width: 24px;
    height: 24px;
  }
`;
const SettingsTitle = styled.div`
  color: ${themeColor("blackText")};
  .active & {
    color: white;
  }
`;
const UserIcon = styled.div`
  color: ${themeColor("teal")};
`;
const NameAndType = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;
const Name = styled.div`
  font-size: 18px;
  margin-top: -2px;
  line-height: 27px;
  color: ${themeColor("darkGrayText4", { opacity: 0.5 })};
`;
const Type = styled.div`
  margin-top: -3px;
  font-size: 10px;
  font-weight: 500;
  line-height: 14.1px;
  text-transform: capitalize;
  color: ${themeColor("black", { opacity: 0.4 })};
`;

export type MobileNavMenuWithDataProps = {
  // Used for testing only.  The active nav item is normally determined
  // automatically by NavLink.
  forceActivePath?: string;
  onClose: MobileNavMenuProps["onClose"];
  me?: User;
  logout: () => void;
  viewedUser?: User;
  userManagementEnabled: boolean;
  applicationFeatures: ApplicationFeature[] | undefined;
};
export const MobileNavMenuWithData: React.FC<MobileNavMenuWithDataProps> = ({
  onClose,
  me,
  logout,
  viewedUser,
  forceActivePath,
  userManagementEnabled,
  applicationFeatures,
}) => {
  const navOptions = {
    currentUser: me,
    routeMatch: useRouteMatch(),
    viewedUser,
    applicationFeatures,
  };
  return (
    <Container>
      <Top>
        <LogoType src={logoTypeImage} alt="Daybreak logo" />
        <Icon name="close" onClick={onClose} />
      </Top>
      <NavLinks>
        {getDashboardNavItems(navOptions).map((navPath) => {
          return (
            <NavLink
              to={navPath.destinationPath(navOptions)}
              key={navPath.destinationPath(navOptions)}
              activeClassName="active"
              className={[
                "navLink",
                forceActivePath === navPath.destinationPath(navOptions)
                  ? "active"
                  : "",
              ].join(" ")}
            >
              <Item>
                <ItemIconContainer>
                  <Icon name={navPath.icon} />
                </ItemIconContainer>
                <ItemName>{navPath.displayString}</ItemName>
              </Item>
            </NavLink>
          );
        })}
      </NavLinks>
      <Bottom>
        <UserItem>
          <UserIcon>
            <Icon name="profile" width="32" height="32" />
          </UserIcon>
          <NameAndType>
            <Name>{truncate(me?.fullName, 25)}</Name>
            <Type>{Users.userKindDisplayString(me?.userKind)}</Type>
          </NameAndType>
        </UserItem>
        {userManagementEnabled ? (
          <NavLink
            to="/school/settings"
            className={[
              "navLink",
              forceActivePath?.startsWith("/school/settings") ? "active" : "",
            ].join(" ")}
          >
            <SettingsLink>
              <GearIcon>
                <Icon name="gear" />
              </GearIcon>
              <SettingsTitle>Settings</SettingsTitle>
            </SettingsLink>
          </NavLink>
        ) : null}
        <LogoutItem
          onClick={() => {
            reset();
            logout();
          }}
        >
          <LogoutIcon>
            <Icon name="exit" />
          </LogoutIcon>
          Log out
        </LogoutItem>
      </Bottom>
    </Container>
  );
};

const MobileNavMenu: React.FC<MobileNavMenuProps> = ({ onClose }) => {
  const api = useApi();
  const { data: { data: me } = {} } = api.useGetMe();
  const { logout } = useAuth0();
  const { teen: viewedUser } = useSelectedTeen({ redirectIfNone: false });
  const {
    data: { features: applicationFeatures } = {},
  } = api.useGetApplicationFeatures();
  const { active: userManagementEnabled } = useHasOrganizationFeature(
    "organization_manual_user_management"
  );
  return (
    <MobileNavMenuWithData
      {...{
        me,
        logout,
        onClose,
        viewedUser,
        userManagementEnabled,
        applicationFeatures,
      }}
    />
  );
};

export default MobileNavMenu;
