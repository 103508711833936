import React from "react";
import styled from "styled-components/macro";
import Icon from "../../../components/Icon/Icon";
import { OrganizationMemberV1 } from "../../../api/queries/organizationMembers";

const QueuedFlag = styled.div`
  cursor: pointer;
  .icon {
    color: "#F76434";
  }
`;

const NotQueuedFlag = styled.div`
  cursor: pointer;
  .icon {
    color: "#9DA9AA";
  }
`;
export type QueueStudentButtonProps = {
  organizationMember: OrganizationMemberV1;
  onQueueMember: (m: OrganizationMemberV1) => void;
  onDequeueMember: (m: OrganizationMemberV1) => void;
};

const QueueStudentButton: React.FC<QueueStudentButtonProps> = ({
  organizationMember,
  onQueueMember,
  onDequeueMember,
}) => {
  if (organizationMember.referral) {
    return (
      <QueuedFlag
        onClick={() => onDequeueMember(organizationMember)}
        title="Dequeue Student"
      >
        <Icon name="flagFilled" title="Dequeue Student" />
      </QueuedFlag>
    );
  } else {
    return (
      <NotQueuedFlag
        onClick={() => onQueueMember(organizationMember)}
        title="Queue Student"
      >
        <Icon name="flagOutline" title="Queue Student" />
      </NotQueuedFlag>
    );
  }
};

export default QueueStudentButton;
