import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";

const sidePadding = 16;
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 0 80px 0;

  ${themeMinWidth("small")} {
    padding: 0px 24px 80px 24px;
  }
`;
export const StudentDetailsCollapsableSection = styled.div`
  padding: 20px 16px;
  ${themeMinWidth("small")} {
    padding: 20px 0;
  }
`;

export const DischargeGridContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 15px;
  padding-left: ${sidePadding}px;
  padding-right: ${sidePadding}px;
  ${themeMinWidth("small")} {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  gap: 15px;
  ${themeMinWidth("small")} {
    grid-auto-flow: column;
  }
`;
export const MemberTitle = styled.div`
  font-size: 24px;
  color: ${themeColor("darkGrayText4")};
  font-weight: 600;
  line-height: 36px;
  margin-top: 24px;
  margin-bottom: 11px;
  margin-left: ${sidePadding}px;
  margin-right: ${sidePadding}px;
  ${themeMinWidth("small")} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  flex-direction: column;
  margin-left: ${sidePadding}px;
  margin-right: ${sidePadding}px;
  ${themeMinWidth("small")} {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const InfoSection = styled.div`
  flex: 1 1 auto;
  padding: 5px 20px;
  border-bottom: solid ${themeColor("borderGray5")} 1px;
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  ${themeMinWidth("small")} {
    border-bottom: none;
    padding-bottom: 5px;
    border-left: solid ${themeColor("borderGray5")} 1px;
    &:first-child {
      border-left: none;
      padding-left: 0;
    }
  }
`;

export const InfoSectionTitle = styled.p`
  font-size: 16px;
  color: ${themeColor("darkGrayText7")};
  font-weight: 500;
  line-height: 24px;
`;

export const InfoSectionText = styled.p<{
  primary?: boolean;
  small?: boolean;
  subtle?: boolean;
}>`
  font-size: ${(props) => (props.primary ? "18px" : "16px")};
  color: ${(props) =>
    props.primary
      ? props.theme.colors.black
      : props.theme.colors.darkGrayText2};
  font-weight: ${(props) => (props.small ? 400 : 500)};
  opacity: ${(props) => (props.subtle ? 0.4 : 1.0)};
`;

export const SectionTitle = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: ${themeColor("black")};
  margin-left: ${sidePadding}px;
  margin-right: ${sidePadding}px;
  ${themeMinWidth("small")} {
    margin-left: 0;
    margin-right: 0;
  }
`;
export const StatsRow = styled.div`
  display: grid;
  margin-top: 16px;
  grid-template-columns: repeat(2, auto);
  gap: 16px;
  margin-left: ${sidePadding}px;
  margin-right: ${sidePadding}px;
  ${themeMinWidth("small")} {
    margin-left: 0;
    margin-right: 0;
    gap: 18px;
    grid-template-columns: repeat(6, auto);
  }
`;
export const TableContainer = styled.div`
  font-size: 14px;
`;

export const ROIBox = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${themeColor("orange0")};
  border: 2px solid ${themeColor("orange5")};
  border-radius: 12px;
  margin-top: 24px;
  padding: 16px 24px;
  margin-left: ${sidePadding}px;
  margin-right: ${sidePadding}px;
  ${themeMinWidth("small")} {
    margin-left: 0;
    margin-right: 0;
  }
  // max-width: 800px;
`;
export const ROIIcon = styled.div`
  display: flex;
  .icon {
    color: ${themeColor("orange5")};
    width: 42px;
    height: 42px;
  }
`;
export const ROIText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;
export const ROIHeadline = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
export const ROIBody = styled.div`
  font-size: 14px;
  word-break: break-word;
`;

export const InsuranceAndMultipleReferrals = styled.div`
  display: flex;
  flex-direction: column;
  ${themeMinWidth("small")} {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    gap: 32px;
  }
`;

export const InsuranceSection = styled.div``;
export const InsuranceSectionBody = styled.div`
  margin-left: ${sidePadding}px;
  margin-right: ${sidePadding}px;
  ${themeMinWidth("small")} {
    margin-left: 0;
    margin-right: 0;
  }
`;
export const MultipleReferralsSection = styled.div``;
export const MultipleReferralsBody = styled.div`
  margin-left: ${sidePadding}px;
  margin-right: ${sidePadding}px;
  ${themeMinWidth("small")} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const CareStatusSection = styled.div`
  margin-top: 18px;
`;
