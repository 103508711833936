import { useState } from "react";
import YesNoButtons from "./YesNoButtons";
import { FormSection, H1, H3 } from "../elements";
import styled from "styled-components";
import { TFunction } from "i18next";
import { LooksLikeLink } from "./elements";
import { trackEvent } from "client/amplitudeHelper";

type Callback = () => void;

export type IdentityConfirmationProps = {
  t: TFunction;
  isConfirmed: boolean;
  parentName: string;
  kiddoName: string;
  onConfirmed: Callback;
  onDenied: Callback;
  initialState?: {
    kiddoConfirmed?: boolean;
    parentConfirmed?: boolean;
  };
};

const ConfirmedRow = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

const IdentityConfirmation = ({
  t,
  isConfirmed,
  kiddoName,
  parentName,
  onConfirmed,
  onDenied,
  initialState,
}: IdentityConfirmationProps): JSX.Element => {
  const [kiddoConfirmed, setKiddoConfirmed] = useState<boolean>(
    initialState?.kiddoConfirmed || isConfirmed
  );
  const [parentConfirmed, setParentConfirmed] = useState<boolean>(
    initialState?.parentConfirmed || isConfirmed
  );

  return (
    <FormSection>
      <H1>{t("Let's confirm your identity")}</H1>
      <H3>{t("Are you a guardian of Kiddo Name?", { kiddoName })}</H3>
      {kiddoConfirmed ? (
        <ConfirmedRow>
          <p>{t("You answered Yes")}</p>
          <LooksLikeLink
            onClick={() => {
              setKiddoConfirmed(false);
              trackEvent("PWP:KiddoIdentity:Changed");
            }}
          >
            {t("Change response")}
          </LooksLikeLink>
        </ConfirmedRow>
      ) : (
        <YesNoButtons
          t={t}
          onYes={() => {
            setKiddoConfirmed(true);
            trackEvent("PWP:KiddoIdentity:Yes");
          }}
          onNo={() => {
            onDenied();
            trackEvent("PWP:KiddoIdentity:No");
          }}
        />
      )}
      {kiddoConfirmed && (
        <>
          <H3>{t("Are you Guardian Name?", { parentName })}</H3>
          {parentConfirmed ? (
            <ConfirmedRow>
              <p>{t("You answered Yes")}</p>
              <LooksLikeLink
                onClick={() => {
                  setParentConfirmed(false);
                  trackEvent("PWP:GuardianIdentity:Changed");
                }}
              >
                {t("Change response")}
              </LooksLikeLink>
            </ConfirmedRow>
          ) : (
            <YesNoButtons
              t={t}
              onYes={() => {
                setParentConfirmed(true);
                trackEvent("PWP:GuardianIdentity:Yes");
                onConfirmed();
              }}
              onNo={() => {
                onDenied();
                trackEvent("PWP:GuardianIdentity:No");
              }}
            />
          )}
        </>
      )}
    </FormSection>
  );
};

export default IdentityConfirmation;
