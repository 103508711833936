import React from "react";
import { themeColor, themeMinWidth } from "../../../app/theme";
import styled from "styled-components/macro";
import { Session } from "../../../api/queries/sessions";
import TableV2, { createTableV2 } from "../../../components/TableV2/TableV2";
import AppointmentBadge from "../../../components/AppointmentBadge/AppointmentBadge";
import { DateTime } from "luxon";
import { SyntheticSession } from "./StudentDetailsPage";

const Container = styled.div`
  .table-element {
    min-width: 600px;

    ${themeMinWidth("small")} {
      min-width: auto;
    }
  }
`;
const EmptyTableContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${themeColor("darkGrayText7")};
  padding: 24px;
  font-weight: 500;
`;

const HeaderCellContent = styled.div`
  margin-left: 12px;
`;

const LastHeaderCellContent = styled.div`
  width: 100%;
  text-align: right;
`;

const sessionsTable = createTableV2<Session | SyntheticSession>();
const columns = [
  sessionsTable.createDataColumn("startTime", {
    id: "start_time",
    enableSorting: false,
    header: () => {
      return <span style={{ minWidth: "120px" }}>Date and Time</span>;
    },
    cell: (data) => {
      const value = data.getValue();
      if (value === null) {
        return "";
      }
      const dateTime =
        value instanceof DateTime ? value : DateTime.fromSeconds(value);
      return dateTime.toLocaleString({
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
  }),
  sessionsTable.createDataColumn("types", {
    id: "types",
    header: () => {
      return (
        <HeaderCellContent style={{ minWidth: "140px" }}>
          Session Type
        </HeaderCellContent>
      );
    },
    enableSorting: false,
    cell: (data) => {
      return (data.getValue() || [])?.join(",");
    },
  }),
  sessionsTable.createDataColumn("status", {
    id: "status",
    enableSorting: false,
    header: () => {
      return <HeaderCellContent>Status</HeaderCellContent>;
    },
    cell: (data) => <AppointmentBadge inline status={data.getValue()!} />,
  }),
  sessionsTable.createDataColumn("zoomLink", {
    id: "zoom_link",
    enableSorting: false,
    header: () => {
      return <LastHeaderCellContent>Zoom Link</LastHeaderCellContent>;
    },
    cell: (data) => data.getValue() || "",
  }),
];

export type SessionsTableProps = {
  sessions: (Session | SyntheticSession)[];
};
const SessionsTable: React.FC<SessionsTableProps> = ({ sessions }) => {
  return (
    <Container>
      <TableV2
        table={sessionsTable}
        columns={columns}
        updateData={() => {}}
        data={sessions}
        emptyContent={
          <EmptyTableContent>No Sessions Available</EmptyTableContent>
        }
      />
    </Container>
  );
};

export default SessionsTable;
