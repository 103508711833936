import { useFormik } from "formik";
import React from "react";
import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";
import TealButton from "../../buttons/TealButton/TealButton";
import TextField from "../../Forms/TextField/TextField";
import * as Yup from "yup";
import { isDaybreakErrorV4, RequestError } from "../../../api/apiClient";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  color: ${themeColor("darkGrayText6")};
  padding-bottom: 4px;
  border-bottom: 1px solid ${themeColor("borderGray5")};
  margin-top: 24px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 16px;
`;
const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${themeMinWidth("small")} {
    flex-direction: row;
  }
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 13px;
`;
export const Errors = styled.div`
  color: ${themeColor("red")};
`;

const messageFromSubmissionError = (error: RequestError | null) => {
  if (error == null) {
    return null;
  }

  if (!isDaybreakErrorV4(error)) {
    return "Unknown Error";
  }

  if (error.errorType === "email_taken") {
    return "That email is already in use by another staff member.  Please enter a different one.";
  }
  return error.error;
};

const defaultFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  title: "",
};
type FormValues = typeof defaultFormValues;
export type StaffUserModalFormProps = {
  onCancel: () => void;
  onNext: (staffAttributes: FormValues) => void;
  submissionError: RequestError | null;
  initialValues: FormValues;
};

const StaffUserModalForm: React.FC<StaffUserModalFormProps> = ({
  onCancel,
  onNext,
  submissionError,
  initialValues,
}) => {
  const formik = useFormik({
    initialValues: initialValues ?? defaultFormValues,
    onSubmit: (values) => {
      onNext(values);
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required."),
      lastName: Yup.string().required("Last name is required."),
      email: Yup.string().required("Email is required.").email(),
      title: Yup.string().required("Title is required."),
    }),
  });
  const allFieldsTouched = Object.keys(defaultFormValues).reduce(
    (acc, key) => acc && !!formik.touched[key as keyof FormValues],
    true
  );
  const errorMessage = Object.values(formik.errors).join(" ");
  const submissionErrorMessage = messageFromSubmissionError(submissionError);
  return (
    <Container>
      <Header>User info</Header>
      <Form onSubmit={formik.handleSubmit}>
        <FormRow>
          <TextField
            autoFocus
            label="First name"
            id="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={!!formik.errors.firstName && formik.touched.firstName}
          />
          <TextField
            label="Last name"
            id="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={!!formik.errors.lastName && formik.touched.lastName}
          />
        </FormRow>
        <TextField
          label="Email address"
          id="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={!!formik.errors.email && formik.touched.email}
        />
        <TextField
          label="Title"
          id="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={!!formik.errors.title && formik.touched.title}
        />
        {errorMessage && allFieldsTouched ? (
          <Errors>{errorMessage}</Errors>
        ) : null}
        {submissionErrorMessage && <Errors>{submissionErrorMessage}</Errors>}
        <Buttons>
          <TealButton variant="secondary" onClick={onCancel}>
            Cancel
          </TealButton>
          <TealButton variant="primary" type="submit">
            Next
          </TealButton>
        </Buttons>
      </Form>
    </Container>
  );
};

export default StaffUserModalForm;
