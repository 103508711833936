import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../app/theme";
import { CenteredLoading } from "../../components/Loading/Loading";
import SimpleHeader from "../../components/SimpleHeader/SimpleHeader";

const HeaderContainer = styled.div`
  display: flex;
`;
const LayoutContainer = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background-color: white;
  ${themeMinWidth("small")} {
    background-color: ${themeColor("teal2")};
  }
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const CenteredContent = styled.div`
  min-height: 500px;
  min-width: 200px;
  width: 100%;
  ${themeMinWidth("small")} {
    width: auto;
  }
`;
const GlobalStyle = createGlobalStyle`
  html, body, #root, .app {
    height: 100%;
  }
`;
const ContentBox = styled.div`
  background-color: white;
  ${themeMinWidth("small")} {
    margin-top: 43px;
    margin-bottom: 16px;
    overflow: hidden;
  }
`;
const LoadingContainer = styled.div`
  display: flex;
  padding: 80px;
`;
export type ParentWelcomePacketLayoutProps = { loading?: boolean };
const ParentWelcomePacketLayout: React.FC<ParentWelcomePacketLayoutProps> = ({
  loading,
  children,
}) => {
  return (
    <LayoutContainer>
      <GlobalStyle />
      <HeaderContainer>
        <SimpleHeader />
      </HeaderContainer>
      <ContentContainer>
        <CenteredContent>
          <ContentBox>
            {loading ? (
              <LoadingContainer>
                <CenteredLoading />
              </LoadingContainer>
            ) : (
              children
            )}
          </ContentBox>
        </CenteredContent>
      </ContentContainer>
    </LayoutContainer>
  );
};

export default ParentWelcomePacketLayout;
