import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useApi } from "../../../api/apiContext";
import { OrganizationMemberV1 } from "../../../api/queries/organizationMembers";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { trackEvent } from "client/amplitudeHelper";

const shouldUpdateReferral = (organizationMember: OrganizationMemberV1) => {
  const lastReferral = organizationMember?.referral;
  const hasRightInsuranceStatus =
    lastReferral?.insuranceStatus === "requested" ||
    lastReferral?.insuranceStatus === "pending";
  const hasRightReferralStatus = [
    "referred",
    "scheduling",
    "scheduled",
    "completed",
    "rescheduled",
    "in_progress",
  ].includes(organizationMember?.latestReferralStatus ?? "");

  return hasRightInsuranceStatus && hasRightReferralStatus;
};
export const useInsuranceReturnHandler = ({
  landingPage,
}: {
  landingPage: string;
}) => {
  const organizationMemberExternalId = useQueryParams().get(
    "organizationMemberId"
  );
  useEffect(() => {
    trackEvent("SCD:returnedFromSalesforceInsuranceForm", {
      organizationMemberExternalId,
    });
  }, [organizationMemberExternalId]);
  const api = useApi();
  const {
    data: { data: organizationMember } = {},
    isLoading,
    isSuccess,
  } = api.useGetOrganizationMember({
    externalId: organizationMemberExternalId ?? "",
    options: { enabled: !!organizationMemberExternalId },
  });
  const lastReferralId = organizationMember?.referral?.id;
  const { mutate: patchReferral } = api.usePatchReferrals({});
  const history = useHistory();

  useEffect(() => {
    const orgMemberFailedToLoad = !isLoading && !isSuccess;
    const orgMemberIdWasBad =
      !organizationMemberExternalId || organizationMemberExternalId.length <= 0;
    if (orgMemberFailedToLoad || orgMemberIdWasBad) {
      console.error("Insurance redirect handler with bad inputs!", {
        orgMemberFailedToLoad,
        orgMemberIdWasBad,
      });
      history.replace(landingPage);
    }
  }, [
    history,
    isLoading,
    isSuccess,
    landingPage,
    organizationMemberExternalId,
  ]);

  useEffect(() => {
    if (organizationMember && lastReferralId) {
      if (shouldUpdateReferral(organizationMember)) {
        patchReferral(
          { id: lastReferralId, insuranceStatus: "processing" },
          {
            onSettled(_data, error) {
              if (error) {
                console.error("Errored updating referral: ", error);
              }
              console.log("Successfully updated referral.");
              history.replace(landingPage);
            },
          }
        );
      } else {
        console.warn("Referral not updatable");
        history.replace(landingPage);
      }
    }
  }, [history, landingPage, lastReferralId, organizationMember, patchReferral]);
};
