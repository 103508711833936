import React from "react";
import styled, { css } from "styled-components/macro";
import { themeColor, themeFont, themeMinWidth } from "../../app/theme";
import { languagePreferenceStorage } from "../../lib/localStorageManagers";

// Put shared step lists here:
export const OnboardingWizardSteps = [
  "Take Quiz",
  "See Results",
  "Get Support",
];

export const ScreenerWizardSteps = [
  "Start",
  "Welcome",
  "Survey",
  "Thank you",
] as const;

const SpanishScreenerSteps: { [key: string]: string } = {
  Start: "Comenzar",
  Welcome: "Bienvenido",
  Survey: "Encuesta",
  "Thank you": "Gracias",
};

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const MobileProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  ${themeMinWidth("small")} {
    display: none;
  }
`;
const EmptyBar = styled.div`
  background-color: #f0f0f0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;
const FilledBar = styled.div<{ filledPercent: number }>`
  background-color: ${themeColor("teal")};
  width: ${(props) => props.filledPercent}%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
`;
const DesktopBubbles = styled.div`
  display: none;
  flex-direction: row;

  ${themeMinWidth("small")} {
    display: flex;
  }
`;
const BubbleAndLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StepBubble = styled.div<{ filled: boolean }>`
  border: 1px solid #f0f0f0;
  border-radius: 16px;
  // height: 32px;
  font-family: ${themeFont("helveticaNeue")};
  font-size: 14px;
  padding: 5.5px 16px 5.5px 16px;
  color: #8b8b8b;
  ${(props) =>
    props.filled &&
    css`
      border-color: ${themeColor("teal")};
      background-color: ${themeColor("teal")};
      color: white;
    `};
`;
const BubbleLine = styled.div`
  width: 24px;
  height: 2px;
  background-color: #f0f0f0;
`;
const BubbleLineFill = styled.div<{ filledPercent: number }>`
  width: ${(props) => props.filledPercent}%;
  height: 100%;
  background-color: ${themeColor("teal")};
`;

export type WizardProps = {
  steps: readonly string[];
  lastFilledStepIndex: number;
};

const Wizard: React.FC<WizardProps> = ({ lastFilledStepIndex, steps }) => {
  // There should always be at least a little bit of bar filled, even if you're
  // just starting, or else you won't know it's a bar.
  let percentFull = Math.max((lastFilledStepIndex / steps.length) * 100, 3);
  const localLang = languagePreferenceStorage.get();

  // Per designs, the bar should never be entirely full either.
  percentFull = Math.min(percentFull, 95);
  return (
    <Container>
      <MobileProgressBar>
        <EmptyBar />
        <FilledBar filledPercent={percentFull} />
      </MobileProgressBar>
      <DesktopBubbles>
        {steps.map((step, index) => {
          let stepText = step;
          if (localLang && localLang === "es" && SpanishScreenerSteps[step]) {
            stepText = SpanishScreenerSteps[step];
          }
          const filled = index < lastFilledStepIndex;
          const nextFilled = index + 1 < lastFilledStepIndex;
          const lineFillPercent = filled ? (nextFilled ? 100 : 50) : 0;
          return (
            <BubbleAndLine key={step}>
              <StepBubble filled={filled}>{stepText}</StepBubble>
              {index < steps.length - 1 && (
                <BubbleLine>
                  <BubbleLineFill filledPercent={lineFillPercent} />
                </BubbleLine>
              )}
            </BubbleAndLine>
          );
        })}
      </DesktopBubbles>
    </Container>
  );
};

export default Wizard;
