import { Session } from "../../api/queries/sessions";

const chargedCodes = ["90834", "90847"];

type SessionStats = {
  charged: number;
  upcoming: number;
  attended: number;
  missed: number;
};
const calculateStats = (sessions: Session[]): SessionStats => {
  let charged = 0;
  let upcoming = 0;
  let attended = 0;
  let missed = 0;
  sessions.forEach((session) => {
    switch (session.status) {
      case "kept":
        attended++;
        break;
      case "latecancel":
        break;
      case "noshow":
        missed++;
        break;
      case "upcoming":
        upcoming++;
        break;
    }
    if (isCharged(session)) {
      charged++;
    }
  });
  return {
    charged,
    upcoming,
    attended,
    missed,
  };
};

const partitionByUpcoming = (
  sessions: Session[]
): { recent: Session[]; upcoming: Session[] } => {
  const recent: Session[] = [];
  const upcoming: Session[] = [];
  sessions.forEach((session) => {
    if (session.startTime * 1000 > new Date().getTime()) {
      upcoming.push(session);
    } else {
      recent.push(session);
    }
  });
  return { recent, upcoming };
};

const isCharged = (session: Session) => {
  const isChargedStatus =
    session.status === "kept" ||
    session.status === "latecancel" ||
    session.status === "noshow";

  const isChargedCode = session.serviceTypes.find((serviceType) =>
    chargedCodes.includes(serviceType.code)
  );

  return isChargedStatus && isChargedCode;
};

const mapType = (types: string[]) => {
  const type = types[0] || "";

  // Try to shorten a few of the types with long names like "Psychotherapy,
  // 30-minutes with patient and/or family member".
  if (type.match(/Psychotherapy/i)) {
    return "Psychotherapy";
  } else if (type.match(/Behavioral/i)) {
    return "Behavioral Therapy";
  } else if (type.match(/Family Therapy/i)) {
    return "Family Therapy";
  } else {
    return type;
  }
};

const Sessions = {
  calculateStats,
  partitionByUpcoming,
  isCharged,
  mapType,
};

export default Sessions;
