import React from "react";
import styled, { css } from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";
import Icon, { ValidIconNames } from "../../Icon/Icon";

type Variant = "primary" | "secondary";
export const Button = styled.button<{
  variant: Variant;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 24px;
  background-color: ${themeColor("teal")};
  color: white;
  border: 0;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all ${(props) => props.theme.animations.defaultHover};
  .icon {
    width: 20px;
    height: 20px;
  }
  ${themeMinWidth("small")} {
    font-size: 14px;
  }
  ${({ variant }) =>
    (!variant || variant === "primary") &&
    css`
      :hover {
        filter: brightness(95%);
      }
      :active {
        filter: brightness(90%);
      }
    `}
  ${({ variant }) =>
    variant === "secondary" &&
    css`
      background: none;
      border: 1px solid ${themeColor("teal")};
      color: ${themeColor("teal")};
      :hover {
        background-color: ${themeColor("teal4", { opacity: 0.2 })};
        border-color: ${themeColor("teal", { opacity: 0.5 })};
        filter: none;
      }
      :active {
        background-color: ${themeColor("teal4", { opacity: 0.4 })};
        border-color: ${themeColor("teal", { opacity: 0.7 })};
        filter: none;
      }
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${themeColor("darkGrayText8")};
      border-color: ${themeColor("darkGrayText7")};
      cursor: default;
      :hover {
        background-color: ${themeColor("darkGrayText8")};
        border-color: ${themeColor("darkGrayText7")};
        filter: none;
      }
      :active {
        background-color: ${themeColor("darkGrayText8")};
        border-color: ${themeColor("darkGrayText7")};
        filter: none;
      }
    `}
    ${({ disabled, variant }) =>
    disabled &&
    variant === "secondary" &&
    css`
      background-color: ${themeColor("borderGray6")};
      border-color: ${themeColor("borderGray5")};
      color: ${themeColor("darkGrayText8")};
      cursor: default;
      :hover {
        background-color: ${themeColor("borderGray6")};
        border-color: ${themeColor("borderGray5")};
        color: ${themeColor("darkGrayText8")};
        filter: none;
      }
      :active {
        background-color: ${themeColor("borderGray6")};
        border-color: ${themeColor("borderGray5")};
        color: ${themeColor("darkGrayText8")};
        filter: none;
      }
    `}
`;

export type TealButtonProps = React.ComponentProps<typeof Button> & {
  icon?: ValidIconNames;
  variant?: Variant;
};

const TealButton: React.FC<TealButtonProps> = ({
  children,
  icon,
  onClick,
  type,
  ...divProps
}) => {
  return (
    <Button
      type={type ?? "button"}
      onClick={divProps.disabled ? () => {} : onClick}
      {...divProps}
    >
      {icon && <Icon name={icon} />}
      {children}
    </Button>
  );
};

export default TealButton;
