import React, { useState } from "react";
import styled from "styled-components/macro";
import { useApi } from "../../../api/apiContext";
import { OrganizationStaffMember } from "../../../api/queries/organizationStaffMembers";
import queryClient from "../../../api/queryClient";
import { themeColor } from "../../../app/theme";
import TealButton from "../../buttons/TealButton/TealButton";
import BaseModal, { BaseModalProps } from "../BaseModal/BaseModal";
import { trackEvent } from "client/amplitudeHelper";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 385px;
`;
const Header = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: black;
`;
const Body = styled.div`
  margin-top: 4px;
  color: ${themeColor("darkGrayText7")};
`;
const Actions = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const useConfirmStaffDeletionModal = ({
  onDelete,
}: {
  onDelete?: () => void;
} = {}) => {
  const [state, setState] = useState({
    showing: false,
    organizationStaffMember: undefined as OrganizationStaffMember | undefined,
  });

  const showModal = ({
    organizationStaffMember,
  }: {
    organizationStaffMember: OrganizationStaffMember;
  }) => {
    setState({ showing: true, organizationStaffMember });
  };
  const hideModal = () => {
    setState({ showing: false, organizationStaffMember: undefined });
  };
  return {
    showModal,
    hideModal,
    onDelete,
    showing: state.showing,
    organizationStaffMember: state.organizationStaffMember,
  };
};
type ModalContext = ReturnType<typeof useConfirmStaffDeletionModal>;

type ConfirmStaffDeletionModalInsertProps = {
  modalContext: ModalContext;
};

export const ConfirmStaffDeletionModalInsert: React.FC<ConfirmStaffDeletionModalInsertProps> = ({
  modalContext,
}) => {
  if (!modalContext.showing || !modalContext.organizationStaffMember) {
    return null;
  }
  return (
    <ConfirmStaffDeletionModal
      closeModal={modalContext.hideModal}
      onDelete={modalContext.onDelete}
      organizationStaffMember={modalContext.organizationStaffMember}
    />
  );
};

export type ConfirmStaffDeletionModalProps = {
  organizationStaffMember: OrganizationStaffMember;
  onDelete?: () => void;
} & BaseModalProps;
const ConfirmStaffDeletionModal: React.FC<ConfirmStaffDeletionModalProps> = ({
  organizationStaffMember,
  onDelete,
  ...baseModalProps
}) => {
  const api = useApi();
  const {
    mutate: deleteStaff,
    isLoading: isDeleting,
  } = api.useDeleteOrganizationStaffMember({
    options: {
      useErrorBoundary: true,
      onSuccess: () => {
        queryClient.invalidateQueries("organizationStaffMembers");
        baseModalProps.onClose?.();
        onDelete?.();
      },
    },
  });
  return (
    <BaseModal {...baseModalProps}>
      <Container>
        <Header>
          Delete {organizationStaffMember.firstName}{" "}
          {organizationStaffMember.lastName}
        </Header>
        <Body>This is a permanent action and can't be undone.</Body>
        <Actions>
          <TealButton
            variant="secondary"
            onClick={baseModalProps.closeModal}
            disabled={isDeleting}
          >
            Cancel
          </TealButton>
          <TealButton
            onClick={() => {
              trackEvent("SCD:deletedStaffMember", {
                organizationStaffMember,
              });
              deleteStaff({ id: organizationStaffMember.id });
            }}
            disabled={isDeleting}
          >
            Delete
          </TealButton>
        </Actions>
      </Container>
    </BaseModal>
  );
};

export default ConfirmStaffDeletionModal;
