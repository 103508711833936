import { createUseApiMutation } from "./queryHelpers";
import { SurveyMonkeyResponse } from "./surveys";

type PostAnsweredFormVariables = {
  featureApiKey: string;
  formData: SurveyMonkeyResponse;
};

type PostAnsweredFormResponse = {
  surveyMonkeyName: string;
  surveyMonkeyId: string;
  kind: string;
  surveyMonkeyResponseData: SurveyMonkeyResponse;
};

export const usePostAnsweredFormByFeature = createUseApiMutation<
  PostAnsweredFormResponse,
  PostAnsweredFormVariables
>({
  requestBuilder: (mutation) => ({
    method: "post",
    url: `/v4/form_responses/by_feature/${mutation.featureApiKey}`,
    data: mutation.formData,
  }),
  auth: false,
});
