import { DateTime } from "luxon";
import React from "react";
import { Link } from "react-router-dom";
import { Milestone, MilestoneKind } from "../../api/queries/milestones";
import { theme } from "../../app/theme";
import CardBox from "../Cards/CardBox/CardBox";
import Icon from "../Icon/Icon";
import graphImage from "../../images/graph.svg";
import {
  Body,
  Direction,
  EmptyBody,
  EmptyBodyImage,
  EmptyBodyReminderLink,
  HeaderContent,
  HeaderSeeMore,
  HeaderTitle,
  LevelCircle,
  LevelLabel,
  LevelSection,
  Period,
} from "./elements";
import SurveyMilestones from "../../lib/models/surveyMilestones";
import TrendBadge from "../TrendBadge/TrendBadge";
import { User } from "../../api/queries/users";

const circleColor = {
  low: theme.colors.green,
  medium: theme.colors.yellow5,
  high: theme.colors.orange5,
  critical: theme.colors.red,
};
export type ClinicalProgressBoxProps = {
  title: string;
  levelText: string;
  milestones: Milestone[];
  kind: MilestoneKind;
  reminderMailto: string;
  isParent: boolean;
  teen: User;
};
const EmptyBox: React.FC<
  Pick<ClinicalProgressBoxProps, "title" | "reminderMailto" | "isParent">
> = ({ title, reminderMailto, isParent }) => {
  return (
    <CardBox
      header={
        <HeaderContent>
          <HeaderTitle>{title}</HeaderTitle>
        </HeaderContent>
      }
    >
      <EmptyBody>
        <EmptyBodyImage src={graphImage} />
        <div>
          No clinical progress forms have been completed.
          {!isParent ? null : (
            <EmptyBodyReminderLink href={reminderMailto}>
              Send a reminder
            </EmptyBodyReminderLink>
          )}
        </div>
      </EmptyBody>
    </CardBox>
  );
};

const ClinicalProgressBox: React.FC<ClinicalProgressBoxProps> = ({
  title,
  levelText,
  milestones,
  kind,
  reminderMailto,
  isParent,
  teen,
}) => {
  if (milestones.length === 0) {
    return (
      <EmptyBox
        title={title}
        reminderMailto={reminderMailto}
        isParent={isParent}
      />
    );
  }
  // Sort latest to oldest
  milestones.sort((a, b) => (b.completedAt ?? 0) - (a.completedAt ?? 0));

  // Find the newest milestone from at least a month ago
  const oneMonthAgo = DateTime.now().minus({ months: 1 });
  const latestMilestone = milestones[0];
  const lastMonthMilestone = milestones.find(
    (milestone) =>
      DateTime.fromSeconds(milestone.completedAt ?? 0) < oneMonthAgo &&
      milestone !== latestMilestone
  );

  // Figure out progress since last month
  const lastScore = latestMilestone?.integerCompletionMetric;
  const lastMonthScore = lastMonthMilestone?.integerCompletionMetric;

  // Figure out your severity of your latest score
  const [_, severity] = [...SurveyMilestones.scoreThresholds[kind].thresholds]
    .reverse()
    .find(
      ([threshold, _]) => latestMilestone.integerCompletionMetric >= threshold
    ) || [null, "low"];

  return (
    <CardBox
      header={
        <HeaderContent>
          <HeaderTitle>{title}</HeaderTitle>
          <Link
            to={
              isParent
                ? `/${teen.id}/clinical-progress`
                : `/clinician/${teen.id}/clinical-progress`
            }
          >
            <HeaderSeeMore>
              See more <Icon name="leftArrow" flip />
            </HeaderSeeMore>
          </Link>
        </HeaderContent>
      }
    >
      <Body>
        <Period>Current month</Period>
        <LevelSection>
          <LevelCircle style={{ backgroundColor: circleColor[severity] }} />
          <LevelLabel>
            {severity} {levelText}
          </LevelLabel>
        </LevelSection>
        {lastMonthScore && (
          <Direction>
            Since last month:{" "}
            <TrendBadge
              newScore={lastScore}
              oldScore={lastMonthScore}
              lowIsGood={SurveyMilestones.scoreThresholds[kind].lowIsGood}
            />
          </Direction>
        )}
      </Body>
    </CardBox>
  );
};

export default ClinicalProgressBox;
