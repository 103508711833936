import { FieldArray, Formik } from "formik";
import React from "react";
import TextLink from "../../../components/TextLink/TextLink";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import analytics from "../../../lib/analytics";
import { OrganizationMemberV1 } from "../../../api/queries/organizationMembers";
import { ConfirmParentSchedule } from "../../../components/Modals/ConfirmParentScheduleModal/ConfirmParentScheduleModal";
import { languageOptions } from "../../../constants";
import {
  FamilyMemberKind,
  OrganizationMemberReferralPostArguments,
  usePostOrganizationMemberReferral,
} from "../../../api/queries/organizationMembers/referrals";
import {
  Buttons,
  Errors,
  Form,
  FormGroup,
  FormGroupHeader,
  FormRow,
  InsuranceTooltip,
  InsuranceTooltipAndField,
  InsuranceTooltipContents,
  YesNoQuestion,
  FieldAndError,
} from "./elements";
import TealButton from "../../../components/buttons/TealButton/TealButton";
import { uniq } from "lodash";
import styled from "styled-components/macro";
import { themeMinWidth } from "../../../app/theme";
import { DaybreakErrorV4, isDaybreakErrorV4 } from "../../../api/apiClient";
import Loading from "../../../components/Loading/Loading";
import {
  StandardCheckboxField,
  StandardSelectField,
  StandardRadioButtonGroup,
  StandardTextArea,
  StandardTextField,
} from "../../../components/Forms/Field/StandardField";
import TextField from "../../../components/Forms/TextField/TextField";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Icon from "../../../components/Icon/Icon";
import { selectOptions } from "../../../components/Forms/SelectField/SelectField";
import { RadioButtons } from "../../../components/Forms/RadioButtonGroup/RadioButtonGroup";
import { useModal } from "../../../components/Modals/BaseModal/BaseModal";
import { getObjectValues } from "../../../lib/objectUtilities";

type YesNo = "yes" | "no";

export const FormRowGrid = styled(FormRow)`
  display: grid;
  grid-template-columns: 1fr;

  ${themeMinWidth("small")} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const testDefaultValues = {
  partialReferralAttributes: {
    schedulingMethod: "" as "staff_scheduled" | "parent_scheduled",
    patientPersonalEmail: "student@example.com",
    patientPhoneNumber: "555-555-5555",
    patientPreferredName: "Ted",
    staffReferrerPhoneNumber: "456-789-0123",
    attendanceIssues: "no" as YesNo,
    roiSent: true,
    academicBehaviorAndSchoolIssues: "Straight-A student",
    counselingGoals: "Lower anxiety",
    specialConsiderations: "",
    hasNoInsurance: false,
    verbalConsent: true,
  },
  familyMemberData: [
    {
      role: "mother" as FamilyMemberKind,
      firstName: "Jane",
      lastName: "Doe",
      email: "jane.doe@example.com",
      phone: "555-666-7777",
      preferredLanguage: "mandarin",
      preferredLanguageOther: "",
      isEmergencyContact: true,
      isPrimaryParent: true,
      okToContact: true,
    },
    {
      role: "father" as FamilyMemberKind,
      firstName: "John",
      lastName: "Smith",
      email: "john.smith@example.com",
      phone: "555-666-8888",
      preferredLanguage: "tagalog",
      preferredLanguageOther: "",
      isEmergencyContact: false,
      isPrimaryParent: false,
      okToContact: true,
    },
  ],
};

export const defaultValues = {
  partialReferralAttributes: {
    schedulingMethod: "" as "staff_scheduled" | "parent_scheduled",
    patientPersonalEmail: "",
    patientPhoneNumber: "",
    patientPreferredName: "",
    staffReferrerPhoneNumber: "",
    attendanceIssues: "" as YesNo,
    roiSent: false,
    academicBehaviorAndSchoolIssues: "",
    counselingGoals: "",
    specialConsiderations: "",
    hasNoInsurance: false,
    verbalConsent: false,
  },
  familyMemberData: [
    {
      role: "" as FamilyMemberKind,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      preferredLanguage: "english",
      preferredLanguageOther: "",
      isEmergencyContact: false,
      isPrimaryParent: false,
      okToContact: true, // TODO: This should not default to true
    },
  ],
};

const validationSchema = Yup.object().shape({
  partialReferralAttributes: Yup.object().shape({
    patientPersonalEmail: Yup.string()
      .required("Student email is required.")
      .email("Student email must be a valid email."),
    patientPhoneNumber: Yup.string().required(
      "Student phone number is required."
    ),
    patientPreferredName: Yup.string(),
    staffReferrerPhoneNumber: Yup.string().required(
      "Referring staff phone number is required."
    ),
    attendanceIssues: Yup.string().required("Attendance issues is required."),
    roiSent: Yup.string(),
    academicBehaviorAndSchoolIssues: Yup.string().required(
      "Academic & Behavioral issues are required."
    ),
    counselingGoals: Yup.string().required("Problem areas are required."),
    specialConsiderations: Yup.string(),
    hasNoInsurance: Yup.boolean().required("REquire"),
  }),
  familyMemberData: Yup.array().of(
    Yup.object().shape({
      role: Yup.string().required("Parent role is required."),
      firstName: Yup.string().required("Parent first name is required."),
      lastName: Yup.string().required("Parent last name is required."),
      phone: Yup.string().required("Parent phone number is required."),
      email: Yup.string()
        .email("Parent email must be a valid email.")
        .required("Parent email is required."),
      preferredLanguage: Yup.string()
        .oneOf(Object.keys(languageOptions))
        .required("Please select the guardian's preferred language."),
      preferredLanguageOther: Yup.string().when("guardianPreferredLanguage", {
        is: "other",
        then: (yup) =>
          yup.required("Please specify the guardian's preferred language."),
      }),
      // verbalConsent: Yup.boolean().required(
      //   "Family member must consent to participating in family therapy"
      // ),
      // oktoContact: Yup.boolean().required(
      //   "Only family members who are OK to contact should be a part of family therapy."
      // ),
    })
  ),
});

const toastSuccessMessage = (organizationMember: OrganizationMemberV1) => (
  <div>
    <p>Successfully referred {organizationMember.firstName}!</p>
    <TextLink href={`/school/students/${organizationMember.id}`}>
      Click here for more details
    </TextLink>
    .
    <p>
      The student's parent will receive an email to complete intake scheduling.
    </p>
  </div>
);

const setFieldErrors = (
  error: DaybreakErrorV4,
  setFieldError: (field: string, message: string | undefined) => void
) => {
  const translateKey = (key: string): string =>
    key.replace("partialReferral", "partialReferralAttributes");

  for (const key in error.errorData) {
    if (Array.isArray(error.errorData[key])) {
      setFieldError(translateKey(key), error.errorData[key].join(", "));
    } else {
      setFieldError(translateKey(key), error.errorData[key]);
    }
  }
};

export type FamilyTherapyReferralFormProps = {
  createReferral: ReturnType<
    typeof usePostOrganizationMemberReferral
  >["mutate"];
  organizationMember: OrganizationMemberV1;
  collectInsuranceInfo: boolean;
  allowScheduleNow: boolean;
};

export const FamilyTherapyReferralForm: React.FC<FamilyTherapyReferralFormProps> = ({
  createReferral,
  organizationMember,
  collectInsuranceInfo,
  allowScheduleNow,
}) => {
  const history = useHistory();

  const { openModal, closeModal, asModal } = useModal();

  const saveReferral = (
    setFieldError: (field: string, message: string | undefined) => void,
    valuesToPost: OrganizationMemberReferralPostArguments,
    onSettled: () => void
  ) => {
    createReferral(valuesToPost, {
      onSuccess: (data) => {
        if (
          valuesToPost.partialReferralAttributes.schedulingMethod ===
            "parent_scheduled" ||
          valuesToPost.partialReferralAttributes.schedulingMethod ===
            "pwp_scheduled"
        ) {
          toast.success(() => toastSuccessMessage(organizationMember), {
            duration: 30000,
          });
          history.push("/school");
        } else {
          history.push(
            `/school/scheduling/${organizationMember.id}?partialReferralId=${data.data.partialReferral?.id}`
          );
        }
      },
      onSettled: onSettled,
      onError: (error) => {
        if (isDaybreakErrorV4(error)) {
          if (error.errorType === "validation_failed") {
            setFieldErrors(error, setFieldError);
          } else {
            console.log("An unknown error occured.");
          }
        } else {
          console.log("An unknown error occured.");
        }
      },
    });
  };

  const valuesToPost = (values: typeof defaultValues) => {
    // The checkbox simply *being* checked doesn't actually set this to anything
    values.familyMemberData[0].isPrimaryParent = true;

    const insuranceHandlingDirective = collectInsuranceInfo
      ? values.partialReferralAttributes.hasNoInsurance
        ? "no_insurance"
        : "collect_info"
      : "not_applicable";

    return {
      partialReferralAttributes: {
        ...values.partialReferralAttributes,
        insuranceHandlingDirective: insuranceHandlingDirective,
      },
      familyMemberData: values.familyMemberData,
      organizationMemberId: organizationMember.id,
    };
  };

  const onSubmit = (
    values: typeof defaultValues,
    {
      setSubmitting,
      setFieldError,
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
      setFieldError: (field: string, message: string | undefined) => void;
    }
  ) => {
    closeModal();

    analytics.track("SCD:SubmitFamilyTherapyReferralForm", {
      schedulingMethod: values.partialReferralAttributes.schedulingMethod,
      organizationMemberId: organizationMember.id,
    });

    saveReferral(setFieldError, valuesToPost(values), () => {
      setSubmitting(false);
    });
  };

  const combinedDefaultValues = {
    ...defaultValues,
    partialReferralAttributes: {
      ...defaultValues.partialReferralAttributes,
      patientPersonalEmail: organizationMember.studentEmail,
      patientPreferredName: organizationMember.firstName,
    },
  };

  return (
    <Formik
      initialValues={
        window.localStorage.getItem("prefillTestValues")
          ? testDefaultValues
          : combinedDefaultValues
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        submitForm,
        isSubmitting,
        setFieldValue,
        values,
        errors,
        isValid,
      }) => {
        const scheduleViaParentButton = (
          <TealButton
            variant="secondary"
            onClick={() => {
              setFieldValue(
                "partialReferralAttributes.schedulingMethod",
                "pwp_scheduled"
              );
              openModal();
            }}
            disabled={isSubmitting || !isValid}
          >
            Ask Parent to Schedule Intake
          </TealButton>
        );

        const scheduleNowButton = (
          <TealButton
            variant="primary"
            onClick={() => {
              setFieldValue(
                "partialReferralAttributes.schedulingMethod",
                "staff_scheduled"
              );
              submitForm();
            }}
            disabled={isSubmitting || !isValid}
          >
            Schedule now
          </TealButton>
        );

        return (
          <Form>
            {asModal(
              <ConfirmParentSchedule
                closeModal={closeModal}
                onConfirm={handleSubmit}
              />
            )}
            <FormGroup>
              <FormGroupHeader>Student</FormGroupHeader>
              <FormRowGrid>
                <FieldAndError>
                  <TextField
                    label="First Name"
                    disabled
                    value={organizationMember.firstName}
                  />
                </FieldAndError>
                <FieldAndError>
                  <TextField
                    label="Last Name"
                    disabled
                    value={organizationMember.lastName}
                  />
                </FieldAndError>
              </FormRowGrid>
              <FormRowGrid>
                <StandardTextField
                  label="School Email"
                  name="partialReferralAttributes.studentEmail"
                  value={organizationMember.studentEmail}
                  disabled={true}
                />
                <StandardTextField
                  label="Preferred Name"
                  name="partialReferralAttributes.patientPreferredName"
                  {...(organizationMember.firstName ===
                  values.partialReferralAttributes.patientPreferredName
                    ? { info: "This student prefers their legal first name." }
                    : {})}
                />
              </FormRowGrid>

              <FormRowGrid>
                <StandardTextField
                  name="partialReferralAttributes.patientPersonalEmail"
                  label="Personal email"
                  {...(organizationMember.studentEmail ===
                  values.partialReferralAttributes.patientPersonalEmail
                    ? {
                        info:
                          "This email is the same as the student's school email.",
                      }
                    : {})}
                />
                <StandardTextField
                  name="partialReferralAttributes.patientPhoneNumber"
                  label="Phone number"
                />
              </FormRowGrid>
            </FormGroup>

            {collectInsuranceInfo ? (
              <FormGroup>
                <FormGroupHeader>Insurance</FormGroupHeader>
                <FormRow>
                  <StandardCheckboxField
                    name="partialReferralAttributes.hasNoInsurance"
                    label="I'm certain this student is not covered by insurance."
                    layout={(field) => (
                      <InsuranceTooltipAndField>
                        {field}
                        <InsuranceTooltip>
                          <Tooltip
                            tooltipContents={
                              <InsuranceTooltipContents>
                                Only check this if you are sure this individual
                                is not covered by insurance. If there's any
                                doubt, leave it unchecked.
                              </InsuranceTooltipContents>
                            }
                          >
                            <Icon name="info" title="" />
                          </Tooltip>
                        </InsuranceTooltip>
                      </InsuranceTooltipAndField>
                    )}
                  />
                </FormRow>
              </FormGroup>
            ) : null}

            <FormGroupHeader>Family Members</FormGroupHeader>
            <FieldArray name="familyMemberData">
              {({ remove, push }) => (
                <div>
                  {values.familyMemberData.map((familyMember, index) => (
                    <FormGroup
                      key={index}
                      style={index !== 0 ? { paddingTop: "60px" } : {}}
                    >
                      <FormRowGrid>
                        <FormRow>
                          <StandardRadioButtonGroup
                            name={`familyMemberData.${index}.role`}
                            label="Role:"
                            renderChildren={(props) => (
                              <RadioButtons
                                buttons={[
                                  ["mother", "Mother"],
                                  ["father", "Father"],
                                  ["guardian", "Guardian"],
                                ]}
                                field_name={`familyMemberData.${index}.role`}
                                {...props}
                              />
                            )}
                          />
                        </FormRow>
                      </FormRowGrid>
                      <FormRowGrid>
                        {index === 0 ? (
                          <StandardCheckboxField
                            name={`familyMemberData.${index}.isPrimaryParent`}
                            label="Primary Guardian"
                            disabled
                            checked
                          />
                        ) : null}
                        <StandardCheckboxField
                          disabled={index === 0}
                          name={`familyMemberData.${index}.okToContact`}
                          label="OK To Contact"
                        />
                      </FormRowGrid>
                      <FormRowGrid>
                        <StandardTextField
                          label="First name"
                          name={`familyMemberData.${index}.firstName`}
                        />
                        <StandardTextField
                          label="Last name"
                          name={`familyMemberData.${index}.lastName`}
                        />
                      </FormRowGrid>
                      <FormRowGrid>
                        <StandardTextField
                          label="Email"
                          name={`familyMemberData.${index}.email`}
                        />
                        <StandardTextField
                          label="Phone number"
                          name={`familyMemberData.${index}.phone`}
                        />
                      </FormRowGrid>
                      <FormRowGrid>
                        <StandardSelectField
                          name={`familyMemberData.${index}.preferredLanguage`}
                          label="Preferred language"
                        >
                          {selectOptions(
                            Object.entries(languageOptions) as [
                              string,
                              string
                            ][],
                            `familyMemberData.${index}.preferredLanguage`
                          )}
                        </StandardSelectField>
                        {familyMember.preferredLanguage === "other" ? (
                          <StandardTextField
                            name={`familyMemberData.${index}.preferredLanguageOther`}
                            label="Please specify language"
                          />
                        ) : null}
                      </FormRowGrid>
                      {index !== 0 && (
                        <FormRow>
                          <TealButton
                            variant="primary"
                            onClick={() => remove(index)}
                          >
                            Remove Family Member
                          </TealButton>
                        </FormRow>
                      )}
                    </FormGroup>
                  ))}

                  <Buttons>
                    <TealButton
                      variant="primary"
                      onClick={() => push(defaultValues.familyMemberData[0])}
                    >
                      Add Family Member
                    </TealButton>
                  </Buttons>
                </div>
              )}
            </FieldArray>

            <FormGroup>
              <FormGroupHeader>Referring Staff</FormGroupHeader>
              <FormRowGrid>
                <StandardTextField
                  label="Phone number"
                  name="partialReferralAttributes.staffReferrerPhoneNumber"
                />
              </FormRowGrid>
            </FormGroup>

            <FormGroup>
              <FormGroupHeader>Intake Information</FormGroupHeader>
              <FormRow>
                <StandardRadioButtonGroup
                  name="partialReferralAttributes.attendanceIssues"
                  label=""
                  renderChildren={(props) => (
                    <RadioButtons
                      buttons={[
                        ["yes", "Yes"],
                        ["no", "No"],
                      ]}
                      field_name="partialReferralAttributes.attendanceIssues"
                      {...props}
                    />
                  )}
                  layout={(field) => (
                    <YesNoQuestion>
                      {field}
                      Is the student being referred known to have attendance
                      issues at school?
                    </YesNoQuestion>
                  )}
                />
              </FormRow>
              <FormRow>
                <StandardCheckboxField
                  name="partialReferralAttributes.verbalConsent"
                  label="Parent / Caregiver has given verbal consent to treatment and will provide written consent to Daybreak Health by first therapy appointment"
                />
              </FormRow>
              <FormRow>
                <StandardCheckboxField
                  name="partialReferralAttributes.roiSent"
                  label={
                    <>
                      Student and/or Parent has been emailed the{" "}
                      <TextLink
                        href="https://www.surveymonkey.com/r/daybreakROI"
                        target="_blank"
                      >
                        Daybreak Release of Info
                      </TextLink>
                      .
                    </>
                  }
                />
              </FormRow>
            </FormGroup>

            <FormGroup>
              <FormRow>
                <StandardTextArea
                  name="partialReferralAttributes.academicBehaviorAndSchoolIssues"
                  label="Academic & Behavioral School Issues"
                  placeholder="E.g. classroom participation, conduct disorder, etc."
                />
              </FormRow>
              <FormRow>
                <StandardTextArea
                  name="partialReferralAttributes.counselingGoals"
                  label="Problem areas (socio-emotional) & treatment goals."
                  placeholder="E.g. reduce anxiety"
                />
              </FormRow>
              <FormRow>
                <StandardTextArea
                  name="partialReferralAttributes.specialConsiderations"
                  label="Special considerations"
                  placeholder="E.g. issues around parent consent, or privacy at home to have the counseling"
                />
              </FormRow>
            </FormGroup>

            {!isValid && (
              <Errors>{uniq(getObjectValues(errors)).join(" ")}</Errors>
            )}

            <Buttons>
              {isSubmitting ? (
                <Loading size="small" />
              ) : (
                <>
                  {scheduleViaParentButton}
                  {allowScheduleNow ? scheduleNowButton : null}
                </>
              )}
            </Buttons>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FamilyTherapyReferralForm;
