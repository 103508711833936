import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../api/apiContext";
import { OrganizationFeature } from "../../api/queries/organizationFeatures";
import DistrictSelector from "./DistrictSelector";
import ParentWelcomePacketForm from "./ParentWelcomePacketForm";
import ParentWelcomePacketLayout from "../../layouts/ParentWelcomePacketLayout/ParentWelcomePacketLayout";
import DisabledPage from "../DisabledPage/DisabledPage";
import { trackPage, trackEvent } from "client/amplitudeHelper";

export const GENERIC_ORG_API_KEY = "web-referral";

export type ParentWelcomePacketPageWithDataProps = {
  hasPWP: boolean;
  consentBlocking: boolean;
  organizationApiSlug: string;
};
export const ParentWelcomePacketPageWithData: React.FC<ParentWelcomePacketPageWithDataProps> = ({
  hasPWP,
  consentBlocking,
  organizationApiSlug,
}) => {
  if (!hasPWP) {
    return <DisabledPage />;
  } else if (
    organizationApiSlug &&
    organizationApiSlug !== GENERIC_ORG_API_KEY
  ) {
    return (
      <ParentWelcomePacketForm
        organizationApiSlug={organizationApiSlug}
        consentBlocking={consentBlocking}
      />
    );
  } else {
    return <DistrictSelector />;
  }
};

export type ParentWelcomePacketPageProps = {};
const ParentWelcomePacketPage: React.FC<ParentWelcomePacketPageProps> = () => {
  const { organizationApiKey } = useParams<{ organizationApiKey: string }>();
  useEffect(() => {
    trackPage("PWP:MainPage", { apiKey: organizationApiKey });
  }, [organizationApiKey]);

  // Organizations that we're not currently engaged with have an api slug like "A12FS-1234"
  const isDaybreakOrg = !organizationApiKey.match(/\d+$/);

  const { useGetOrganizationFeatures } = useApi();

  const {
    data: { data: orgFeatures } = {},
    isLoading: isLoadingOrganizationFeatures,
  } = useGetOrganizationFeatures({
    organizationApiKey: organizationApiKey,
    options: {
      enabled: isDaybreakOrg,
    },
  });
  const pwpFeature = orgFeatures?.find(
    (orgFeature: OrganizationFeature) => orgFeature.featureApiKey === "pwp"
  );
  const consentBlocking = orgFeatures?.find(
    (orgFeature: OrganizationFeature) =>
      orgFeature.featureApiKey === "consent_to_contact_flow"
  );

  const hasPWP = (!isDaybreakOrg || pwpFeature?.active) ?? false;
  useEffect(() => {
    if (hasPWP) {
      trackEvent("PWP:disabledPWP");
    }
  }, [hasPWP]);

  useEffect(() => {
    if (isLoadingOrganizationFeatures) {
      trackEvent("PWP:loadingConfiguration");
    }
  }, [isLoadingOrganizationFeatures]);

  if (isLoadingOrganizationFeatures) {
    return <ParentWelcomePacketLayout loading />;
  } else {
    return (
      <ParentWelcomePacketPageWithData
        hasPWP={hasPWP}
        consentBlocking={consentBlocking?.active ?? false}
        organizationApiSlug={organizationApiKey}
      />
    );
  }
};

export default ParentWelcomePacketPage;
