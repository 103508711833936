import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useApiClient } from "../apiClient";
import { convertSerializedType, withApiErrorHandling } from "./queryHelpers";

type PostIntakesVariables = {
  referralId: string;
  intake: {
    acuityAppointmentId: string;
    startTime: number;
    timezone: string;
    status: "scheduled";
  };
};

type PostIntakeViaReferralVariables = {
  referralId: string;
  intake: {
    acuityAppointmentId: string;
    startTime: number;
    timezone: string;
    status: "scheduled";
    partialReferralId?: string;
  };
};

type Intake = {
  id: string;
  attributes?: {
    acuityAppointmentId?: string;
    referralId?: string;
    startTime?: number;
    status?: string;
    timezone?: string;
    userId?: string;
  };
};
type PostIntakesResponse = {
  data: Intake;
};

// This is the flattened form
// of v4 intake.
export type FlatIntake = {
  id: string;
  // Unscheduled appointments will not have Acuity IDs.
  acuityAppointmentId?: string | null;
  referralId?: string;
  // API does string | null.
  startTime?: null | string;
  status?: string;
  timezone?: string;
  userId?: string;
};

export const usePostIntakes = (
  options: UseMutationOptions<
    PostIntakesResponse,
    AxiosError,
    PostIntakesVariables
  > = {}
) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<PostIntakesResponse, AxiosError, PostIntakesVariables>(
    async ({ referralId, intake }) => {
      return withApiErrorHandling(async () => {
        const response = await apiClient.post(
          `referrals/v1/${referralId}/intake`,
          {
            intake,
          }
        );
        return convertSerializedType(response.data);
      });
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("organizationMembers");
        options.onSuccess && options.onSuccess(data, variables, context);
      },
      ...options,
    }
  );
};

export const usePostIntakesViaReferral = (
  options: UseMutationOptions<
    PostIntakesResponse,
    AxiosError,
    PostIntakeViaReferralVariables
  > = {}
) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<
    PostIntakesResponse,
    AxiosError,
    PostIntakeViaReferralVariables
  >(
    async ({ referralId, intake }) => {
      return withApiErrorHandling(async () => {
        const response = await apiClient.post(
          `referrals/v1/${referralId}/intake`,
          {
            intake,
          }
        );
        return convertSerializedType(response.data);
      });
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("organizationMembers");
        options.onSuccess && options.onSuccess(data, variables, context);
      },
      ...options,
    }
  );
};
