import en from "./ContactCard.en.json";
import es from "./ContactCard.es.json";
import { useDaybreakTranslation } from "../../../hooks/useDaybreakTranslation";
import { TextFieldProps } from "../TextField/TextField";
import {
  StandardSelectStateField,
  StandardTextField,
} from "../Field/StandardField";
import * as Yup from "yup";
import { FormGroup, FormRowGrid, H2 } from "../elements";

type ContactCardOptions = {
  prefix: string;
  email?: boolean;
  phone?: boolean;
  address?: boolean;
};

export const contactCardValidationSchema = ({
  prefix,
  email,
  phone,
  address,
}: ContactCardOptions) => {
  const nameSchema = {
    [`${prefix}FirstName`]: Yup.string().required("First Name Required"),
    [`${prefix}LastName`]: Yup.string().required("Last Name Required"),
  };

  const phoneSchema = {
    [`${prefix}PhoneNunber`]: Yup.string().required("Phone Number Required"),
  };

  const emailSchema = {
    [`${prefix}Email`]: Yup.string()
      .email("Invalid email")
      .required("Email Required"),
  };

  const addressSchema = {
    [`${prefix}StreetAddress`]: Yup.string().required(
      "Street Address Required"
    ),
    [`${prefix}City`]: Yup.string().required("City Required"),
    [`${prefix}State`]: Yup.string().required("State Required"),
    [`${prefix}ZipCode`]: Yup.string().required("Zip Code Required"),
  };

  return {
    ...nameSchema,
    ...(phone ? phoneSchema : {}),
    ...(email ? emailSchema : {}),
    ...(address ? addressSchema : {}),
  };
};

export const contactCardInitialValues = ({
  prefix,
  email,
  phone,
  address,
}: ContactCardOptions) => {
  const nameValues = {
    [`${prefix}FirstName`]: "",
    [`${prefix}LastName`]: "",
  };

  const phoneValues = {
    [`${prefix}PhoneNunber`]: "",
  };

  const emailValues = {
    [`${prefix}Email`]: "",
  };

  const addressValues = {
    [`${prefix}StreetAddress`]: "",
    [`${prefix}City`]: "",
    [`${prefix}State`]: "",
    [`${prefix}ZipCode`]: "",
  };

  return {
    ...nameValues,
    ...(phone ? phoneValues : {}),
    ...(email ? emailValues : {}),
    ...(address ? addressValues : {}),
  };
};

type ContactCardFieldProps = Omit<
  TextFieldProps,
  "label" | "formik" | "basicFormik"
>;

export type ContactCardProps = {
  title?: string;
  tracking?: string;
  children?: React.ReactNode;
} & ContactCardOptions &
  ContactCardFieldProps;

const ContactCard = ({
  title,
  prefix,
  children,
  email = true,
  phone = true,
  address = false,
  ...props
}: ContactCardProps): JSX.Element => {
  const { t } = useDaybreakTranslation("contactCard", { en, es });
  const id = (name: string) => `${prefix}${name}`;
  const label = (name: string) => t(`${name}Label`) ?? "";

  return (
    <>
      {title && <H2>{title}</H2>}
      <FormGroup>
        <FormRowGrid>
          <StandardTextField
            name={id("FirstName")}
            label={label("FirstName")}
            {...props}
          />
          <StandardTextField
            name={id("LastName")}
            label={label("LastName")}
            {...props}
          />
          {email && (
            <StandardTextField
              name={id("Email")}
              label={label("Email")}
              {...props}
            />
          )}
          {phone && (
            <StandardTextField
              name={id("PhoneNunber")}
              label={label("PhoneNumber")}
              {...props}
            />
          )}
          {address && (
            <>
              <StandardTextField
                name={id("StreetAddress")}
                label={label("StreetAddress")}
                {...props}
              />
              <StandardTextField
                name={id("City")}
                label={label("City")}
                {...props}
              />
              <StandardSelectStateField
                name={id("State")}
                label={label("State")}
                {...props}
              />
              <StandardTextField
                name={id("ZipCode")}
                label={label("ZipCode")}
                type="number"
                {...props}
              />
            </>
          )}
        </FormRowGrid>
        {children}
      </FormGroup>
    </>
  );
};

export const useContactCard = (props: ContactCardProps) => {
  return {
    ContactCard: (additionalProps?: ContactCardFieldProps) => (
      <ContactCard {...props} {...additionalProps} />
    ),
    validationSchema: contactCardValidationSchema(props),
    initialValues: contactCardInitialValues(props),
  };
};

export default ContactCard;
