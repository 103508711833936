import { PreferredLanguage } from "./partialReferrals";
import { createUseApiMutation, createUseApiQuery } from "./queryHelpers";

export const guardianRelationships = {
  mother: "Mother",
  father: "Father",
  other: "Other",
};
export type GuardianRelationship = keyof typeof guardianRelationships;
export type ParentWelcomePacket = {
  id: string;

  // Patient Info
  patientFirstName: string;
  patientLastName: string;
  patientPreferredFirstName: string;
  patientGender: string;
  patientBirthDate: string;
  patientPersonalEmail: string;
  patientPersonalPhoneNumber: string;
  patientPreferredLanguage: PreferredLanguage;
  patientPreferredLanguageOther: string;
  patientSchoolEmail: string;
  patientStudentIdNumber: string;

  // Guardian Info
  guardianRelationship: GuardianRelationship;
  guardianFirstName: string;
  guardianLastName: string;
  guardianPhoneNumber: string;
  guardianEmail: string;
  guardianPreferredLanguage: PreferredLanguage;
  guardianPreferredLanguageOther: string;

  // Agreements
  agreementText: string;
  agreementTosPp: boolean; // Terms of Service and Privacy Policy
  agreementIc: boolean; // Informed Consent
  agreementAobHipaa: boolean; // Assignment of Benefits and HIPAA authorization
  agreementHipaaRoi: boolean; // Release of Information to schools
  agreementSignature?: string;

  // Insurance
  insuranceLink?: string;
  insuranceRequired: boolean;
  insuranceCompleted: boolean;

  // Scheduling
  schedulingLink?: string;
  schedulingRequired: boolean;
  schedulingCompleted: boolean;
  asyncIntakeLink?: string;
  asyncIntakeQuestionnaireCompleted?: boolean;

  // Other
  linkedAndConfigured: boolean;
  // Status is "completed" once signature is confirmed.
  status: "Completed" | "errors" | "other";
  completedDate?: string;
  flowVersion?: number | null;
  packetComplete?: boolean;
  packetCompletionDate?: string;
  // Without this, the agreement text will
  // miss the substitution value.
  organizationName?: string;
  organizationSlug?: string;

  clientSfid: string;
  clientReferralSfid: string;
  collectInsurance?: boolean;
};

export const useGetParentWelcomePacket = createUseApiQuery<
  ParentWelcomePacket,
  { orgApiKey: string }
>({
  queryBuilder: ({ orgApiKey }) => ({
    cacheKey: ["parent_welcome_packets", orgApiKey],
    url: `/v4/parent_welcome_packets/${orgApiKey}`,
  }),
  auth: false,
});

export type pwpPostOmissions =
  | "id"
  | "packetComplete"
  | "completedDate"
  | "packetCompletionDate"
  | "organizationName";

export type ParentWelcomePacketPostArguments = Partial<
  Omit<ParentWelcomePacket, pwpPostOmissions>
> & { orgApiKey: string; complete?: boolean };
export const usePostParentWelcomePacket = createUseApiMutation<
  ParentWelcomePacket,
  ParentWelcomePacketPostArguments
>({
  requestBuilder: ({ orgApiKey, ...args }) => ({
    method: "put",
    url: `/v4/parent_welcome_packets/${orgApiKey}`,
    data: {
      patient_first_name: args.patientFirstName,
      patient_last_name: args.patientLastName,
      patient_birth_date: args.patientBirthDate,
      patient_preferred_language: args.patientPreferredLanguage,
      patient_preferred_language_other: args.patientPreferredLanguageOther,
      guardian_relationship: args.guardianRelationship,
      guardian_first_name: args.guardianFirstName,
      guardian_last_name: args.guardianLastName,
      guardian_phone_number: args.guardianPhoneNumber,
      guardian_email: args.guardianEmail,
      guardian_preferred_language: args.guardianPreferredLanguage,
      guardian_preferred_language_other: args.guardianPreferredLanguageOther,
      agreement_text: args.agreementText,
      agreement_tos_pp: args.agreementTosPp,
      agreement_ic: args.agreementIc,
      agreement_aob_hipaa: args.agreementAobHipaa,
      agreement_hipaa_roi: args.agreementHipaaRoi,
      agreement_signature: args.agreementSignature,
      scheduling_completed: args.schedulingCompleted,
      complete: !!args.complete,
      flow_version: args.flowVersion ?? 0,
    },
  }),
  auth: false,
});
export type PostParentWelcomePacket = ReturnType<
  typeof usePostParentWelcomePacket
>["mutate"];
export type PostParentWelcomePacketParameters = Parameters<PostParentWelcomePacket>;
