import * as amplitude from "@amplitude/analytics-browser";

export function initializeAmplitude() {
  if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
    amplitude
      .init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
        defaultTracking: true,
      })
      .promise.then(() => {
        if (amplitude.getDeviceId()) {
          amplitude.setUserId(amplitude.getDeviceId());
        }
      });
  } else {
    console.warn("No amplitude API key found in environment");
  }
}

export function identifyUser(
  userId: string | undefined,
  properties?: Record<string, any>
) {
  amplitude.setUserId(userId);

  if (properties) {
    identify(properties);
  } else {
    // Capture UserId with Empty Properties
    identify(new amplitude.Identify());
  }
}

export function identifyAnonymousUser(properties: Record<string, any>) {
  amplitude.setUserId(amplitude.getDeviceId());
  identify(properties);
}

export function trackPage(pageName: string, eventData?: Record<string, any>) {
  amplitude.track("Viewed " + pageName + " page", eventData);
}

export function trackEvent(
  title: string,
  eventData?: Record<string, any>,
  callback?: () => void
) {
  amplitude.track(title, eventData).promise.then(callback);
}

export function reset() {
  amplitude.reset();
}

export function getDeviceId(): string | undefined {
  return amplitude.getDeviceId();
}

function identify(properties?: Record<string, any>) {
  const amplitudeIdentify = new amplitude.Identify();
  for (const [key, value] of Object.entries(properties!)) {
    amplitudeIdentify.set(key, value);
  }
  amplitude.identify(amplitudeIdentify);
}
