import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import React from "react";

type DaybreakAuthProviderProps = Partial<Auth0ProviderOptions>;

const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const audience = AUTH0_AUDIENCE;

export const AUTH0_REDIRECT_URI =
  window.location.origin + "/onboarding/after-auth";
const redirectUri = AUTH0_REDIRECT_URI;

const DaybreakAuthProvider: React.FC<DaybreakAuthProviderProps> = (
  auth0ProviderParams
) => {
  if (clientId && domain && audience) {
    const newParams: Auth0ProviderOptions = {
      ...auth0ProviderParams,
      clientId,
      domain,
      audience,
      redirectUri,
    };
    return <Auth0Provider {...newParams} />;
  } else {
    console.warn("Auth0 setup: missing one of: ", {
      clientId,
      domain,
      audience,
    });
    return <>{auth0ProviderParams.children}</>;
  }
};

export default DaybreakAuthProvider;
