import { decamelizeKeys } from "humps";
import { ParentWelcomePacket } from "./parentWelcomePackets";
import { createUseApiMutation, createUseApiQuery } from "./queryHelpers";
import useResourceHooks from "./resourceHelpers";

export const useGetWelcomePacketBySfid = createUseApiQuery<
  ParentWelcomePacket,
  { sfid: string }
>({
  queryBuilder: ({ sfid }) => ({
    cacheKey: ["welcome_packets", sfid],
    url: `/v4/welcome_packets/by_sfid/${sfid}`,
  }),
  auth: false,
});

export const usePatchWelcomePacketBySfid = createUseApiMutation<
  ParentWelcomePacket,
  { sfid: string } & ParentWelcomePacket
>({
  requestBuilder: ({ sfid, ...args }) => ({
    method: "patch",
    url: `/v4/welcome_packets/by_sfid/${sfid}`,
    data: {
      parent_welcome_packet: decamelizeKeys(args),
    },
  }),
  auth: false,
});

export type PostWelcomePacketBySfid = ReturnType<
  typeof usePatchWelcomePacketBySfid
>["mutate"];

/* Note: PWP is a public page, so no auth */
const useWelcomePacketResourceHooks = () =>
  useResourceHooks<ParentWelcomePacket>("/v4/welcome_packets", false);

export default useWelcomePacketResourceHooks;
