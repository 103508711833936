import { DateTime } from "luxon";
import React, { ReactElement } from "react";
import { useApi } from "../../api/apiContext";
import { ApplicationFeature } from "../../api/queries/features";
import DisabledPage from "../../pages/DisabledPage/DisabledPage";

// Wraps a route and returns a disabled page if the specified feature flag is
// not enabled.
//
// If you want to bypass this block (eg for testing), paste the following into
// your chrome js console while the daybreak frontend is open:
//
// x = new Date(); x.setDate(x.getDate() + 1); localStorage.setItem('skipFeatureFlagBlockUntil', x.toISOString())
//
// Note that the above is meant as a developer-facing tool.  If we want a tool
// to bypass this block that's more appropriate for CST or even customers, we
// should build it properly.  :)
const FeatureFlagWrapper: React.FC<{
  featureFlag: ApplicationFeature;
  featureFlagFailureComponent?: ReactElement;
}> = ({ featureFlag, featureFlagFailureComponent, children }) => {
  const api = useApi();
  const { data } = api.useGetApplicationFeatures();
  const skipBlockUntil = localStorage.getItem("skipFeatureFlagBlockUntil");
  const shouldSkipBlock =
    skipBlockUntil !== null &&
    typeof skipBlockUntil == "string" &&
    DateTime.fromISO(skipBlockUntil) > DateTime.now();

  if (data && !data.features.includes(featureFlag) && !shouldSkipBlock) {
    return featureFlagFailureComponent ? (
      <>{featureFlagFailureComponent}</>
    ) : (
      <DisabledPage />
    );
  }
  return <>{children}</>;
};

export default FeatureFlagWrapper;
