import { ComponentProps } from "react";
import styled, { css } from "styled-components/macro";
import {
  theme,
  themeColor,
  themeFont,
  themeMinWidth,
} from "../../../app/theme";
import Loading from "../../Loading/Loading";
const Button = styled.button<{ variant?: "primary" | "secondary" }>`
  font-family: ${themeFont("helveticaNeue")};
  width: 256px;
  height: 56px;
  border-radius: 40px;
  border: none;
  justify-self: center;
  font-size: 14px;
  line-height: 14px;
  transition: all ${(props) => props.theme.animations.defaultHover};
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  :not(:disabled) {
    cursor: pointer;
  }

  ${themeMinWidth("small")} {
    font-size: 16px;
    line-height: 16px;
  }
  ${({ variant }) =>
    (!variant || variant === "primary") &&
    css`
      background-color: ${themeColor("orange5")};
      color: white;
      :not(:disabled):hover {
        filter: brightness(95%);
      }
    `}
  ${(props) =>
    props.variant === "secondary" &&
    css`
      background-color: transparent;
      color: ${themeColor("orange5")};
      border: 1.5px solid ${themeColor("orange5")};
      :not(:disabled):hover {
        background-color: ${themeColor("orange5")};
        color: white;
      }
    `}
`;

export type OrangeButtonProps = ComponentProps<typeof Button> & {
  loading?: boolean;
};

const OrangeButton: React.FC<OrangeButtonProps> = ({
  loading,
  children,
  ...buttonProps
}) => {
  return (
    <Button {...buttonProps}>
      {loading ? (
        <Loading
          size="small"
          color={
            buttonProps.variant === "secondary" ? theme.colors.orange5 : "white"
          }
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default OrangeButton;
