import "styled-components/macro";

// Define theme values (colors, fonts, etc) here.
export const theme = {
  // We don't have a well-defined color system.  There's value in having one,
  // but not enough to justify creating and sticking to one.  For now, here are
  // some rules of thumb to keep things semi-consistent:
  //
  // - When design calls for a color, look for a close color in here and use
  //   that instead if you find one.
  // - If you've got multiple shades of a color, append a number.  5 is
  //   "medium", 1 is very light, 9 is very dark.
  // - Don't append hex opacity values here - use the themeColor opacity helper.
  // - Avoid adding more grays.  It's inevitable that we'll end up with a few
  //   dozen, but let's stave off that day as long as possible.
  //
  // Some day we'll create a full design system and we'll scrap all these.
  // Until then, try to keep it minimal.  One-off non-theme colors are ok.
  colors: {
    black: "#000000",
    backgroundGray: "#F8F8F8",
    teal2: "#EBF2F5",
    teal4: "#84C8DA",
    teal: "#489FB5",
    teal6: "#308BA2",
    teal7: "#159AB5",
    teal8: "#0A6E82",
    green1: "#EEF9E8",
    green: "#52C41A",
    olive: "#FFE6C7",
    yellow5: "#FBBC04",
    orange0: "#FBE7CE",
    orange1: "#FFE6C7",
    orange5: "#FFA62B", // Bright orange, eg for buttons
    red1: "#FFE6E6",
    red: "#FE6433",
    blackText: "#0B3439",

    // Higher number is lighter here.  TODO: clean that up.
    darkGrayText2: "#1E293B",
    darkGrayText3: "#2B374A",
    darkGrayText4: "#393939",
    darkGrayText5: "#42474E",
    darkGrayText6: "#576466",
    darkGrayText7: "#727272",
    darkGrayText8: "#979797",

    borderGray5: "#0B34391A", // 10% opacity, deprecated, use {opacity: 0.1}
    borderGray6: "#D5DBDB",
    borderGray7: "#E8EDED",
  },
  sizes: {},
  fonts: {
    poppins: "poppins, sans-serif",
    helveticaNeue: "helvetica-neue, helvetica, arial, sans-serif",
  },
  breaks: {
    xsmall: "340px", // Smaller than this and you're a small phone like the
    // iphone 5. Avoid using this one in favor of using "small" and making it look
    // decent on all phone sizes.
    small: "640px", // Smaller than this and you're a phone in portrait mode
    medium: "768px", // Smaller than this and your a phone in landscape mode
    large: "1024px", // Larger than this and you're probably a laptop
  },
  animations: {
    defaultHover: "0.15s ease-in-out",
  },
};

export type themeType = typeof theme;

// Apparently this is the blessed way to provide styled-components with types
// for your theme: monkeypatch the default theme type. ¯\_(ツ)_/¯
declare module "styled-components" {
  export interface DefaultTheme extends themeType {}
}

/******** Some helpers for working with the theme *********/

export type styledPropsType = { theme: themeType };

// Easy way to do media queries:
//
// ${themeMinWidth("small")} {
//   color: red;
// }
//
// Instead of:
//   @media (min-width: ${props => props.theme.breaks.small}) {
//   display: none;
// }
//
type breakType = keyof typeof theme.breaks;
export const themeMinWidth = (breakKey: breakType) => (
  props: styledPropsType
) => {
  return `@media (min-width: ${props.theme.breaks[breakKey]})`;
};

// Some typescript hocus-pocus so you can do
//   `background: ${themeColor("red")}`
// instead of
//   `background: ${props => props.theme.colors.red}`
// inside styled blocks and still get type checking on the color argument.
//
// Some utilities for simply modifying colors are available under `options`
type colorsType = keyof typeof theme.colors;
type colorOptionsType = {
  opacity?: number;
};
export const themeColor = (
  color: colorsType,
  options: colorOptionsType = {}
) => (props: styledPropsType) => {
  let colorString = props.theme.colors[color];
  if (options.opacity) {
    colorString += hexOpacity(options.opacity);
  }
  return colorString;
};

type fontsType = keyof typeof theme.fonts;
export const themeFont = (font: fontsType) => (props: styledPropsType) =>
  props.theme.fonts[font];

export const hexOpacity = (floatOpacity: number) =>
  Math.trunc(255 * floatOpacity)
    .toString(16)
    .padStart(2, "0");
