import React from "react";
import styled from "styled-components/macro";
import { themeMinWidth } from "../../app/theme";
import { CenteredLoading } from "../../components/Loading/Loading";
import Wizard, { ScreenerWizardSteps } from "../../components/Wizard/Wizard";
import useIntercom from "../../hooks/useIntercom";
import CenteredLayout from "../CenteredLayout/CenteredLayout";

const Box = styled.div`
  // prevent margin collapse
  overflow: auto;
  width: 100%;
  ${themeMinWidth("small")} {
    width: 704px;
  }
`;
const WizardContainer = styled.div`
  padding: 24px 17px;

  ${themeMinWidth("small")} {
    border-bottom: 1px solid #e4e4e4;
  }
`;
const LoadingContainer = styled.div`
  display: flex;
  padding: 80px;
`;

type WizardStep = typeof ScreenerWizardSteps[number];
type ScreenerWizardProps = {
  currentWizardStep?: WizardStep;
};
const ScreenerWizard: React.FC<ScreenerWizardProps> = ({
  currentWizardStep,
}) => {
  const lastFilledStepIndex = currentWizardStep
    ? ScreenerWizardSteps.indexOf(currentWizardStep) + 1
    : 0;
  return (
    <WizardContainer>
      <Wizard
        steps={ScreenerWizardSteps}
        lastFilledStepIndex={lastFilledStepIndex}
      />
    </WizardContainer>
  );
};
export type ScreenerLayoutProps = { loading?: boolean } & ScreenerWizardProps;
const ScreenerLayout: React.FC<ScreenerLayoutProps> = ({
  loading,
  currentWizardStep,
  children,
}) => {
  useIntercom({ boot: false });
  const content = loading ? (
    <LoadingContainer>
      <CenteredLoading />
    </LoadingContainer>
  ) : (
    children
  );

  return (
    <CenteredLayout headerStyle="simple">
      <Box>
        {currentWizardStep && (
          <ScreenerWizard currentWizardStep={currentWizardStep} />
        )}
        {content}
      </Box>
    </CenteredLayout>
  );
};

export default ScreenerLayout;
