import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageDetector from "i18next-browser-languagedetector";

// As a warning: i18next currently has some problems with typescript.  See:
// https://github.com/i18next/i18next/issues/2008
// Our solutions are either:
// 1. Hope they fix typescript 4.x support soon
// 2. Upgrade to typescript 5.x
// And #2 is currently too large a rabbit hole.
//
// So what I've done is downgraded react-i18next and i18next by a major version
// each, since these bugs are only a couple months old right now.  This gets us
// by, but does mean we can't upgrade either safely.  Please fix this if you're
// able!  -- kevinkuchta 2023-09-13
const initI18N = () => {
  const instance = i18n.use(initReactI18next).use(languageDetector);
  instance.init({
    fallbackLng: "en",
  });
  return instance;
};
export default initI18N;
