import { Field } from "formik";
import React from "react";
import { ReactElement } from "react";
import { Input, Layout } from "../elements";

export type SearchSelectFieldProps = {
  name: string;
  label: string | ReactElement;
  error?: boolean;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

const SearchSelectField: React.FC<SearchSelectFieldProps> = ({
  name,
  label,
  error,
  children,
  ...inputProps
}) => {
  return (
    <Layout label={label} error={error} {...inputProps}>
      <Field list={name} name={name} error={error} as={Input} {...inputProps} />
      <datalist id={name}>{children}</datalist>
    </Layout>
  );
};

export default SearchSelectField;
