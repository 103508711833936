import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { GroupChannel } from "sendbird";
import styled from "styled-components/macro";
import { useApi } from "../../../api/apiContext";
import { useSendBirdStore } from "../../../app/SendBirdStore";
import { themeColor, themeMinWidth } from "../../../app/theme";
import ChannelBrowser from "../../../components/Messages/ChannelBrowser/ChannelBrowser";
import ChannelView from "../../../components/Messages/ChannelView/ChannelView";
import { useSelectedTeen } from "../../../hooks/useSelectedTeen";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import SendBirdClient from "../../../lib/sendBirdClient";
import { trackPage } from "client/amplitudeHelper";

const Container = styled.div`
  display: flex;

  // Counteract dashboardlayout's bottom margin
  margin-bottom: -24px;
`;
const ChannelBrowserContainer = styled.div`
  border-right: 1px solid ${themeColor("borderGray6")};
  display: none;
  &.showingOnMobile {
    display: block;
  }
  ${themeMinWidth("small")} {
    display: block;
    min-width: 484px;
  }
`;
const ChannelViewContainer = styled.div`
  flex-grow: 1;
  display: none;
  &.showingOnMobile {
    display: flex;
  }
  ${themeMinWidth("small")} {
    display: flex;
  }
`;

export type MessagesPageWithDataProps = {
  channels: GroupChannel[];
};
export const MessagesPageWithData: React.FC<MessagesPageWithDataProps> = ({
  channels,
}) => {
  const [selectedChannel, setSelectedChannel] = useState<GroupChannel>(
    channels[0]
  );

  // This is a bit of UI state that's only applicable on mobile.  On desktop,
  // both the channel browser and the channel view show up at the same time.
  const [showingChannelViewMobile, setShowingChannelViewMobile] = useState(
    false
  );

  return (
    <DashboardLayout topNav={{ type: "counselorTopNav" }}>
      <Container>
        <ChannelBrowserContainer
          className={showingChannelViewMobile ? "" : "showingOnMobile"}
        >
          <ChannelBrowser
            onChannelSelect={(channel) => {
              setSelectedChannel(channel);
              setShowingChannelViewMobile(true);
            }}
          />
        </ChannelBrowserContainer>

        <ChannelViewContainer
          className={showingChannelViewMobile ? "showingOnMobile" : ""}
        >
          {selectedChannel && (
            <ChannelView
              channel={selectedChannel}
              onBack={() => setShowingChannelViewMobile(false)}
            />
          )}
        </ChannelViewContainer>
      </Container>
    </DashboardLayout>
  );
};

export type MessagesPageProps = {};

const MessagesPage: React.FC<MessagesPageProps> = () => {
  useEffect(() => {
    trackPage("dashboardMessages");
  }, []);

  const api = useApi();
  const { data: { data: me } = {}, isLoading: isLoadingMe } = api.useGetMe();
  const { teen: user, isLoadingTeen: isLoadingUser } = useSelectedTeen();

  const { sendBird } = useSendBirdStore();
  const [channels, setChannels] = useState<GroupChannel[] | undefined>();

  // Get all the channels
  useEffect(() => {
    if (user && sendBird) {
      (async () => {
        const chatClient = new SendBirdClient(sendBird);
        const channels = await chatClient.findChannels({
          sendBirdUserIds: [user.sendbirdUser.externalUserId],
        });

        setChannels(channels);
      })();
    }
  }, [sendBird, user]);

  if (isLoadingMe || !me || !sendBird || isLoadingUser || !user || !channels) {
    return <DashboardLayout loading />;
  }
  const isCounselor = me.userKind === "counselor";
  if (!isCounselor) {
    // Web messaging is counselor-only for now.
    return <Redirect to="/dashboard" />;
  }
  return <MessagesPageWithData channels={channels} />;
};

export default MessagesPage;
