import { useFormik } from "formik";
import React, { useEffect } from "react";
import OrangeButton from "../../../components/buttons/OrangeButton/OrangeButton";
import ScreenerLayout from "../../../layouts/ScreenerLayout/ScreenerLayout";
import * as Yup from "yup";
import { isDaybreakError, RequestError } from "../../../api/apiClient";
import { useHistory } from "react-router-dom";
import {
  Container,
  DateInput,
  Field,
  Fields,
  Form,
  Input,
  Label,
  Title,
  Error,
  Action,
  Select,
} from "./elements";
import { useApi } from "../../../api/apiContext";
import { useOrgApiKeyParam } from "../../../app/Routes/ScreenerRoutes";
import { useMandateOrganization } from "../../../hooks/screener/useMandateOrganization";
import { PostClaimOrganizationMemberVariables } from "../../../api/queries/organizationMembers";
import { Organization } from "../../../api/queries/organizations";
import { languagePreferenceStorage } from "../../../lib/localStorageManagers";
import { isClosedScreenerStatus } from "../../../api/queries/userFeatures";
import { trackEvent, trackPage } from "client/amplitudeHelper";

export type EligibilityPageWithDataProps = {
  claimOrganizationMember: (
    variables: Omit<PostClaimOrganizationMemberVariables, "organizationApiKey">
  ) => void;
  submitting: boolean;
  claimError?: RequestError;
  organization?: Organization;
};
export const EligibilityPageWithData: React.FC<EligibilityPageWithDataProps> = ({
  claimOrganizationMember,
  submitting,
  claimError,
  organization,
}) => {
  const browserLanguage = navigator.language;
  const formik = useFormik({
    initialValues: {
      dob: "",
      studentId: "",
      screenerLanguage: browserLanguage.startsWith("es") ? "es" : "en",
    },
    validationSchema: Yup.object().shape({
      dob: Yup.string().required("Please enter your date of birth"),
      studentId: Yup.string()
        .required("Please enter your student ID")
        .max(100, "Must be 100 characters or less")
        .matches(/^[^@]*$/, "Please use your student ID number"),
    }),
    onSubmit: async ({ dob: dobString, studentId, screenerLanguage }) => {
      const dob = new Date(dobString);
      languagePreferenceStorage.set(screenerLanguage);
      claimOrganizationMember({
        studentId,
        dob,
        screenerLanguage,
      });
    },
  });

  const showLanguageDropdown =
    organization && organization.screenerLanguages.length > 1;

  return (
    <ScreenerLayout currentWizardStep="Start">
      <Container>
        <Title>Let's get started</Title>
        <Form onSubmit={formik.handleSubmit}>
          <Fields>
            <Field>
              <Label htmlFor="studentId">Your student ID number:</Label>
              <Input
                id="studentId"
                type="text"
                placeholder="0000000000"
                onChange={formik.handleChange}
                value={formik.values.studentId}
                autoComplete="off"
              />
            </Field>
            {formik.errors.studentId && formik.touched.studentId && (
              <Error>{formik.errors.studentId}</Error>
            )}

            <Field>
              <Label htmlFor="dob">Your date of birth:</Label>
              <DateInput
                id="dob"
                type="date"
                placeholder=""
                className={formik.values.dob === "" ? "empty" : ""}
                onChange={formik.handleChange}
                value={formik.values.dob}
              />
              <Error>
                {formik.errors.dob && formik.touched.dob && (
                  <Error>{formik.errors.dob}</Error>
                )}
              </Error>
            </Field>
            {showLanguageDropdown && (
              <Field>
                <Label htmlFor="screenerLanguage">Preferred Language:</Label>
                <Select
                  id="screenerLanguage"
                  value={formik.values.screenerLanguage}
                  onChange={formik.handleChange}
                >
                  <option value="en">English</option>
                  <option value="es">Spanish</option>
                </Select>
              </Field>
            )}
          </Fields>
          {claimError && (
            <Error>
              {isDaybreakError(claimError) &&
              claimError.error_type === "eligibility_file_row_not_found"
                ? ""
                : "Unknown error"}
            </Error>
          )}
          <Action>
            <OrangeButton
              type="submit"
              loading={submitting}
              disabled={submitting}
            >
              Get Started
            </OrangeButton>
          </Action>
        </Form>
      </Container>
    </ScreenerLayout>
  );
};

export type EligibilityPageProps = {};
const EligibilityPage: React.FC<EligibilityPageProps> = () => {
  const organizationApiKey = useOrgApiKeyParam();
  const organizationData = useMandateOrganization(organizationApiKey);

  const {
    isLoading: submitting,
    error: claimError,
    data: { data: claimResponseData } = {},
    mutate: claimOrganizationMember,
    isSuccess: claimSuccessful,
  } = useApi().usePostClaimOrganizationMember({});
  const history = useHistory();

  useEffect(() => {
    if (isDaybreakError(claimError)) {
      if (claimError.error_type === "eligibility_file_row_not_found") {
        trackEvent("universalScreenerEligibilityFailed");
        history.push(
          `/organization/${organizationApiKey}/screener/student-not-found`
        );
      } else if (isClosedScreenerStatus(claimError.error_type)) {
        trackEvent("screenerNotOpen", {
          claimError: claimError,
        });
        history.push(
          `/organization/${organizationApiKey}/screener/status/${claimError.error_type}`
        );
      } else {
        // Other errors are handled in the UI.
        // Let's just track them.
        trackEvent("claimError", {
          claimError: claimError,
        });
      }
    }
  }, [claimError, history, organizationApiKey]);

  useEffect(() => {
    if (claimSuccessful) {
      const screenerFeature = claimResponseData?.userFeatures?.find(
        (userFeature) => userFeature.startsWith("universal_screener")
      );

      // If this student was opted out, then they won't have a universal
      // screener user feature.  However, if there's no `userFeatures` key, then
      // the backend to support this has not yet been deployed, so let them
      // through anyway.
      if (claimResponseData?.userFeatures && !screenerFeature) {
        trackEvent("universalScreenerExempted");

        history.push(
          `/organization/${organizationApiKey}/screener/student-exempted`
        );
      } else {
        trackEvent("universalScreenerEligibilitySucceeded");

        history.push(
          `/organization/${organizationApiKey}/screener/instructions`
        );
      }
    }
  }, [claimResponseData, claimSuccessful, history, organizationApiKey]);

  useEffect(() => {
    trackPage("universalScreenerEligibilityPage");
  }, []);

  return (
    <EligibilityPageWithData
      submitting={submitting}
      claimOrganizationMember={(variables) => {
        claimOrganizationMember({ organizationApiKey, ...variables });
      }}
      claimError={claimError ?? undefined}
      organization={organizationData?.data}
    />
  );
};

export default EligibilityPage;
