import { useDaybreakTranslation } from "../../../hooks/useDaybreakTranslation";
import logoImage from "../../../images/daybreak-logo.svg";
import logoTypeImage from "../../../images/daybreak-logo-type.svg";
import {
  ButtonGroup,
  Header,
  LanguageButton,
  Left,
  Logo,
  LogoType,
  Right,
} from "./elements";

const DirectReferralHeader = (): JSX.Element => {
  const { i18n } = useDaybreakTranslation("directReferralHeader", {
    en: {},
    es: {},
  });
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <Header>
        <Left>
          <Logo src={logoImage} alt="Daybreak logo" />
          <LogoType src={logoTypeImage} alt="Daybreak logo" />
        </Left>
        <Right>
          <ButtonGroup>
            <LanguageButton
              language="en"
              currentLanguage={i18n.language}
              onClick={() => changeLanguage("en")}
            >
              English
            </LanguageButton>
            <LanguageButton
              language="es"
              currentLanguage={i18n.language}
              onClick={() => changeLanguage("es")}
            >
              Spanish
            </LanguageButton>
          </ButtonGroup>
        </Right>
      </Header>
    </>
  );
};

export default DirectReferralHeader;
