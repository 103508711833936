import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../app/theme";

export const Table = styled.table`
  width: 100%;
  display: none;
  border-collapse: collapse;
  ${themeMinWidth("small")} {
    display: table;
  }
`;
export const THead = styled.thead`
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
`;

export const HeadTR = styled.tr`
  background-color: ${themeColor("backgroundGray")};
`;
export const TH = styled.th`
  font-size: 14px;
  color: ${themeColor("darkGrayText7")};
  font-weight: 500;
  padding: 14px 8px;
  text-align: left;
  :first-child {
    padding-left: 24px;
  }
  :last-child {
    padding-right: 24px;
  }
`;
export const TBody = styled.tbody``;
export const TR = styled.tr`
  border-bottom: 1px solid ${themeColor("borderGray6", { opacity: 0.5 })};
`;
export const TD = styled.td`
  padding: 24px 8px;
  max-width: 200px;
  font-size: 14px;
  color: ${themeColor("darkGrayText4")};
  :first-child {
    padding-left: 24px;
  }
  :last-child {
    padding-right: 24px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  color: ${themeColor("darkGrayText7")};
`;
export const CardBody = styled.div`
  padding: 24px 16px;
  display: flex;
  flex-direction: row;
`;
export const CardIconContainer = styled.div`
  background-color: ${themeColor("orange5", { opacity: 0.1 })};
  color: ${themeColor("orange5")};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  .icon {
    width: 34px;
    height: 34px;
  }
`;
export const CardDetails = styled.div`
  margin-left: 16px;
  overflow: hidden;
`;
export const CardTitle = styled.div`
  font-weight: 500;
  font-size: 16p;x
  overflow: hidden;
`;
export const CardDetailRow = styled.div`
  font-size: 14px;
  color: ${themeColor("darkGrayText4")};
  margin-top: 8px;
`;
export const CardDetailRowKey = styled.div`
  color: ${themeColor("darkGrayText7")};
  display: inline;
  font-weight: 500;
  margin-right: 6px;
`;

export const CardHeaderField = styled.div``;

export const MobileBoxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${themeMinWidth("small")} {
    display: none;
  }
`;

export const EmptyContentContainer = styled.div``;
export const EmptyContentMobile = styled.div`
  ${themeMinWidth("small")} {
    display: none;
  }
`;
export const EmptyContentDesktop = styled.div`
  display: none;
  ${themeMinWidth("small")} {
    display: flex;
  }
`;
