import Bugsnag from "@bugsnag/js";
import { History } from "history";
import React, { ReactElement, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { isDaybreakError } from "../../api/apiClient";
import { useApi } from "../../api/apiContext";
import { themeColor, themeMinWidth } from "../../app/theme";
import Icon from "../../components/Icon/Icon";
import { CenteredLoading } from "../../components/Loading/Loading";
import OrangeButton from "../../components/buttons/OrangeButton/OrangeButton";
import { supportEmail } from "../../constants";
import { useQueryParams } from "../../hooks/useQueryParams";
import CenteredLayout from "../../layouts/CenteredLayout/CenteredLayout";
import secureImage from "../../images/secure.svg";
import TextLink from "../../components/TextLink/TextLink";
import { trackPage } from "client/amplitudeHelper";

export type WelcomePageProps = {
  type: "school" | "parent";
};

const SupportEmailLink = styled.a`
  &,
  :visited {
    color: ${themeColor("teal")};
  }
  margin-left: 3px;
  margin-right: 3px;
`;
const supportEmailLink = (
  <SupportEmailLink href={`mailto:${supportEmail}`}>
    {supportEmail}
  </SupportEmailLink>
);
const error_messages: Record<string, ReactElement> = {
  token_not_found: (
    <>
      We don't recognize the invitation URL you used. Please reach out to your
      daybreak contact, or email us at {supportEmailLink} and we'll send you a
      new one.
    </>
  ),
  token_invalid: (
    <>
      The invitation URL you used is invalid. Please reach out to your daybreak
      contact, or email us at {supportEmailLink} and we'll send you a new one.
    </>
  ),
  token_consumed: (
    <>
      <p>
        This invitation has already been used. <br />
        Try <TextLink>logging in</TextLink> instead.
      </p>
      <p>
        If you're not the one who used this invitation, please reach out to your
        daybreak contact, or email us at {supportEmailLink}.
      </p>
    </>
  ),
  token_expired: (
    <>
      The invitation URL you used has expired. Please reach out to your daybreak
      contact, or email us at {supportEmailLink} and we'll send you a fresh one.
    </>
  ),
  missing_token: (
    <>
      Something went wrong with the invitation URL you used. Please copy and
      paste the link from your email into your browser bar and try again. If you
      still receive an error, please reach out to your daybreak contact, or
      email us at {supportEmailLink} and we'll sort it out.
    </>
  ),
  unknown: (
    <>
      Something unexpected went wrong. Please reach out to your daybreak
      contact, or email us at {supportEmailLink} and we'll sort it out.
    </>
  ),
};
const ErrorBox = styled.div`
  width: 100%;
  padding: 32px 32px 80px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  ${themeMinWidth("small")} {
    padding: 50px;
    padding-bottom: 80px;
    width: 704px;
  }
`;
const ErrorSquare = styled.div`
  width: 85px;
  height: 85px;
  background-color: ${themeColor("orange5", { opacity: 0.1 })};
  color: ${themeColor("orange5")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  .icon {
    width: 43px;
    height: 43px;
  }
`;
const ErrorTitle = styled.div`
  margin-top: 32px;
  font-size: 24px;
  font-weight: 600;
`;
const ErrorBody = styled.div`
  margin-top: 32px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  ${themeMinWidth("small")} {
    width: 400px;
    padding: 0;
  }
`;
const ErrorScreen: React.FC<{ error_type: string }> = ({ error_type }) => {
  const error_message = error_messages[error_type];
  return (
    <CenteredLayout headerStyle="simple">
      <ErrorBox>
        <ErrorSquare>
          <Icon name="alert" />
        </ErrorSquare>
        <ErrorTitle>An error occured</ErrorTitle>
        <ErrorBody>{error_message}</ErrorBody>
      </ErrorBox>
    </CenteredLayout>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 32px 32px 80px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  ${themeMinWidth("small")} {
    padding: 50px;
    padding-bottom: 80px;
    width: 704px;
  }
`;
const Title = styled.div`
  margin-top: 32px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;
const Body = styled.div`
  margin-top: 32px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 48px;
  ${themeMinWidth("small")} {
    width: 400px;
    padding: 0;
  }
`;
const SecureImage = styled.img``;
const Email = styled.div`
  margin-top: 16px;
  font-size: 18px;
  color: ${themeColor("teal")};
`;

const welcomeCopy = (type: WelcomePageProps["type"], email: string) => {
  switch (type) {
    case "parent":
      return (
        <>
          Create an account on the next page to securely access your teen's
          data.
        </>
      );
    case "school":
      return (
        <>
          <div>
            To securely access your students, create an account on the next page
            using the email you received this invite at:
          </div>
          <Email>{email}</Email>
        </>
      );
  }
};

export type WelcomePageWithDataProps = {
  history: History;
  token?: string;
  error: unknown;
  type: WelcomePageProps["type"];
  email: string;
};
export const WelcomePageWithData: React.FC<WelcomePageWithDataProps> = ({
  history,
  token,
  error,
  type,
  email,
}) => {
  const onRegister = () => {
    console.log("register");
    history.push(`/signup-redirect?token=${token}`);
  };
  let error_type;
  if (error) {
    error_type = "unknown";

    if (isDaybreakError(error)) {
      error_type = error.error_type;
    } else {
      Bugsnag.notify(`Unknown error on invite page: ${error}`);
    }

    return <ErrorScreen error_type={error_type} />;
  } else if (!token) {
    error_type = "missing_token";
  }

  if (error_type) {
    return <ErrorScreen error_type={error_type} />;
  }

  return (
    <CenteredLayout headerStyle="simple">
      <Container>
        <SecureImage src={secureImage} />
        <Title>Welcome to Daybreak!</Title>
        <Body>{welcomeCopy(type, email)}</Body>
        <OrangeButton onClick={onRegister}>Register</OrangeButton>
      </Container>
    </CenteredLayout>
  );
};
const WelcomePage: React.FC<WelcomePageProps> = ({ type }) => {
  useEffect(() => {
    trackPage("WelcomePage");
  }, []);

  const history: History = useHistory();
  const token = useQueryParams().get("token") ?? undefined;

  // Validate the token.  We don't actually care about the response body here -
  // we just want to be sure we didn't get an error.
  const { isLoading, error, data: inviteData } = useApi().useGetInvite(token, {
    enabled: !!token,
  });

  if (isLoading || !(inviteData || error)) {
    return <CenteredLoading />;
  }

  return (
    <WelcomePageWithData
      history={history}
      token={token}
      error={error}
      type={type}
      email={inviteData?.email ?? ""}
    />
  );
};

export default WelcomePage;
