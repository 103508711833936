import styled from "styled-components/macro";
import { themeColor } from "../../../../app/theme";

const leftMargin = "16px";
export const NameBar = styled.div`
  display: flex;
  max-width: 600px;
  justify-content: space-between;
  margin-top: 24px;
  margin: 24px ${leftMargin};
  margin-bottom: 0;
`;
export const NameBarLeft = styled.div`
  display: flex;
`;
export const NameBarRight = styled.div``;
export const NameAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;
export const Name = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: ${themeColor("darkGrayText4")};
`;
export const Title = styled.div`
  color: ${themeColor("darkGrayText7")};
`;
export const Actions = styled.div`
  display: flex;
`;
export const EditLink = styled.div`
  a {
    width: 92px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const TrashButton = styled.div`
  a {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 24px;
      height: 24px;
    }
  }
`;
export const UserInfo = styled.div`
  margin-left: ${leftMargin};
  margin-top: 25px;
`;
export const Header = styled.div`
  font-size: 16px;
  color: ${themeColor("darkGrayText6")};
  padding-bottom: 4px;
  border-bottom: 1px solid ${themeColor("borderGray7")};
`;
export const UserInfoRow = styled.div`
  display: grid;
  padding: 12px 0;
  border-bottom: 1px solid #f0f4f4;
  grid-template-columns: 192px auto;
`;
export const UserInfoLabel = styled.div`
  color: ${themeColor("darkGrayText7")};
`;
export const UserInfoValue = styled.div`
  color: black;
`;
export const SchoolAccess = styled.div`
  margin-left: ${leftMargin};
  margin-top: 32px;
`;
export const SchoolAccessRow = styled.div`
  padding: 12px;
  color: black;
  border-bottom: 1px solid #f0f4f4;
`;
