import React, { useState, useEffect } from "react";
import SchoolCounselorLayout from "../../../layouts/SchoolCounselorLayout/SchoolCounselorLayout";
import { OrganizationMemberV1 } from "../../../api/queries/organizationMembers";
import styled from "styled-components/macro";
import SearchBar from "./SearchBar";
import StudentsTable from "./StudentsTable";
import { useApi } from "../../../api/apiContext";
import { SortingState } from "@tanstack/react-table";
import {
  ReferralModalInsert,
  useReferralModal,
} from "../../../components/Modals/ReferAStudentModal/ReferAStudentModal";
import { themeMinWidth } from "../../../app/theme";
import { trackPage, trackEvent } from "client/amplitudeHelper";

export type StudentListPageWithDataProps = {
  showingFilteredResults: boolean;
  organizationMembers: OrganizationMemberV1[];
  initialQuery: string;
  pageIndex: number;
  maxPageIndex: number;
  setPageIndex: (pageIndex: number) => void;
  setPageSort: (sort: SortingState[0]) => void;
  onSearch: (q: string) => void;
  onReset: () => void;
  onQueueMember: (organizationMember: OrganizationMemberV1) => void;
  onDequeueMember: (organizationMember: OrganizationMemberV1) => void;
};

const Container = styled.div`
  width: 100%;
  padding: 0 0 80px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
const SearchBarContainer = styled.div`
  padding-left: 32px;
  padding-right: 32px;
`;
const TableContainer = styled.div`
  ${themeMinWidth("small")} {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

export const StudentListPageWithData: React.FC<StudentListPageWithDataProps> = ({
  showingFilteredResults,
  organizationMembers,
  onSearch,
  onReset,
  initialQuery,
  setPageIndex,
  setPageSort,
  pageIndex,
  maxPageIndex,
  onDequeueMember,
  onQueueMember,
}) => {
  useEffect(() => {
    if (initialQuery.trim()) {
      trackEvent("SCD:StudentList:search", {
        searchQuery: initialQuery,
      });
    }
  }, [initialQuery]);
  const referralModalContext = useReferralModal();
  return (
    <SchoolCounselorLayout>
      <ReferralModalInsert referralModalContext={referralModalContext} />
      <Container>
        <SearchBarContainer>
          <SearchBar
            onSearch={onSearch}
            onReset={onReset}
            showingFilteredResults={showingFilteredResults}
            initialQuery={initialQuery}
          />
        </SearchBarContainer>
        <TableContainer>
          <StudentsTable
            organizationMembers={organizationMembers}
            setPageIndex={setPageIndex}
            setPageSort={setPageSort}
            pageIndex={pageIndex}
            maxPageIndex={maxPageIndex}
            onDequeueMember={onDequeueMember}
            onQueueMember={onQueueMember}
            onReferMember={(organizationMember) => {
              referralModalContext.showModal(organizationMember.id);
              trackEvent("SCD:showReferModal", {
                referralTargetOrganizationMemberId: organizationMember.id,
              });
            }}
          />
        </TableContainer>
      </Container>
    </SchoolCounselorLayout>
  );
};

const defaultSort = { id: "first_name", desc: false };
const sortToOrderParam = (sort: SortingState[0] | undefined) => {
  if (!sort) sort = defaultSort;
  return sort.id + "_" + (sort.desc ? "desc" : "asc");
};

export type StudentListPageProps = {};

const StudentListPage: React.FC<StudentListPageProps> = () => {
  useEffect(() => {
    trackPage("SCD:StudentListPage");
  }, []);

  const api = useApi();
  const [searchParams, setSearchParams] = useState({
    query: "",
    pageIndex: 0,
  });
  const [pageSort, setPageSort] = useState<SortingState[0]>(defaultSort);

  const {
    data: { data: organizationMembers, meta: metaData } = {},
    isLoading,
  } = api.useGetOrganizationMembers({
    q: searchParams.query === "" ? undefined : searchParams.query,
    page: searchParams.pageIndex + 1,
    perPage: 10,
    orderBy: sortToOrderParam(pageSort),
    options: { useErrorBoundary: true, keepPreviousData: true },
  });

  const { mutate: removeFromQueue } = api.useDeleteReferrals();
  const { mutateAsync: createReferral } = api.usePostReferrals();

  if (isLoading || !organizationMembers || !metaData) {
    return <SchoolCounselorLayout loading />;
  }

  return (
    <StudentListPageWithData
      showingFilteredResults={!!searchParams.query}
      organizationMembers={organizationMembers}
      initialQuery={searchParams.query}
      pageIndex={searchParams.pageIndex}
      maxPageIndex={metaData.maxPage - 1}
      setPageIndex={(i) =>
        setSearchParams({ query: searchParams.query, pageIndex: i })
      }
      setPageSort={setPageSort}
      onSearch={(q) => setSearchParams({ query: q, pageIndex: 0 })}
      onReset={() => setSearchParams({ query: "", pageIndex: 0 })}
      onDequeueMember={(organizationMember) => {
        if (organizationMember.referral) {
          removeFromQueue({
            referralId: organizationMember.referral?.id,
          });
          trackEvent("SCD:removeFromWatchQueue", {
            removedOrganizationMemberId: organizationMember.id,
            removedReferralId: organizationMember.referral?.id,
          });
        }
      }}
      onQueueMember={(organizationMember) => {
        createReferral({ organizationMemberId: organizationMember.id }).then(
          (value) => {
            trackEvent("SCD:addToWatchQueue", {
              addedOrganizationMemberId: organizationMember.id,
              referralData: value,
            });
          }
        );
      }}
    />
  );
};

export default StudentListPage;
