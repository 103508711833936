import isChromatic from "chromatic/isChromatic";
import { DateTime } from "luxon";
import React, { useEffect, useRef } from "react";
import {
  BaseMessageInstance,
  GroupChannel,
  Member,
  UserMessage,
} from "sendbird";
import { useApi } from "../../../api/apiContext";
import { User } from "../../../api/queries/users";
import { useMessageView } from "../../../hooks/useMessageView";
import Icon from "../../Icon/Icon";
import Initial from "../../Initial/Initial";
import NewMessageBar from "../NewMessageBar/NewMessageBar";
import {
  BackButton,
  ChannelHeader,
  Container,
  DateBreak,
  DateBreakBubble,
  Message,
  MessageBubble,
  MessageGroup,
  MessageIcon,
  MessageList,
  MessageText,
  MessageTimeStamp,
  UserName,
} from "./elements";

type MessageLineProps = {
  message: UserMessage;
  lastMessage?: UserMessage;
  me: User;
  senderNickname: string;
  isLastOfGroup: boolean;
};
const MessageLine: React.FC<MessageLineProps> = ({
  message,
  me,
  senderNickname,
  lastMessage,
  isLastOfGroup,
}) => {
  const isMe = message.sender.userId === me.sendbirdUser.externalUserId;
  const messageTime = DateTime.fromMillis(message.createdAt);
  const timeString = messageTime.toLocaleString(DateTime.TIME_SIMPLE);
  const messageDateTime = DateTime.fromMillis(message.createdAt);

  // Insert a date bubble whenever we cross from one day to the next.
  const shouldInsertDate =
    lastMessage == null ||
    DateTime.fromMillis(lastMessage?.createdAt).toISODate() !==
      messageDateTime.toISODate();

  return (
    <>
      {shouldInsertDate ? (
        <DateBreak key={messageDateTime.toISODate()}>
          <DateBreakBubble>
            {messageDateTime.toLocaleString({
              month: "short",
              day: "numeric",
            })}
          </DateBreakBubble>
        </DateBreak>
      ) : null}

      <Message
        key={message.messageId}
        id={`message-${message.messageId}`}
        isMe={isMe}
      >
        <MessageBubble isMe={isMe}>
          <MessageText>{message.message}</MessageText>
          <MessageTimeStamp>{timeString}</MessageTimeStamp>
        </MessageBubble>
        <MessageIcon>
          {isLastOfGroup ? (
            <Initial size="medium">{senderNickname}</Initial>
          ) : null}
        </MessageIcon>
      </Message>
    </>
  );
};

export type ChannelViewWithDataProps = {
  channelName: string;
  messages: BaseMessageInstance[];
  sendMessage: (message: string) => Promise<void>;
  loadOlderMessages: () => Promise<unknown>;
  me: User;
  otherChannelMember?: Member;
  nicknameMap: Record<string, string>;
  onBack: () => void;
};
export const ChannelViewWithData: React.FC<ChannelViewWithDataProps> = ({
  messages,
  sendMessage,
  channelName,
  me,
  otherChannelMember,
  nicknameMap,
  loadOlderMessages,
  onBack,
}) => {
  // Group messages into contiguous blocks with the same sender
  const messageGroups: UserMessage[][] = [];
  let currentGroup: UserMessage[] = [];
  messages.forEach((message) => {
    if (message.messageType !== "user") {
      return;
    }
    const userMessage = message as UserMessage;
    if (
      currentGroup[0] &&
      userMessage.sender.userId !== currentGroup[0].sender.userId
    ) {
      messageGroups.push(currentGroup);
      currentGroup = [];
    }
    currentGroup.push(userMessage);
  });
  messageGroups.push(currentGroup);

  // For scrolling purposes, keep a reference to the main list of messages
  const scrollPaneRef = useRef<HTMLDivElement>(null);

  const lastMessageId = messages[messages.length - 1]?.messageId;
  const firstMessageId = messages[0]?.messageId;

  // Scroll to the bottom of the chat, but only when new messages come in (or on
  // the first render)
  useEffect(() => {
    // Skip scrolling if we're in CI - it leads to nondeterminism.
    if (scrollPaneRef.current && !isChromatic) {
      scrollPaneRef.current.scrollTop = scrollPaneRef.current.scrollHeight;
    }
  }, [lastMessageId]);

  // When you scroll to the top of the chat, load more old messages
  useEffect(() => {
    const scrollPaneElement = scrollPaneRef.current;
    const oldFirstMessageId = firstMessageId;
    if (!scrollPaneElement || !loadOlderMessages) {
      return;
    }
    let lastRefresh = 0;

    const onScroll = async () => {
      const distanceFromTop = scrollPaneRef.current?.scrollTop;
      // If we're near the top of the scroll pane, fetch older messages (unless
      // we did that in the last second already).
      if (
        distanceFromTop != null &&
        distanceFromTop < 20 &&
        new Date().getTime() - lastRefresh > 1000
      ) {
        lastRefresh = new Date().getTime();
        await loadOlderMessages();

        // Immediately scroll back to the same message we were just view so the
        // view doesn't jump around confusingly.
        const firstMessage = document.getElementById(
          "message-" + oldFirstMessageId
        );
        firstMessage?.scrollIntoView();
      }
    };

    scrollPaneElement.addEventListener("scroll", onScroll);
    return () => {
      scrollPaneElement.removeEventListener("scroll", onScroll);
    };
  }, [firstMessageId, loadOlderMessages]);

  const channelTitle = otherChannelMember
    ? nicknameMap[otherChannelMember.userId]
    : channelName;
  return (
    <Container>
      <ChannelHeader onClick={onBack}>
        <BackButton>
          <Icon name="leftArrow" />
        </BackButton>
        <Initial>{channelTitle}</Initial>
        <UserName>{channelTitle}</UserName>
      </ChannelHeader>
      <MessageList ref={scrollPaneRef}>
        {messageGroups.map((messageGroup, groupIndex) => {
          return (
            <MessageGroup key={groupIndex}>
              {messageGroup.map((message, indexWithinGroup) => {
                // Find the message previous to this one, even if it's in
                // another group.
                let previousMessage: UserMessage | undefined;
                if (indexWithinGroup === 0) {
                  if (groupIndex === 0) {
                    previousMessage = undefined;
                  } else {
                    const lastGroup = messageGroups[groupIndex - 1];
                    previousMessage = lastGroup[lastGroup.length - 1];
                  }
                } else {
                  previousMessage = messageGroup[indexWithinGroup - 1];
                }

                return (
                  <MessageLine
                    key={message.messageId}
                    message={message}
                    senderNickname={nicknameMap[message.sender.userId]}
                    lastMessage={previousMessage}
                    me={me}
                    isLastOfGroup={indexWithinGroup === messageGroup.length - 1}
                  />
                );
              })}
            </MessageGroup>
          );
        })}
      </MessageList>
      <NewMessageBar sendMessage={sendMessage} />
    </Container>
  );
};

export type ChannelViewProps = { channel: GroupChannel; onBack: () => void };

const ChannelView: React.FC<ChannelViewProps> = ({ channel, onBack }) => {
  const { messages, sendMessage, loadOlderMessages } = useMessageView(channel);
  const api = useApi();
  const { data: { data: me } = {} } = api.useGetMe();
  const { data: { data: nicknames } = {} } = api.useGetChatNicknames({});

  // Mark all messages in this channel as read after 5 seconds (assuming the
  // viewer doesn't switch tabs before then).
  useEffect(() => {
    const channelToClear = channel;
    if (channelToClear) {
      const markReadTimeout = setTimeout(() => {
        channelToClear.markAsRead();
      }, 5000);
      return () => clearTimeout(markReadTimeout);
    }
  }, [channel]);

  if (!me || !nicknames || !loadOlderMessages || !channel) {
    return null;
  }
  const otherChannelMember = channel.members.find(
    (member) => member.userId !== me.sendbirdUser.externalUserId
  );

  return (
    <ChannelViewWithData
      channelName={channel.name}
      messages={messages}
      sendMessage={sendMessage}
      loadOlderMessages={loadOlderMessages}
      me={me}
      otherChannelMember={otherChannelMember}
      onBack={onBack}
      nicknameMap={nicknames.reduce<Record<string, string>>((map, user) => {
        map[user.id] = user.nickname;
        return map;
      }, {})}
    />
  );
};

export default ChannelView;
