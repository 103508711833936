import { QueryClient } from "react-query";
/**
 * How to add a new api query hook:
 * https://www.notion.so/daybreakhealth/Access-api-data-with-a-query-hook-1677db549b834b50a6b568c4d15325cd
 */

// Sets up the react-query default config options
const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: 3,
    },
    queries: {
      // By default, react-query refetches queries whenever you refocus the
      // window.  This is generally good behavior, but it can be annoying when
      // you're trying to debug some precise ordering issues from the js
      // console.  Set this to false in dev to disable that.
      refetchOnWindowFocus: true,
    },
  },
});

export default queryClient;
