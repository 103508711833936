import React, { ErrorInfo, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { supportEmail } from "../../constants";
import { trackEvent } from "client/amplitudeHelper";

export type RootErrorDisplayProps = {
  error: Error;
  info: ErrorInfo;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MessageBox = styled.div`
  padding: 20px;
`;
const Message = styled.div`
  font-size: 20px;
  max-width: 600px;
  text-align: center;
  margin-bottom: 20px;
`;
const Details = styled.div`
  font-size: 12px;
  border: 1px solid #faa;
  border-radius: 5px;
  padding: 20px;
  opacity: 0.4;
`;
const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }
`;

// Set this to true to hide the helpful stack trace overlay that react provides
// when an error is thrown. That overlay is usually super helpful, but it gets
// annoying when you're trying to test out error boundaries.
const hideDevErrorOverlay = true;
const HideErrorOverlay = createGlobalStyle`
  iframe
  {
      display: none;
  }
`;

// This is the absolute top-level, last-ditch error boundary if all lower error
// boundaries have failed. It's ugly, it's simple, it does the job. We should
// have error boundaries in lower-level components (especially in layouts) to
// present better-looking errors and more actionable next-steps.
const RootErrorDisplay: React.FC<RootErrorDisplayProps> = ({ error }) => {
  useEffect(() => {
    trackEvent("rootErrorDisplayed", { error });
  });
  return (
    <Container>
      <GlobalStyle />
      {hideDevErrorOverlay && <HideErrorOverlay />}
      <MessageBox>
        <Message>
          We're sorry, but we've encountered an error and can't continue. This
          has already been reported to us and we'll get it fixed as soon as we
          can. In the meantime, you can reach us at{" "}
          <a href={`mailto:${supportEmail}`}>{supportEmail}</a> for further
          help.
        </Message>
        {error?.message && (
          <Details>
            <p>{error.name}:</p>
            <p>{error.message}</p>
          </Details>
        )}
      </MessageBox>
    </Container>
  );
};

export default RootErrorDisplay;
