import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../../app/theme";
import Icon, { ValidIconNames } from "../../../../components/Icon/Icon";
import OrganizationMembers, {
  JourneyStatus,
} from "../../../../lib/models/organizationMembers";

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  padding: 24px 32px;
  border: 1px solid ${themeColor("borderGray5")};
  border-radius: 12px;
  color: ${themeColor("darkGrayText5")};
  flex-direction: column;
  gap: 16px;
  margin: 16px;
  ${themeMinWidth("small")} {
    gap: 48px;
    flex-direction: row;
    margin: 0;
  }
`;
export const Header = styled.div`
  display: flex;
  gap: 24px;
`;
export const StatusIcon = styled.div`
  display: flex;
  align-items: center;
  .icon {
    color: ${themeColor("darkGrayText8")};
    height: 30px;
    width: 30px;
    ${themeMinWidth("small")} {
      height: 60px;
      width: 60px;
      min-width: 40px;
    }
  }
`;
export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  padding-right: 48px;
  ${themeMinWidth("small")} {
    border-right: 1px solid ${themeColor("borderGray7")};
  }
`;
export const Detail = styled.div`
  max-width: 600px;
  display: flex;
  align-items: center;
`;
export const statusIcons: Record<JourneyStatus, ValidIconNames> = {
  Pending: "clock2",
  Closed: "stop",
  "Closed:_Client_not_Responsive": "timeAlert",
  Counseling_Declined: "userDelete",
  Counseling_Not_Recommended: "stop",
  Daybreak_Working: "gear",
  Discharged: "checkCircle",
  Insurance_Info_Needed: "exclamationCircle",
  Intake_Missed: "timeAlert",
  Match_in_Progress: "gear",
  Ongoing_Care: "checkmark",
  Waiting_for_Intake: "clock2",
  Client_Referral: "gear",
  Scheduling: "calendar",
};
export const statusDetails: Record<JourneyStatus, ReactElement> = {
  Pending: (
    <>
      Daybreak is processing this student and will have an updated status
      shortly.
    </>
  ),
  Closed: <>This referral has been closed.</>,
  "Closed:_Client_not_Responsive": (
    <>
      This referral is no longer active because the client did not respond or
      stopped responding to Daybreak outreach.
    </>
  ),
  Counseling_Declined: (
    <>This student declined to start or continue with counseling.</>
  ),
  Counseling_Not_Recommended: (
    <>
      Daybreak has determined that this student's needs are not a good fit for
      counseling at this time.
    </>
  ),
  Daybreak_Working: (
    <>
      This referral is waiting on action by Daybreak staff. This message will
      update once that has happened.
    </>
  ),
  Discharged: (
    <>
      This student has completed their care with Daybreak and been discharged.
    </>
  ),
  Insurance_Info_Needed: <>This student needs insurance information added.</>,
  Intake_Missed: <>This student missed their intake appointment.</>,
  Match_in_Progress: (
    <>
      Matching is in progress and this student will soon be assigned a Daybreak
      clinician.
    </>
  ),
  Ongoing_Care: (
    <>
      This student has been matched with and is receiving care from a Daybreak
      clinician.
    </>
  ),
  Waiting_for_Intake: (
    <>
      This student has an upcoming scheduled appointment with a Daybreak Intake
      Specialist.
    </>
  ),
  Client_Referral: <>This student has been referred to Daybreak</>, // this status shouldn't really get shown.
  Scheduling: (
    <>
      This student is in the process of scheduling an appointment with Daybreak.
    </>
  ),
};
export type CareStatusBoxProps = {
  status: JourneyStatus;
};

const CareStatusBox: React.FC<CareStatusBoxProps> = ({ status }) => {
  return (
    <Container>
      <Header>
        <StatusIcon>
          <Icon name={statusIcons[status] ?? "gear"} />
        </StatusIcon>
        <Title>{OrganizationMembers.journeyStatusDisplayString(status)}</Title>
      </Header>
      <Detail>{statusDetails[status]}</Detail>
    </Container>
  );
};

export default CareStatusBox;
