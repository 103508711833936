import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import PreAuthPage from "../../pages/Onboarding/AfterAuthPage/AfterAuthPage";
import AuthRedirectPage from "../../pages/AuthRedirectPage/AuthRedirectPage";
import WelcomePage from "../../pages/WelcomePage/WelcomePage";
import DashboardHomePage from "../../pages/Dashboard/DashboardHomePage/DashboardHomePage";
import ClinicalProgressPage from "../../pages/Dashboard/ClinicalProgressPage/ClinicalProgressPage";
import AppointmentsPage from "../../pages/Dashboard/AppointmentsPage/AppointmentsPage";
import { RouteWith } from "./RouteWith";
import ScreenerRoutes from "./ScreenerRoutes";
import CounselorPatientList from "../../pages/CounselorPatientList/CounselorPatientList";
import MessagesPage from "../../pages/Dashboard/MessagesPage/MessagesPage";
import NoChildrenFoundPage from "../../pages/Dashboard/NoChildrenFoundPage/NoChildrenFoundPage";
import SchoolStaffDashboardRoutes from "./SchoolStaffDashboardRoutes";
import DirectReferralRoutes from "./DirectReferralRoutes";
import GeneralErrorPage from "../../pages/GeneralErrorPage/GeneralErrorPage";
import LogoutPage from "../../pages/LogoutPage/LogoutPage";
import { useApi } from "../../api/apiContext";
import { isAxiosError } from "../../api/apiClient";
import ParentWelcomePacketPage from "../../pages/ParentWelcomePacket/ParentWelcomePacketPage";
import DistrictSelector from "../../pages/ParentWelcomePacket/DistrictSelector";
import WelcomePacketPage from "../../pages/ParentWelcomePacket/WelcomePacketPage";

const MaintenancePageWrapper: React.FC = ({ children }) => {
  const api = useApi();
  const { error } = api.useGetApplicationFeatures();
  if (isAxiosError(error)) {
    return <GeneralErrorPage errorKey="maintenanceMode" />;
  } else {
    return <>{children}</>;
  }
};

// These are the routes - the url paths we handle. Google React-Router for some
// pretty solid docs on how it works. Short version is to add a <Route>
// component to the <Switch> below and if a user loads that url, the Route
// component (and whatever child components it has) will be rendered inside that
// switch statement.
type RoutesProps = {};
const Routes: React.FC<RoutesProps> = () => {
  return (
    <Router>
      <MaintenancePageWrapper>
        <Switch>
          {/* ------   Parent Welcome Packet   ------- */}
          <RouteWith path="/packet/:sfid">
            <WelcomePacketPage />
          </RouteWith>

          <RouteWith path="/school/:organizationApiKey/packet/:sfid">
            <WelcomePacketPage />
          </RouteWith>

          <RouteWith path="/school/:organizationApiKey/packet">
            <ParentWelcomePacketPage />
          </RouteWith>

          <RouteWith path="/parent-packet">
            <DistrictSelector />
          </RouteWith>

          {/* ------   Registration   ------- */}
          <RouteWith path="/welcome" featureFlag="ParentWelcomeEmailV1">
            <WelcomePage type="parent" />
          </RouteWith>

          <RouteWith path="/school/welcome">
            <WelcomePage type="school" />
          </RouteWith>

          {ScreenerRoutes()}

          {/* Screener fallback route if you typo something after the org api key: */}
          <Redirect
            from="/organization/:organizationApiKey"
            to="/organization/:organizationApiKey/screener"
          />

          <RouteWith auth path="/clinician/patients">
            <CounselorPatientList />
          </RouteWith>

          {SchoolStaffDashboardRoutes()}

          {DirectReferralRoutes()}

          {/* ------   Dashboard Pages   ------- */}
          <RouteWith
            auth
            path="/no-children-found"
            featureFlag="ParentWelcomeEmailV1"
          >
            <NoChildrenFoundPage />
          </RouteWith>
          <RouteWith
            auth
            path="/:userExternalId/dashboard"
            featureFlag="ParentWelcomeEmailV1"
          >
            <DashboardHomePage />
          </RouteWith>

          <RouteWith auth path="/dashboard" featureFlag="ParentWelcomeEmailV1">
            <DashboardHomePage />
          </RouteWith>

          <RouteWith
            auth
            path="/clinician/:userExternalId/dashboard"
            userKind="counselor"
          >
            <DashboardHomePage />
          </RouteWith>

          <RouteWith
            auth
            path="/:userExternalId/clinical-progress"
            featureFlag="ParentWelcomeEmailV1"
          >
            <ClinicalProgressPage />
          </RouteWith>
          <RouteWith
            auth
            path="/clinician/:userExternalId/clinical-progress"
            userKind="counselor"
          >
            <ClinicalProgressPage />
          </RouteWith>

          <RouteWith
            auth
            path="/:userExternalId/appointments"
            featureFlag="ParentWelcomeEmailV1"
          >
            <AppointmentsPage />
          </RouteWith>
          <RouteWith
            auth
            path="/clinician/:userExternalId/appointments"
            userKind="counselor"
          >
            <AppointmentsPage />
          </RouteWith>

          <RouteWith
            auth
            path="/clinician/:userExternalId/messages"
            userKind="counselor"
          >
            <MessagesPage />
          </RouteWith>
          <RouteWith auth path="/clinician">
            <Redirect to="/clinician/patients" />
          </RouteWith>

          {/* ------   Auth Redirect   ------- */}
          <Route path="/signup-redirect">
            <AuthRedirectPage kind="signup" />
          </Route>
          <Route path="/login-redirect">
            <AuthRedirectPage kind="login" />
          </Route>
          <Route path="/unverified-email-error">
            <GeneralErrorPage errorKey="unverifiedEmail" />
          </Route>
          <Route path="/no-invite-error">
            <GeneralErrorPage errorKey="noInviteError" />
          </Route>
          <Route path="/staff-login-disabled-error">
            <GeneralErrorPage errorKey="staffLoginDisabled" />
          </Route>
          <Route path="/teen-user-login">
            <GeneralErrorPage errorKey="teenUserLogin" />
          </Route>
          <Route path="/no-user">
            <GeneralErrorPage errorKey="noUser" />
          </Route>
          <Route path="/logout">
            <LogoutPage />
          </Route>

          {/* This is the route auth0 will redirect to after successful signup
          or login.  This shouldn't need /onboarding/ in the route, but changing
          the route would require updatng our auth0 settings and that's not
          worth the effort at the moment. */}
          <RouteWith
            path="/onboarding/after-auth"
            featureFlag="Parent Onboarding Quiz V1"
          >
            <PreAuthPage />
          </RouteWith>

          {/* ------   Fallback route   ------- */}
          <Route path="/">
            <Redirect to="/login-redirect" />
          </Route>
        </Switch>
      </MaintenancePageWrapper>
    </Router>
  );
};

export default Routes;
