import React from "react";
import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";
import Icon, { ValidIconNames } from "../../Icon/Icon";
import CardBox from "../CardBox/CardBox";

export type StatCardProps = {
  icon: ValidIconNames;
  stat: string;
};
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;

  padding-left: 18px;
  padding-right: 16px;
  ${themeMinWidth("small")} {
    height: 66px;
    padding-left: 26px;
    padding-right: 24px;
  }
`;
const IconContainer = styled.div`
  color: ${themeColor("orange5")};
  margin-right: 8px;
  display: flex;
  align-items: center;
  .icon {
    width: 16px;
    height: 16px;
  }
  ${themeMinWidth("small")} {
    .icon {
      width: 24px;
      height: 24px;
    }
  }
`;
const Left = styled.div`
  display: flex;
  align-items: center;
`;
const Right = styled.div``;
const Text = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: ${themeColor("darkGrayText7")};
  ${themeMinWidth("xsmall")} {
    font-size: 13px;
  }
  ${themeMinWidth("small")} {
    font-size: 14px;
  }
`;
const Stat = styled.div`
  font-weight: 500;
  font-size: 16px;
  ${themeMinWidth("xsmall")} {
    font-size: 20px;
  }
  ${themeMinWidth("small")} {
    font-size: 30px;
  }
`;

const StatCard: React.FC<StatCardProps> = ({ icon, children, stat }) => {
  return (
    <CardBox>
      <Container>
        <Left>
          <IconContainer>
            <Icon name={icon} />
          </IconContainer>
          <Text>{children}</Text>
        </Left>
        <Right>
          <Stat>{stat}</Stat>
        </Right>
      </Container>
    </CardBox>
  );
};

export default StatCard;
