import { Field, FormikProps } from "formik";
import get from "lodash/get";
import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { themeColor } from "../../../app/theme";
import { Input, Layout } from "../elements";

export type DateFieldProps = {
  label: string | ReactElement;
  error?: boolean;
  formik?: FormikProps<any>;
  basicFormik?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const DateInput = styled(Input)`
  // This is a workaround because it's currently impossible to style the
  // placeholder text on a native date control, and it defaults to dark black.
  // It relies on the 'empty' class being set by JS.
  &.empty {
    color: ${themeColor("darkGrayText8")};
  }
`;

const DateField: React.FC<DateFieldProps> = ({
  formik,
  label,
  error,
  basicFormik = false,
  ...inputProps
}) => {
  const id = inputProps.id ?? inputProps.name;
  if (formik) {
    const key = id ?? "";
    return (
      <DateField
        label={label}
        value={get(formik.values, key) ?? ""}
        name={key}
        onChange={formik.handleChange}
        error={!!(formik.errors[key] && formik.touched[key])}
        onBlur={formik.handleBlur}
        {...inputProps}
        disabled={inputProps.disabled || formik.isSubmitting}
        className={
          inputProps.className + (formik.values[key] === "" ? " empty" : "")
        }
      />
    );
  }

  if (basicFormik) {
    return (
      <Layout label={label} error={error} {...inputProps}>
        <Field type="date" {...inputProps} id={id} as={DateInput} />
      </Layout>
    );
  }

  return (
    <Layout label={label} error={error} {...inputProps}>
      <DateInput type="date" {...inputProps} id={id} error={error} />
    </Layout>
  );
};

export default DateField;
