import React from "react";
import styled from "styled-components/macro";
import { themeMinWidth } from "../../../app/theme";
import AcuityScheduler from "../../AcuityScheduler/AcuityScheduler";
import BaseModal, { BaseModalProps } from "../BaseModal/BaseModal";

const Container = styled.div`
  flex-grow: 1;
  ${themeMinWidth("small")} {
    padding: 8px;
    min-width: 800px;
  }
  height: calc(100vh - 64px);
`;
export type PWPSchedulingModalProps = {
  schedulingLink: string;
  onSchedule: () => void;
} & BaseModalProps;
const PWPSchedulingModal: React.FC<PWPSchedulingModalProps> = ({
  schedulingLink,
  onSchedule,
  ...baseModalProps
}) => {
  return (
    <BaseModal {...baseModalProps}>
      <Container>
        <AcuityScheduler url={schedulingLink} onSchedule={onSchedule} />
      </Container>
    </BaseModal>
  );
};

export default PWPSchedulingModal;
