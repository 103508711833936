import React, { useEffect } from "react";
import styled from "styled-components/macro";
import urlBuilder, { PrefillOptions } from "./urlBuilder";
import { themeMinWidth } from "../../app/theme";

const Container = styled.div`
  flex-grow: 1;
  ${themeMinWidth("small")} {
    padding: 8px;
    min-width: 800px;
  }
  height: calc(100vh - 64px);
`;
const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 16px;
  &.loading {
    display: none;
  }
`;

export type AcuityConversionMessageData = {
  AppointmentType: string; // "Info Call (Staging/Dev)"
  Calendar: string; // "Staging/Dev"
  ClientDate: string; // "April 14, 2021"
  ClientTime: string; // "9:40am"
  Email: string; // "fdfsafds@example.com"
  ID: string; // "570644940"
  Price: string; // "0"
  Type: string; // "appointment"
};

// This message is sent by Acuity's "CustomConversion Tracking" integration when
// a user completes scheduling.  See here for details:
//
// https://www.notion.so/daybreakhealth/Acuity-Integration-77d67deab36e422e9c4ece904b37bff7
const createOnConversionMessage = (
  onConversion: (scheduleData: AcuityConversionMessageData) => void
) => (event: MessageEvent<AcuityConversionMessageData>) => {
  if (
    event.origin !== "https://conversion-tracking-sandbox.acuityinnovation.com"
  ) {
    return;
  }
  onConversion(event.data);
};

type ManualUrlProps = {
  url: string;
};
type AutoUrlProps = {
  appointmentType: string;
  prefill?: PrefillOptions;
  isBasic: boolean;
};
export type AcuitySchedulerProps = {
  onSchedule: (scheduleData: AcuityConversionMessageData) => void;
} & (ManualUrlProps | AutoUrlProps);

const AcuityScheduler: React.FC<AcuitySchedulerProps> = ({
  onSchedule,
  ...args
}) => {
  useEffect(() => {
    if ("url" in args) {
      console.log("Rendering scheduling url", args.url);
    } else {
      console.log("Rendering appointment type ", args.appointmentType);
    }
  }, [args]);

  useEffect(() => {
    const onConversionMessage = createOnConversionMessage(onSchedule);
    window.addEventListener("message", onConversionMessage);
    return () => {
      window.removeEventListener("message", onConversionMessage);
    };
  }, [onSchedule]);
  const url =
    "url" in args
      ? args.url
      : urlBuilder(
          args.appointmentType || "",
          args.prefill || {},
          args.isBasic
        );

  return (
    <Container>
      <IFrame title="Daybreak Info Call Scheduling" frameBorder="0" src={url} />
    </Container>
  );
};

export default AcuityScheduler;
