import styled, { css } from "styled-components";
import { themeColor, themeMinWidth } from "../../app/theme";

type LayoutProps = {
  id?: string;
  disabled?: boolean;
  error?: boolean;
  label?: string | JSX.Element | null;
  children: JSX.Element | JSX.Element[];
};

export const Layout = ({
  id,
  label,
  error,
  disabled,
  children,
}: LayoutProps): JSX.Element => (
  <FormFieldContainer disabled={disabled}>
    <Label htmlFor={id} error={error}>
      {label}
    </Label>
    {children}
  </FormFieldContainer>
);

export const errorStyles = css<{ error?: boolean }>`
  ${(props) =>
    props.error &&
    css`
      color: ${themeColor("red")};
      border-color: ${themeColor("red")};
    `}
`;

export const disabledStyles = css<{ disabled?: boolean }>`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const inputStyles = css`
  border-radius: 8px;
  padding: 16px 14px;
  font-size: 14px;
  color: black;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border: 1px solid ${themeColor("darkGrayText6")};
  display: flex;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const FormRowGrid = styled.div`
  display: grid;
  flex-direction: row;
  gap: 24px;
  grid-template-columns: 1fr;
  min-height: 54px;
  ${themeMinWidth("small")} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const FormFieldContainer = styled.div<{
  error?: boolean;
  disabled?: boolean;
}>`
  position: relative;
  ${disabledStyles}
  display: flex;
  width: 100%;
`;

export const Input = styled.input<{ error?: boolean }>`
  padding: 18px 16px;
  flex-grow: 1;
  ${inputStyles}
  ${errorStyles}
`;

export const Label = styled.label<{ error?: boolean }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
  overflow: hidden;
  position: absolute;
  font-size: 14px;
  color: ${themeColor("darkGrayText6")};
  top: -10.5px;
  left: 16px;
  background-color: white;
  padding: 0 4px;
  ${errorStyles}
`;

export const H2 = styled.h2`
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: black;
  margin-top: 0;
  margin-bottom: 32px;
`;
