import { DateTime } from "luxon";
import React from "react";
import { theme } from "../../app/theme";
import SurveyMilestones from "../../lib/models/surveyMilestones";
import { Datum, levelToColor } from "./ClinicalProgressChart";

type CustomLabelProps = {
  x?: number;
  y?: number;
  labelWidth: number;
  labelHeight: number;
  datum?: Datum;
};

export const CustomLabel: React.FC<CustomLabelProps> = ({
  x,
  y,
  datum,
  labelWidth,
  labelHeight,
}) => {
  if (!datum) {
    return null;
  }
  const { scoreConfig, score, chartLabel, dateSeconds } = datum;
  const level = SurveyMilestones.levelFromScore(score, scoreConfig.thresholds);
  const severityLabel = scoreConfig.severityLabels[level];

  const width = labelWidth;
  const height = labelHeight;
  const topPadding = 32;
  const sidePadding = 16;
  const firstLineFontSize = 14;

  const secondLineY = 50;
  const circleRadius = 6;
  const circleColor = levelToColor[level];
  const secondLineFontSize = 12;

  return (
    <svg x={(x || 0) - width / 2} y={(y || 0) - height / 2}>
      <text
        x={sidePadding}
        y={topPadding}
        fontSize={firstLineFontSize}
        fontFamily={theme.fonts.poppins}
        fill={theme.colors.darkGrayText3}
      >
        {chartLabel}
      </text>
      <text
        x={width - sidePadding}
        y={topPadding}
        fontSize={firstLineFontSize}
        fontFamily={theme.fonts.poppins}
        fontWeight="300"
        textAnchor="end"
        fill={theme.colors.darkGrayText8}
      >
        {DateTime.fromSeconds(dateSeconds).toLocaleString({
          month: "short",
          day: "numeric",
        })}
      </text>
      <circle
        r={circleRadius}
        cx={sidePadding + circleRadius}
        cy={secondLineY + secondLineFontSize / 2 + 1}
        fill={circleColor}
      />
      <text
        y={secondLineY + secondLineFontSize}
        x={sidePadding + 2 * circleRadius + 4}
        fontSize={secondLineFontSize}
        fontWeight={600}
      >
        {severityLabel} = {score}/{scoreConfig.max}
      </text>
    </svg>
  );
};
