import { Form } from "../../api/queries/forms";
import {
  SurveyMonkeyPage,
  SurveyMonkeyQuestion,
  SurveyMonkeySurvey,
} from "../../api/queries/surveys";

type Question = SurveyMonkeyQuestion & {
  // The unmodified index of this question in the survemonkey survey (counting
  // non-userfacing question elements like propmpts and presentation questions).
  // This is *not* the daybreak-user-facing question index.
  rawIndex: number;

  // The surveymonkey page this question came from
  page: SurveyMonkeyPage;
};

/**
 * The Survey class is a convenience wrapper around a surveymonkey survey
 * response json object.  It provides handy methods for getting info about that
 * survey and encodes some of the logic that we've embedded into our surveys,
 * like prompts.
 */
class Survey {
  // TODO: because the survey that this class wraps is readonly, we could
  // probably memoize many of these methods.
  readonly rawSurvey: SurveyMonkeySurvey;

  constructor(rawForm: Form) {
    this.rawSurvey = rawForm.surveyMonkeyFormData;
  }

  /**
   * Get all questions in this survey, regardless of their page.
   * @returns A list of all questions with added fields
   */
  questions(): Question[] {
    let i = 0;
    return this.rawSurvey.pages.reduce(
      (questions: Question[], page: SurveyMonkeyPage) => {
        const pageQuestions = page.questions.map((pageQuestion) => {
          return { ...pageQuestion, rawIndex: i++, page: page };
        });
        return [...questions, ...pageQuestions];
      },
      []
    );
  }

  /**
   * Get all the questions in this survey, grouped by whether they're prompts /
   * other non-question questions or real, actual questions that the end user
   * should see.
   * @returns A mapping of question types to question arrays
   */
  questionsByType() {
    const prompts: Question[] = [];
    const userFacingQuestions: Question[] = [];
    this.questions().forEach((question: Question) => {
      if (
        question.family === "presentation" &&
        question.nickname === "prompt"
      ) {
        prompts.push(question);
      } else {
        userFacingQuestions.push(question);
      }
    });
    return {
      prompts,
      userFacingQuestions,
    };
  }

  /**
   * Grabs the last prompt heading before the given raw index.  This should
   * generally return the prompt that should be show for the question at
   * rawIndex.
   * @param rawIndex the raw question index (including prompts) from which we
   * should start searching backwards.
   * @returns
   */
  mostRecentPrompt(rawIndex: number) {
    const { prompts } = this.questionsByType();
    const earlierPrompts = prompts.filter((promptQuestion) => {
      return promptQuestion.rawIndex < rawIndex;
    });
    const promptQuestion = earlierPrompts[earlierPrompts.length - 1];
    const promptText = promptQuestion
      ? promptQuestion.headings[0].heading
      : undefined;
    return promptText;
  }

  /**
   * Gets the prompt and question for a given question index.
   * @param index The non-raw index of the question - ie the user-facing index
   * of this question, ignoring prompts in the survey.
   * @returns
   */
  questionInfo(index: number): { question?: Question; promptText?: string } {
    const { userFacingQuestions } = this.questionsByType();
    const question = userFacingQuestions[index];
    const promptText = question
      ? this.mostRecentPrompt(question.rawIndex)
      : undefined;
    return { question, promptText };
  }
}
export default Survey;
