import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../app/theme";
import DesktopNav from "../../components/DesktopNav/DesktopNav";
import { CenteredLoading } from "../../components/Loading/Loading";
import MobileNav from "../../components/MobileNav/MobileNav";
import useIntercom from "../../hooks/useIntercom";
import BackNav from "../../components/TopNav/BackNav/BackNav";

export type SchoolCounselorLayoutProps = {
  loading?: boolean;
  backNavLink?: string;
  backNavText?: string;
};
const GlobalStyle = createGlobalStyle`
  html, body, #root, .App {
    height: 100%;
  }
`;
const LoadingContainer = styled.div`
  display: flex;
  padding-top: 80px;
`;
const desktopNavWidth = 258;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${themeMinWidth("small")} {
    flex-direction: row;
  }
`;
const DesktopNavContainer = styled.div`
  width: ${desktopNavWidth}px;
  min-width: ${desktopNavWidth}px;
  display: none;
  background-color: ${themeColor("backgroundGray")};
  height: 100%;
  position: fixed;
  z-index: 1;

  ${themeMinWidth("small")} {
    display: flex;
  }
`;
const MobileNavContainer = styled.div`
  display: flex;
  min-height: 71px;
  background-color: ${themeColor("backgroundGray")};
  ${themeMinWidth("small")} {
    display: none;
  }
`;
const HeaderAndContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${themeMinWidth("small")} {
    margin-left: ${desktopNavWidth}px;
  }
`;
const Content = styled.div`
  padding-bottom: 24px;
`;
const BackNavContainer = styled.div`
  margin-left: 24px;
`;

const SchoolCounselorLayout: React.FC<SchoolCounselorLayoutProps> = ({
  loading,
  children,
  backNavLink,
  backNavText,
}) => {
  useIntercom({ boot: true }); // Enable intercom
  const content = loading ? (
    <LoadingContainer>
      <CenteredLoading />
    </LoadingContainer>
  ) : (
    children
  );
  return (
    <Container>
      <GlobalStyle />
      <DesktopNavContainer>
        <DesktopNav />
      </DesktopNavContainer>
      <MobileNavContainer>
        <MobileNav />
      </MobileNavContainer>
      <HeaderAndContent>
        {backNavLink && backNavText && (
          <BackNavContainer>
            <BackNav
              backText={backNavText}
              backTarget={backNavLink}
              includeUser={false}
              noMargin
            />
          </BackNavContainer>
        )}
        <Content>{content}</Content>
      </HeaderAndContent>
    </Container>
  );
};

export default SchoolCounselorLayout;
