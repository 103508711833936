import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { SendBirdInstance } from "sendbird";
import { useApi } from "../api/apiContext";
import SendBirdClient from "../lib/sendBirdClient";

/**
 * Stores an initialized SendBird instance so we don't have to recreate and
 * reinitialize one everywhere one is required.
 *
 * To use, use this hook in any component nested underneath the <SendBirdStore>
 * component:
 *
 * ```
 * const { sendBird } = useSendBird();
 * ```
 *
 * Notes:
 * - The returned `sendBird` object may be undefined before successfully
 * loading.
 * - This sendBird object is scoped to the currently-authed user.
 */

if (!process.env.REACT_APP_SENDBIRD_APP_ID) {
  console.warn("Missing REACT_APP_SENDBIRD_APP_ID env variable.");
}
type SendBirdType = {
  sendBird?: SendBirdInstance;
};
export const sendBirdStoreContext = createContext<SendBirdType>({
  sendBird: undefined,
});

// A helper hook to easily use this context in components
export const useSendBirdStore = () => useContext(sendBirdStoreContext);

export type SendBirdStoreProps = {};
const SendBirdStore: React.FC<SendBirdStoreProps> = ({ children }) => {
  const { isAuthenticated } = useAuth0();

  const api = useApi();
  const { data: { data: me } = {} } = api.useGetMe({
    options: { enabled: isAuthenticated },
  });
  const { data: { data: tokenResponse } = {} } = api.useGetChatToken({
    options: { enabled: !!me },
  });
  const [sendBirdInstance, setSendBirdInstance] = useState<SendBirdInstance>();

  useEffect(() => {
    (async () => {
      if (tokenResponse && me) {
        const sendBirdInstance = await SendBirdClient.createSendBird({
          sendBirdUserId: me.sendbirdUser.externalUserId,
          accessToken: tokenResponse.token,
        });

        setSendBirdInstance(sendBirdInstance);
      }
    })();
  }, [me, tokenResponse]);

  return (
    <sendBirdStoreContext.Provider value={{ sendBird: sendBirdInstance }}>
      {children}
    </sendBirdStoreContext.Provider>
  );
};

/**
 * This component will use either a real sendbirdstore (which sets up and
 * provides an actual connection to sendbird by giving you a SendBirdInstance)
 * or a dummy sendbirdstore (which just gives you whatever you passed in via the
 * sendbird prop) depending on whether the sendbird prop is provided.  Mostly
 * useful for testing.
 */
type MaybeDummySendBirdStoreProps = {
  sendBird?: SendBirdInstance;
};
const MaybeDummySendBirdStore: React.FC<MaybeDummySendBirdStoreProps> = ({
  sendBird,
  children,
}) => {
  if (sendBird) {
    return (
      <sendBirdStoreContext.Provider value={{ sendBird }}>
        {children}
      </sendBirdStoreContext.Provider>
    );
  } else {
    return <SendBirdStore>{children}</SendBirdStore>;
  }
};

export default MaybeDummySendBirdStore;
