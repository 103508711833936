import React, { ReactElement, useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components/macro";
import { theme } from "../../app/theme";
import Icon from "../Icon/Icon";

const TooltipContent = styled.div``;
const TooltipWrapper = styled.div`
  .__react_component_tooltip.show {
    opacity: 1;
    box-shadow: 0px 22px 45px rgba(0, 0, 0, 0.06),
      0px 9.19107px 15.6908px rgba(0, 0, 0, 0.0431313),
      0px 4.91399px 4.92693px rgba(0, 0, 0, 0.0357664);
    border-radius: 12px;

    // For mobile, completely mangle the original stylings to create a
    // hover-over modal.
    @media (max-width: ${(props) => props.theme.breaks["small"]}) {
      left: 0 !important;
      right: 0 !important;
      margin: 50px 10px !important;

      // Remove the arrow
      ::after,
      ::before {
        display: none;
      }
    }
  }
`;
const Overlay = styled.div`
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.4;
  pointer-events: auto;
  @media (max-width: ${(props) => props.theme.breaks["small"]}) {
    &.visible {
      display: block;
    }
  }
`;
const TooltipTargetWrapper = styled.span``;
const TooltipClose = styled.span`
  display: none;
  position: absolute;
  right: 14px;
  top: 10px;
  .icon {
    width: 10px;
    height: 10px;
  }
  @media (max-width: ${(props) => props.theme.breaks["small"]}) {
    display: block;
  }
`;
export type TooltipProps = {
  tooltipContents: ReactElement;

  // Testing only: used to force the tooltip to always show.
  forceShow?: boolean;
};
declare global {
  interface Window {
    lastTooltipKey: number;
  }
}
const getTooltipKey = () => {
  window.lastTooltipKey = (window.lastTooltipKey ?? 0) + 1;
  console.log("Returning getTooltipKey:", window.lastTooltipKey);
  return window.lastTooltipKey;
};
const Tooltip: React.FC<TooltipProps> = ({
  tooltipContents,
  children,
  forceShow,
}) => {
  const tooltipKey = useRef("");
  if (tooltipKey.current === "") {
    tooltipKey.current = "tooltip_" + getTooltipKey();
  }
  const ref = useRef<HTMLSpanElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const show = () => ref?.current && ReactTooltip.show(ref.current);
  useEffect(() => {
    if (ref.current && forceShow) {
      show();
    }
  }, [ref, forceShow]);
  return (
    <>
      <TooltipWrapper>
        <ReactTooltip
          id={tooltipKey.current}
          effect="solid"
          backgroundColor="white"
          borderColor={theme.colors.borderGray6}
          border
          textColor="black"
          afterShow={() => {
            console.log("showing");
            setTimeout(() => {
              overlayRef.current?.classList?.add("visible");
            });
          }}
          afterHide={() => {
            console.log("hiding");
            setTimeout(() => {
              overlayRef.current?.classList?.remove("visible");
            });
          }}
        >
          <TooltipContent onClick={() => console.log("content click")}>
            {tooltipContents}
          </TooltipContent>
          <TooltipClose>
            <Icon name="close" />
          </TooltipClose>
        </ReactTooltip>
        {/* The onClick here fix a weird issue where click events would go
        through the tooltip to background elements because the tooltip lib uses
        the `pointer-events: none` css directive:
        */}
        <Overlay ref={overlayRef} onClick={(e) => e.stopPropagation()} />
      </TooltipWrapper>
      <TooltipTargetWrapper data-tip data-for={tooltipKey.current} ref={ref}>
        {children}
      </TooltipTargetWrapper>
    </>
  );
};

export default Tooltip;
