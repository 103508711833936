import React from "react";
import { useHistory } from "react-router-dom";
import { useApi } from "../../../../api/apiContext";
import { OrganizationStaffMember } from "../../../../api/queries/organizationStaffMembers";
import Icon from "../../../../components/Icon/Icon";
import Initial from "../../../../components/Initial/Initial";
import {
  ConfirmStaffDeletionModalInsert,
  useConfirmStaffDeletionModal,
} from "../../../../components/Modals/ConfirmStaffDeletionModal/ConfirmStaffDeletionModal";
import TableV2, { createTableV2 } from "../../../../components/TableV2/TableV2";
import TextLink from "../../../../components/TextLink/TextLink";
import {
  ActionCell,
  DeleteIcon,
  DeleteIconPlaceHolder,
  EmailCell,
  NameCell,
} from "./tableElements";

const table = createTableV2<OrganizationStaffMember>();

export type ActiveUsersTableWithDataProps = {
  staffMembers?: OrganizationStaffMember[];
  currentStaff?: OrganizationStaffMember;
};
export const ActiveUsersTableWithData: React.FC<ActiveUsersTableWithDataProps> = ({
  staffMembers,
  currentStaff,
}) => {
  const confirmStaffDeletionModalContext = useConfirmStaffDeletionModal();
  const history = useHistory();

  const columns = [
    table.createDataColumn("firstName", {
      enableSorting: false,
      id: "firstName",
      cell: ({ row }) => {
        const fullName = row.original?.firstName + " " + row.original?.lastName;
        return (
          <NameCell>
            <Initial>{fullName}</Initial>
            {fullName}
          </NameCell>
        );
      },
    }),
    table.createDataColumn("email", {
      enableSorting: false,
      id: "email",
      cell: ({ getValue }) => {
        return <EmailCell>{getValue()}</EmailCell>;
      },
    }),
    table.createDisplayColumn({
      id: "actions",
      cell: ({ row: { original } }) => {
        const canDelete =
          original?.id !== currentStaff?.id &&
          original?.managementMode === "manual";
        return (
          <ActionCell>
            <TextLink
              onClick={() =>
                history.push(`/school/settings/users/${original?.id}`)
              }
            >
              View more
            </TextLink>
            {canDelete ? (
              <DeleteIcon
                onClick={() =>
                  original &&
                  confirmStaffDeletionModalContext.showModal({
                    organizationStaffMember: original,
                  })
                }
              >
                <Icon name="trashCan" />
              </DeleteIcon>
            ) : (
              <DeleteIconPlaceHolder />
            )}
          </ActionCell>
        );
      },
    }),
  ];
  if (!staffMembers) {
    return (
      <TableV2
        data={[] as OrganizationStaffMember[]}
        table={table}
        columns={columns}
        hideHeader
        loading
        updateData={() => {}}
      />
    );
  }
  return (
    <>
      <ConfirmStaffDeletionModalInsert
        modalContext={confirmStaffDeletionModalContext}
      />
      <TableV2
        data={staffMembers}
        table={table}
        columns={columns}
        hideHeader
        updateData={() => {}}
      />
    </>
  );
};

export type ActiveUsersTableProps = {};

const ActiveUsersTable: React.FC<ActiveUsersTableProps> = () => {
  const api = useApi();
  const { data: { data: currentStaff } = {} } = api.useGetMyStaff();
  const { data: { data: myOrg } = {} } = api.useGetMyOrg({
    options: { useErrorBoundary: true },
  });
  const {
    data: { data: staffMembers } = {},
  } = api.useGetOrganizationStaffMembers({
    organizationApiKey: myOrg?.apiKey ?? "",
    hasAuthed: true,
    options: { enabled: !!myOrg, useErrorBoundary: true },
  });

  return (
    <ActiveUsersTableWithData
      staffMembers={staffMembers}
      currentStaff={currentStaff}
    />
  );
};

export default ActiveUsersTable;
