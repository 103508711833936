import React, { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import { useOrgApiKeyParam } from "../../../app/Routes/ScreenerRoutes";
import { themeColor, themeMinWidth } from "../../../app/theme";
import Icon from "../../../components/Icon/Icon";
import OrangeButton from "../../../components/buttons/OrangeButton/OrangeButton";
import { useMandateOrganization } from "../../../hooks/screener/useMandateOrganization";
import ScreenerLayout from "../../../layouts/ScreenerLayout/ScreenerLayout";
import { trackPage } from "client/amplitudeHelper";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 16px 60px 0;
`;
const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${themeColor("orange5", { opacity: 0.1 })};
  width: 85px;
  height: 85px;
  border-radius: 8px;
  .icon {
    width: 43px;
    height: 39px;
    color: ${themeColor("orange5")};
  }
`;
const H1 = styled.h1`
  margin-top: 32px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;
const Details = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  max-width: 436px;
  text-align: center;
`;
const Buttons = styled.div`
  margin-top: 48px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  ${themeMinWidth("small")} {
    flex-direction: row;
  }
`;
export type StudentExemptedPageProps = {};

const StudentExemptedPage: React.FC<StudentExemptedPageProps> = () => {
  const history = useHistory();
  const organizationApiKey = useOrgApiKeyParam();
  useMandateOrganization(organizationApiKey);
  useEffect(() => {
    trackPage("universalScreenerStudentExemptedPage");
  }, []);
  return (
    <ScreenerLayout>
      <Container>
        <IconBox>
          <Icon name="alert" />
        </IconBox>
        <H1>You've been exempted from this screener.</H1>
        <Details>
          Your parent, guardian, or school has asked that you not be required to
          take this Social, Emotional & Mental Health Survey.
        </Details>
        <Buttons>
          <OrangeButton
            onClick={() =>
              history.push(`/organization/${organizationApiKey}/screener`)
            }
          >
            Go Back
          </OrangeButton>
        </Buttons>
      </Container>
    </ScreenerLayout>
  );
};

export default StudentExemptedPage;
