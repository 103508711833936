import React, { useEffect, useState } from "react";
import { useApi } from "../../../api/apiContext";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Milestone } from "../../../api/queries/milestones";
import ClinicalProgressChart, {
  ClinicalProgressChartProps,
  levelToColor,
} from "../../../components/ClinicalProgressChart/ClinicalProgressChart";
import SurveyMilestones from "../../../lib/models/surveyMilestones";
import CardBox from "../../../components/Cards/CardBox/CardBox";
import {
  CardBoxChartContainer,
  ChartBoxBody,
  ChartBoxContainer,
  ChartBoxCurrent,
  ChartBoxCurrentSection,
  ChartBoxDirection,
  ChartBoxDot,
  ChartBoxes,
  ChartBoxHeader,
  ChartBoxStatus,
  ChartBoxStatusDesktop,
  ChartBoxStatusMobile,
  ChartBoxStatusText,
  ChartBoxTitle,
  Container,
  DesktopScoresLink,
  Disclaimer,
  DisclaimerLink,
  EmptyChartBoxBody,
  EmptyChartButton,
  EmptyChartImage,
  EmptyChartText,
  MobileScoresLink,
  PageHeader,
  PeriodSelect,
  PeriodSelectorContainer,
  WhereFromLink,
} from "./elements";

import TrendBadge from "../../../components/TrendBadge/TrendBadge";
import { DateTime } from "luxon";
import Icon from "../../../components/Icon/Icon";
import graphImage from "../../../images/graph.svg";
import TealButton from "../../../components/buttons/TealButton/TealButton";
import SurveyThresholdsTooltip from "../../../components/Tooltip/SurveyThresholdsTooltip/SurveyThresholdsTooltip";
import { User } from "../../../api/queries/users";
import { useSelectedTeen } from "../../../hooks/useSelectedTeen";
import TextLink from "../../../components/TextLink/TextLink";
import HowWeGetScoresModal from "../../../components/Modals/HowWeGetScoresModal/HowWeGetScoresModal";
import { trackPage } from "client/amplitudeHelper";

type ChartBoxProps = {
  isParent: boolean;
  showScoresModal: () => void;
} & ClinicalProgressChartProps;
const ChartBox: React.FC<ChartBoxProps> = ({
  isParent,
  showScoresModal,
  ...chartProps
}) => {
  const milestonesNewestFirst = [...chartProps.milestones].sort(
    (a, b) => (b.completedAt ?? 0) - (a.completedAt ?? 0)
  );
  const [newestMilestone, secondNewestMilestone] = milestonesNewestFirst;
  const level = SurveyMilestones.levelFromScore(
    newestMilestone?.integerCompletionMetric,
    chartProps.scoreConfig.thresholds
  );

  const status = (
    <ChartBoxStatus>
      <ChartBoxCurrent>Current</ChartBoxCurrent>
      <ChartBoxDot
        style={{
          backgroundColor: levelToColor[level],
        }}
      />
      <ChartBoxStatusText>
        {chartProps.scoreConfig.severityLabels[level]}
      </ChartBoxStatusText>
    </ChartBoxStatus>
  );

  return (
    <ChartBoxContainer>
      <CardBox
        header={
          <ChartBoxHeader>
            <ChartBoxTitle>
              <div>{chartProps.chartLabel}</div>
              <SurveyThresholdsTooltip
                title={chartProps.chartLabel}
                scoreConfig={chartProps.scoreConfig}
              />
              <WhereFromLink onClick={showScoresModal}>
                <DesktopScoresLink>
                  <TextLink>How we get scores</TextLink>
                </DesktopScoresLink>
                <MobileScoresLink>
                  <TextLink>Scores?</TextLink>
                </MobileScoresLink>
              </WhereFromLink>
            </ChartBoxTitle>
            {newestMilestone && (
              <ChartBoxCurrentSection>
                <ChartBoxStatusDesktop>{status}</ChartBoxStatusDesktop>
                {secondNewestMilestone && (
                  <ChartBoxDirection>
                    <TrendBadge
                      oldScore={secondNewestMilestone.integerCompletionMetric}
                      newScore={newestMilestone.integerCompletionMetric}
                      lowIsGood={chartProps.scoreConfig.lowIsGood}
                    />
                  </ChartBoxDirection>
                )}
              </ChartBoxCurrentSection>
            )}
          </ChartBoxHeader>
        }
      >
        {chartProps.milestones.length > 0 ? (
          <ChartBoxBody>
            <ChartBoxStatusMobile>{status}</ChartBoxStatusMobile>
            <CardBoxChartContainer>
              <ClinicalProgressChart {...chartProps} />
            </CardBoxChartContainer>
          </ChartBoxBody>
        ) : (
          <EmptyChartBoxBody>
            <EmptyChartImage src={graphImage} />
            <EmptyChartText>
              No clinical progress forms have been completed
            </EmptyChartText>
            {isParent && (
              <EmptyChartButton>
                <TealButton>Send a reminder</TealButton>
              </EmptyChartButton>
            )}
          </EmptyChartBoxBody>
        )}
      </CardBox>
    </ChartBoxContainer>
  );
};

const dropdownPeriods: {
  label: string;
  filter: (date: DateTime) => boolean;
}[] = [
  {
    label: "This Year",
    filter: (date) => date > DateTime.now().minus({ years: 1 }),
  },
  {
    label: "Last Year",
    filter: (date) =>
      date > DateTime.now().minus({ years: 2 }) &&
      date < DateTime.now().minus({ years: 1 }),
  },
  { label: "All Time", filter: () => true },
];

export type ClinicalProgressPageWithDataProps = {
  milestones: Milestone[];
  counselorEmail: string;
  me: User;
};
export const ClinicalProgressPageWithData: React.FC<ClinicalProgressPageWithDataProps> = ({
  milestones,
  counselorEmail,
  me,
}) => {
  const [showingScoresModal, setShowingScoresModal] = useState(false);
  const [period, setPeriod] = useState(dropdownPeriods[0]);
  const periodMilestones = milestones.filter((milestone) => {
    return period.filter(DateTime.fromSeconds(milestone.completedAt ?? 0));
  });
  // Show the same dates for all charts so they line up.
  const datesToShow = periodMilestones.map(
    (milestone) => milestone.completedAt
  );
  const parentCheckinMailto = `mailto:${counselorEmail}?subject=Scheduling a parent check-in`;
  const isCounselor = me.userKind === "counselor";
  const isParent = me.userKind === "parent";

  return (
    <DashboardLayout
      topNav={{
        type: isCounselor ? "counselorTopNav" : "childSelectorTopNav",
      }}
      showPatientName={isCounselor}
    >
      {showingScoresModal ? (
        <HowWeGetScoresModal closeModal={() => setShowingScoresModal(false)} />
      ) : (
        <></>
      )}
      <Container>
        <PageHeader>
          {!isParent ? null : (
            <Disclaimer>
              Results are preliminary should be discussed live with your teen's
              therapist in a parent check-in.
              <DisclaimerLink href={parentCheckinMailto}>
                Schedule check-in
              </DisclaimerLink>
            </Disclaimer>
          )}
          <PeriodSelectorContainer>
            <Icon name="calendar" />
            <PeriodSelect
              onChange={(event) =>
                setPeriod(
                  dropdownPeriods.find(
                    (period) => period.label === event.target.value
                  ) ?? dropdownPeriods[0]
                )
              }
              value={period.label}
            >
              {dropdownPeriods.map(({ label }) => (
                <option key={label} value={label}>
                  {label}
                </option>
              ))}
            </PeriodSelect>
          </PeriodSelectorContainer>
        </PageHeader>

        <ChartBoxes>
          <ChartBox
            showScoresModal={() => setShowingScoresModal(true)}
            milestones={periodMilestones.filter(
              (milestone) => milestone.kind === "GadSurvey"
            )}
            datesToShow={datesToShow}
            chartLabel="Anxiety level"
            isParent={isParent}
            scoreConfig={{
              thresholds: SurveyMilestones.scoreThresholds.GadSurvey.thresholds,
              severityLabels: {
                low: "Minimal",
                medium: "Mild",
                high: "Moderate",
                critical: "Severe",
              },
              max: SurveyMilestones.scoreThresholds.GadSurvey.max,
              lowIsGood: SurveyMilestones.scoreThresholds.GadSurvey.lowIsGood,
            }}
          />
          <ChartBox
            showScoresModal={() => setShowingScoresModal(true)}
            milestones={periodMilestones.filter(
              (milestone) => milestone.kind === "PhqSurvey"
            )}
            datesToShow={datesToShow}
            chartLabel="Depression disorder"
            isParent={isParent}
            scoreConfig={{
              thresholds: SurveyMilestones.scoreThresholds.PhqSurvey.thresholds,
              severityLabels: {
                low: "Minimal",
                medium: "Mild",
                high: "Moderate",
                critical: "Severe",
              },
              max: SurveyMilestones.scoreThresholds.PhqSurvey.max,
              lowIsGood: SurveyMilestones.scoreThresholds.PhqSurvey.lowIsGood,
            }}
          />
          <ChartBox
            showScoresModal={() => setShowingScoresModal(true)}
            milestones={periodMilestones.filter(
              (milestone) => milestone.kind === "WhoSurvey"
            )}
            datesToShow={datesToShow}
            chartLabel="Overall wellbeing"
            isParent={isParent}
            scoreConfig={{
              thresholds: SurveyMilestones.scoreThresholds.WhoSurvey.thresholds,
              severityLabels: {
                critical: "Poor",
                high: "Fair",
                medium: "Good",
                low: "Excellent",
              },
              max: SurveyMilestones.scoreThresholds.WhoSurvey.max,
              lowIsGood: SurveyMilestones.scoreThresholds.WhoSurvey.lowIsGood,
            }}
          />
        </ChartBoxes>
      </Container>
    </DashboardLayout>
  );
};

export type ClinicalProgressPageProps = {};

const ClinicalProgressPage: React.FC<ClinicalProgressPageProps> = () => {
  useEffect(() => {
    trackPage("dashboardClinicalProgress");
  }, []);

  const api = useApi();
  const { teen } = useSelectedTeen();

  const {
    data: milestones,
    isLoading: isLoadingMilestones,
  } = api.useGetMilestones(
    {
      userId: teen?.id,
      kind: ["GadSurvey", "WhoSurvey", "PhqSurvey"],
      state: "completed",
    },
    { enabled: !!teen }
  );
  const { data: { data: me } = {}, isLoading: isLoadingMe } = api.useGetMe();

  if (!teen || isLoadingMilestones || !milestones || isLoadingMe || !me) {
    return <DashboardLayout loading />;
  }

  return (
    <ClinicalProgressPageWithData
      counselorEmail={teen.counselorEmail}
      milestones={milestones}
      me={me}
    />
  );
};

export default ClinicalProgressPage;
