import styled from "styled-components/macro";
import { themeColor } from "../../../app/theme";

export const ReferButton = styled.button`
  border-radius: 8px;
  font-weight: 500;
  line-height: 21px;
  font-size: 14px;
  color: ${themeColor("teal8")};
  background-color: #eff8fb;
  border: none;
  cursor: pointer;
  padding: 9.5px 16px;
`;
