import styled from "styled-components/macro";
import { themeColor } from "../../app/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 16px;
  justify-content: space-between;
  flex-grow: 1;
`;
export const Top = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const NavItems = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const ItemWithButtonState = styled.div`
  transition: all ${(props) => props.theme.animations.defaultHover};
  :hover {
    background-color: ${themeColor("orange1")};
  }
  &:active {
    background-color: ${themeColor("orange5", { opacity: 0.4 })};
    color: ${themeColor("blackText", { opacity: 0.8 })};
  }
  .active & {
    background-color: ${themeColor("orange5")};
    color: white;
  }
`;
export const Item = styled(ItemWithButtonState)`
  height: 50px;
  color: ${themeColor("blackText")};
  font-size: 16px;
  line-height: 24px;
  border-radius: 16px;
  padding: 12px 16px;
  box-sizing: border-box;
  display: flex;
  align-content: center;
`;
export const ItemIconContainer = styled.div`
  margin-right: 16px;
  color: ${themeColor("teal")};
  .active & {
    color: white;
  }
`;
export const ItemName = styled.div``;
export const LogoType = styled.img`
  cursor: pointer;
  height: 39px;
  width: 174px;
`;
export const LogoutIcon = styled.div`
  color: ${themeColor("teal")};
  margin-right: 16px;
  transition: transform 0.25s ease-out;
`;
export const LogoutItem = styled.div`
  display: flex;
  margin-left: 18px;
  transition: all ${(props) => props.theme.animations.defaultHover};
  cursor: pointer;
  :hover {
    opacity: 0.5;
    & ${LogoutIcon} {
      transform: translateX(5px);
    }
  }
`;
export const UserItem = styled.div`
  padding: 0 18px;
  display: flex;
  flex-direction: row;
  .icon {
    width: 24px;
    height: 24px;
  }
`;
export const SettingsLink = styled(ItemWithButtonState)`
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  align-items: center;
  border-radius: 8px;
  .active & {
    color: white;
    background-color: ${themeColor("orange5")};
    .icon {
      color: white;
    }
  }
`;
export const GearIcon = styled.div`
  display: flex;
  margin-right: 14px;
  color: #9da9aa;
  .icon {
    width: 24px;
    height: 24px;
  }
`;
export const SettingsTitle = styled.div`
  color: ${themeColor("blackText")};
  .active & {
    color: white;
  }
`;
export const UserIcon = styled.div`
  color: #9da9aa;
  display: flex;
  align-items: center;
`;
export const NameAndType = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
`;
export const Name = styled.div`
  font-size: 18px;
  // margin-top: -2px;
  // line-height: 27px;
  font-weight: normal;
  color: ${themeColor("darkGrayText4")};
`;
