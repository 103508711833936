import React, { useEffect } from "react";
import styled from "styled-components/macro";
import TealButton from "../../../../components/buttons/TealButton/TealButton";
import {
  StaffUserModalInsert,
  useStaffUserModal,
} from "../../../../components/Modals/StaffUserModal/StaffUserModal";
import SchoolCounselorLayout from "../../../../layouts/SchoolCounselorLayout/SchoolCounselorLayout";
import ActiveUsersTable from "./ActiveUsersTable";
import PendingUsersTable from "./PendingUsersTable";
import { trackEvent, trackPage } from "client/amplitudeHelper";

const Header = styled.div`
  font-weight: 600;
  font-size: 20px;
  margin-left: 24px;
  margin-top: 36px;
  margin-bottom: 40px;
`;

const TableHeader = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`;
const TableAndHeader = styled.div`
  margin-bottom: 40px;
  margin-left: 26px;
  margin-right: 26px;
`;
const TableHeaderText = styled.div``;
const TableHeaderButton = styled.div`
  .icon {
    width: 20px;
    height: 20px;
  }
`;

export type SettingsUsersPageWithDataProps = {};
export const SettingsUsersPageWithData: React.FC<SettingsUsersPageWithDataProps> = () => {
  const staffUserModalContext = useStaffUserModal();
  return (
    <SchoolCounselorLayout>
      <StaffUserModalInsert modalContext={staffUserModalContext} />
      <Header>Settings</Header>
      <TableAndHeader>
        <TableHeader>
          <TableHeaderText>Active users</TableHeaderText>
          <TableHeaderButton>
            <TealButton
              icon="addCircle"
              variant="secondary"
              onClick={() => {
                trackEvent("SCD:clickInviteNewUser");
                staffUserModalContext.showModal({ mode: "new" });
              }}
            >
              Invite new user
            </TealButton>
          </TableHeaderButton>
        </TableHeader>
        <ActiveUsersTable />
      </TableAndHeader>
      <TableAndHeader>
        <TableHeader>Pending invites</TableHeader>
        <PendingUsersTable />
      </TableAndHeader>
    </SchoolCounselorLayout>
  );
};

export type SettingsUsersPageProps = {};

const SettingsUsersPage: React.FC<SettingsUsersPageProps> = () => {
  useEffect(() => {
    trackPage("SCD:SettingsUsersPage");
  }, []);
  return <SettingsUsersPageWithData />;
};

export default SettingsUsersPage;
