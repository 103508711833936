import React from "react";
import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";
import Icon, { ValidIconNames } from "../../Icon/Icon";

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-top: 4px;
`;
const Subtitle = styled.div`
  margin-top: 1px;
  font-weight: 400;
  font-size: 14px;
  color: ${themeColor("darkGrayText7")};
  transition: all ${(props) => props.theme.animations.defaultHover};
  :hover {
    color: white;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${themeColor("borderGray6")};
  border-radius: 12px;
  padding: 14px 24px 12px 24px;
  color: ${themeColor("teal8")};
  cursor: pointer;
  transition: all ${(props) => props.theme.animations.defaultHover};
  .icon {
    width: 24px;
    height: 24px;
  }
  :hover {
    color: white;
    background-color: ${themeColor("teal")};
    ${Subtitle} {
      color: white;
    }
  }
  :active {
    color: white;
    background-color: ${themeColor("teal7")};
    ${Subtitle} {
      color: white;
    }
  }
  ${themeMinWidth("small")} {
    width: 364px;
  }
`;

export type ReferralButtonProps = {
  icon: ValidIconNames;
  title: string;
  subtitle: string;
  onClick?: () => void;
};
const ReferralButton: React.FC<ReferralButtonProps> = ({
  icon,
  title,
  subtitle,
  onClick = () => {},
}) => {
  return (
    <Container onClick={onClick}>
      <Icon name={icon} />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  );
};

export default ReferralButton;
