import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components/macro";
import { themeColor, themeFont } from "../../../app/theme";
import Select, { CSSObjectWithLabel } from "react-select";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";

export const Container = styled.div`
  margin-top: 36px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: 25%;
  margin-right: 25%;
  @media (max-width: 768px) {
    margin: 2%;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

export const Title2 = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-top: 32px;
  text-align: left;
  margin-left: 5%;
  margin-right: 5%;
`;

export const LoadingTitle = styled(Title)`
  margin-top: 85px;
  margin-left: 3%;
  margin-right: 3%;
`;

export const Question = styled.div`
  font-size: 14px;
  margin-top: 34px;
  margin-bottom: 8px;
  font-weight: 600;
  white-space: nowrap;

  @media (max-width: 1500px) {
    white-space: normal;
  }
`;

export const QuestionsHeader = styled.div`
  font-size: 14px;
  margin-top: 34px;
  margin-bottom: 8px;
  font-weight: 600;
  white-space: nowrap;

  @media (max-width: 1500px) {
    white-space: normal;
  }
`;

export const SubHeader = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
  white-space: nowrap;

  @media (max-width: 1500px) {
    white-space: normal;
  }
`;

export const Instruction = styled.div`
  font-size: 14px;
  margin-top: 8px;
  margin-left: 5%;
  margin-right: 5%;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (max-width: 1500px) {
    align-items: center;
  }
`;
export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 1500px) {
    width: 80%;
  }
`;
export const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1500px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  padding-left: 8px;
  font-family: ${themeFont("poppins")};
  font-size: 14px;
  ::placeholder {
    color: ${themeColor("darkGrayText8")};
    opacity: 1;
  }

  border: none;
  background: #def4fe;
  border-bottom: 1px solid ${themeColor("black")};
  height: 36px;
`;

export const StyledInputMask = styled(InputMask)`
  padding-left: 8px;
  font-family: ${themeFont("poppins")};
  font-size: 14px;
  ::placeholder {
    color: ${themeColor("darkGrayText8")};
    opacity: 1;
  }

  border: none;
  background: #def4fe;
  border-bottom: 1px solid ${themeColor("black")};
  height: 36px;
`;

interface SelectDropdownProps {
  options: Array<{ value: string; label: string }>;
  id: string;
  value: any;
  placeholder: string;
  onChange: (selectedOption: any) => void;
}

export const SelectDropdown: React.FC<SelectDropdownProps> = ({
  options,
  id,
  value,
  placeholder,
  onChange,
}) => (
  <Select
    options={options}
    id={id}
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    styles={{
      control: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#def4fe",
      }),
      placeholder: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        color: "#979797",
        fontSize: "14px",
      }),
    }}
  />
);

export const StyledDatePicker = styled(DatePicker)`
  width: 98%;
  font-family: ${themeFont("poppins")};
  font-size: 14px;
  ::placeholder {
    color: ${themeColor("darkGrayText8")};
    opacity: 1;
  }

  border: none;
  background: #def4fe;
  border-bottom: 1px solid ${themeColor("black")};
  height: 36px;
  padding-left: 8px;
`;

export const Action = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
`;

export const Error = styled.div`
  color: ${themeColor("red")};
  font-size: 14px;
  padding: 4px;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormRowGrid = styled.div`
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 1fr;
  min-height: 54px;
  gap: 56px;

  @media (max-width: 1500px) {
    grid-template-columns: 1fr;
    gap: 16px;
    margin-top: 24px;
  }
`;

export const CheckboxGroup = styled.div`
  margin-top: 24px;
  display: grid;
  row-gap: 16px;
  font-size: 14px;
  line-height: 25px;
  v-align: center;
`;

export const StyledRadioButtonGroup = styled.div`
  font-size: 14px !important;
  width: 80% !important;
`;
