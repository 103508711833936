import React, { useMemo } from "react";
import { themeColor } from "../../../app/theme";
import styled from "styled-components/macro";
import { OrganizationMemberV1 } from "../../../api/queries/organizationMembers";
import TableV2, { createTableV2 } from "../../../components/TableV2/TableV2";
import { ReferButton } from "./elements";
import { SortingState } from "@tanstack/react-table";
import QueueStudentButton from "./QueueStudentButton";

export type StudentsTableProps = {
  organizationMembers: OrganizationMemberV1[];
  setPageIndex: (pageIndex: number) => void;
  setPageSort: (sort: SortingState[0]) => void;
  onQueueMember: (organizationMember: OrganizationMemberV1) => void;
  onDequeueMember: (organizationMember: OrganizationMemberV1) => void;
  onReferMember: (organizationMember: OrganizationMemberV1) => void;
  pageIndex: number;
  maxPageIndex: number;
};

const Container = styled.div`
  .table-element {
    min-width: 1000px;
  }
`;
const EmptyTableContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${themeColor("darkGrayText7")};
  padding: 24px;
  font-weight: 500;
`;

const HeaderCellContent = styled.div`
  margin-left: 12px;
`;

const CenteredCell = styled.div`
  text-align: center;
`;

const studentsTable = createTableV2<OrganizationMemberV1>();

const StudentsTable: React.FC<StudentsTableProps> = ({
  organizationMembers,
  setPageIndex,
  setPageSort,
  pageIndex,
  maxPageIndex,
  onQueueMember,
  onDequeueMember,
  onReferMember,
}) => {
  const columns = useMemo(
    () => [
      studentsTable.createDataColumn(
        (row) => `${row.firstName} ${row.lastName}`,
        {
          id: "first_name",
          header: () => {
            return <span>Student name</span>;
          },
          cell: (data) => data.getValue(),
        }
      ),
      studentsTable.createDataColumn("studentEmail", {
        enableSorting: false,
        header: () => {
          return <HeaderCellContent>Email</HeaderCellContent>;
        },
        cell: (data) => data.getValue(),
      }),
      studentsTable.createDataColumn("gradeLevel", {
        enableSorting: false,
        header: () => {
          return <HeaderCellContent>Grade</HeaderCellContent>;
        },
        cell: (data) => <CenteredCell>{data.getValue()}</CenteredCell>,
      }),
      studentsTable.createDataColumn("schoolName", {
        enableSorting: false,
        header: () => {
          return <HeaderCellContent>School Site</HeaderCellContent>;
        },
        cell: (data) => data.getValue(),
      }),
      studentsTable.createDisplayColumn({
        id: "refer button",
        header: () => {
          return <></>;
        },
        cell: ({ row }) => {
          return (
            <ReferButton
              onClick={() => {
                row.original && onReferMember(row.original);
              }}
            >
              Refer to Daybreak
            </ReferButton>
          );
        },
      }),
      studentsTable.createDisplayColumn({
        id: "queue student",
        header: () => {
          return <></>;
        },
        cell: ({ row }) => {
          return (
            <QueueStudentButton
              organizationMember={row.original!}
              onDequeueMember={onDequeueMember}
              onQueueMember={onQueueMember}
            />
          );
        },
      }),
    ],
    [onReferMember, onDequeueMember, onQueueMember]
  );

  return (
    <Container>
      <TableV2
        table={studentsTable}
        columns={columns}
        data={organizationMembers}
        emptyContent={<EmptyTableContent>No Students Found</EmptyTableContent>}
        updateData={({ sorting, currentPageIndex }) => {
          if (currentPageIndex != null) {
            setPageIndex(currentPageIndex);
          }
          if (sorting != null) {
            setPageSort(sorting[0]);
          }
        }}
        currentPageIndex={pageIndex}
        maxPageIndex={maxPageIndex}
      />
    </Container>
  );
};

export default StudentsTable;
