import React, { useState } from "react";
import styled from "styled-components/macro";
import MobileNavMenu from "./MobileNavMenu/MobileNavMenu";
import logoTypeImage from "../../images/daybreak-logo-type.svg";
import Icon from "../Icon/Icon";
import { themeColor } from "../../app/theme";

export type MobileNavProps = {};

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: ${themeColor("backgroundGray")};
`;
const LogoType = styled.img`
  cursor: pointer;
  height: 39px;
  width: 174px;
`;
const MobileNav: React.FC<MobileNavProps> = () => {
  const [showingMenu, setShowingMenu] = useState(false);
  return (
    <Container>
      {showingMenu && <MobileNavMenu onClose={() => setShowingMenu(false)} />}
      <LogoType src={logoTypeImage} alt="Daybreak logo" />
      <Icon name="hamburgerMenu" onClick={() => setShowingMenu(true)}>
        menu
      </Icon>
    </Container>
  );
};

export default MobileNav;
