import React from "react";
import styled from "styled-components/macro";
import { themeColor } from "../../app/theme";
import Icon from "../Icon/Icon";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
const Button = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${themeColor("borderGray6")};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  transition: all ${(props) => props.theme.animations.defaultHover};
  :hover {
    background-color: ${themeColor("teal4")};
    color: white;
  }
  :active {
    background-color: ${themeColor("teal")};
    color: white;
  }
`;
const LeftRightButton = styled(Button)`
  .icon {
    height: 11px;
  }
  &.inactive {
    color: ${themeColor("borderGray6")};
    background-color: ${themeColor("backgroundGray")};
    cursor: default;
  }
`;
const PageButton = styled(Button)`
  &.active {
    background-color: ${themeColor("orange5")};
    color: white;
    border-color: ${themeColor("orange5")};
    cursor: default;
  }
`;

const getPageIndexesToShow = (
  currentPageIndex: number,
  maxPageIndex: number,
  pagesToShow: number
) => {
  const startPageIndex = Math.max(
    0,
    Math.min(
      currentPageIndex - (pagesToShow - 1) / 2,
      maxPageIndex - pagesToShow + 1
    )
  );
  const stopPageIndex = Math.min(
    Math.max(currentPageIndex + (pagesToShow - 1) / 2, pagesToShow - 1),
    maxPageIndex
  );
  let pageIndexesToShow = [];
  for (let i = startPageIndex; i <= stopPageIndex; i++) {
    pageIndexesToShow.push(i);
  }
  return pageIndexesToShow;
};

export type PaginationProps = {
  currentPageIndex: number;
  maxPageIndex: number;
  pagesToShow?: number;
  showJumpToLimit?: boolean;
  showStepNext?: boolean;
  changePage: (newPageIndex: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({
  currentPageIndex,
  maxPageIndex,
  pagesToShow = 3,
  changePage,
  showStepNext,
  showJumpToLimit,
}) => {
  const pageIndexesToShow = getPageIndexesToShow(
    currentPageIndex,
    maxPageIndex,
    pagesToShow
  );

  const pagesToShiftOnArrow = 1;

  const hasMorePagesLeft = currentPageIndex > 0;
  const hasMorePagesRight = currentPageIndex < maxPageIndex;
  if (hasMorePagesLeft || hasMorePagesRight) {
    return (
      <Container>
        {showJumpToLimit ? (
          <LeftRightButton
            className={hasMorePagesLeft ? "active" : "inactive"}
            onClick={() => hasMorePagesLeft && changePage(0)}
          >
            <Icon name="leftArrowBar" />
          </LeftRightButton>
        ) : null}
        {showStepNext ? (
          <LeftRightButton
            className={hasMorePagesLeft ? "active" : "inactive"}
            onClick={() =>
              hasMorePagesLeft &&
              changePage(currentPageIndex - pagesToShiftOnArrow)
            }
          >
            <Icon name="leftArrow" />
          </LeftRightButton>
        ) : null}
        {pageIndexesToShow.map((pageIndex) => {
          return (
            <PageButton
              // Add current page index to the key here to correctly bust react's
              // caching of this list on page change.  Prevents a visual stutter.
              key={pageIndex + " " + currentPageIndex}
              className={pageIndex === currentPageIndex ? "active" : ""}
              onClick={() => changePage(pageIndex)}
            >
              {pageIndex + 1}
            </PageButton>
          );
        })}
        {showStepNext ? (
          <LeftRightButton
            className={hasMorePagesRight ? "active" : "inactive"}
            onClick={() =>
              hasMorePagesRight &&
              changePage(currentPageIndex + pagesToShiftOnArrow)
            }
          >
            <Icon name="leftArrow" flip />
          </LeftRightButton>
        ) : null}
        {showJumpToLimit ? (
          <LeftRightButton
            className={hasMorePagesRight ? "active" : "inactive"}
            onClick={() => hasMorePagesRight && changePage(maxPageIndex)}
          >
            <Icon name="leftArrowBar" flip />
          </LeftRightButton>
        ) : null}
      </Container>
    );
  } else {
    return <Container />;
  }
};

export default Pagination;
