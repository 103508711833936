import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import { RequestError, useApiClient } from "../apiClient";
import { convertSerializedType, withApiErrorHandling } from "./queryHelpers";

export type PutMilestoneCompleteData = {};
export type PutMilestoneCompleteVariables = {
  milestoneId: string;
};
export const usePutMilestoneComplete = () => {
  const apiClient = useApiClient();
  return useMutation<
    PutMilestoneCompleteData,
    AxiosError,
    PutMilestoneCompleteVariables
  >(async ({ milestoneId }) => {
    return await apiClient.put(`/milestones/${milestoneId}/complete`, {});
  });
};

export type PatchMilestoneV2CompleteData = {};
export type PatchMilestoneV2CompleteVariables = {
  milestoneId: string;
};
export const usePutMilestoneV2Complete = (
  { auth } = { auth: false },
  options: UseMutationOptions<
    PutMilestoneCompleteData,
    AxiosError,
    PutMilestoneCompleteVariables
  > = {}
) => {
  const apiClient = useApiClient({ auth });
  return useMutation<
    PutMilestoneCompleteData,
    AxiosError,
    PutMilestoneCompleteVariables
  >(async ({ milestoneId }) => {
    return await apiClient.put(`/v2/milestones/${milestoneId}/complete`, {});
  }, options);
};

export type MilestoneKind = "GadSurvey" | "WhoSurvey" | "PhqSurvey";
export type MilestoneState = "completed" | "scheduled" | "triggered";
export type Milestone = {
  kind: MilestoneKind;
  completedAt?: number;
  integerCompletionMetric: number;
};
export type CompleteMilestone = {};
type Milestones = Milestone[];
export const useGetMilestones = (
  params: {
    userId?: string | string[];
    kind?: MilestoneKind | MilestoneKind[];
    state?: MilestoneState | MilestoneState[];
  } = {},
  options: UseQueryOptions<Milestones, RequestError> = {}
) => {
  const queryKey = ["milestones", params];
  const apiClient = useApiClient();
  return useQuery<Milestones, RequestError>(
    queryKey,
    async () => {
      return withApiErrorHandling(async () => {
        const response = await apiClient.get(`/milestones`, {
          params: {
            user_id: params.userId,
            kind: params.kind,
            state: params.state,
          },
        });
        return response.data.data?.map(convertSerializedType);
      });
    },
    options
  );
};
