import { ParentWelcomePacket } from "../api/queries/parentWelcomePackets";

// 2023-10-20 @dgsan added agreement text
// due to making required as part of debugging.
export const mockParentWelcomePacket = (
  props: Partial<ParentWelcomePacket> = {}
): ParentWelcomePacket => {
  return {
    id: "123",
    patientFirstName: "Theodore",
    patientLastName: "Andowski",
    patientPreferredFirstName: "Ted",
    patientGender: "male",
    patientBirthDate: "2010-06-07",
    patientPersonalEmail: "tedasaurusrex@example.com",
    patientPersonalPhoneNumber: "555-555-5555",
    patientPreferredLanguage: "english",
    patientPreferredLanguageOther: "",
    patientSchoolEmail: "ted.a@example.edu",
    patientStudentIdNumber: "1111",
    guardianRelationship: "mother",
    guardianFirstName: "Janet",
    guardianLastName: "Andowski",
    guardianPhoneNumber: "555-555-5555",
    guardianEmail: "janet.a@example.com",
    guardianPreferredLanguage: "english",
    guardianPreferredLanguageOther: "",
    agreementTosPp: true,
    agreementIc: true,
    agreementAobHipaa: true,
    agreementHipaaRoi: true,
    agreementText: "I sell my soul to the devil for 13 years in return for _.",
    insuranceLink: "",
    asyncIntakeLink: "",
    asyncIntakeQuestionnaireCompleted: false,
    insuranceRequired: true,
    insuranceCompleted: true,
    schedulingLink: "",
    schedulingRequired: true,
    schedulingCompleted: true,
    status: "other",
    linkedAndConfigured: true,
    packetComplete: false,
    organizationName: "Some District",
    clientReferralSfid: "a0D5g000",
    clientSfid: "a0D6OI45Y",
    ...props,
  };
};
