import { ReactElement } from "react";
import { RouteWith, RouteWithParams } from "./RouteWith";
import DirectReferralFormPage from "pages/DirectReferral/DirectReferralFormPage/DirectReferralFormPage";
import DirectReferralSelfCareInstructionPage from "pages/DirectReferral/DirectReferralSelfCareInstructionPage/DirectReferralSelfCareInstructionPage";

const DirectReferralRoutes = () => {
  const routes: { routeParams: RouteWithParams; component: ReactElement }[] = [
    {
      routeParams: { path: "/direct-care/lausd", exact: true },
      component: <DirectReferralFormPage />,
    },
    {
      routeParams: { path: "/direct-care/lausd/self" },
      component: <DirectReferralSelfCareInstructionPage />,
    },
  ];
  return routes.map(({ routeParams, component }, i) => (
    <RouteWith
      {...routeParams}
      key={typeof routeParams.path === "string" ? routeParams.path : i}
    >
      <>{component}</>
    </RouteWith>
  ));
};

export default DirectReferralRoutes;
