import { match } from "react-router-dom";
import { ApplicationFeature } from "../api/queries/features";
import { User } from "../api/queries/users";
import { ValidIconNames } from "../components/Icon/Icon";

type NavItemData = {
  currentUser?: User;
  viewedUser?: User;
  routeMatch: match;
  applicationFeatures?: ApplicationFeature[];
};
type NavItemConfig = {
  destinationPath: (data: NavItemData) => string;
  shouldShow?: (data: NavItemData) => boolean;
  displayString: string;
  icon: ValidIconNames;
};

const parentNavItems: NavItemConfig[] = [
  {
    displayString: "Overview",
    destinationPath: ({ viewedUser }) => `/${viewedUser?.id}/dashboard`,
    icon: "dashboard",
  },
  {
    displayString: "Clinical Progress",
    destinationPath: ({ viewedUser }) => `/${viewedUser?.id}/clinical-progress`,
    icon: "trendingUp",
  },
  {
    displayString: "Appointments",
    destinationPath: ({ viewedUser }) => `/${viewedUser?.id}/appointments`,
    icon: "clock",
  },
];

const counselorNavItems: NavItemConfig[] = [
  {
    displayString: "Overview",
    destinationPath: ({ viewedUser }) =>
      `/clinician/${viewedUser?.id}/dashboard`,
    icon: "dashboard",
    shouldShow: ({ viewedUser }) => !!viewedUser,
  },
  {
    displayString: "Clinical Progress",
    destinationPath: ({ viewedUser }) =>
      `/clinician/${viewedUser?.id}/clinical-progress`,
    icon: "trendingUp",
    shouldShow: ({ viewedUser }) => !!viewedUser,
  },
  {
    displayString: "Appointments",
    destinationPath: ({ viewedUser }) =>
      `/clinician/${viewedUser?.id}/appointments`,
    icon: "clock",
    shouldShow: ({ viewedUser }) => !!viewedUser,
  },
  {
    displayString: "Messages",
    destinationPath: ({ viewedUser }) =>
      `/clinician/${viewedUser?.id}/messages`,
    icon: "chatBubble",
    shouldShow: ({ viewedUser }) => !!viewedUser,
  },
];

const schoolNavItems: NavItemConfig[] = [
  {
    displayString: "Home",
    destinationPath: () => `/school/home`,
    icon: "dashboard",
  },
  {
    displayString: "Referred Students",
    destinationPath: () => `/school/referred-students-list`,
    icon: "seat",
  },
  {
    displayString: "Students list",
    destinationPath: () => `/school/students`,
    icon: "user",
  },
];

export const getDashboardNavItems = ({
  currentUser,
  routeMatch,
  viewedUser,
  applicationFeatures,
}: NavItemData) => {
  let navItems: NavItemConfig[] = [];
  switch (currentUser?.userKind) {
    case "parent":
      navItems = parentNavItems;
      break;
    case "counselor":
      navItems = counselorNavItems;
      break;
    case "organization_staff_member":
      navItems = schoolNavItems;
      break;
  }

  return navItems.filter(({ shouldShow }) =>
    shouldShow
      ? shouldShow({ currentUser, viewedUser, routeMatch, applicationFeatures })
      : true
  );
};
