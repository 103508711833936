import React from "react";
import styled from "styled-components/macro";
import { themeColor } from "../../../app/theme";
import Icon from "../../../components/Icon/Icon";
import TextLink from "../../../components/TextLink/TextLink";

export type NeedsInsuranceBannerProps = {};
const Container = styled.div`
  display: flex;
  gap: 16px;
  border: 2px solid ${themeColor("teal6")};
  background-color: ${themeColor("teal2")};
  padding: 10px 30px 10px 20px;
  border-radius: 12px;
  align-items: center;
  .icon {
    color: ${themeColor("teal6")};
    width: 60px;
    height: 60px;
    min-width: 30px;
  }
`;
const Header = styled.div`
  font-weight: 500;
`;
const Body = styled.div`
  font-color: ${themeColor("darkGrayText8")};
`;
const Text = styled.div``;

const NeedsInsuranceBanner: React.FC<NeedsInsuranceBannerProps> = () => {
  return (
    <Container>
      <Icon name="info" />
      <Text>
        <Header>
          These students need additional information on file before their next
          steps in getting care with Daybreak.
        </Header>
        <Body>
          If you think the family needs assistance in completing the required
          forms please reach out to{" "}
          <TextLink href="mailto:carecoordinator@daybreakhealth.com">
            carecoordinator@daybreakhealth.com
          </TextLink>
          .
        </Body>
      </Text>
    </Container>
  );
};

export default NeedsInsuranceBanner;
