import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { GroupChannel } from "sendbird";
import styled from "styled-components/macro";
import { useApi } from "../../api/apiContext";
import { User } from "../../api/queries/users";
import { useSendBirdStore } from "../../app/SendBirdStore";
import { themeColor } from "../../app/theme";
import DashboardTable, {
  MobileFields,
  TableDataConfig,
} from "../../components/DashboardTable/DashboardTable";
import Icon from "../../components/Icon/Icon";
import Initial from "../../components/Initial/Initial";
import TealButton from "../../components/buttons/TealButton/TealButton";
import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import SendBirdClient from "../../lib/sendBirdClient";
import { trackPage } from "client/amplitudeHelper";

const TableContainer = styled.div`
  padding: 24px;
`;
const LinkWrapper = styled.div`
  display: flex;
`;
const UnreadMessageBox = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${themeColor("teal")};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  .icon {
    width: 24px;
    height: 24px;
  }
  :hover {
    filter: brightness(95%);
  }
  :active {
    filter: brightness(90%);
  }
`;
const DisabledIconBox = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${themeColor("borderGray6")};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: relative;
  cursor: default;
  .icon {
    width: 24px;
    height: 24px;
  }
`;
const UnreadCountCircle = styled.div`
  position: absolute;
  right: -8px;
  top: -7px;
  color: white;
  background-color: ${themeColor("orange5")};
  border: 2px solid white;
  border-radius: 99px;
  height: 16px;
  font-size: 11px;
  font-weight: 600;
  padding: 0 4px;
`;
const PatientName = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const NameText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${themeColor("teal")};
  cursor: pointer;
  :hover {
    filter: brightness(95%);
  }
  :active {
    filter: brightness(90%);
  }
`;

export type CounselorPatientListWithDataProps = {
  users: User[];
  channelMap: Record<string, GroupChannel>;
};

export const CounselorPatientListWithData: React.FC<CounselorPatientListWithDataProps> = ({
  users,
  channelMap,
}) => {
  const history = useHistory();

  const tableConfig: TableDataConfig<User> = {
    fullName: {
      headerDisplay: "Patient Name",
      renderCell: (fullName, user) => {
        return (
          <PatientName
            onClick={() => history.push(`/clinician/${user.id}/dashboard`)}
          >
            <Initial>{fullName}</Initial>
            <NameText>{fullName}</NameText>
          </PatientName>
        );
      },
    },
    unreadMessages: {
      headerDisplay: "",
      renderCell: (_, user) => {
        const hasChannel = Object.prototype.hasOwnProperty.call(
          channelMap,
          user.sendbirdUser.externalUserId
        );
        const unreadCount =
          channelMap[user.sendbirdUser.externalUserId]?.unreadMessageCount;
        if (!hasChannel) {
          return (
            <DisabledIconBox>
              <Icon name="stop" title="This patient isn't ready to chat yet." />
            </DisabledIconBox>
          );
        }
        return (
          <UnreadMessageBox
            onClick={() => history.push(`/clinician/${user.id}/messages`)}
          >
            <Icon name="chatBubble2" />
            {unreadCount == null || unreadCount <= 0 ? null : (
              <UnreadCountCircle>{unreadCount}</UnreadCountCircle>
            )}
          </UnreadMessageBox>
        );
      },
    },
    clinicalProgress: {
      headerDisplay: "",
      renderCell: (_, user) => {
        return (
          <LinkWrapper>
            <TealButton
              onClick={() =>
                history.push(`/clinician/${user.id}/clinical-progress`)
              }
            >
              Clinical Progress
            </TealButton>
          </LinkWrapper>
        );
      },
    },
    appointments: {
      headerDisplay: "",
      renderCell: (_, user) => {
        return (
          <LinkWrapper>
            <TealButton
              onClick={() => history.push(`/clinician/${user.id}/appointments`)}
            >
              Appointments
            </TealButton>
          </LinkWrapper>
        );
      },
    },
  };
  const tableMobileConfig: MobileFields<User> = {
    boxHeaderFields: ["fullName"],
    titleField: "",
    bodyFields: ["appointments", "clinicalProgress", "unreadMessages"],
  };

  return (
    <DashboardLayout topNav={{ type: "titleTopNav", title: "Patients" }}>
      <TableContainer>
        <DashboardTable
          config={tableConfig}
          mobileFields={tableMobileConfig}
          data={users.map((user) => {
            return { ...user };
          })}
        />
      </TableContainer>
    </DashboardLayout>
  );
};

export type CounselorPatientListProps = {};

const CounselorPatientList: React.FC<CounselorPatientListProps> = () => {
  useEffect(() => {
    trackPage("clinicianPatientList");
  }, []);

  const api = useApi();
  const {
    data: { data: users } = {},
    isLoading: isLoadingUsers,
  } = api.useGetUsers({
    client_archived: false,
  });
  const { data: { data: me } = {}, isLoading: isLoadingMe } = api.useGetMe();
  const [channelMap, setChannelMap] = useState<
    Record<string, GroupChannel> | undefined
  >();
  const { sendBird } = useSendBirdStore();

  useEffect(() => {
    if (!isLoadingUsers && users != null && sendBird) {
      (async () => {
        const chatClient = new SendBirdClient(sendBird);
        const sendBirdUserIds =
          users?.map((user) => user.sendbirdUser.externalUserId) || [];
        const channels = await chatClient.findChannels({ sendBirdUserIds });

        const userIdToChannelMap = channels.reduce<
          Record<string, GroupChannel>
        >((map, channel) => {
          channel.members.forEach((member) => {
            map[member.userId] = channel;
          });
          return map;
        }, {});
        setChannelMap(userIdToChannelMap);
      })();
    }
  }, [isLoadingUsers, sendBird, sendBird?.GroupChannel, users]);
  if (isLoadingUsers || users == null || !channelMap || isLoadingMe || !me) {
    return <DashboardLayout loading />;
  }
  return (
    <CounselorPatientListWithData
      users={users.filter((user) => user.id !== me.id)}
      channelMap={channelMap}
    />
  );
};

export default CounselorPatientList;
