import { AxiosInstance } from "axios";
import useResourceHooks, { buildResource } from "./resourceHelpers";

export type PresignedUrl = {
  url: string;
  key: string;
  filename: string;
};

const useUploadsResourceHooks = () =>
  useResourceHooks<PresignedUrl>("v4/uploads", false);

export const buildUploadsResource = (apiClient: AxiosInstance) =>
  buildResource<PresignedUrl>(apiClient, "v4/uploads");

export default useUploadsResourceHooks;
