import { MilestoneKind } from "../../api/queries/milestones";

export type SurveyLevel = "low" | "medium" | "high" | "critical";
export type SurveyThreshold = [number, SurveyLevel];
const scoreThresholds: Record<
  MilestoneKind,
  { thresholds: [number, SurveyLevel][]; max: number; lowIsGood: boolean }
> = {
  GadSurvey: {
    thresholds: [
      [0, "low"],
      [5, "medium"],
      [10, "high"],
      [15, "critical"],
    ],
    max: 21,
    lowIsGood: true,
  },
  PhqSurvey: {
    thresholds: [
      [0, "low"],
      [5, "medium"],
      [10, "high"],
      [20, "critical"],
    ],
    max: 27,
    lowIsGood: true,
  },
  WhoSurvey: {
    thresholds: [
      [0, "critical"],
      [7, "high"],
      [14, "medium"],
      [20, "low"],
    ],
    max: 25,
    lowIsGood: false,
  },
};

const levelFromScore = (
  score: number,
  thresholds: SurveyThreshold[]
): SurveyLevel => {
  const [_, severity] = [...thresholds]
    .reverse()
    .find(([threshold, _]) => score >= threshold) || [null, "low"];
  return severity;
};

const SurveyMilestones = {
  scoreThresholds,
  levelFromScore,
};

export default SurveyMilestones;
