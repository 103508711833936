import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { useOrgApiKeyParam } from "../../../app/Routes/ScreenerRoutes";
import { themeColor, themeMinWidth } from "../../../app/theme";
import Icon from "../../../components/Icon/Icon";
import OrangeButton from "../../../components/buttons/OrangeButton/OrangeButton";
import { schoolSupportEmail } from "../../../constants";
import { useMandateOrganization } from "../../../hooks/screener/useMandateOrganization";
import ScreenerLayout from "../../../layouts/ScreenerLayout/ScreenerLayout";
import { languagePreferenceStorage } from "../../../lib/localStorageManagers";
import { useApi } from "../../../api/apiContext";
import { isClosedScreenerStatus } from "../../../api/queries/userFeatures";
import { trackPage, trackEvent } from "client/amplitudeHelper";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 16px 60px 16px;
  ${themeMinWidth("small")} {
    padding: 50px 48px 60px 48px;
  }
`;
const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${themeColor("orange5", { opacity: 0.1 })};
  width: 85px;
  height: 85px;
  border-radius: 8px;
  .icon {
    width: 47px;
    height: 47px;
    color: ${themeColor("orange5")};
  }
`;
const H1 = styled.h1`
  margin-top: 32px;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;
const Text = styled.div`
  font-size: 16px;
  margin-top: 32px;
  b,
  strong {
    font-weight: 700;
  }
`;
const P = styled.p`
  margin: 0;
`;
const H2 = styled.h2`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 8px;
  margin-top: 32px;
`;
const Buttons = styled.div`
  margin-top: 48px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  ${themeMinWidth("small")} {
    flex-direction: row;
  }
`;
const Disclaimer = styled.div`
  margin-top: 32px;
  font-size: 12px;
`;
const DisclaimerStrong = styled.strong`
  font-weight: 700;
  font-style: italic;
`;

export type SurveyInstructionsPageWithDataProps = {
  history: any;
  organizationApiKey: string;
};
export const SurveyInstructionsPageWithData: React.FC<SurveyInstructionsPageWithDataProps> = ({
  history,
  organizationApiKey,
}) => {
  const preferredLanguage = languagePreferenceStorage.get();
  return (
    <ScreenerLayout currentWizardStep="Welcome">
      <Container>
        <IconBox>
          <Icon name="bookInfo" />
        </IconBox>
        {preferredLanguage === "es" && (
          <>
            <H1>Encuesta sobre salud socioemocional</H1>
            <Text>
              <P>
                ¡Hola! Te damos la bienvenida a la encuesta sobre salud
                socioemocional, administrada por Daybreak Health en asociación
                con tu escuela. Para darte un mejor apoyo, a tu escuela y tus
                consejeros les gustaría hacerte algunas preguntas sobre lo que
                piensas y cómo te sientes.{" "}
                <b>
                  Tus respuestas serán privadas, pero se compartirán
                  recomendaciones para que recibas apoyo adicional con los
                  consejeros de tu escuela dependiendo de cómo contestas cada
                  sección.
                </b>
                Tus respuestas no afectarán tus calificaciones. Contesta con
                honestidad, ¡no hay respuestas correctas o equivocadas!
              </P>
              <H2>Instructions</H2>
              <P>
                La siguiente encuesta de elección múltiple debería tomarte entre
                10 y 15 minutos. No pienses más de 10 o 15 segundos por
                respuesta (no hay respuestas correctas o equivocadas).
              </P>
            </Text>
            <Buttons>
              <OrangeButton
                onClick={() =>
                  history.push(
                    `/organization/${organizationApiKey}/screener/assessment`
                  )
                }
              >
                Comenzar encuesta
              </OrangeButton>
            </Buttons>
            <Disclaimer>
              Solo con fines informativos.{" "}
              <DisclaimerStrong>No es un diagnóstico médico.</DisclaimerStrong>
            </Disclaimer>
          </>
        )}
        {preferredLanguage !== "es" && (
          <>
            <H1>Social & Emotional Health Survey</H1>
            <Text>
              <P>
                Hi! Welcome to the Social, Emotional & Mental Health Survey
                administered by Daybreak Health in partnership with your school.
                To better support you, your school and counselors would like to
                ask you some questions about how you think and feel.{" "}
                <b>
                  Your individual question responses will be kept private, but
                  recommendations for additional support will be shared with
                  your school counselors based on how you answer for each
                  section.
                </b>
                Your answers will not affect your class grades. Please respond
                honestly - there are no right or wrong answers!
              </P>
              <H2>Instructions</H2>
              <P>
                The following multiple choice question survey should take
                between 10-15 minutes to complete. Please do not spend more than
                10-15 seconds on each question (there are no right or wrong
                answers). If you get stuck please reach out to Daybreak support
                at {schoolSupportEmail}. Please complete this survey in one
                sitting, as your answers will not be saved.
              </P>
            </Text>
            <Buttons>
              <OrangeButton
                onClick={() =>
                  history.push(
                    `/organization/${organizationApiKey}/screener/assessment`
                  )
                }
              >
                Start Survey
              </OrangeButton>
            </Buttons>
            <Disclaimer>
              For informational purposes only.{" "}
              <DisclaimerStrong>Not a medical diagnosis.</DisclaimerStrong>
            </Disclaimer>
          </>
        )}
      </Container>
    </ScreenerLayout>
  );
};

export type SurveyInstructionsPageProps = {};
const SurveyInstructionsPage: React.FC<SurveyInstructionsPageProps> = () => {
  const organizationApiKey = useOrgApiKeyParam();
  useMandateOrganization(organizationApiKey);
  const history = useHistory();
  const {
    data: userFeatures,
    isLoading: userFeaturesLoading,
  } = useApi().useGetUserFeatures("me", false, {
    useErrorBoundary: true,
  });

  useEffect(() => {
    trackPage("universalScreenerSurveyInstructionsPage", []);
  });

  if (userFeaturesLoading) {
    return <ScreenerLayout currentWizardStep="Survey" loading />;
  }

  if (!userFeatures) {
    throw new Error("Unable to load userFeatures");
  }

  // Find the feature that tells us this user's screener feature api key
  const screenerFeatures = userFeatures.filter((userFeature) =>
    userFeature.apiKey.startsWith("universal_screener")
  );

  // universal_screener_v1_spanish_standard_subscales
  // universal_screener_v1_standard_subscales
  // universal_screener_v1_standard_covid_school_climate_subscales
  let screenerUserFeature = screenerFeatures[0];

  // if more than one universal screener features are present, try to
  // select based on language preference
  if (screenerFeatures.length > 1) {
    const preferredLanguage = languagePreferenceStorage.get();
    const preferredFeature = screenerFeatures.find((userFeature) => {
      return preferredLanguage === "es"
        ? userFeature.apiKey.includes("spanish")
        : !userFeature.apiKey.includes("spanish");
    });
    if (preferredFeature) {
      screenerUserFeature = preferredFeature;
    }
  }

  if (isClosedScreenerStatus(screenerUserFeature?.scheduledStatus)) {
    const scheduledStatus = screenerUserFeature?.scheduledStatus;
    trackEvent("screenerNotOpen", {
      featureInfo: screenerUserFeature,
    });
    history.push(
      `/organization/${organizationApiKey}/screener/status/${scheduledStatus}`
    );
  }

  return (
    <SurveyInstructionsPageWithData
      history={history}
      organizationApiKey={organizationApiKey}
    />
  );
};

export default SurveyInstructionsPage;
