import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useApi } from "../../api/apiContext";
import { User } from "../../api/queries/users";
import { useSendBirdStore } from "../../app/SendBirdStore";
import { themeColor } from "../../app/theme";
import { useSelectedTeen } from "../../hooks/useSelectedTeen";
import SendBirdClient from "../../lib/sendBirdClient";
import Initial from "../Initial/Initial";

const Container = styled.div`
  display: flex;
`;
const NameAndMessages = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  justify-content: center;
`;
const Name = styled.div`
  font-size: 24px;
  font-weight: 600;
`;
const UnreadMessageCount = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${themeColor("teal")};
`;

const findFirstName = (user: User) =>
  user.firstName || (user.fullName ? user.fullName.split(" ")[0] : undefined);

export type PatientNameBarWithDataProps = {
  patient: User;
  unreadMessageCount: number | undefined;
};
export const PatientNameBarWithData: React.FC<PatientNameBarWithDataProps> = ({
  patient,
  unreadMessageCount,
}) => {
  return (
    <Container>
      <Initial>{findFirstName(patient)}</Initial>
      <NameAndMessages>
        <Name>{patient.fullName}</Name>
        {unreadMessageCount == null ? null : (
          <UnreadMessageCount>
            {unreadMessageCount} new messages
          </UnreadMessageCount>
        )}
      </NameAndMessages>
    </Container>
  );
};

export type PatientNameBarProps = {};

const PatientNameBar: React.FC<PatientNameBarProps> = () => {
  const api = useApi();
  const { teen: user } = useSelectedTeen();
  const { data: tokenResponse } = api.useGetChatToken({});
  const { data: me, isLoading: isLoadingMe } = api.useGetMe();
  const [unreadCount, setUnreadCount] = useState<number | undefined>(undefined);
  const { sendBird } = useSendBirdStore();

  useEffect(() => {
    (async () => {
      if (user && sendBird) {
        const chatClient = new SendBirdClient(sendBird);
        const channels = await chatClient.findChannels({
          sendBirdUserIds: [user.sendbirdUser.externalUserId],
        });
        setUnreadCount(channels[0]?.unreadMentionCount);
      }
    })();
  }, [sendBird, user]);

  if (!user || !me || isLoadingMe || !tokenResponse) {
    return <></>;
  }
  return (
    <PatientNameBarWithData patient={user} unreadMessageCount={unreadCount} />
  );
};

export default PatientNameBar;
