import { useEffect } from "react";
import {
  trackPage,
  trackEvent as trackEventAmplitude,
} from "client/amplitudeHelper";

/**
 * When adding a new page or track event for analtyics purposes, please include
 * a comment.  That comment should be a short prose description of the page or
 * event that is sufficient for someone to identify it in the product UI if
 * they've seen it before.
 */

type PagesEvents =
  // This is the page a staff sees when they're referring a student with the
  // "Parent Referral" feature active where they can fill out a bunch of fields
  // with info about the student, parent, and referring staff member.
  | "SCD:PartialReferralPage"

  // This page is where school staff schedule an appointment for a student
  // they're referring.  It contains an acuity page embedded within an iframe.
  | "SCD:SchoolSchedulingPage";

type TrackEvents =
  // When a staff member completes and submits the partial referral form when
  // referring a student.
  | "SCD:SubmitPartialReferralForm"

  // When a staff member completes and submits the partial referral form when
  // referring a student to family therapy.
  | "SCD:SubmitFamilyTherapyReferralForm"

  // When an intake is successfully created in our backend after a staff member
  // finishes scheduling an appointment for a new referral.
  | "SCD:intakeCreated";

const usePage = (pageEvent: PagesEvents, options?: any) =>
  useEffect(() => {
    trackPage(pageEvent, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageEvent]);

const track = (trackEvent: TrackEvents, options?: any) =>
  trackEventAmplitude(trackEvent, options);

const analytics = { usePage, track };
export default analytics;
