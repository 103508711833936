// Don't include any other imports here - we want as few things as possible to
// import before Bugsnag loads. If anything else is loaded first and throws an
// error, that error will not be captured in our monitoring.
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const apiKey = process.env.REACT_APP_BUGSNAG_API_KEY;

if (apiKey) {
  Bugsnag.start({
    apiKey: apiKey,
    plugins: [new BugsnagPluginReact()],
    redactedKeys: [/token/i, /password/i, /Authorization/i],
  });
} else {
  if (process.env.NODE_ENV !== "test") {
    console.error("Bugsnag api key missing");
  }
}

export { Bugsnag };
