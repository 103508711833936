import styled from "styled-components";
import { themeColor } from "../../../app/theme";

export const StyledField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  /*
    With flex-grow of 1, errors and info text will get pushed down towards the bottom of the div.
  */
  gap: 4px;
`;

export const Spacer = styled.div`
  margin: 0;
  border: 0;
  padding: 0;
  min-width: 1px;
  height: 26px;
`;

export const ErrorText = styled.div`
  color: ${themeColor("red")};
  padding: 0 16px 4px 16px;
  font-size: 14px;
`;

export const Info = styled.div`
  color: ${themeColor("teal6")};
  font-size: 12px;
  font-style: italic;
`;
