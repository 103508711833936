import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { useApiClient } from "../apiClient";

export type Patient = {
  attributes: {
    createdAt: number;
    fullName: string;
  };
  id: number;
};
export type PatientsResponse = {
  data: Patient[];
};

export const useGetPatients = () => {
  const queryKey = ["patients"];
  const apiClient = useApiClient();
  return useQuery<PatientsResponse, AxiosError>(queryKey, async () => {
    const response = await apiClient.get("/v1/patients");
    return response?.data;
  });
};
