import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../app/theme";
import OrangeButton from "../../components/buttons/OrangeButton/OrangeButton";
import Icon from "../../components/Icon/Icon";
import TextLink from "../../components/TextLink/TextLink";
import { supportEmail } from "../../constants";
import useIntercom from "../../hooks/useIntercom";
import CenteredLayout from "../../layouts/CenteredLayout/CenteredLayout";

const ErrorBox = styled.div`
  width: 100%;
  padding: 32px 32px 80px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  ${themeMinWidth("small")} {
    padding: 50px;
    padding-bottom: 80px;
    width: 704px;
  }
`;
const ErrorSquare = styled.div`
  width: 85px;
  height: 85px;
  background-color: ${themeColor("orange5", { opacity: 0.1 })};
  color: ${themeColor("orange5")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  .icon {
    width: 43px;
    height: 43px;
  }
`;
const ErrorTitle = styled.div`
  margin-top: 32px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;
const ErrorBody = styled.div`
  margin-top: 32px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  ${themeMinWidth("small")} {
    width: 500px;
    padding: 0;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 40px;
`;

const supportLink = (
  <TextLink href={"mailto:" + supportEmail}>{supportEmail}</TextLink>
);
const errors = {
  unverifiedEmail: {
    title: <>Unverified email</>,
    body: (
      <>
        Your email is unverified. To fix this, find the invitation email you
        should have received from Daybreak and sign up using that. If you never
        received one, please reach out to your daybreak contact or via email at{" "}
        {supportLink}
      </>
    ),
  },
  staffLoginDisabled: {
    title: <>Login Disabled</>,
    body: (
      <>
        Login and signup for your organization is currently disabled. If this
        seems incorrect, please reach out to your daybreak contact or via email
        at {supportLink}.
      </>
    ),
  },
  noInviteError: {
    title: <>Invite Required</>,
    body: (
      <>
        <p>
          Please use the invite link that was sent to your email address to sign
          in for the first time. If that email link has expired, contact your
          organization's technical contact or Daybreak support at {supportLink}{" "}
          to request a new invite.
        </p>
      </>
    ),
  },
  ssdDisabled: {
    title: <>Thanks for visiting the Daybreak School Dashboard</>,
    body: (
      <>
        <p>
          Unfortunately, the dashboard is down for maintenance right now. Please
          make referrals using this page:
          <br />
          <br />
          <TextLink href="https://daybreakhealth.com/client-referral-parent">
            https://daybreakhealth.com/client-referral-parent
          </TextLink>
        </p>
        <h3>What’s going on?</h3>
        <p>
          We’re aware that the dashboard isn’t loading properly for some users.
          We want to ensure the best experience while using the dashboard so
          we’ve decided to take the site down until the issues are resolved.
        </p>
      </>
    ),
  },
  maintenanceMode: {
    title: <>Thanks for visiting the Daybreak Health</>,
    body: (
      <>
        <p>Unfortunately, Daybreak is down for maintenance right now.</p>
        <p>
          Daybreak's engineering team is aware of the situation and are working
          to bring the site back up as soon as possible. If you have a specific
          question, you can reach us at {supportLink}.
        </p>
      </>
    ),
  },
  teenUserLogin: {
    title: <>Please use our teen mobile app</>,
    body: (
      <>
        Daybreak's website is optimized for school staff and parent use. If
        you're a teen patient, please download our mobile app on{" "}
        <TextLink href="https://apps.apple.com/us/app/daybreak-teen-counseling/id1523709183">
          iOS
        </TextLink>{" "}
        or{" "}
        <TextLink href="https://play.google.com/store/apps/details?id=com.daybreakhealth.two.production&hl=en_US&gl=US&pli=1">
          Android
        </TextLink>
        .
      </>
    ),
  },
  noUser: {
    title: <>User Not Set Up</>,
    body: (
      <>
        <p>Your user account has not yet been set up.</p>
        <p>
          If you're a parent or school staff member, please sign up using the
          unique link that was emailed to you. If you have not yet received that
          unique link and think you should have, please reach out to your
          Daybreak contact or email us at {supportLink}.
        </p>
        <p>
          If you have already signed up with daybreak, please make sure you're
          logging in using the same google account, apple account, or
          email/password you used to sign up.
        </p>
      </>
    ),
  },
  unknownError: {
    title: <>Unknown Error</>,
    body: <>An unknown error has occurred.</>,
  },
  noSitesAssignedError: {
    title: <>No sites assigned to your account</>,
    body: (
      <>
        Please get in touch with Daybreak Health through your account manager or{" "}
        <a href={`mailto:carecoordinator@daybreakhealth.com`}>
          carecoordinator@daybreakhealth.com
        </a>{" "}
        so we can complete setting up your account.
      </>
    ),
  },
} as const;
export type GeneralErrorPageProps = {
  errorKey: keyof typeof errors;
  showLogout?: boolean;
};

const GeneralErrorPage: React.FC<GeneralErrorPageProps> = ({
  errorKey,
  showLogout = true,
}) => {
  const { isAuthenticated, logout } = useAuth0();
  const errorData = errors[errorKey];
  useIntercom({ boot: true });
  return (
    <CenteredLayout headerStyle="simple">
      <ErrorBox>
        <ErrorSquare>
          <Icon name="alert" />
        </ErrorSquare>
        <ErrorTitle>{errorData["title"]}</ErrorTitle>
        <ErrorBody>{errorData["body"]}</ErrorBody>
        {isAuthenticated && showLogout ? (
          <ButtonContainer>
            <OrangeButton onClick={logout}>Logout</OrangeButton>
          </ButtonContainer>
        ) : null}
      </ErrorBox>
    </CenteredLayout>
  );
};

export default GeneralErrorPage;
