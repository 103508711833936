import React from "react";
import { SessionStatus } from "../../api/queries/sessions";
import { SyntheticSession } from "../../pages/School/StudentDetailsPage/StudentDetailsPage";
import Badge, { BadgeProps } from "../Badge/Badge";

export type CombinedSessionStatus = SessionStatus | SyntheticSession["status"];

const badgePropsPerStatus: Record<CombinedSessionStatus, BadgeProps> = {
  kept: {
    text: "Kept",
    type: "heavyGreen",
    size: "medium",
    icon: "checkCircle",
  },
  noshow: {
    text: "Missed",
    type: "heavyBlack",
    size: "medium",
    icon: "userDelete",
  },
  missed: {
    text: "Missed",
    type: "heavyBlack",
    size: "medium",
    icon: "userDelete",
  },
  no_show: {
    text: "Missed",
    type: "heavyBlack",
    size: "medium",
    icon: "userDelete",
  },
  canceled: {
    text: "Canceled",
    type: "heavyBlack",
    size: "medium",
    icon: "stop",
  },
  latecancel: {
    text: "Canceled",
    type: "heavyBlack",
    size: "medium",
    icon: "stop",
  },
  upcoming: {
    text: "Upcoming",
    type: "heavyOrange",
    size: "medium",
    icon: "exclamationCircle",
  },
  scheduled: {
    text: "Scheduled",
    type: "heavyOrange",
    size: "medium",
    icon: "exclamationCircle",
  },
  rescheduled: {
    text: "Rescheduled",
    type: "heavyOrange",
    size: "medium",
    icon: "clock",
  },
  unscheduled: {
    text: "Unscheduled",
    type: "heavyBlack",
    size: "medium",
    icon: "exclamationCircle",
  },
  passed: {
    text: "Passed",
    type: "heavyBlack",
    size: "medium",
    icon: "exclamationCircle",
  },
  unknown: {
    text: "Unknown",
    type: "heavyBlack",
    size: "medium",
    icon: "exclamationCircle",
  },
};

export type AppointmentBadgeProps = {
  status: CombinedSessionStatus;
  inline?: boolean;
};
const AppointmentBadge: React.FC<AppointmentBadgeProps> = ({
  status,
  inline,
}) => {
  const badgeProps = badgePropsPerStatus[status] || {
    text: "Unknown",
    type: "heavyBlack",
    size: "medium",
    icon: "exclamationCircle",
  };
  return <Badge {...badgeProps} inline={inline} />;
};

export default AppointmentBadge;
