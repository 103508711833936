import useResourceHooks from "./resourceHelpers";

type ManualInsurance = {
  insuranceCompanyName: string;
  memberId: string;
  groupId: string;
  planHolderFirstName: string;
  planHolderLastName: string;
  planHolderDateOfBirth: string;
  planHolderStreetAddress: string;
  planHolderCity: string;
  planHolderState: string;
  planHolderZipCode: string;
  insuranceStatusFromReferralForm: string;
};

/* Note: The way the upload stuff works, these are the URIs (s3:// allowed) of the uploaded files */
type InsuranceCard = {
  cardFront: string;
  cardBack: string;
};

export type Insurance = {
  clientSfid: string;
  clientReferralSfid: string;
} & (ManualInsurance | InsuranceCard);

/* Note: Doesn't use authorization, since it's used in the Parent Welcome Packet which is "public" */
const useInsuranceResourceHooks = () =>
  useResourceHooks<Insurance>("insurance", false);

export default useInsuranceResourceHooks;
