import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";

export const Container = styled.div`
  padding-top: 40px;
  max-width: 1024px;
  padding-bottom: 80px;
`;
export const Header = styled.div`
  font-weight: 600;
  font-size: 20px;
  margin-left: 24px;
  margin-top: 36px;
  margin-bottom: 40px;
`;
export const Form = styled.form`
  margin: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  color: ${themeColor("darkGrayText6")};
`;
export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  column-gap: 32px;
`;
export const FormGroupHeader = styled.div`
  font-size: 16px;
  color: ${themeColor("darkGrayText6")};
  padding-bottom: 4px;
  border-bottom: 1px solid ${themeColor("borderGray7")};
`;
export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;
export const FormRowGrid = styled(FormRow)`
  display: grid;
  grid-template-columns: 1fr;
  min-height: 54px;
  ${themeMinWidth("small")} {
    grid-template-columns: 1fr 1fr;
  }
`;
export const YesNoQuestion = styled.div`
  display: grid;
  grid-template-columns: 170px 1fr;
`;
export const Buttons = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;
export const ErrorText = styled.div`
  color: ${themeColor("red")};
  padding: 4px 16px;
  font-size: 14px;
`;
export const FieldAndError = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  min-height: 90px;
`;
export const Errors = styled.div`
  color: ${themeColor("red")};
`;
export const Info = styled.div`
  color: ${themeColor("teal6")};
  font-size: 12px;
  font-style: italic;
`;
export const InsuranceTooltipAndField = styled.div`
  display: flex;
  align-items: center;
`;
export const InsuranceTooltip = styled.div`
  margin-left: 12px;
  line-height: 100%;
  .icon {
    height: 18px;
    width: 18px;
    &:hover {
      color: black;
      transition: all ${(props) => props.theme.animations.defaultHover};
    }
  }
`;
export const InsuranceTooltipContents = styled.div`
  max-width: 300px;
`;
