import {
  createUseApiMutation,
  createUseApiQuery,
  PageMetaTypeV1,
} from "./queryHelpers";

export type PreferredLanguage =
  | "english"
  | "american_sign_language"
  | "armenian"
  | "farsi"
  | "korean"
  | "mandarin"
  | "spanish"
  | "tagalog"
  | "vietnamese"
  | "cantonese"
  | "prefer_not_to_answer"
  | "other";
export type InsuranceHandlingDirective =
  | "not_applicable"
  | "collect_info"
  | "no_insurance";

export type PartialReferralPostArguments = {
  organizationMemberId: string;
  organizationApiKey: string;
  schedulingMethod: "staff_scheduled" | "parent_scheduled" | "pwp_scheduled";
  guardianKind: "father" | "mother" | "custodian" | "guardian" | "_parent";
  guardianVerbalConsent: boolean;
  guardianOkToContact: boolean;
  roiSent: boolean;
  attendanceIssues: boolean;
  patientPersonalEmail: string;
  patientPhoneNumber: string;
  patientPreferredName: string;
  staffReferrerPhoneNumber: string;
  guardianFirstName: string;
  guardianLastName: string;
  guardianEmail: string;
  guardianPhone: string;
  guardianPreferredLanguage: PreferredLanguage;
  guardianPreferredLanguageOther: string;
  counselingGoals: string;
  academicBehaviorAndSchoolIssues: string;
  specialConsiderations: string;
  insuranceHandlingDirective: InsuranceHandlingDirective;
};
export type PartialReferral = {
  id: string;
  patientPhoneNumber: string;
  guardianFirstName: string;
  guardianLastName: string;
  schedulingMethod: string;
  guardianOkToContact: boolean;
  roiSent: boolean;
  attendanceIssues: boolean;
  guardianEmail: string;
  guardianPhone: string;
  guardianPreferredLanguage: PreferredLanguage;
  guardianPreferredLanguageOther: string;
  insuranceHandlingDirective: InsuranceHandlingDirective;
  schedulingLink: string;
  schedulingLinkSentAt: string;
  referralId: string;
};
export const usePostPartialReferral = createUseApiMutation<
  PartialReferral,
  PartialReferralPostArguments
>({
  requestBuilder: (args) => ({
    method: "post",
    url: "/v4/partial_referrals",
    data: {
      organization_api_key: args.organizationApiKey,
      partial_referral: {
        external_id: args.organizationMemberId,
        scheduling_method: args.schedulingMethod,
        guardian_kind: args.guardianKind,
        guardian_verbal_consent: args.guardianVerbalConsent,
        guardian_ok_to_contact: args.guardianOkToContact,
        roi_sent: args.roiSent,
        attendance_issues: args.attendanceIssues,
        patient_personal_email: args.patientPersonalEmail,
        patient_phone_number: args.patientPhoneNumber,
        patient_preferred_name: args.patientPreferredName,
        staff_referrer_phone_number: args.staffReferrerPhoneNumber,
        guardian_first_name: args.guardianFirstName,
        guardian_last_name: args.guardianLastName,
        guardian_email: args.guardianEmail,
        guardian_phone: args.guardianPhone,
        guardian_preferred_language: args.guardianPreferredLanguage,
        guardian_preferred_language_other: args.guardianPreferredLanguageOther,
        counseling_goals: args.counselingGoals,
        academic_behavior_and_school_issues:
          args.academicBehaviorAndSchoolIssues,
        special_considerations: args.specialConsiderations,
        insurance_handling_directive: args.insuranceHandlingDirective,
      },
    },
  }),
});

export const useGetPartialReferral = createUseApiQuery<
  PartialReferral,
  { id: string },
  PageMetaTypeV1
>({
  queryBuilder: ({ id }) => ({
    url: `v4/partial_referrals/${id}`,
    cacheKey: ["partialReferrals", id],
  }),
});
