import { createUseApiQuery, PageMetaTypeV1 } from "./queryHelpers";

export type UserKind =
  | "parent"
  | "teen"
  | "counselor"
  | "psychiatrist"
  | "organization_staff_member";
export type User = {
  id: string;
  firstName: string;
  fullName: string;
  lastName: string;
  userKind: UserKind;
  counselorEmail: string;
  sendbirdUser: {
    externalUserId: string;
  };
};

type View = "parent";

export const useGetUsers = createUseApiQuery<
  User[],
  { view?: View; client_archived?: boolean },
  PageMetaTypeV1
>({
  queryBuilder: ({ view = "parent", client_archived = null } = {}) => {
    const params = { view, client_archived };
    return {
      cacheKey: ["users", params],
      url: `/v1/users`,
      config: { params },
    };
  },
});

export const useGetUser = createUseApiQuery<
  User,
  { id: string; view?: View },
  PageMetaTypeV1
>({
  queryBuilder: ({ id, view = "parent" }) => {
    return {
      cacheKey: ["user", id, view],
      url: `/v1/users/${id}`,
      config: { params: { view } },
    };
  },
});

export const useGetMe = (
  {
    options,
  }: {
    options: Parameters<typeof useGetUser>[0]["options"];
  } = { options: {} }
) => useGetUser({ id: "me", options });
