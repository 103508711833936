import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isDaybreakError } from "../../api/apiClient";
import { useApi } from "../../api/apiContext";

/**
 * Check whether the given api key corresponds to a real organization in the
 * backend.  If it's not, redirect to a 404 page.  This is intended to handle
 * students typoing their organization's api key in the url.
 *
 * Note that because this is an error-correction mechanism, the redirect is
 * asynchronous.  Your component will load and be visible to the user before
 * this component does its redirect.  This is a tradeoff to prevent slowing down
 * page loads in the more common case where the api key *is* present.
 *
 * @param apiKey The organization api to check the existence of.
 */
export const useMandateOrganization = (apiKey: string) => {
  const history = useHistory();
  const { data: organization, error: orgError } = useApi().useGetOrganization({
    apiKey,
    auth: false,
    options: { retry: false },
  });

  useEffect(() => {
    if (
      orgError &&
      isDaybreakError(orgError) &&
      orgError.error_type === "not_found"
    ) {
      history.push("/screener-404");
    }
  }, [history, orgError]);

  return organization;
};
