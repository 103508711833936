import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { useApiClient } from "../apiClient";

export type ApplicationFeature =
  | "Parent Onboarding Quiz V1"
  | "ParentWelcomeEmailV1"
  | "School Counselor Dashboard";
export type ApplicationFeatures = { features: ApplicationFeature[] };

export const useGetApplicationFeatures = () => {
  const queryKey = ["features", "application"];
  const apiClient = useApiClient({ auth: false });
  return useQuery<ApplicationFeatures, AxiosError>(
    queryKey,
    async () => {
      const response = await apiClient.get("/v1/features/application");

      return response.data;
    },
    { retry: false }
  );
};
