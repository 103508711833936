import { DateTime } from "luxon";
import React from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryTooltip,
} from "victory";
import { Milestone, MilestoneKind } from "../../api/queries/milestones";
import { theme } from "../../app/theme";
import SurveyMilestones, {
  SurveyLevel,
  SurveyThreshold,
} from "../../lib/models/surveyMilestones";
import { CustomLabel } from "./CustomLabel";
import { SizeMe } from "react-sizeme";

export const levelToColor: Record<SurveyLevel, string> = {
  low: theme.colors.green,
  medium: "#FBBC04",
  high: "#FF6B20",
  critical: "red",
};

const labelWidth = 244;
const labelHeight = 86;
export type Datum = {
  score: number;
  dateIndex: number;
  dateSeconds: number;
  kind?: MilestoneKind;
  chartLabel: string;
  scoreConfig: ScoreConfig;
};

const levels: SurveyLevel[] = ["low", "medium", "high", "critical"];
const reverseLevels = [...levels].reverse();

export type ScoreConfig = {
  thresholds: SurveyThreshold[];
  severityLabels: Record<SurveyLevel, string>;
  lowIsGood: boolean;
  max: number;
};
export type ClinicalProgressChartProps = {
  milestones: Milestone[];
  datesToShow: (number | undefined)[];
  chartLabel: string;
  scoreConfig: ScoreConfig;

  // Used only for testing - forces all tooltips to show.
  forceAllActive?: boolean;
};
const ClinicalProgressChart: React.FC<ClinicalProgressChartProps> = ({
  milestones,
  datesToShow,
  chartLabel,
  scoreConfig,
  forceAllActive,
}) => {
  // Take all the dates we want to show on the x axis, bucket them by date (so
  // 2pm on 9/13 and 3pm on 9/13 are the same), deduplicate them, and convert
  // them to second timestamps.
  const uniqueDaysToShow = [
    ...new Set(
      datesToShow.map((timestamp) =>
        DateTime.fromSeconds(timestamp ?? 0)
          .startOf("day")
          .toSeconds()
      )
    ),
  ].sort();

  // Bucket all our milestones by day (as second timestamps)
  const milestonesByDayTimestamp: Record<number, Milestone> = {};
  milestones.forEach((milestone) => {
    milestonesByDayTimestamp[
      DateTime.fromSeconds(milestone.completedAt ?? 0)
        .startOf("day")
        .toSeconds()
    ] = milestone;
  });

  // Draw a bar on the chart for each day we want to show.  If there was a
  // milestone that day, show it, otherwise leave it blank.
  const chartData: Datum[] = uniqueDaysToShow.map(
    (dayTimestamp: number, i: number) => {
      const milestone = milestonesByDayTimestamp[dayTimestamp];

      // We put a little extra junk in each data point object so we can use it
      // in our custom tooltip component (which gets passed these datums).
      return {
        // Our x axis is really "the numbers 1 through 5 or however many dates
        // there are", and then we just label the x axis ticks with dates.  This
        // is a trick to make all x axis dates evenly spaced, even if the dates
        // aren't evenly spaced apart.
        //
        // Side note: we do "1 through N + 1" instead of "0 through N" to make
        // domainPadding work. Victory won't let us pad the domain to less than
        // 0, so we start from 1 instead so we can add padding on the left of
        // the chart.
        dateIndex: i + 1,

        dateSeconds: dayTimestamp,
        score: milestone?.integerCompletionMetric ?? 0,
        kind: milestone?.kind,
        chartLabel,
        scoreConfig,
      };
    }
  );
  return (
    <SizeMe monitorHeight>
      {({ size }) => (
        <VictoryChart
          padding={{ left: 81, bottom: 30 }}
          domainPadding={{ x: 30 }}
          domain={{ y: [0, scoreConfig.max] }}
          height={Math.max(size.height ?? 0, 100)}
          width={size.width ?? undefined}
          containerComponent={
            // This chart needs to be inside a containing element with a fixed
            // height set.  If it's not, it grows indefinitely due to SizeMe.
            // This max height prevents that.
            <VictoryContainer style={{ maxHeight: 1000 }} />
          }
        >
          {/* X axis */}
          <VictoryAxis
            tickValues={uniqueDaysToShow.map((_, i) => i + 1)}
            tickFormat={uniqueDaysToShow.map((dateTime) =>
              DateTime.fromSeconds(dateTime).toLocaleString({
                month: "short",
                day: "numeric",
              })
            )}
            style={{
              axis: { stroke: "transparent" },
              tickLabels: { fill: "black", opacity: 0.4 },
            }}
            fixLabelOverlap
          />

          {/* Y axis */}
          <VictoryAxis
            dependentAxis
            tickValues={scoreConfig.thresholds.map(
              ([threshold, _]) => threshold
            )}
            tickFormat={(scoreConfig.lowIsGood ? levels : reverseLevels).map(
              (level: SurveyLevel) => scoreConfig.severityLabels[level]
            )}
            tickLabelComponent={
              <VictoryLabel
                textAnchor="start"
                dx={-70}
                style={{
                  fill: "rgba(0,0,0,0.4)",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              />
            }
            style={{
              grid: { stroke: "#EDEDED" },
              axis: { stroke: "transparent" },
            }}
          />

          <VictoryBar
            data={chartData}
            y="score"
            x="dateIndex"
            barWidth={({ active }) => {
              // Widen the bar on hove
              return active || forceAllActive ? 11 : 10;
            }}
            cornerRadius={{ top: 5, bottom: 5 }}
            // Required for tooltips to work:
            labels={() => ""}
            events={[
              // Default events required for VictoryLabel to work:
              // Some of these events are the default events required by
              // VictoryTooltip to work.  Don't remove those or you'll break
              // VictoryToolip!  :)
              // https://formidable.com/open-source/victory/guides/tooltips/#tooltips-with-other-events
              //
              // The rest power the hover-over-a-bar functionality, which lets us
              // change bar color and other styles based on the 'active' state.
              {
                target: "data",
                eventHandlers: {
                  onMouseOver: () => [
                    {
                      target: "labels",
                      mutation: () => ({ active: true }),
                    },
                    {
                      target: "data",
                      mutation: () => ({ active: true }),
                    },
                  ],
                  onMouseOut: () => [
                    {
                      target: "labels",
                      mutation: () => ({ active: undefined }),
                    },
                    {
                      target: "data",
                      mutation: () => ({ active: undefined }),
                    },
                  ],
                  onFocus: () => [
                    {
                      target: "labels",
                      mutation: () => ({ active: true }),
                    },
                    {
                      target: "data",
                      mutation: () => ({ active: true }),
                    },
                  ],
                  onBlur: () => [
                    {
                      target: "labels",
                      mutation: () => ({ active: undefined }),
                    },
                    {
                      target: "data",
                      mutation: () => ({ active: undefined }),
                    },
                  ],
                },
              },
            ]}
            labelComponent={
              <VictoryTooltip
                labelComponent={
                  <CustomLabel
                    labelWidth={labelWidth}
                    labelHeight={labelHeight}
                  />
                }
                constrainToVisibleArea
                flyoutWidth={labelWidth}
                flyoutHeight={labelHeight}
                active={forceAllActive}
                flyoutStyle={{
                  fill: "white",
                  stroke: "#EBEBEB",
                  filter:
                    "drop-shadow(0px 22px 45px rgba(0, 0, 0, 0.06)) drop-shadow(0px 9.19107px 15.6908px rgba(0, 0, 0, 0.0431313)) drop-shadow(0px 4.91399px 4.92693px rgba(0, 0, 0, 0.0357664))",
                }}
              />
            }
            style={{
              data: {
                filter: ({ active }) => {
                  // Darken the bar on hover
                  return active || forceAllActive ? "brightness(90%)" : "";
                },
                fill: ({ datum }) => {
                  // Color bar correctly for its score
                  const score = datum.score ?? 0;
                  const level = SurveyMilestones.levelFromScore(
                    score,
                    datum.scoreConfig.thresholds
                  );
                  return levelToColor[level];
                },
              },
            }}
          />
        </VictoryChart>
      )}
    </SizeMe>
  );
};

export default ClinicalProgressChart;
