import toast from "react-hot-toast";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useApi } from "../../../../api/apiContext";
import { OrganizationStaffMember } from "../../../../api/queries/organizationStaffMembers";
import Icon from "../../../../components/Icon/Icon";
import Initial from "../../../../components/Initial/Initial";
import {
  ConfirmStaffDeletionModalInsert,
  useConfirmStaffDeletionModal,
} from "../../../../components/Modals/ConfirmStaffDeletionModal/ConfirmStaffDeletionModal";
import TextLink from "../../../../components/TextLink/TextLink";
import BackNav from "../../../../components/TopNav/BackNav/BackNav";
import SchoolCounselorLayout from "../../../../layouts/SchoolCounselorLayout/SchoolCounselorLayout";
import {
  Actions,
  EditLink,
  Header,
  Name,
  NameAndTitle,
  NameBar,
  NameBarLeft,
  NameBarRight,
  SchoolAccess,
  SchoolAccessRow,
  Title,
  TrashButton,
  UserInfo,
  UserInfoLabel,
  UserInfoRow,
  UserInfoValue,
} from "./elements";
import { DateTime } from "luxon";
import {
  StaffUserModalInsert,
  useStaffUserModal,
} from "../../../../components/Modals/StaffUserModal/StaffUserModal";
import { trackEvent, trackPage } from "client/amplitudeHelper";

export type SettingsUserDetailsPageWithDataProps = {
  organizationStaffMember: OrganizationStaffMember;
  myStaff: OrganizationStaffMember;
};
export const SettingsUserDetailsPageWithData: React.FC<SettingsUserDetailsPageWithDataProps> = ({
  organizationStaffMember,
  myStaff,
}) => {
  const history = useHistory();
  const deleteModalContext = useConfirmStaffDeletionModal({
    onDelete: () => {
      history.push("/school/settings/users");
      toast.success("Staff member deleted");
    },
  });
  const editModalContext = useStaffUserModal();
  const fullName = [
    organizationStaffMember.firstName,
    organizationStaffMember.lastName,
  ].join(" ");
  const dateAddedString = DateTime.fromISO(
    organizationStaffMember.createdAt
  ).toLocaleString();
  return (
    <SchoolCounselorLayout>
      <ConfirmStaffDeletionModalInsert modalContext={deleteModalContext} />
      <StaffUserModalInsert modalContext={editModalContext} />
      <BackNav
        backText="Back to Settings"
        backTarget="/school/settings/users"
        includeUser={false}
      />
      <NameBar>
        <NameBarLeft>
          <Initial>{fullName}</Initial>
          <NameAndTitle>
            <Name>{fullName}</Name>
            <Title>{organizationStaffMember.title}</Title>
          </NameAndTitle>
        </NameBarLeft>
        <NameBarRight>
          <Actions>
            {organizationStaffMember.managementMode !== "manual" ? null : (
              <>
                <EditLink
                  onClick={() => {
                    trackEvent("SCD:editStaffLinkClicked");
                    editModalContext.showModal({
                      mode: "edit",
                      organizationStaffMember,
                    });
                  }}
                >
                  <TextLink>Edit</TextLink>
                </EditLink>
                {myStaff.id === organizationStaffMember.id ? null : (
                  <TrashButton>
                    <TextLink
                      onClick={() =>
                        deleteModalContext.showModal({
                          organizationStaffMember,
                        })
                      }
                    >
                      <Icon name="trashCan" />
                    </TextLink>
                  </TrashButton>
                )}
              </>
            )}
          </Actions>
        </NameBarRight>
      </NameBar>
      <UserInfo>
        <Header>User info</Header>
        <UserInfoRow>
          <UserInfoLabel>Email</UserInfoLabel>
          <UserInfoValue>{organizationStaffMember.email}</UserInfoValue>
        </UserInfoRow>
        <UserInfoRow>
          <UserInfoLabel>Role</UserInfoLabel>
          <UserInfoValue>{organizationStaffMember.title}</UserInfoValue>
        </UserInfoRow>
        <UserInfoRow>
          <UserInfoLabel>Date Added</UserInfoLabel>
          <UserInfoValue>{dateAddedString}</UserInfoValue>
        </UserInfoRow>
      </UserInfo>
      <SchoolAccess>
        <Header>School Access</Header>
        {organizationStaffMember.organizationSites?.map((site) => (
          <SchoolAccessRow key={site.id}>{site.name}</SchoolAccessRow>
        ))}
      </SchoolAccess>
    </SchoolCounselorLayout>
  );
};

export type SettingsUserDetailsPageProps = {};

const SettingsUserDetailsPage: React.FC<SettingsUserDetailsPageProps> = () => {
  const { organizationStaffMemberId } = useParams<{
    organizationStaffMemberId: string;
  }>();
  const api = useApi();
  const { data: { data: staff } = {} } = api.useGetOrganizationStaffMember({
    id: organizationStaffMemberId,
  });
  const { data: { data: myStaff } = {} } = api.useGetMyStaff();
  useEffect(() => {
    if (!organizationStaffMemberId) {
      return;
    }
    trackPage("SCD:SettingsUsersDetailsPage", {
      viewedOrganizationStaffMemberId: organizationStaffMemberId,
    });
  }, [organizationStaffMemberId]);
  if (!staff || !myStaff) {
    return <SchoolCounselorLayout loading />;
  }

  return (
    <SettingsUserDetailsPageWithData
      organizationStaffMember={staff}
      myStaff={myStaff}
    />
  );
};

export default SettingsUserDetailsPage;
