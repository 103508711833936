import { createUseApiQuery, PageMetaTypeV1 } from "./queryHelpers";

export type Counselor = {
  id: string;
  fullName: string;
  daybreakHealthEmail: string;
};
export type SessionStatus =
  | "kept"
  | "latecancel"
  | "upcoming"
  | "rescheduled"
  | "canceled"
  | "noshow"
  | "no_show"
  | "scheduled"
  | "missed"
  | "unscheduled";
// Passed and Unknown are FE logic
// display use.
export type ServiceType = {
  code: string;
  name: string;
};
export type Session = {
  status: SessionStatus | null;
  createdAt: number;
  startTime: number;
  endTime: number;
  counselors: Counselor[];
  types: string[];
  serviceTypes: ServiceType[];
  zoomLink: string;
};
export type Sessions = Session[];

export const useGetSessions = createUseApiQuery<
  Sessions,
  { userId?: string },
  PageMetaTypeV1
>({
  queryBuilder: ({ userId }) => {
    const params = userId == null ? {} : { user_id: userId };
    return {
      url: "/v1/sessions",
      cacheKey: ["sessions", params],
      config: { params },
    };
  },
});
