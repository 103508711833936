import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;
export const Section = styled.div``;
export const ClinicalProgressSection = styled(Section)`
  display: grid;
  grid-template-columns: auto;
  column-gap: 24px;
  row-gap: 16px;
  ${themeMinWidth("small")} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const SectionHeader = styled.div`
  margin-top: 32px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  ${themeMinWidth("small")} {
    margin-bottom: 24px;
  }
`;

export const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${themeColor("darkGrayText4")};
`;
export const MoreLink = styled.div`
  color: ${themeColor("teal")};
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 500;
  .icon {
    margin-left: 5px;
    height: 8px;
  }
`;
export const AppointmentsSection = styled(Section)`
  display: grid;
  gap: 16px;
  ${themeMinWidth("small")} {
    grid-template-columns: repeat(4, auto);
  }
`;
export const AppointmentBoxContainer = styled.div`
  grid-column-start: span 2;
  display: flex;
  flex-grow: 1;
`;
export const Disclaimer = styled.div`
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  color: ${themeColor("darkGrayText7")};
`;
export const DisclaimerLink = styled.a`
  margin-left: 8px;
  &,
  :visited {
    color: ${themeColor("teal")};
  }
`;

export const EmptyAppointmentText = styled.div`
  margin-bottom: 16px;
`;

export const EmptySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${themeColor("borderGray6")};
  border-radius: 12px;
  height: 240px;
  font-size: 14px;
  ${themeMinWidth("small")} {
    height: 290px;
  }
`;
export const EmptySectionImage = styled.img`
  width: 50px;
`;
export const EmptySectionText = styled.div`
  margin-top: 24px;
  color: ${themeColor("darkGrayText7")};
`;
export const EmptySectionButton = styled.div`
  margin-top: 24px;
`;

export const UtilizedTooltipContainer = styled.span`
  position: relative;
  top: 2px;
  margin-left: 4px;
  .icon {
    opacity: 0.6;
    width: 14px;
    height: 14px;
  }
  ${themeMinWidth("small")} {
    top: 1px;
    .icon {
      width: 16px;
      height: 16px;
    }
  }
`;
export const ChargedText = styled.div`
  display: flex;
`;
