import { Field, FormikProps } from "formik";
import get from "lodash/get";
import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import { themeFont } from "../../../app/theme";
import { Layout, errorStyles, inputStyles } from "../elements";

const TextArea = styled.textarea<{ error?: boolean }>`
  ${inputStyles}
  ${errorStyles}
  font-family: ${themeFont("poppins")};
`;

export type TextAreaFieldProps = {
  label?: string | ReactElement;
  error?: boolean;
  formik?: FormikProps<any>;
  basicFormik?: boolean;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;
const TextAreaField: React.FC<TextAreaFieldProps> = ({
  label,
  error,
  formik,
  basicFormik = false,
  ...inputProps
}) => {
  if (formik) {
    const key = inputProps.name ?? "";
    return (
      <TextAreaField
        label={label}
        error={!!(formik.errors[key] && formik.touched[key])}
        name={key}
        {...inputProps}
        value={get(formik.values, key) ?? ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={inputProps.disabled || formik.isSubmitting}
      />
    );
  }
  if (basicFormik) {
    return (
      <Layout label={label} error={error} {...inputProps}>
        <Field type="textarea" error={error} {...inputProps} as={TextArea} />
      </Layout>
    );
  }
  return (
    <Layout label={label} error={error} {...inputProps}>
      <TextArea error={error} {...inputProps} />
    </Layout>
  );
};

export default TextAreaField;
