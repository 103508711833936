import React, { useState } from "react";
import styled from "styled-components/macro";
import { themeColor } from "../../app/theme";
import Icon from "../../components/Icon/Icon";

export type CollapsibleSectionProps = {
  title: string;
  isOpen: boolean;
};

const Container = styled.div``;
const Header = styled.div`
  cursor: pointer;
  border-bottom: solid ${themeColor("borderGray6")} 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  &:hover {
    background-color: ${themeColor("borderGray6")};
  }
`;
const Title = styled.div`
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const IconContainer = styled.div<{ open: boolean }>`
  flex: 0;
  margin-right: 10px;
  transform: ${(props) => (props.open ? "scale(1.75)" : "scale(1)")};
  position: relative;
  top: ${(props) => (props.open ? "-5px" : "0")};
`;
const Body = styled.div`
  padding-top: 16px;
`;

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  isOpen,
  children,
}) => {
  const [open, setOpen] = useState(isOpen);

  return (
    <Container>
      <Header onClick={() => setOpen(!open)}>
        <Title>{title}</Title>
        <IconContainer open={open}>
          <Icon name={open ? "downArrow" : "leftArrow"} />
        </IconContainer>
      </Header>
      <Body>{open && children}</Body>
    </Container>
  );
};

export default CollapsibleSection;
