import { createUseApiQuery, PageMetaTypeV1 } from "./queryHelpers";

export type Organization = {
  id: string; // this id is an api key
  apiKey: string;
  name: string;
  kind: string;
  isTest: boolean;
  screenerLanguages: string[];
  configuration: {
    acuity: {
      appointment_type_id?: string;
      staff_scheduled_appointment_type_id?: string;
    };
    frpl_filter: boolean;
  };
  organizationSites?: {
    id: string;
    type: string;
  }[];
};
export const useGetOrganization = createUseApiQuery<
  Organization,
  { apiKey: string; include?: string },
  PageMetaTypeV1
>({
  queryBuilder: ({ apiKey, include }) => {
    const url = include
      ? `/organizations/v1/${apiKey}?include=${include}`
      : `/organizations/v1/${apiKey}`;
    return {
      cacheKey: ["organization", apiKey, include],
      url: url,
    };
  },
});

export const useGetMyOrg = (
  {
    options,
  }: { options: Parameters<typeof useGetOrganization>[0]["options"] } = {
    options: {},
  }
) => useGetOrganization({ apiKey: "me", options });
