import styled from "styled-components";
import Icon from "../../../components/Icon/Icon";
import TealButton from "../../../components/buttons/TealButton/TealButton";
import { Trans } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 24px;
`;

const Alert = styled.div`
  display: flex;
  padding: 8px;
  gap: 10px;
  max-width: 704px;
  align-items: center;
  text-align: left;
  border-radius: 8px;
  background: var(--Orange-90, #ffddba);
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export type NoInsuranceProps = {
  t: (_: string) => string;
  onConfirm: () => void;
  onCancel: () => void;
};

const NoInsurance = ({
  t,
  onConfirm,
  onCancel,
}: NoInsuranceProps): JSX.Element => {
  return (
    <Container>
      <Alert>
        <Icon name="alert" />
        <Trans>{t("noInsuranceAttestation")}</Trans>
      </Alert>
      <Buttons>
        <TealButton onClick={onConfirm}>
          {t("I attest my child has no insurance")}
        </TealButton>
        <TealButton onClick={onCancel}>
          {t("My child has insurance")}
        </TealButton>
      </Buttons>
    </Container>
  );
};

export default NoInsurance;
