import React from "react";
import styled, { css } from "styled-components/macro";
import {
  SurveyMonkeyAnswers,
  SurveyMonkeyChoice,
  SurveyMonkeyResponse_Answer,
} from "../../../api/queries/surveys";
import { themeColor, themeFont, themeMinWidth } from "../../../app/theme";

export type MultipleChoiceQuestionProps = {
  prompt?: string;
  question: string;
  answers: SurveyMonkeyAnswers;
  onAnswer: (answers: SurveyMonkeyResponse_Answer[]) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 17px;
  margin-right: 17px;
`;
const Prompt = styled.div`
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  ${themeMinWidth("small")} {
    font-size: 16px;
  }
`;
const Question = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-top: 24px;
  color: ${themeColor("orange5")};
  ${themeMinWidth("small")} {
    font-size: 26px;
  }
`;

// We want to apply some styles to a few components when a parent element is
// hovered over. We *don't* want to apply hover styles on mobile, however,
// because on mobile you can't hover - only click. And when you click on mobile,
// the hover states are applies. Then, if the button moves (eg because you're
// now on the next page), the hover styles still apply, resulting in a weird UI
// glitch.
//
// Anyway, this helper applies the given style on hover (for desktop) and on
// active (mobile + desktop). Supply a hoverGroup element if you want them to
// apply when a parent element is hovered/active.
const hoverState = (styles: any, hoverGroup: any = null) => css`
  ${hoverGroup ?? ""}:active ${hoverGroup && "&"} {
    ${styles}
  }
  @media (hover: hover) and (pointer: fine) {
    ${hoverGroup ?? ""}:hover ${hoverGroup && "&"} {
      ${styles}
    }
  }
`;

const Answers = styled.div`
  margin-top: 64px;
`;
const Answer = styled.div`
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  padding-left: 18px;
  height: 56px;
  font-family: ${themeFont("helveticaNeue")};
  font-size: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all ${(props) => props.theme.animations.defaultHover};
  ${hoverState(
    css`
      background-color: ${themeColor("backgroundGray")};
      border-color: ${themeColor("orange5")};
    `,
    ""
  )}
`;
const Circle = styled.div`
  border: 1px solid #dfdfdf;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  transition: all ${(props) => props.theme.animations.defaultHover};
  ${hoverState(
    css`
      border-color: ${themeColor("orange5")};
    `,
    Answer
  )}
`;
const CircleFilling = styled.div`
  opacity: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${themeColor("orange5")};
  position: relative;
  top: 1px;
  left: 1px;
  transition: all ${(props) => props.theme.animations.defaultHover};

  ${hoverState(
    css`
      opacity: 1;
    `,
    Answer
  )}
`;
const AnswerText = styled.div`
  position: relative;
  top: 1px;
  margin-left: 12px;
  display: flex;
  font-weight: 500;
`;

const MultipleChoiceQuestion: React.FC<MultipleChoiceQuestionProps> = ({
  prompt,
  question,
  answers,
  onAnswer,
}) => {
  const onClick = (choice: SurveyMonkeyChoice) => {
    const answers: SurveyMonkeyResponse_Answer[] = [
      {
        choice_id: choice.id,
      },
    ];
    onAnswer(answers);
  };
  return (
    <Container>
      <Prompt>{prompt}</Prompt>
      <Question>{question}</Question>
      <Answers>
        {answers.choices.map((choice) => {
          return (
            <Answer key={choice.text} onClick={() => onClick(choice)}>
              <Circle>
                <CircleFilling />
              </Circle>
              <AnswerText>{choice.text}</AnswerText>
            </Answer>
          );
        })}
      </Answers>
    </Container>
  );
};

export default MultipleChoiceQuestion;
