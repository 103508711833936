import { decamelizeKeys } from "humps";
import { createUseApiMutation } from "../queryHelpers";

export type FamilyMemberKind =
  | "mother"
  | "father"
  | "guardian"
  | "sibling"
  | "relative";

type common = {
  partialReferralAttributes: {
    schedulingMethod: "staff_scheduled" | "parent_scheduled" | "pwp_scheduled";
    roiSent: boolean;
    patientPersonalEmail: string;
    patientPhoneNumber: string;
    patientPreferredName: string;
    staffReferrerPhoneNumber: string;
    counselingGoals: string;
    academicBehaviorAndSchoolIssues: string;
    specialConsiderations: string;
    insuranceHandlingDirective: string;
  };

  familyMemberData: {
    role: FamilyMemberKind;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    preferredLanguage: string;
    preferredLanguageOther: string;
    isEmergencyContact: boolean;
    isPrimaryParent: boolean;
    okToContact: boolean;
  }[];
};

type Referral = common & {
  id: string;

  partialReferral?: {
    id: string;
  };
};

export type OrganizationMemberReferralPostArguments = common & {
  organizationMemberId: string;
};

export const usePostOrganizationMemberReferral = createUseApiMutation<
  Referral,
  OrganizationMemberReferralPostArguments
>({
  requestBuilder: (referralData) => ({
    method: "post",
    url: `/v4/organization_members/${referralData.organizationMemberId}/referrals`,
    data: { referral: decamelizeKeys(referralData) },
  }),
});
