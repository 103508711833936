import { useFormikContext } from "formik";
import styled from "styled-components";
import { errorStyles } from "../elements";

const Errors = styled.div`
  ${errorStyles}
`;

const ErrorsSummary = (): JSX.Element => {
  const { errors, touched, isValid } = useFormikContext();

  const displayableErrors = Object.entries(errors).reduce(
    (acc, [key, value]) => {
      (touched as any)[key] && acc.push(value as string);
      return acc;
    },
    [] as string[]
  );

  if (isValid) {
    return <></>;
  }

  return <Errors error={true}>{displayableErrors.join(", ")}</Errors>;
};

export default ErrorsSummary;
