import React from "react";
import styled from "styled-components/macro";
import CardBox from "../CardBox/CardBox";
import { themeColor, themeMinWidth } from "../../../app/theme";

export type InfoCardProps = {
  title: string;
};

const TitleContainer = styled.div`
  border-bottom: 1px solid ${themeColor("borderGray6")};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  color: ${themeColor("darkGrayText7")};
  padding: 14px;
  ${themeMinWidth("small")} {
    white-space: nowrap;
  }
`;
const BodyContainer = styled.div`
  padding: 14px;
`;

const InfoCard: React.FC<InfoCardProps> = ({ title, children }) => {
  return (
    <CardBox>
      <TitleContainer>{title}</TitleContainer>
      <BodyContainer>{children}</BodyContainer>
    </CardBox>
  );
};

export default InfoCard;
