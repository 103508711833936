// A single component to let you specify a bunch of route conditions, eg "this
// route requires authentication" or "this route should be disabled if feature
// flag X is disabled".  Takes in all the arguments a React-Router <Route>
// takes.

import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ReactElement } from "react";
import { Route, RouteProps } from "react-router-dom";
import { ApplicationFeature } from "../../api/queries/features";
import { UserKind } from "../../api/queries/users";
import FeatureFlagWrapper from "./FeatureFlagWrapper";
import UserKindWrapper from "./UserKindWrapper";

export type RouteWithParams = {
  auth?: boolean;
  featureFlag?: ApplicationFeature;
  userKind?: UserKind;
  featureFlagFailureComponent?: ReactElement;
} & RouteProps;
export const RouteWith: React.FC<RouteWithParams> = ({
  auth,
  featureFlag,
  userKind,
  featureFlagFailureComponent,
  ...routeProps
}) => {
  // Require authentication if necessary
  const RouteComponentFunction = auth
    ? withAuthenticationRequired(Route)
    : Route;

  let routeComponent = <RouteComponentFunction {...routeProps} />;

  // Require that the user have a certain user kind if necessary
  if (auth && userKind) {
    routeComponent = (
      <UserKindWrapper userKind={userKind}>{routeComponent}</UserKindWrapper>
    );
  }

  // Require a feature flag if necessary
  if (featureFlag) {
    routeComponent = (
      <FeatureFlagWrapper
        featureFlag={featureFlag}
        featureFlagFailureComponent={featureFlagFailureComponent}
      >
        {routeComponent}
      </FeatureFlagWrapper>
    );
  }

  return routeComponent;
};
