import { SortingState } from "@tanstack/react-table";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import styled from "styled-components/macro";
import { Referral } from "../../../api/queries/referrals";
import { themeColor, themeMinWidth } from "../../../app/theme";
import TealButton from "../../../components/buttons/TealButton/TealButton";
import TableV2, { createTableV2 } from "../../../components/TableV2/TableV2";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import TextLink from "../../../components/TextLink/TextLink";
import Referrals from "../../../lib/models/referrals";
import Loading from "../../../components/Loading/Loading";
import { trackEvent } from "client/amplitudeHelper";

const Container = styled.div`
  .table-element {
    width: 600px;
    ${themeMinWidth("small")} {
      width: 100%;
    }
  }
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 32px;
`;
const LinkContainer = styled.div`
  display: flex;
  align-items: center;
`;
const PendingContainer = styled.div`
  min-width: 165px;
  justify-content: center;
  display: flex;
  opacity: 0.7;
`;
const LoadingContainer = styled.div`
  position: relative;
  top: -3px;
  margin-right: 16px;
`;
const PendingText = styled.div`
  color: ${themeColor("darkGrayText7")};
`;
const table = createTableV2<Referral>();

export type NeedsIntakeTableProps = {
  data: Referral[];
  pageIndex: number;
  maxPageIndex: number;
  setPageIndex: (pageIndex: number) => void;
  setPageSort: (sort: SortingState) => void;
};
const NeedsIntakeTable: React.FC<NeedsIntakeTableProps> = ({
  data,
  setPageIndex,
  setPageSort,
  pageIndex,
  maxPageIndex,
}) => {
  const columns = useMemo(
    () => [
      table.createDisplayColumn({
        id: "studentName",
        header: "Student Name",
        cell: ({ row }) => {
          const orgMember = row.original?.organizationMember;
          return `${orgMember?.firstName} ${orgMember?.lastName}`;
        },
      }),
      table.createDataColumn("referredAt", {
        id: "referred_at",
        header: "Days Since Referral",
        cell: ({ getValue }) => {
          const referredAt = getValue();
          if (!referredAt) return <></>;

          const timeAgo = DateTime.fromISO(referredAt).diffNow("days");
          return Math.abs(timeAgo.days).toFixed();
        },
      }),
      table.createDisplayColumn({
        id: "insuranceRequestUrl",
        header: () => {
          return <></>;
        },
        cell: ({ row }) => {
          const referral = row.original;
          const orgMember = referral?.organizationMember;
          const partialReferral = referral?.partialReferral;
          const parentFormUrl = partialReferral?.schedulingLink;
          const teenName = orgMember?.firstName + " " + orgMember?.lastName;
          const parentEmail = partialReferral?.guardianEmail;
          const parentFirstName = partialReferral?.guardianFirstName;
          if (!orgMember || !partialReferral) {
            return <Buttons />;
          }

          const mailTo = () => {
            if (!parentFormUrl) return;
            const mailToLink = Referrals.parentIntakeFormMailto({
              parentEmail: parentEmail ?? "",
              parentFirstName,
              teenName,
              parentFormUrl,
            });
            trackEvent(
              "SCD:activedParentMailToForIntakeScheduling",
              {
                mailToLink,
                referralExternalId: referral?.id,
                orgMemberId: orgMember?.id,
              },
              () => {
                window.location.assign(mailToLink);
              }
            );
          };
          const copyUrl = () => {
            if (!parentFormUrl) return;
            copy(parentFormUrl);
            toast.success("Url copied to clipboard");
            trackEvent("SCD:copiedParentLinkToIntakeSchedulingForm", {
              parentFormUrl,
              referralExternalId: referral?.id,
              orgMemberId: orgMember?.id,
            });
          };
          const visitForm = () => {
            if (!parentFormUrl) return;
            trackEvent(
              "SCD:clickedVisitFormForParentIntakeSchedulingForm",
              {
                parentFormUrl,
                referralExternalId: referral?.id,
                orgMemberId: orgMember?.id,
              },
              () => {
                window.location.assign(parentFormUrl);
              }
            );
          };
          return (
            <Buttons>
              {parentFormUrl ? (
                <>
                  <LinkContainer>
                    <TextLink onClick={mailTo}>Email Parent</TextLink>
                  </LinkContainer>
                  <LinkContainer>
                    <TextLink onClick={copyUrl}>Copy Form URL</TextLink>
                  </LinkContainer>
                </>
              ) : (
                <PendingContainer>
                  <LoadingContainer>
                    <Loading size="mini" widthOverride={16} />
                  </LoadingContainer>
                  <PendingText>Pending</PendingText>
                </PendingContainer>
              )}
              <TealButton onClick={visitForm}>Schedule Intake</TealButton>
            </Buttons>
          );
        },
      }),
    ],
    []
  );
  return (
    <Container>
      <TableV2
        table={table}
        columns={columns}
        data={data}
        updateData={({ sorting, currentPageIndex }) => {
          if (currentPageIndex != null) {
            setPageIndex(currentPageIndex);
          }
          if (sorting != null) {
            setPageSort(sorting);
          }
        }}
        currentPageIndex={pageIndex}
        maxPageIndex={maxPageIndex}
        paginationProps={{
          showStepNext: true,
          showJumpToLimit: maxPageIndex > 2,
        }}
      />
    </Container>
  );
};

export default NeedsIntakeTable;
