import { createUseApiQuery } from "./queryHelpers";

export type SearchSelectOrganization = {
  id: string;
  name: string;
  stateAbbreviation: string;
  stateName: string;
  type: "searchSelectOrganization";
};
export const useGetSearchSelectOrganizations = createUseApiQuery<
  SearchSelectOrganization[],
  {}
>({
  queryBuilder: () => {
    return {
      cacheKey: ["searchSelectOrganizations"],
      url: `/v4/search_select_organizations`,
    };
  },
  auth: false,
});
