import React from "react";
import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";
import TealButton from "../../buttons/TealButton/TealButton";
import { useFormik } from "formik";
import { useApi } from "../../../api/apiContext";
import { Organization } from "../../../api/queries/organizations";
import { DateTime, Duration } from "luxon";
import {
  getFormikOrganizationMemberConfig,
  genderOptions,
  PostOrganizationMemberParams,
} from "../../../api/queries/organizationMembers";
import { RequestError, isDaybreakErrorV4 } from "../../../api/apiClient";
import { OrganizationSite } from "../../../api/queries/organizationSites";
import SelectField from "../../Forms/SelectField/SelectField";
import { CenteredLoading } from "../../Loading/Loading";
import TextLink from "../../TextLink/TextLink";
import { trackEvent } from "client/amplitudeHelper";
import { useHasOrganizationFeature } from "../../../hooks/useHasOrganizationFeature";
import visitOnsiteUrl from "./VisitOnsiteUrl";

const Container = styled.div`
  padding: 24px;
  flex-grow: 1;
  ${themeMinWidth("small")} {
    min-width: 600px;
  }
`;
const Header = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: black;
`;
const SectionHeader = styled.div`
  margin-top: 20px;
  margin-bottom: 14px;
  color: ${themeColor("darkGrayText6")};
  font-size: 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid ${themeColor("borderGray7")};
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  gap: 14px;
`;
const LightTealButton = styled(TealButton)`
  background-color: ${themeColor("orange5")};
`;
const FormFieldContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  display: block;
  flex-grow: 1;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  > *:first-child {
    min-width: 56%;
  }
`;
const Input = styled.input`
  display: flex;
  border: 1px solid ${themeColor("darkGrayText6")};
  border-radius: 8px;
  padding: 16px 14px;
  font-size: 14px;
  color: black;
  display: block;
  box-sizing: border-box;
  width: 100%;
  ::placeholder {
    opacity: 0.5;
  }
`;
const OtherInput = styled(Input)`
  margin-top: 10px;
`;
const Form = styled.form`
  *:disabled {
    background-color: ${themeColor("backgroundGray")};
    border-color: ${themeColor("darkGrayText8")};
    color: ${themeColor("darkGrayText7")};
  }
`;
const Label = styled.label`
  position: absolute;
  font-size: 14px;
  color: ${themeColor("darkGrayText6")};
  top: -10.5px;
  left: 16px;
  background-color: white;
  padding: 0 4px;
  border-radius: 4px;
`;

export const Error = styled.div`
  color: ${themeColor("red")};
  padding: 4px 16px;
  font-size: 14px;
`;

export const Info = styled.div`
  color: ${themeColor("teal")};
  padding: 4px 16px;
  font-size: 14px;
`;

const extractApiErrors = (errorsObj: RequestError | null) => {
  if (errorsObj && isDaybreakErrorV4(errorsObj)) {
    const errorData = errorsObj?.errorData;
    if (errorData) {
      trackEvent("errorCreatingOrganizationMember", errorData);
      for (const key in errorData) {
        if (Array.isArray(errorData[key])) {
          errorData[key] = errorData[key].join(", ");
        }
      }
      return errorData;
    }
  }
  return {};
};

export type AddFormModeProps = {
  title: string;
  goBack: () => void;
  onCreate: (organizationMemberId: string) => void;
  sites: OrganizationSite[];
};

export const AddFormMode: React.FC<AddFormModeProps> = ({
  title,
  goBack,
  onCreate,
  sites,
}) => {
  const api = useApi();

  const {
    isLoading: myOrgLoading,
    data: { data: myOrg } = {},
  } = api.useGetOrganization({
    apiKey: "me",
  });

  const {
    active: onsiteReferralsLinkActive,
    loading: onsiteReferralsLinkLoading,
  } = useHasOrganizationFeature("organization_onsite_referral_form_link");

  const { isLoading: meLoading, data: meData } = api.useGetMe();

  const {
    isLoading: submitting,
    error: postOrganizationMemberErrors,
    mutate: postOrganizationMember,
  } = api.usePostOrganizationMember({
    options: {
      onSuccess: (data) => {
        if (data.data.id) {
          trackEvent("createdOrganizationMember", {
            created: data.data,
          });
          onCreate(data.data.id);
        }
      },
    },
  });

  // Extra conditions (!myOrg, !myStaff) make typescript happy -
  // Not sure if there's supposed to be a better way of handling,
  // but if someone's using SSD being finished loading
  // should mean they're not undefined.
  if (
    !myOrg ||
    onsiteReferralsLinkLoading ||
    myOrgLoading ||
    !myOrg ||
    meLoading ||
    !meData
  ) {
    return <CenteredLoading />;
  }
  const onSubmit = async (
    values: Omit<PostOrganizationMemberParams, "apiKey">
  ) => {
    postOrganizationMember({ ...values, apiKey: myOrg.apiKey });
  };

  const apiErrors = extractApiErrors(postOrganizationMemberErrors);

  return (
    <AddFormModeWithData
      title={title}
      goBack={goBack}
      sites={sites}
      submitting={submitting}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
      onsiteReferralsLinkActive={onsiteReferralsLinkActive}
      myOrg={myOrg}
      myStaffUserId={meData.data.id}
    />
  );
};

export type OrganizationMemberInfos = {
  dateOfBirth?: string;
};

/*
 * Note: Built in relative date formatting
 * does not work quite right for ages.
 */
export const formatDifference = (diff: Duration) => {
  const yTerm = diff.years;
  const mTerm = Math.floor(diff.months);
  let mString = "";
  let yString = "";
  if (mTerm === 1) {
    mString = `${mTerm} month`;
  } else if (mTerm > 1) {
    mString = `${mTerm} months`;
  }
  if (yTerm === 1) {
    yString = `${yTerm} year`;
  } else if (yTerm > 1) {
    yString = `${yTerm} years`;
  }
  if (mString && yString) {
    return `${yString} and ${mString} old`;
  } else if (yString) {
    return `${yString} old`;
  } else if (mString) {
    return `${mString} old`;
  } else {
    return "";
  }
};

export type AddFormModeWithDataProps = {
  title: string;
  goBack: () => void;
  sites: OrganizationSite[];
  submitting: boolean | undefined;
  onSubmit: (values: Omit<PostOrganizationMemberParams, "apiKey">) => void;
  apiErrors: any;
  onsiteReferralsLinkActive: boolean;
  myOrg: Organization;
  myStaffUserId: string;
};

export const AddFormModeWithData: React.FC<AddFormModeWithDataProps> = ({
  title,
  goBack,
  sites,
  submitting,
  onSubmit,
  apiErrors,
  onsiteReferralsLinkActive,
  myOrg,
  myStaffUserId,
}) => {
  const formikConfig = getFormikOrganizationMemberConfig(sites);
  const formik = useFormik({
    initialValues: formikConfig.initialValues,
    validationSchema: formikConfig.validationSchema,
    onSubmit: onSubmit,
  });
  const birthdate = Date.parse(formik.values.dateOfBirth);
  const Infos: OrganizationMemberInfos = {};
  // Note: Infos do not prevent save or submission.
  if (formik.values.dateOfBirth && birthdate && birthdate > 0) {
    const nowYear = DateTime.now();
    const thenYear = DateTime.fromMillis(birthdate);
    const difference = nowYear.diff(thenYear, ["years", "months"]);
    const diffStr = formatDifference(difference);
    if (diffStr) {
      Infos.dateOfBirth = diffStr;
    }
  }
  const hasSchoolError =
    apiErrors?.organizationSiteId ||
    apiErrors?.schoolName ||
    (formik.errors.organizationSiteId && formik.touched.organizationSiteId);
  return (
    <Container>
      <Header>{title}</Header>
      {apiErrors.existingOrganizationMemberId && (
        <Error>
          A record for {formik.values.firstName} {formik.values.lastName}{" "}
          already exists!{" "}
          <TextLink
            href={`/school/scheduling/referral-form/${apiErrors.existingOrganizationMemberId}`}
          >
            Click here to refer.
          </TextLink>
        </Error>
      )}
      <Form as="form" onSubmit={formik.handleSubmit}>
        <SectionHeader>Student info</SectionHeader>
        <Section>
          <FormFieldContainer>
            <Label htmlFor="firstName">Student First Name</Label>
            <Input
              id="firstName"
              type="text"
              placeholder="Suzie"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              autoComplete="off"
              disabled={submitting}
            />
            {(apiErrors?.firstName ||
              (formik.errors.firstName && formik.touched.firstName)) && (
              <Error>{formik.errors.firstName}</Error>
            )}
          </FormFieldContainer>

          <FormFieldContainer>
            <Label htmlFor="lastName">Student Last Name</Label>
            <Input
              id="lastName"
              type="text"
              placeholder="Sunshine"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              autoComplete="off"
              disabled={submitting}
            />
            {(apiErrors?.lastName ||
              (formik.errors.lastName && formik.touched.lastName)) && (
              <Error>{formik.errors.lastName || apiErrors?.lastName}</Error>
            )}
          </FormFieldContainer>

          <FormFieldContainer>
            <Label htmlFor="studentEmail">Student Email</Label>
            <Input
              id="studentEmail"
              type="email"
              placeholder="suzie.sunshine@daybreakhealth.com"
              onChange={formik.handleChange}
              value={formik.values.studentEmail}
              autoComplete="off"
              disabled={submitting}
            />
            {(apiErrors?.studentEmail ||
              (formik.errors.studentEmail && formik.touched.studentEmail)) && (
              <Error>
                {formik.errors.studentEmail || apiErrors?.studentEmail}
              </Error>
            )}
          </FormFieldContainer>

          <Row>
            <FormFieldContainer>
              <Label htmlFor="dateOfBirth">Student Birthdate</Label>
              <Input
                id="dateOfBirth"
                type="date"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.dateOfBirth}
                autoComplete="off"
                disabled={submitting}
              />
              {(apiErrors?.dateOfBirth ||
                (formik.errors.dateOfBirth && formik.touched.dateOfBirth)) && (
                <Error>
                  {formik.errors.dateOfBirth || apiErrors?.dateOfBirth}
                </Error>
              )}
              {Infos?.dateOfBirth && <Info>{Infos.dateOfBirth}</Info>}
            </FormFieldContainer>

            <FormFieldContainer>
              <Label htmlFor="uniqueStudentId">Student ID Number</Label>
              <Input
                id="uniqueStudentId"
                type="string"
                placeholder="A0000000"
                onChange={formik.handleChange}
                value={formik.values.uniqueStudentId}
                autoComplete="off"
                disabled={submitting}
              />
              {(apiErrors?.uniqueStudentId ||
                (formik.errors.uniqueStudentId &&
                  formik.touched.uniqueStudentId)) && (
                <Error>
                  {formik.errors.uniqueStudentId || apiErrors?.uniqueStudentId}
                </Error>
              )}
            </FormFieldContainer>
          </Row>

          <Row>
            <FormFieldContainer>
              <SelectField
                label="Student Gender"
                id="gender"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gender}
                autoComplete="off"
                disabled={submitting}
                error={formik.errors.gender ?? apiErrors?.gender}
              >
                <option value="" label="Select a gender">
                  Select a gender
                </option>
                {genderOptions.map((opt) => (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                ))}
              </SelectField>
              {formik.values.gender === "other" && (
                <OtherInput
                  id="otherGender"
                  type="text"
                  placeholder="Other gender"
                  onChange={formik.handleChange}
                  value={formik.values.otherGender}
                  autoComplete="off"
                  disabled={submitting}
                />
              )}
              {(apiErrors?.gender ||
                (formik.errors.gender && formik.touched.gender)) && (
                <Error>{formik.errors.gender || apiErrors?.gender}</Error>
              )}
            </FormFieldContainer>

            <FormFieldContainer>
              <Label htmlFor="gradeLevel">Student Grade</Label>
              <Input
                id="gradeLevel"
                type="number"
                placeholder="9"
                onChange={formik.handleChange}
                value={formik.values.gradeLevel}
                autoComplete="off"
                disabled={submitting}
              />
              {(apiErrors?.gradeLevel ||
                (formik.errors.gradeLevel && formik.touched.gradeLevel)) && (
                <Error>
                  {formik.errors.gradeLevel || apiErrors?.gradeLevel}
                </Error>
              )}
            </FormFieldContainer>
          </Row>
          <FormFieldContainer>
            <SelectField
              label="Student School"
              id="organizationSiteId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.organizationSiteId}
              autoComplete="off"
              disabled={submitting}
              error={hasSchoolError}
            >
              <option value="" label="Select a school">
                Select a school
              </option>
              {sites.map((site) => (
                <option key={site.id} value={site.id}>
                  {site.name}
                </option>
              ))}
              ;
            </SelectField>
            {hasSchoolError && (
              <Error>
                {formik.errors.organizationSiteId ||
                  apiErrors?.organizationSiteId ||
                  apiErrors?.schoolName}
              </Error>
            )}
          </FormFieldContainer>
        </Section>
        <ButtonRow>
          <LightTealButton onClick={goBack} disabled={submitting}>
            Back
          </LightTealButton>
          {onsiteReferralsLinkActive && (
            <TealButton
              onClick={() => {
                formik.validateForm().then((errors) => {
                  // Is there really not a better way to do this?
                  if (Object.keys(errors).length === 0) {
                    visitOnsiteUrl(
                      {
                        ...formik.values,
                        organizationSiteName: sites.find(
                          ({ id }) =>
                            id === "ec6dc2e0-705f-4854-8186-9362d337eb22"
                        )?.name,
                      },
                      myOrg.apiKey,
                      myStaffUserId
                    );
                  }
                });
              }}
              variant="secondary"
              disabled={formik.isSubmitting || formik.isValidating}
            >
              Onsite Referral
            </TealButton>
          )}
          <TealButton as="button" type="submit" disabled={submitting}>
            {submitting ? "submitting…" : "Submit"}
          </TealButton>
        </ButtonRow>
      </Form>
    </Container>
  );
};

export default AddFormMode;
