import React from "react";
import { useApi } from "../../api/apiContext";
import { UserKind } from "../../api/queries/users";
import DisabledPage from "../../pages/DisabledPage/DisabledPage";

// Wraps a route and returns a disabled page if the specified feature flag is
// not enabled.
const UserKindWrapper: React.FC<{ userKind: UserKind }> = ({
  userKind,
  children,
}) => {
  const api = useApi();
  const { data: { data } = {} } = api.useGetMe();
  if (data && data.userKind !== userKind) {
    return <DisabledPage />;
  }
  return <>{children}</>;
};

export default UserKindWrapper;
