import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { BaseMessageInstance, GroupChannel, UserMessage } from "sendbird";
import styled from "styled-components/macro";
import { useApi } from "../../../api/apiContext";
import { User } from "../../../api/queries/users";
import { useSendBirdStore } from "../../../app/SendBirdStore";
import { themeColor } from "../../../app/theme";
import { useSelectedTeen } from "../../../hooks/useSelectedTeen";
import SendBirdClient from "../../../lib/sendBirdClient";
import { truncate } from "../../../lib/stringUtilities";
import Badge from "../../Badge/Badge";
import Initial from "../../Initial/Initial";
import { CenteredLoading } from "../../Loading/Loading";

const ChannelList = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  gap: 20px;
`;
const Channel = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-radius: 12px;
  padding: 16px;
  &.selected {
    background-color: #fafafa;
  }
  :hover {
    background-color: #fafafaaa;
  }
`;
const InitialSection = styled.div``;
const TitleAndPreview = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 16px;
  margin-right: 16px;
`;
const TimeAndUnread = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const LastActiveTime = styled.div`
  opacity: 0.6;
`;
const UnreadCount = styled.div`
  color: white;
  background-color: ${themeColor("orange5")};
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  padding: 3px 7px 2px 7px;
`;
const ChannelHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ChannelName = styled.div`
  font-size: 18px;
  font-weight: 500;
`;
const LastMessagePreview = styled.div`
  opacity: 0.5;
  overflow-wrap: anywhere;

  // Deprecated, but necessary until safari supports overflow-wrap.
  word-break: break-word;
`;
const UserType = styled.div`
  margin-left: 16px;
  text-transform: capitalize;
`;
const NoMessagesText = styled.span`
  color: ${themeColor("darkGrayText8")};
  font-style: italic;
`;

const messagePreview = (message: BaseMessageInstance) => {
  if (message.messageType === "user") {
    return truncate((message as UserMessage).message, 300);
  }
  return "";
};

type ChannelLineProps = {
  channel: GroupChannel;
  otherChannelUser?: User;
  onSelect: () => void;
  selected: boolean;
};
const ChannelLine: React.FC<ChannelLineProps> = ({
  channel,
  onSelect,
  selected,
  otherChannelUser,
}) => {
  // Show "2:34 PM" if the last message was send within the last 24 hours,
  // or "12/28/2021" if older than 24 hours
  // or nothing if there are no messages yet.
  const anyMessages: boolean =
    channel.lastMessage != null && channel.lastMessage?.createdAt > 0;
  const lastMessageTime: boolean | DateTime = anyMessages
    ? DateTime.fromMillis(channel.lastMessage?.createdAt)
    : false;
  const timeFormat =
    lastMessageTime && lastMessageTime > DateTime.now().minus({ hours: 24 })
      ? DateTime.TIME_SIMPLE
      : DateTime.DATE_SHORT;
  const lastMessageTimeString = anyMessages
    ? lastMessageTime.toLocaleString(timeFormat)
    : "no activity yet";
  const unreadCount = channel.unreadMessageCount;
  return (
    <Channel
      className={selected ? "selected" : ""}
      onClick={onSelect}
      key={channel.url}
    >
      <InitialSection>
        <Initial>{channel.name}</Initial>
      </InitialSection>
      <TitleAndPreview>
        <ChannelHeader>
          <ChannelName>
            {otherChannelUser?.userKind === "teen"
              ? otherChannelUser.fullName
              : channel.name}
          </ChannelName>
          <UserType>
            {!otherChannelUser?.userKind ? null : (
              <Badge
                type="heavyGray"
                text={otherChannelUser?.userKind}
                size="medium"
              />
            )}
          </UserType>
        </ChannelHeader>
        <LastMessagePreview>
          {anyMessages && messagePreview(channel.lastMessage)}
        </LastMessagePreview>
      </TitleAndPreview>
      <TimeAndUnread>
        <LastActiveTime>
          {anyMessages ? (
            lastMessageTimeString
          ) : (
            <NoMessagesText>{lastMessageTimeString}</NoMessagesText>
          )}
        </LastActiveTime>
        {unreadCount == null || unreadCount <= 0 ? null : (
          <UnreadCount>{unreadCount}</UnreadCount>
        )}
      </TimeAndUnread>
    </Channel>
  );
};

export type ChannelBrowserWithDataProps = {
  onChannelSelect: (channel: GroupChannel) => void;
  channels: GroupChannel[];
  me: User;
  users: User[];
};
export const ChannelBrowserWithData: React.FC<ChannelBrowserWithDataProps> = ({
  channels,
  me,
  users,
  onChannelSelect,
}) => {
  const [selectedChannel, setSelectedChannel] = useState<
    GroupChannel | undefined
  >(channels[0]);
  return (
    <ChannelList>
      {channels.map((channel) => {
        const otherChannelMember = channel.members.find(
          (member) => member.userId !== me.sendbirdUser.externalUserId
        );
        const otherChannelUser = users.find(
          (user) =>
            user.sendbirdUser.externalUserId === otherChannelMember?.userId
        );

        return (
          <ChannelLine
            channel={channel}
            otherChannelUser={otherChannelUser}
            onSelect={() => {
              onChannelSelect(channel);
              setSelectedChannel(channel);
            }}
            selected={channel === selectedChannel}
            key={channel.url}
          />
        );
      })}
    </ChannelList>
  );
};

export type ChannelBrowserProps = {
  onChannelSelect: (channel: GroupChannel) => void;
};

const ChannelBrowser: React.FC<ChannelBrowserProps> = ({ onChannelSelect }) => {
  const api = useApi();
  const { data: { data: me } = {} } = api.useGetMe();
  const { teen: user } = useSelectedTeen();
  const { data: { data: users } = {} } = api.useGetUsers({});
  const { sendBird } = useSendBirdStore();
  const [channels, setChannels] = useState<GroupChannel[] | undefined>();

  useEffect(() => {
    if (user && sendBird) {
      (async () => {
        const chatClient = new SendBirdClient(sendBird);
        const foundChannels = await chatClient.findChannels({
          sendBirdUserIds: [user.sendbirdUser.externalUserId],
        });

        setChannels(foundChannels);
      })();
    }
  }, [sendBird, user]);

  if (!me || !sendBird || !user || !channels || !users) {
    return <CenteredLoading />;
  }

  return (
    <ChannelBrowserWithData
      channels={channels}
      onChannelSelect={onChannelSelect}
      me={me}
      users={users}
    />
  );
};

export default ChannelBrowser;
