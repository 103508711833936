import { useApi } from "../../api/apiContext";
import ParentWelcomePacketLayout from "../../layouts/ParentWelcomePacketLayout/ParentWelcomePacketLayout";
import {
  Container,
  FormGroup,
  FormRowGrid,
  FormSection,
  H1,
  SubmitButtonSection,
} from "./elements";
import { SearchSelectOrganization } from "../../api/queries/searchSelectOrganizations";
import { Formik } from "formik";
import TealButton from "../../components/buttons/TealButton/TealButton";
import { useMemo } from "react";
import SearchSelectField from "../../components/Forms/SearchSelectField/SearchSelectField";
import { trackEvent } from "client/amplitudeHelper";

export type DistrictSelectorWithDataProps = {
  searchSelectOrganizations: SearchSelectOrganization[];
  onSelectDistrict: (sso: SearchSelectOrganization) => void;
};
export const DistrictSelectorWithData: React.FC<DistrictSelectorWithDataProps> = ({
  searchSelectOrganizations,
  onSelectDistrict,
}) => {
  // Group searchSelectOrganizations by state
  const ssosByState = useMemo(
    () =>
      searchSelectOrganizations?.reduce(
        (acc: Record<string, SearchSelectOrganization[]>, org) => {
          acc[org.stateAbbreviation] = acc[org.stateAbbreviation] ?? [];
          acc[org.stateAbbreviation].push(org);
          return acc;
        },
        {}
      ),
    [searchSelectOrganizations]
  );

  const states = Object.keys(ssosByState).map((stateAbbreviation: string) => {
    return [stateAbbreviation, ssosByState[stateAbbreviation][0].stateName];
  });

  const districtsByName = searchSelectOrganizations.reduce(
    (map: { [id: string]: SearchSelectOrganization }, sso) => {
      map[sso.name] = sso;
      return map;
    },
    {}
  );

  const onSubmit = (values: { state: string; districtName: string }) => {
    const district = districtsByName[values.districtName];

    trackEvent("PWP:findDistrict", {
      state: values.state,
      district: district.id,
    });

    if (district) {
      onSelectDistrict(district);
    } else {
      console.error("District not found", district);
    }
  };

  return (
    <ParentWelcomePacketLayout>
      <Container>
        <FormSection>
          <H1>Select your district</H1>
          <Formik
            initialValues={{ state: "", districtName: "" }}
            onSubmit={onSubmit}
          >
            {({ values, isSubmitting, submitForm }) => {
              return (
                <FormGroup>
                  <FormRowGrid>
                    <SearchSelectField label="State" name="state">
                      {states.map(([abbreviation, name]) => {
                        return (
                          <option value={abbreviation} key={name}>
                            {name}
                          </option>
                        );
                      })}
                    </SearchSelectField>
                  </FormRowGrid>
                  <FormRowGrid>
                    {ssosByState[values.state] && (
                      <SearchSelectField label="District" name="districtName">
                        {ssosByState[values.state].map((sso) => {
                          return <option value={sso.name} key={sso.id} />;
                        })}
                      </SearchSelectField>
                    )}
                  </FormRowGrid>
                  {districtsByName[values.districtName] && (
                    <SubmitButtonSection>
                      <TealButton
                        type="submit"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        Next
                      </TealButton>
                    </SubmitButtonSection>
                  )}
                </FormGroup>
              );
            }}
          </Formik>
        </FormSection>
      </Container>
    </ParentWelcomePacketLayout>
  );
};

const DistrictSelector: React.FC<{}> = () => {
  // TODO: aggressively cache this
  const {
    data: { data: searchSelectOrganizations } = {},
  } = useApi().useGetSearchSelectOrganizations({});

  if (!searchSelectOrganizations) {
    return <ParentWelcomePacketLayout loading />;
  }

  const handleSelectDistrict = (sso: SearchSelectOrganization) => {
    trackEvent("PWP:foundDistrict", { organization: sso });
    window.location.href = `/school/${sso.id}/packet`;
  };
  return (
    <DistrictSelectorWithData
      searchSelectOrganizations={searchSelectOrganizations}
      onSelectDistrict={handleSelectDistrict}
    />
  );
};
export default DistrictSelector;
