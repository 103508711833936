import React from "react";
import styled, { keyframes } from "styled-components/macro";

const sizes = {
  mini: 0.2,
  small: 0.5,
  medium: 1,
  large: 2,
};

const animationKeyframe = keyframes`
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
`;

const LoadingDiv = styled.div<{
  sizeMultiple: number;
  widthOverride: number;
  color?: string;
}>`
  display: inline-block;
  position: relative;
  width: ${({ widthOverride }) => widthOverride}px;
  height: ${({ widthOverride }) => widthOverride}px;
  transform: scale(${(props) => props.sizeMultiple});

  & div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: ${(props) => props.color ?? props.theme.colors.teal};
    border-radius: 50%;
    animation: ${animationKeyframe} 1.2s linear infinite;
  }
  & div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  & div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  & div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  & div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  & div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  & div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  & div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  & div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  & div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  & div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  & div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  & div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
`;

export type LoadingProps = {
  size: keyof typeof sizes;
  color?: string;
  widthOverride?: number;
};
const Loading: React.FC<LoadingProps> = ({
  size,
  color,
  widthOverride = 80,
}) => {
  return (
    <LoadingDiv
      sizeMultiple={sizes[size]}
      color={color}
      widthOverride={widthOverride}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoadingDiv>
  );
};

const CenteredLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: inherit;
`;
// A little helper for the common use case of "I have a big box that'll be full
// of content, but until it is I want a loading icon in the middle of it."
export const CenteredLoading: React.FC<{ size?: keyof typeof sizes }> = ({
  size,
}) => {
  return (
    <CenteredLoadingContainer>
      <Loading size={size ?? "large"} />
    </CenteredLoadingContainer>
  );
};

export default Loading;
