import styled from "styled-components/macro";
import { themeColor, themeFont, themeMinWidth } from "../../../app/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 32px 16px 48px 16px;

  ${themeMinWidth("small")} {
    padding-left: 0;
    padding-right: 0;
    padding: 50px 0 60px 0;
  }
`;
export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${themeMinWidth("small")} {
    align-items: center;
  }
`;
export const Fields = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;

  ${themeMinWidth("small")} {
    width: 430px;
  }
`;
export const Field = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;
export const Label = styled.label`
  font-size: 14px;
`;
export const Input = styled.input`
  margin-top: 8px;
  border: 1px solid ${themeColor("borderGray6")};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  font-family: ${themeFont("poppins")};
  font-size: 14px;
  ::placeholder {
    color: ${themeColor("darkGrayText8")};
    opacity: 1;
  }
`;

export const Select = styled.select`
  margin-top: 8px;
  border: 1px solid ${themeColor("borderGray6")};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  font-family: ${themeFont("poppins")};
  font-size: 14px;
  ::placeholder {
    color: ${themeColor("darkGrayText8")};
    opacity: 1;
  }
`;

export const DateInput = styled(Input)`
  // This is a workaround because it's currently impossible to style the
  // placeholder text on a native date control, and it defaults to dark black.
  &.empty {
    color: ${themeColor("darkGrayText8")};
  }
`;
export const Action = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
`;

export const Error = styled.div`
  color: ${themeColor("red")};
`;
