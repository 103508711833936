// This import *must* come first so that any errors in subsequent imports get reported.
import { Bugsnag } from "./ErrorReporting";

import React from "react";
import App from "./App";
import RootErrorDisplay from "../components/RootErrorDisplay/RootErrorDisplay";

const bugsnagReactPlugin = Bugsnag.getPlugin("react");

const AppWithErrorHandling: React.FC = () => {
  if (bugsnagReactPlugin) {
    const ErrorBoundary = bugsnagReactPlugin.createErrorBoundary(React);

    return (
      <ErrorBoundary FallbackComponent={RootErrorDisplay}>
        <App />
      </ErrorBoundary>
    );

    // bugsnagReactPlugin should never be null, but if it is, still render the page.
  } else {
    // No good way to report that our error monitoring isn't working, so let's
    // just scream into the void.
    console.error("Error monitoring failed to load.");
    return <App />;
  }
};

export default AppWithErrorHandling;
