import React from "react";
import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";
import BaseModal, { BaseModalProps } from "../BaseModal/BaseModal";
import SurveyImage from "../../../images/example-screener-question.png";
import OrangeButton from "../../buttons/OrangeButton/OrangeButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px 60px 20px;
  ${themeMinWidth("small")} {
    padding: 50px 48px 60px 48px;
  }
  max-width: 436px;
`;
const H1 = styled.h1`
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;
const P = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
`;
const SurveyImg = styled.img`
  display: flex;
  width: 80%;
  border: 1px solid ${themeColor("borderGray6")};
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const Button = styled.div`
  margin-top: 20px;
`;
export type HowWeGetScoresModalProps = {} & BaseModalProps;
const HowWeGetScoresModal: React.FC<HowWeGetScoresModalProps> = (
  baseModalProps
) => {
  return (
    <BaseModal {...baseModalProps}>
      <Container>
        <H1>Clinical Scores</H1>
        <P>
          Clinical progress scores are derrived from teen self-assessments.
          Teens are periodically asked a series of questions like:
        </P>
        <SurveyImg src={SurveyImage} />
        <P>
          These questions come from clinically-validated questionnaires
          including the GAD-7, PHQ-9, and WHO-5.
        </P>
        <Button>
          <OrangeButton onClick={baseModalProps.closeModal}>Ok</OrangeButton>
        </Button>
      </Container>
    </BaseModal>
  );
};

export default HowWeGetScoresModal;
