import "../fonts.css";

import { createGlobalStyle } from "styled-components/macro";
import { themeColor, themeFont } from "./theme";

// Let's be suuuper minimal with these global styles.
const DefaultStyles = createGlobalStyle`
  html, body {
    padding: 0;
    margin: 0;
  }
  body {
    font-family: ${themeFont("poppins")};
    color: ${themeColor("blackText")};
  }
  a {
    text-decoration: none;
    :visited {
      color: inherit;
    }
  }
`;

export default DefaultStyles;
