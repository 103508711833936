import { SortingState } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { OrganizationMemberV1 } from "../../../api/queries/organizationMembers";
import { themeColor, themeMinWidth } from "../../../app/theme";
import ReferralButton from "../../../components/buttons/ReferralButton/ReferralButton";
import AddToQueueModal from "../../../components/Modals/AddToQueueModal/AddToQueueModal";
import {
  ReferralModalInsert,
  useReferralModal,
} from "../../../components/Modals/ReferAStudentModal/ReferAStudentModal";
import SchoolCounselorLayout from "../../../layouts/SchoolCounselorLayout/SchoolCounselorLayout";
import calendarImage from "../../../images/calendar.png";
import { useApi } from "../../../api/apiContext";
import { useHasOrganizationFeature } from "../../../hooks/useHasOrganizationFeature";
import { Referral } from "../../../api/queries/referrals";
import NeedsIntakeTable from "./NeedsIntakeTable";
import NeedsInsuranceBanner from "./NeedsInsuranceBanner";
import NeedsInsuranceTable from "./NeedsInsuranceTable";
import QueuedTable from "./QueuedTable";
import { trackEvent } from "client/amplitudeHelper";

const sidePadding = 32;
const Container = styled.div`
  width: 100%;
  padding: 32px 0 80px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${themeMinWidth("small")} {
    padding: 50px;
    padding-bottom: 80px;
  }
`;
const ButtonBar = styled.div`
  display: flex;
  gap: 20px;
  margin-left: ${sidePadding}px;
  margin-right: ${sidePadding}px;
  flex-direction: column;
  ${themeMinWidth("small")} {
    margin-left: 0;
    margin-right: 0;
    flex-direction: row;
  }
`;
const TableSection = styled.div`
  margin-top: 24px;
`;
const H1 = styled.h1`
  color: black;
  font-weight: 400;
  font-size: 22px;
  margin-left: ${sidePadding}px;
  margin-right: ${sidePadding}px;

  ${themeMinWidth("small")} {
    margin-left: 0;
    margin-right: 0;
  }
`;
const BothEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start;
  margin-top: 40px;
  padding: 0 16px;
  ${themeMinWidth("small")} {
    padding: 0;
    margin-top: 180px;
  }
`;
const CalendarImage = styled.img`
  width: 269px;
  height: 124px;
`;
const EmptyHeader = styled.div`
  color: black;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  margin-top: 19px;
`;
const EmptySubtitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  margin-top: 8px;
  color: ${themeColor("darkGrayText7")};
`;
const EmptyButtonBar = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 40px;
`;
const NeedsInsuranceBannerContainer = styled.div`
  padding-bottom: 12px;
  margin-top: -10px;
  margin-left: ${sidePadding}px;
  margin-right: ${sidePadding}px;

  ${themeMinWidth("small")} {
    margin-left: 0;
    margin-right: 0;
  }
`;
const NeedsIntakeDescription = styled.div`
  margin-bottom: 16px;
  border: 2px solid ${themeColor("borderGray7")};
  border-radius: 12px;
  padding: 10px 30px 10px 20px;
  margin-left: ${sidePadding}px;
  margin-right: ${sidePadding}px;
  color: ${themeColor("darkGrayText5")};
  ${themeMinWidth("small")} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export type SchoolHomePageWithDataProps = {
  onsiteReferralsLinkActive?: boolean;
  myOrgKey: string;
  needsIntakeReferrals?: Referral[];
  needsIntakePageIndex: number;
  needsIntakeMaxPageIndex: number;
  setNeedsIntakePageIndex: (pageIndex: number) => void;
  setNeedsIntakePageSort: (sort: SortingState) => void;
  needsInsuranceOrganizationMembers?: OrganizationMemberV1[];
  needsInsurancePageIndex: number;
  needsInsuranceMaxPageIndex: number;
  setNeedsInsurancePageIndex: (pageIndex: number) => void;
  setNeedsInsurancePageSort: (sort: SortingState[0]) => void;
  queuedOrganizationMembers: OrganizationMemberV1[];
  queuedPageIndex: number;
  queuedMaxPageIndex: number;
  setQueuedPageIndex: (pageIndex: number) => void;
  setQueuedPageSort: (sort: SortingState[0]) => void;
  removeFromQueue: (referralId: string) => void;
  staffUserExternalId: string;
};
export const SchoolHomePageWithData: React.FC<SchoolHomePageWithDataProps> = ({
  onsiteReferralsLinkActive,
  myOrgKey,
  needsIntakeReferrals,
  needsIntakePageIndex,
  needsIntakeMaxPageIndex,
  setNeedsIntakePageIndex,
  setNeedsIntakePageSort,
  needsInsuranceOrganizationMembers,
  needsInsurancePageIndex,
  needsInsuranceMaxPageIndex,
  setNeedsInsurancePageIndex,
  setNeedsInsurancePageSort,
  queuedOrganizationMembers,
  queuedPageIndex,
  queuedMaxPageIndex,
  setQueuedPageIndex,
  setQueuedPageSort,
  removeFromQueue,
  staffUserExternalId,
}) => {
  const allEmpty =
    queuedOrganizationMembers.length === 0 &&
    needsIntakeReferrals?.length === 0 &&
    needsInsuranceOrganizationMembers?.length === 0;

  const referralModalContext = useReferralModal();

  const [addToQueueModalShowing, setAddToQueueModalShowing] = useState(false);

  const referButton = (
    <ReferralButton
      title="Refer a Student"
      subtitle="Set an appointment or let a parent schedule one"
      icon="seat"
      onClick={() => {
        referralModalContext.showModal(null);
        trackEvent("SCD:showReferStudentMethodChoiceModal");
      }}
    />
  );
  const queueButton = (
    <ReferralButton
      title="Add to queue"
      subtitle="Put a student in your queue to refer later"
      icon="userGhost"
      onClick={() => {
        setAddToQueueModalShowing(true);
        trackEvent("SCD:showAddToQueueModal");
      }}
    />
  );
  const onsiteButton = (
    <ReferralButton
      title="Onsite Referral"
      subtitle="Referrer a student for onsite services"
      icon="house"
      onClick={() => {
        // Doing this because of the way build process handles env vars.
        const urlString = process.env.REACT_APP_ONSITE_REFERRAL_URL;
        if (!urlString) {
          console.error("REACT_APP_ONSITE_REFERRAL_URL is not set");
          trackEvent("SCD:ERROR:navigateToOnsiteReferralForm", {
            missing_url: "REACT_APP_ONSITE_REFERRAL_URL",
          });
        } else {
          trackEvent("SCD:navigateToOnsiteReferralForm");
          const url = new URL(urlString);
          url.search = new URLSearchParams({
            language: "en_US",
            did: myOrgKey,
            rsid: staffUserExternalId,
          }).toString();
          window.open(url.toString(), "_blank");
        }
      }}
    />
  );

  return (
    <SchoolCounselorLayout>
      <ReferralModalInsert referralModalContext={referralModalContext} />
      {addToQueueModalShowing ? (
        <AddToQueueModal onClose={() => setAddToQueueModalShowing(false)} />
      ) : null}
      <Container>
        {allEmpty ? (
          <BothEmptyContainer>
            <CalendarImage src={calendarImage} />
            <EmptyHeader></EmptyHeader>
            <EmptySubtitle>
              Refer a student to Daybreak or add one to the queue to get started
            </EmptySubtitle>
            <EmptyButtonBar>
              {referButton}
              {queueButton}
              {onsiteReferralsLinkActive && onsiteButton}
            </EmptyButtonBar>
          </BothEmptyContainer>
        ) : (
          <>
            <ButtonBar>
              {referButton}
              {queueButton}
              {onsiteReferralsLinkActive && onsiteButton}
            </ButtonBar>
            {needsInsuranceOrganizationMembers &&
            needsInsuranceOrganizationMembers.length > 0 ? (
              <TableSection>
                <H1>Missing Information</H1>
                <NeedsInsuranceBannerContainer>
                  <NeedsInsuranceBanner />
                </NeedsInsuranceBannerContainer>
                <NeedsInsuranceTable
                  data={needsInsuranceOrganizationMembers}
                  pageIndex={needsInsurancePageIndex}
                  maxPageIndex={needsInsuranceMaxPageIndex}
                  setPageIndex={setNeedsInsurancePageIndex}
                  setPageSort={setNeedsInsurancePageSort}
                />
              </TableSection>
            ) : null}
            {needsIntakeReferrals && needsIntakeReferrals.length > 0 ? (
              <TableSection>
                <H1>Needs Intake Scheduled</H1>
                <NeedsIntakeDescription>
                  These referrals are waiting for a parent to schedule an
                  appointment using the link already emailed to them. Please use
                  the referral-specific link to schedule an appointment, or send
                  the parent a reminder email.
                </NeedsIntakeDescription>
                <NeedsIntakeTable
                  data={needsIntakeReferrals}
                  pageIndex={needsIntakePageIndex}
                  maxPageIndex={needsIntakeMaxPageIndex}
                  setPageIndex={setNeedsIntakePageIndex}
                  setPageSort={setNeedsIntakePageSort}
                />
              </TableSection>
            ) : null}
            <TableSection>
              <H1>In queue</H1>
              <QueuedTable
                data={queuedOrganizationMembers}
                pageIndex={queuedPageIndex}
                maxPageIndex={queuedMaxPageIndex}
                setPageIndex={setQueuedPageIndex}
                setPageSort={setQueuedPageSort}
                referStudent={(organizationMemberId) => {
                  referralModalContext.showModal(organizationMemberId);
                  trackEvent("SCD:showReferStudentModal", {
                    organizationMemberId,
                  });
                }}
                removeFromQueue={removeFromQueue}
                openAddToQueueModal={() => setAddToQueueModalShowing(true)}
              />
            </TableSection>
          </>
        )}
      </Container>
    </SchoolCounselorLayout>
  );
};

const defaultSort = { id: "referred_at", desc: true };
const sortToOrderParam = (sort: SortingState[0] | undefined = defaultSort) => {
  return sort.id + "_" + (sort.desc ? "desc" : "asc");
};
const sortToV4SortParam = (sorts: SortingState | undefined = [defaultSort]) => {
  if (sorts.length === 0) {
    sorts = [defaultSort];
  }
  return sorts.map((sort) => (sort.desc ? "" : "-") + sort.id);
};
export type SchoolHomePageProps = {};
const SchoolHomePage: React.FC<SchoolHomePageProps> = () => {
  useEffect(() => {
    trackEvent("SCD:SchoolHomePage");
  }, []);

  const [queuedPageIndex, setQueuedPageIndex] = useState(0);
  const [queuedPageSort, setQueuedPageSort] = useState<SortingState[0]>(
    defaultSort
  );

  const [needsInsurancePageIndex, setNeedsInsurancePageIndex] = useState(0);
  const [needsInsurancePageSort, setNeedsInsurancePageSort] = useState<
    SortingState[0]
  >(defaultSort);

  const [needsIntakePageIndex, setNeedsIntakePageIndex] = useState(0);
  const [needsIntakePageSort, setNeedsIntakePageSort] = useState<SortingState>([
    {
      id: "referred_at",
      desc: true,
    },
  ]);

  const api = useApi();

  const { isLoading: meLoading, data: meData } = api.useGetMe();

  const {
    isLoading: myOrgLoading,
    data: { data: myOrg } = {},
  } = api.useGetMyOrg();

  const { active: hasInsuranceCollection } = useHasOrganizationFeature(
    "ssd_insurance_collection"
  );
  const {
    active: consentToContactFeatureActive,
    loading: loadingConsentToContactFeature,
  } = useHasOrganizationFeature("consent_to_contact_flow");
  const {
    active: onsiteReferralsLinkActive,
    loading: onsiteReferralsLinkLoading,
  } = useHasOrganizationFeature("organization_onsite_referral_form_link");

  // Get all referrals that need intakes.  This is generally intakes that are
  // supposed to be scheduled by parents, but have not yet been scheduled.
  const { data: needsIntakeReferralsData } = api.useGetReferrals({
    page: needsIntakePageIndex + 1,
    perPage: 4,
    sort: sortToV4SortParam(needsIntakePageSort),
    filter: {
      needs_scheduling: true,
    },
    options: {
      useErrorBoundary: true,
      keepPreviousData: true,
      enabled:
        !loadingConsentToContactFeature && !consentToContactFeatureActive,
    },
  });

  // Get all org members that need insurance info
  const {
    data: needsInsuranceOrganizationMembersData,
  } = api.useGetOrganizationMembers({
    page: needsInsurancePageIndex + 1,
    perPage: 4,
    orderBy: sortToOrderParam(needsInsurancePageSort),
    needInsuranceInfo: true,
    options: {
      useErrorBoundary: true,
      keepPreviousData: true,
      enabled: hasInsuranceCollection,
    },
  });

  const { data: queuedOrganizationMembersData } = api.useGetOrganizationMembers(
    {
      page: queuedPageIndex + 1,
      perPage: 4,
      orderBy: sortToOrderParam(queuedPageSort),
      referral_status: ["referral_queued", "referral_in_progress"],
      options: { useErrorBoundary: true, keepPreviousData: true },
    }
  );

  const { mutate: removeFromQueue } = api.useDeleteReferrals();

  if (
    loadingConsentToContactFeature ||
    onsiteReferralsLinkLoading ||
    myOrgLoading ||
    meLoading ||
    (!needsIntakeReferralsData && !consentToContactFeatureActive) ||
    !queuedOrganizationMembersData ||
    (!needsInsuranceOrganizationMembersData && hasInsuranceCollection)
  ) {
    return <SchoolCounselorLayout loading />;
  }
  const queuedOrganizationMembers = queuedOrganizationMembersData.data;
  const needsInsuranceOrganizationMembers =
    needsInsuranceOrganizationMembersData?.data;
  const needsIntakeReferrals = needsIntakeReferralsData?.data;

  return (
    <SchoolHomePageWithData
      onsiteReferralsLinkActive={onsiteReferralsLinkActive}
      myOrgKey={myOrg?.apiKey ?? ""}
      setNeedsIntakePageSort={setNeedsIntakePageSort}
      setNeedsIntakePageIndex={setNeedsIntakePageIndex}
      needsIntakeReferrals={needsIntakeReferrals}
      needsIntakePageIndex={needsIntakePageIndex}
      needsIntakeMaxPageIndex={
        needsIntakeReferralsData
          ? needsIntakeReferralsData.meta.pagination.lastPage - 1
          : 0
      }
      needsInsuranceOrganizationMembers={needsInsuranceOrganizationMembers}
      needsInsurancePageIndex={needsInsurancePageIndex}
      needsInsuranceMaxPageIndex={
        (needsInsuranceOrganizationMembersData?.meta?.maxPage ?? 1) - 1
      }
      setNeedsInsurancePageSort={setNeedsInsurancePageSort}
      setNeedsInsurancePageIndex={setNeedsInsurancePageIndex}
      queuedOrganizationMembers={queuedOrganizationMembers}
      queuedPageIndex={queuedPageIndex}
      queuedMaxPageIndex={queuedOrganizationMembersData.meta.maxPage - 1}
      setQueuedPageSort={setQueuedPageSort}
      setQueuedPageIndex={setQueuedPageIndex}
      removeFromQueue={(referralId: string) => {
        removeFromQueue({ referralId });
        trackEvent("SCD:removeFromWatchQueue", {
          removedReferralId: referralId,
        });
      }}
      staffUserExternalId={meData?.data?.id || ""}
    />
  );
};

export default SchoolHomePage;
