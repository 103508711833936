// Padding applied to the left of the first cell in a row and the right of the

import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../app/theme";

export const TableViewPort = styled.div`
  overflow-x: scroll;
  padding-bottom: 1px;
  ${themeMinWidth("small")} {
    overflow-x: visible;
  }
`;

// last cell in a row (headers included).
const firstLastPadding = "18px";
export const Container = styled.div``;
export const TableViewPortAndShadow = styled.div`
  position: relative;
  overflow-x: hidden;
  // padding-bottom: 1px;
  ${themeMinWidth("small")} {
    display: block;
  }
`;
export const TableShadowLine = styled.div`
  position: absolute;
  right: -5px;
  height: 100%;
  background-color: black;
  width: 5px;
  box-shadow: -1px 0 16px #0008;
  ${themeMinWidth("small")} {
    display: none;
  }
`;
export const TableElement = styled.table`
  border-collapse: collapse;
  min-width: 100%;

  ${themeMinWidth("small")} {
    display: table;
    width: 100%;
  }
`;
export const THead = styled.thead`
  background-color: #f5f5f4;
  text-align: left;
  box-shadow: 0px 1px 0px #e3e8e8;
`;
export const THContent = styled.div`
  padding-top: 8.5px;
  padding-bottom: 6.5px;
  display: flex;
  align-items: center;
  &.sort-inactive {
    color: #777c7e;
  }
  &.sort-active {
    color: black;
  }
  &.sortable {
    cursor: pointer;
  }
`;
export const TH = styled.th`
  font-size: 14px;
  font-weight: normal;
  .icon {
    margin-left: 4px;
  }
  :first-child {
    ${THContent} {
      padding-left: ${firstLastPadding};
    }
  }
  :last-child {
    ${THContent} {
      padding-right: ${firstLastPadding};
    }
  }
`;
export const TR = styled.tr``;
export const TD = styled.td`
  padding: 12px;
  :first-child {
    padding-left: ${firstLastPadding};
  }
  :last-child {
    padding-right: ${firstLastPadding};
  }
`;
export const TBody = styled.tbody<{ skipLastBorder?: boolean }>`
  ${TR} {
    height: 64px;
    border-bottom: 1px solid ${themeColor("borderGray7")};
    ${(props) =>
      props.skipLastBorder &&
      `
      :last-child {
        border-bottom: none;
      }
    `}
  }
`;
export const EmptyContent = styled.div``;
export const PaginationContainer = styled.div`
  margin-top: 27px;
  display: flex;
  justify-content: center;
`;
export const LoadingTR = styled.tr``;
export const LoadingTD = styled.td`
  padding: 40px;
  column-span: all;
`;
