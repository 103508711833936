import { useFormik } from "formik";
import React, { Suspense, useEffect } from "react";
import * as Yup from "yup";
import { useDaybreakTranslation } from "../../../hooks/useDaybreakTranslation";
import { Trans } from "react-i18next";
import en from "./DirectReferralFormPage.en.json";
import es from "./DirectReferralFormPage.es.json";
import LausdSchoolList from "./schoolLists/lausdSchoolList.json";
import {
  Container,
  Field,
  Fields,
  Form,
  Input,
  Title,
  Error,
  Action,
  SubHeader,
  FormRowGrid,
  Title2,
  LoadingTitle,
  Instruction,
  Question,
  CheckboxGroup,
  StyledRadioButtonGroup,
  QuestionsHeader,
  SelectDropdown,
  StyledDatePicker,
  StyledInputMask,
} from "./elements";
import TealButton from "components/buttons/TealButton/TealButton";
import CheckboxField from "components/Forms/CheckboxField/CheckboxField";
import RadioButtonGroup from "components/Forms/RadioButtonGroup/RadioButtonGroup";
import { useApi } from "api/apiContext";
import { PostDirectReferralVariables } from "api/queries/directReferral";
import { CenteredLoading } from "components/Loading/Loading";
import { useHistory } from "react-router-dom";
import { differenceInYears } from "date-fns";
import DirectReferralHeader from "../DirectReferralHeader/DirectReferralHeader";
import toast from "react-hot-toast";
import { trackEvent, identifyAnonymousUser } from "client/amplitudeHelper";

export type DirectReferralFormWithDataProps = {
  directReferral: (variables: PostDirectReferralVariables) => Promise<any>;
  submitting: boolean;
};

export const DirectReferralFormWithData: React.FC<DirectReferralFormWithDataProps> = ({
  directReferral,
  submitting,
}) => {
  const { t, i18n: tContext, ready } = useDaybreakTranslation(
    "directReferralFormPage",
    {
      en,
      es,
    }
  );
  const history = useHistory();

  const schools = LausdSchoolList;
  const birthGenders = [
    {
      value: "Male",
      label: t("patient_male"),
    },
    {
      value: "Female",
      label: t("patient_female"),
    },
    {
      value: "Other",
      label: t("patient_other"),
    },
  ];
  const relationshipsToPatient = [
    {
      value: "Mother",
      label: t("relationship_mother"),
    },
    {
      value: "Father",
      label: t("relationship_father"),
    },
    {
      value: "Guardian",
      label: t("relationship_guardian"),
    },
  ];

  const languagePreferences = [
    {
      value: "English",
      label: t("primary_language_english"),
    },
    { value: "Spanish", label: t("primary_language_spanish") },
    { value: "Mandarin", label: t("primary_language_mandarin") },
    { value: "Cantonese", label: t("primary_language_cantonese") },
    { value: "Vietnamese", label: t("primary_language_vietnamese") },
    { value: "Other", label: t("primary_language_other") },
  ];

  useEffect(() => {
    if (tContext.language !== "en") {
      tContext.changeLanguage("en");
    }
  }, [tContext]);

  const phoneRegExp = /^(\(\d{3}\) \d{3}-\d{4}|\d{3}-\d{3}-\d{4})$/;
  const formik = useFormik({
    initialValues: {
      schoolName: "",
      patientIsSelf: "",
      patientFirstName: "",
      patientLastName: "",
      patientDOB: null,
      patientBirthGender: "",
      patientSchoolEmail: "",
      patientPersonalEmail: "",
      patientStudentId: "",
      patientPhoneNum: "",
      patientLanguagePreference: "",
      parentLanguagePreference: "",
      parentFirstName: "",
      parentLastName: "",
      parentPhoneNum: "",
      parentEmail: "",
      relationshipToPatient: "",
      parentContactConsent: false,
    },
    validationSchema: Yup.object().shape({
      schoolName: Yup.string().required("school_name_required_validation"),
      patientIsSelf: Yup.string().required("is_self_required_validation"),
      patientFirstName: Yup.string().required(
        "patient_first_name_required_validation"
      ),
      patientLastName: Yup.string().required(
        "patient_last_name_required_validation"
      ),
      patientDOB: Yup.date()
        .nullable()
        .required("patient_dob_required_validation")
        .test("min-age", "patient_min_age_validation", (value) => {
          if (!value) return false;
          const today = new Date();
          const birthDate = new Date(value);
          const age = differenceInYears(today, birthDate);
          return age >= 4;
        }),
      patientBirthGender: Yup.string().required(
        "patient_birth_gender_required_validation"
      ),
      patientSchoolEmail: Yup.string()
        .required("patient_school_email_required_validation")
        .email("patient_school_email_format_validation"),
      patientPersonalEmail: Yup.string().email(
        "patient_personal_email_format_validation"
      ),
      patientStudentId: Yup.string()
        .max(100, "patient_student_id_max_validation")
        .matches(/^[^@]*$/, "patient_student_id_format_validation"),
      patientPhoneNum: Yup.string().matches(phoneRegExp, {
        message: "phone_number_format_validation",
        excludeEmptyString: true,
      }),
      parentFirstName: Yup.string().required(
        "parent_first_name_required_validation"
      ),
      parentLastName: Yup.string().required(
        "parent_last_name_required_validation"
      ),
      parentPhoneNum: Yup.string()
        .required("parent_phone_num_required_validation")
        .matches(phoneRegExp, {
          message: "phone_number_format_validation",
          excludeEmptyString: true,
        }),
      parentEmail: Yup.string()
        .required("parent_email_required_validation")
        .email("parent_email_format_validation"),
      relationshipToPatient: Yup.string().required(
        "relationship_required_validation"
      ),
      parentContactConsent: Yup.boolean().oneOf(
        [true],
        "parent_contact_consent_required_validation"
      ),
    }),

    onSubmit: async ({
      schoolName,
      patientIsSelf,
      patientFirstName,
      patientLastName,
      patientDOB,
      patientBirthGender,
      patientSchoolEmail,
      patientPersonalEmail,
      patientStudentId,
      patientPhoneNum,
      patientLanguagePreference,
      parentLanguagePreference,
      parentFirstName,
      parentLastName,
      parentPhoneNum,
      parentEmail,
      relationshipToPatient,
      parentContactConsent,
    }) => {
      try {
        identifyAnonymousUser({
          firstName: parentFirstName,
          lastName: parentLastName,
        });
        trackEvent("DR:DirectReferralForm:Submit");
        const response = await directReferral({
          schoolName,
          patientIsSelf,
          patientFirstName,
          patientLastName,
          patientDOB: patientDOB,
          patientBirthGender,
          patientSchoolEmail,
          patientPersonalEmail,
          patientStudentId,
          patientPhoneNum,
          patientLanguagePreference,
          parentLanguagePreference,
          parentFirstName,
          parentLastName,
          parentPhoneNum,
          parentEmail,
          relationshipToPatient,
          parentContactConsent,
          organizationSlug: "lausd",
        });
        trackEvent("DR:DirectReferralForm:Submit:Success");
        if (patientIsSelf === "yes") {
          trackEvent("DR:DirectReferralForm:Submit:Success:Self");
          history.push("/direct-care/lausd/self");
        } else {
          trackEvent("DR:DirectReferralForm:Submit:Success:ParentOrGuardian");
          history.push(response.data.url);
        }
      } catch {
        trackEvent("DR:DirectReferralForm:Submit:Error");
        toast.error(t("generic_error"));
      }
    },
  });

  return !ready ? (
    <Container style={{ marginTop: "45vh" }}>
      <CenteredLoading></CenteredLoading>
    </Container>
  ) : (
    <>
      <DirectReferralHeader />
      <Container>
        <Title>
          <span>{t("title")}</span>
        </Title>
        <Title2>
          <span>{t("title2")}</span>
        </Title2>
        <Instruction>
          <Trans components={{ p: <p />, b: <b />, a: <a /> }}>
            {t("instructions")}
          </Trans>
        </Instruction>

        <Form onSubmit={formik.handleSubmit}>
          <Fields>
            <Field>
              <Question>{t("school_inquiry")}</Question>
              <SelectDropdown
                options={schools}
                id="schoolName"
                value={schools.find(
                  (school) => school.value === formik.values.schoolName
                )}
                placeholder={t("school_inquiry_placeholder")}
                onChange={(selectedOption) =>
                  formik.setFieldValue("schoolName", selectedOption.value)
                }
              />
              <Error>
                {formik.errors.schoolName && formik.touched.schoolName && (
                  <Error>{t(formik.errors.schoolName)}</Error>
                )}
              </Error>
            </Field>
            <Field>
              <Question>{t("is_self_inquiry")}</Question>
              <StyledRadioButtonGroup>
                <RadioButtonGroup
                  formik={formik}
                  commonRadioButtonProps={{ name: "patientIsSelf" }}
                  radioButtonProps={[
                    { value: "no", label: t("is_self_no") },
                    { value: "yes", label: t("is_self_yes") },
                  ]}
                />
                <Error>
                  {formik.errors.patientIsSelf &&
                    formik.touched.patientIsSelf && (
                      <Error>{t(formik.errors.patientIsSelf)}</Error>
                    )}
                </Error>
              </StyledRadioButtonGroup>
            </Field>

            <QuestionsHeader>{t("patient_info_header")}</QuestionsHeader>
            <SubHeader>{t("patient_info_sub_header")}</SubHeader>
            <FormRowGrid>
              <Field>
                <Input
                  id="patientFirstName"
                  placeholder={String(
                    t("patient_first_name_inquiry_placeholder")
                  )}
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.patientFirstName}
                />
                <Error>
                  {formik.errors.patientFirstName &&
                    formik.touched.patientFirstName && (
                      <Error>{t(formik.errors.patientFirstName)}</Error>
                    )}
                </Error>
              </Field>
              <Field>
                <Input
                  id="patientLastName"
                  placeholder={String(
                    t("patient_last_name_inquiry_placeholder")
                  )}
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.patientLastName}
                />
                <Error>
                  {formik.errors.patientLastName &&
                    formik.touched.patientLastName && (
                      <Error>{t(formik.errors.patientLastName)}</Error>
                    )}
                </Error>
              </Field>
            </FormRowGrid>

            <FormRowGrid>
              <Field>
                <StyledDatePicker
                  id="patientDOB"
                  customInput={
                    <StyledInputMask type="text" mask="99/99/9999" />
                  }
                  placeholderText={String(t("patient_dob_inquiry_placeholder"))}
                  selected={formik.values.patientDOB}
                  dateFormat="Pp"
                  onChange={(date) => {
                    formik.setFieldValue("patientDOB", date);
                  }}
                />
                <Error>
                  {formik.errors.patientDOB && formik.touched.patientDOB && (
                    <Error>{t(formik.errors.patientDOB)}</Error>
                  )}
                </Error>
              </Field>
              <Field>
                <SelectDropdown
                  options={birthGenders}
                  id="patientBirthGender"
                  value={birthGenders.find(
                    (gender) =>
                      gender.value === formik.values.patientBirthGender
                  )}
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      "patientBirthGender",
                      selectedOption.value
                    )
                  }
                  placeholder={t("patient_gender_inquiry_placeholder")}
                />
                <Error>
                  {formik.errors.patientBirthGender &&
                    formik.touched.patientBirthGender && (
                      <Error>{t(formik.errors.patientBirthGender)}</Error>
                    )}
                </Error>
              </Field>
            </FormRowGrid>
            <FormRowGrid>
              <Field>
                <Input
                  id="patientSchoolEmail"
                  placeholder={String(
                    t("patient_school_email_inquiry_placeholder")
                  )}
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.patientSchoolEmail}
                />
                <Error>
                  {formik.errors.patientSchoolEmail &&
                    formik.touched.patientSchoolEmail && (
                      <Error>{t(formik.errors.patientSchoolEmail)}</Error>
                    )}
                </Error>
              </Field>
              <Field>
                <Input
                  id="patientPersonalEmail"
                  placeholder={String(
                    t("patient_personal_email_inquiry_placeholder")
                  )}
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.patientPersonalEmail}
                />
                <Error>
                  {formik.errors.patientPersonalEmail &&
                    formik.touched.patientPersonalEmail && (
                      <Error>{t(formik.errors.patientPersonalEmail)}</Error>
                    )}
                </Error>
              </Field>
            </FormRowGrid>

            <FormRowGrid>
              <Field>
                <Input
                  id="patientStudentId"
                  type="text"
                  placeholder={String(
                    t("patient_school_id_number_inquiry_placeholder")
                  )}
                  onChange={formik.handleChange}
                  value={formik.values.patientStudentId}
                  autoComplete="off"
                />
              </Field>
              <Field>
                <StyledInputMask
                  id="patientPhoneNum"
                  mask="999-999-9999"
                  type="tel"
                  placeholder={String(
                    t("patient_phone_number_inquiry_placeholder")
                  )}
                  onChange={formik.handleChange}
                  value={formik.values.patientPhoneNum}
                  autoComplete="off"
                />
                <Error>
                  {formik.errors.patientPhoneNum &&
                    formik.touched.patientPhoneNum && (
                      <Error>{t(formik.errors.patientPhoneNum)}</Error>
                    )}
                </Error>
              </Field>
            </FormRowGrid>
            <FormRowGrid>
              <Field>
                <SelectDropdown
                  options={languagePreferences}
                  id="patientLanguagePreference"
                  value={languagePreferences.find(
                    (langaugePreference) =>
                      langaugePreference.value ===
                      formik.values.patientLanguagePreference
                  )}
                  placeholder={t(
                    "patient_primary_language_inquiry_placeholder"
                  )}
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      "patientLanguagePreference",
                      selectedOption.value
                    )
                  }
                />
              </Field>
            </FormRowGrid>
            <QuestionsHeader>{t("parent_info_header")}</QuestionsHeader>
            <SubHeader>{t("parent_info_sub_header")}</SubHeader>
            <FormRowGrid>
              <Field>
                <Input
                  id="parentFirstName"
                  placeholder={String(
                    t("parent_first_name_inquiry_placeholder")
                  )}
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.parentFirstName}
                />
                <Error>
                  {formik.errors.parentFirstName &&
                    formik.touched.parentFirstName && (
                      <Error>{t(formik.errors.parentFirstName)}</Error>
                    )}
                </Error>
              </Field>
              <Field>
                <Input
                  id="parentLastName"
                  placeholder={String(
                    t("parent_last_name_inquiry_placeholder")
                  )}
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.parentLastName}
                />
                <Error>
                  {formik.errors.parentLastName &&
                    formik.touched.parentLastName && (
                      <Error>{t(formik.errors.parentLastName)}</Error>
                    )}
                </Error>
              </Field>
            </FormRowGrid>
            <FormRowGrid>
              <Field>
                <Input
                  id="parentEmail"
                  placeholder={String(t("parent_email_inquiry_placeholder"))}
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.parentEmail}
                />
                <Error>
                  {formik.errors.parentEmail && formik.touched.parentEmail && (
                    <Error>{t(formik.errors.parentEmail)}</Error>
                  )}
                </Error>
              </Field>
              <Field>
                <StyledInputMask
                  id="parentPhoneNum"
                  mask="999-999-9999"
                  placeholder={String(t("parent_phone_inquiry_placeholder"))}
                  type="tel"
                  onChange={formik.handleChange}
                  value={formik.values.parentPhoneNum}
                  autoComplete="off"
                />
                <Error>
                  {formik.errors.parentPhoneNum &&
                    formik.touched.parentPhoneNum && (
                      <Error>{t(formik.errors.parentPhoneNum)}</Error>
                    )}
                </Error>
              </Field>
            </FormRowGrid>
            <FormRowGrid>
              <Field>
                <SelectDropdown
                  options={languagePreferences}
                  id="parentLanguagePreference"
                  value={languagePreferences.find(
                    (langaugePreference) =>
                      langaugePreference.value ===
                      formik.values.parentLanguagePreference
                  )}
                  placeholder={t("parent_primary_language_inquiry_placeholder")}
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      "parentLanguagePreference",
                      selectedOption.value
                    )
                  }
                />
              </Field>
              <Field>
                <SelectDropdown
                  options={relationshipsToPatient}
                  id="relationshipToPatient"
                  value={relationshipsToPatient.find(
                    (relationshipToPatient) =>
                      relationshipToPatient.value ===
                      formik.values.relationshipToPatient
                  )}
                  placeholder={t("patient_relationship_inquiry_placeholder")}
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      "relationshipToPatient",
                      selectedOption.value
                    )
                  }
                />
                <Error>
                  {formik.errors.relationshipToPatient &&
                    formik.touched.relationshipToPatient && (
                      <Error>{t(formik.errors.relationshipToPatient)}</Error>
                    )}
                </Error>
              </Field>
            </FormRowGrid>
          </Fields>
          <CheckboxGroup>
            <CheckboxField
              id="parentContactConsent"
              label={String(t("parent_contact_consent"))}
              formik={formik}
              onChange={formik.handleChange}
            />
          </CheckboxGroup>

          <Action>
            <TealButton type="submit" disabled={submitting}>
              {t("submit_for_care_button")}
            </TealButton>
          </Action>
        </Form>
      </Container>
    </>
  );
};

export type DirectReferralFormProps = {};
const DirectReferralForm: React.FC<DirectReferralFormProps> = () => {
  const {
    isLoading: submitting,
    mutateAsync: directReferral,
  } = useApi().usePostDirectReferral({});

  return submitting ? (
    <Container style={{ marginTop: "22vh" }}>
      <CenteredLoading></CenteredLoading>
      <LoadingTitle>
        {
          "Submission in progress, which can take up to 30 seconds. Thank you for your patience!"
        }
      </LoadingTitle>
    </Container>
  ) : (
    <Suspense
      fallback={
        <Container>
          <CenteredLoading></CenteredLoading>{" "}
        </Container>
      }
    >
      <DirectReferralFormWithData
        submitting={submitting}
        directReferral={async (variables) => {
          return await directReferral({ ...variables });
        }}
      />
    </Suspense>
  );
};

export default DirectReferralForm;
