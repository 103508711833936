import React, { ReactElement, useState } from "react";
import { useApi } from "../../../api/apiContext";
import { OrganizationSite } from "../../../api/queries/organizationSites";
import { useHasOrganizationFeature } from "../../../hooks/useHasOrganizationFeature";
import BaseModal, { BaseModalProps } from "../BaseModal/BaseModal";
import AddFormMode from "../ReferAStudentModal/AddFormMode";
import SearchMode from "../ReferAStudentModal/SearchMode";

type Mode = "searchMode" | "addMode";
export type AddToQueueModalWithDataProps = {
  createReferral: (organizationMemberId: string) => void;
  sites?: OrganizationSite[];
  directReferDisabled: boolean;
} & BaseModalProps;
export const AddToQueueModalWithData: React.FC<AddToQueueModalWithDataProps> = ({
  createReferral,
  sites,
  directReferDisabled,
  ...baseModalProps
}) => {
  const [mode, setMode] = useState<Mode>("searchMode");
  const goToAdd = () => {
    setMode("addMode");
  };
  const goToSearch = () => {
    setMode("searchMode");
  };
  if (!sites) {
    return <BaseModal>Loading</BaseModal>;
  }
  const title = "Add a student to queue";
  let ModeComponent: ReactElement;
  switch (mode) {
    case "addMode":
      ModeComponent = (
        <AddFormMode
          title={title}
          goBack={goToSearch}
          sites={sites}
          onCreate={(organizationMemberId) => {
            createReferral(organizationMemberId);
            baseModalProps.onClose && baseModalProps.onClose();
          }}
        />
      );
      break;
    case "searchMode":
      ModeComponent = (
        <SearchMode
          title={title}
          onSelect={(organizationMemberId) => {
            createReferral(organizationMemberId);
            baseModalProps.onClose && baseModalProps.onClose();
          }}
          goToAdd={directReferDisabled ? undefined : goToAdd}
          searchParameters={{
            referral_status: [null],
          }}
        />
      );
      break;
  }

  return <BaseModal {...baseModalProps}>{ModeComponent}</BaseModal>;
};

export type AddToQueueModalProps = {} & BaseModalProps;

const AddToQueueModal: React.FC<AddToQueueModalProps> = (baseModalProps) => {
  const api = useApi();
  const { mutate: createReferral } = api.usePostReferrals();
  const { data: { data: myStaff } = {} } = api.useGetMyStaff();
  const { active: directReferDisabled } = useHasOrganizationFeature(
    "disable_direct_member_management"
  );
  return (
    <AddToQueueModalWithData
      createReferral={(organizationMemberId: string) =>
        createReferral({ organizationMemberId, status: "referral_queued" })
      }
      directReferDisabled={directReferDisabled}
      sites={myStaff?.organizationSites}
      {...baseModalProps}
    />
  );
};

export default AddToQueueModal;
