import React, { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import {
  useOrgApiKeyParam,
  useClosedScreenerStatusParam,
} from "../../../app/Routes/ScreenerRoutes";
import { themeColor, themeMinWidth } from "../../../app/theme";
import Icon from "../../../components/Icon/Icon";
import OrangeButton from "../../../components/buttons/OrangeButton/OrangeButton";
import { useMandateOrganization } from "../../../hooks/screener/useMandateOrganization";
import ScreenerLayout from "../../../layouts/ScreenerLayout/ScreenerLayout";
import { schoolSupportEmail } from "../../../constants";
import { ClosedScreenerStatus } from "../../../api/queries/userFeatures";
import guestUserTokenStorage from "../../../lib/guestUserTokenStorage";
import { useQueryClient } from "react-query";
import { trackPage } from "client/amplitudeHelper";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 16px 60px 0;
`;
const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${themeColor("teal", { opacity: 0.1 })};
  width: 85px;
  height: 85px;
  border-radius: 8px;
  .icon {
    width: 43px;
    height: 39px;
    color: ${themeColor("teal")};
  }
`;
const H1 = styled.h1`
  margin-top: 32px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  max-width: 480px;
`;
const Details = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  max-width: 436px;
  text-align: center;
`;
const Buttons = styled.div`
  margin-top: 48px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  ${themeMinWidth("small")} {
    flex-direction: row;
  }
`;

type EligbilityText = {
  title: string;
  body: JSX.Element;
};

const screenerStatusMap: Record<string, EligbilityText> = {
  default: {
    title: "The screener you're trying to reach is not available right now.",
    body: (
      <>
        If this is unexpected please contact Daybreak support via{" "}
        <a href={"mailto:" + schoolSupportEmail}>{schoolSupportEmail}</a>.
      </>
    ),
  },
  screener_not_scheduled: {
    title: "The screener you tried to access is not scheduled right now.",
    body: (
      <>
        If you think it should be, please contact Daybreak support via{" "}
        <a href={"mailto:" + schoolSupportEmail}>{schoolSupportEmail}</a>.
      </>
    ),
  },
  screener_closed_recently: {
    title: "The window for responding to this screener recently closed.",
    body: (
      <>
        If you have questions or concerns, please contact Daybreak support via{" "}
        <a href={"mailto:" + schoolSupportEmail}>{schoolSupportEmail}</a>.
      </>
    ),
  },
  screener_opening_soon: {
    title: "Be patient!",
    body: (
      <>
        The screener you're looking for isn't available quite yet. Please check
        back soon!
      </>
    ),
  },
};

export type ScreenerStatusPageWithDataProps = {
  statusFlag: ClosedScreenerStatus | undefined;
  history: any;
  organizationApiKey: string;
};
export const ScreenerStatusPageWithData: React.FC<ScreenerStatusPageWithDataProps> = ({
  statusFlag,
  history,
  organizationApiKey,
}) => {
  const { title, body } = screenerStatusMap[statusFlag ?? "default"];
  return (
    <ScreenerLayout>
      <Container>
        <IconBox>
          <Icon name="info" />
        </IconBox>
        <H1>{title}</H1>
        <Details>{body}</Details>
        <Buttons>
          <OrangeButton
            onClick={() =>
              history.push(`/organization/${organizationApiKey}/screener`)
            }
          >
            Try Again
          </OrangeButton>
        </Buttons>
      </Container>
    </ScreenerLayout>
  );
};

export type ScreenerStatusPageProps = {};
const ScreenerStatusPage: React.FC<ScreenerStatusPageProps> = () => {
  const history = useHistory();
  const organizationApiKey = useOrgApiKeyParam();
  useMandateOrganization(organizationApiKey);
  const queryClient = useQueryClient();
  const statusFlag = useClosedScreenerStatusParam();

  useEffect(() => {
    // Let's avoid using a cached user_features result.
    guestUserTokenStorage.clear();
    queryClient.clear();

    trackPage("ScreenerStatusPage - Universal Screener Status Page", {
      statusFlag: statusFlag,
    });
  });

  return (
    <ScreenerStatusPageWithData
      statusFlag={statusFlag}
      history={history}
      organizationApiKey={organizationApiKey}
    />
  );
};

export default ScreenerStatusPage;
