import React from "react";
import { useDaybreakTranslation } from "../../hooks/useDaybreakTranslation";
import en from "./ParentWelcomePacket.en.json";
import es from "./ParentWelcomePacket.es.json";

import {
  CompleteIntakeH2,
  Description,
  HeaderSection,
  H1,
  ScheduleSection,
} from "./elements";

const IntakeCompletedSection: React.FC = () => {
  const { t } = useDaybreakTranslation("completeIntake", { en, es });
  return (
    <ScheduleSection>
      <HeaderSection>
        <H1>{t("intakeCompleted.title")}</H1>
        <CompleteIntakeH2>{t("intakeCompleted.heading")}</CompleteIntakeH2>
        <Description>{t("intakeCompleted.description")}</Description>
      </HeaderSection>
    </ScheduleSection>
  );
};

export default IntakeCompletedSection;
