import React from "react";
import styled from "styled-components/macro";
import { useOrgApiKeyParam } from "../../../app/Routes/ScreenerRoutes";
import { themeColor, themeMinWidth } from "../../../app/theme";
import Icon from "../../Icon/Icon";
import OrangeButton from "../../buttons/OrangeButton/OrangeButton";
import BaseModal from "../BaseModal/BaseModal";

export type InactivityModalProps = {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 16px 60px 16px;
  ${themeMinWidth("small")} {
    padding: 50px 48px 60px 48px;
  }
`;
const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${themeColor("orange5", { opacity: 0.1 })};
  width: 85px;
  height: 85px;
  border-radius: 8px;
  .icon {
    width: 55px;
    height: 54px;
    color: ${themeColor("orange5")};
  }
`;
const H1 = styled.h1`
  margin-top: 32px;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;
const Details = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  max-width: 436px;
  text-align: center;
`;
const Buttons = styled.div`
  margin-top: 48px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  ${themeMinWidth("small")} {
    flex-direction: row;
  }
`;
const InactivityModal: React.FC<InactivityModalProps> = () => {
  const organizationApiKey = useOrgApiKeyParam();
  return (
    <BaseModal>
      <Container>
        <IconBox>
          <Icon name="timeAlert" />
        </IconBox>
        <H1>Your session has expired</H1>
        <Details>
          You session has timed out. Please click “Log in” to start over again
        </Details>
        <Buttons>
          <OrangeButton
            onClick={() => {
              // Use location.href instead of history.push so that we load that
              // page even if we're already at that url.
              window.location.href = `/organization/${organizationApiKey}/screener`;
            }}
          >
            Log in
          </OrangeButton>
        </Buttons>
      </Container>
    </BaseModal>
  );
};

export default InactivityModal;
