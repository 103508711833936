import React from "react";
import styled from "styled-components/macro";
import {
  levelToColor,
  ScoreConfig,
} from "../../ClinicalProgressChart/ClinicalProgressChart";
import Icon from "../../Icon/Icon";
import Tooltip from "../Tooltip";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const ThresholdLine = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
`;
const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;
const Range = styled.div`
  margin-left: 8px;
`;
const Label = styled.div`
  margin-left: 4px;
`;

export type SurveyThresholdsTooltipProps = {
  title: string;
  scoreConfig: ScoreConfig;
  forceShow?: boolean;
};
const SurveyThresholdsTooltip: React.FC<SurveyThresholdsTooltipProps> = ({
  title,
  scoreConfig,
  forceShow,
}) => {
  const thresholdLines: {
    min: number;
    max: number;
    color: string;
    label: string;
  }[] = [];

  // Go through the thresholds backwards and convert them to ranges.
  const thresholds = [...scoreConfig.thresholds];
  for (let i = 0; i < thresholds.length; i++) {
    const [score, level] = thresholds[i];
    const nextThreshold = thresholds[i + 1];
    thresholdLines.push({
      min: score,
      max: nextThreshold ? nextThreshold[0] - 1 : scoreConfig.max,
      color: levelToColor[level],
      label: scoreConfig.severityLabels[level],
    });
  }

  return (
    <Tooltip
      tooltipContents={
        <Content>
          <Title>{title}</Title>
          {thresholdLines.map(({ color, min, max, label }) => (
            <ThresholdLine key={label}>
              <Circle style={{ backgroundColor: color }} />
              <Range>
                {min}-{max}
              </Range>
              :<Label>{label}</Label>
            </ThresholdLine>
          ))}
        </Content>
      }
      forceShow={forceShow}
    >
      <Icon name="info" />
    </Tooltip>
  );
};

export default SurveyThresholdsTooltip;
