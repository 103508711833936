import { ComponentProps } from "react";
import styled from "styled-components/macro";
import { themeColor } from "../../app/theme";

const TextLink = styled.a`
  color: ${themeColor("teal")};
  cursor: pointer;
  transition: all ${(props) => props.theme.animations.defaultHover};
  :hover {
    filter: brightness(95%);
  }
  :active {
    filter: brightness(85%);
  }
  :visited {
    color: ${themeColor("teal")};
  }
`;

export type TextLinkProps = ComponentProps<typeof TextLink>;
export default TextLink;
