import React from "react";
import styled from "styled-components/macro";
import { hexOpacity, theme } from "../../app/theme";
import Icon, { ValidIconNames } from "../Icon/Icon";

const badgeConfigs = {
  lightGreen: {
    backgroundColor: theme.colors.green1,
    textColor: theme.colors.green,
  },
  lightRed: {
    backgroundColor: theme.colors.red1,
    textColor: "red",
  },
  lightOrange: {
    backgroundColor: theme.colors.orange5 + hexOpacity(0.1),
    textColor: "#FF6B20",
  },
  lightGray: {
    backgroundColor: theme.colors.backgroundGray,
    textColor: theme.colors.darkGrayText6,
  },
  heavyGreen: {
    backgroundColor: theme.colors.green,
    textColor: "white",
  },
  heavyOrange: {
    backgroundColor: theme.colors.orange5,
    textColor: "white",
  },
  heavyBlack: {
    backgroundColor: theme.colors.darkGrayText3,
    textColor: "white",
  },
  heavyGray: {
    backgroundColor: "#e9e9e9",
    textColor: theme.colors.darkGrayText2,
  },
};
export type BadgeType = keyof typeof badgeConfigs;
export type BadgeProps = {
  type: BadgeType;
  text: string;
  size: "small" | "medium";
  icon?: ValidIconNames;
  inline?: boolean;
};

const containerCommon = styled.div`
  display: flex;
  padding: 0 8px;
  align-items: center;
  .icon {
    margin-right: 4px;
    width: 12px;
    height: 12px;
  }
`;
const containers = {
  small: styled(containerCommon)`
    font-size: 12px;
    border-radius: 16px;
  `,
  medium: styled(containerCommon)`
    font-size: 14px;
    border-radius: 30px;
  `,
};
const Badge: React.FC<BadgeProps> = ({
  text,
  type,
  size,
  icon,
  inline = false,
}) => {
  const config = badgeConfigs[type];
  const Container = containers[size];
  let styles: React.CSSProperties = {
    color: config.textColor,
    backgroundColor: config.backgroundColor,
  };
  if (inline) {
    styles.display = "inline-flex";
  }
  return (
    <Container style={styles}>
      {icon && <Icon name={icon} />}
      {text}
    </Container>
  );
};

export default Badge;
