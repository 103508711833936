import styled from "styled-components";

export const ContentContainer = styled.div`
  background: var(--Dusk-99, #f9fdff);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MainContent = styled.div`
  margin-top: 36px;
  display: flex;
  width: 1032px;
  padding-bottom: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 26px;

  @media (max-width: 1500px) {
    width: 100%;
  }
`;

export const Title = styled.div`
  display: flex;
  padding: 0px 88px;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
  color: var(--Night, #000);
  font-family: Poppins;
  font-size: 45px;
  font-weight: 500;
  line-height: 52px;
  background: #fff;
`;

export const ContentBox = styled.div`
  display: flex;
  height: 356px;
  padding: 20px 88px 48px 88px;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Cloud, #fff);
`;

export const Heading = styled.div`
  color: var(--Night, #000);
  display: flex;
  justify-content: flex-start;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
`;

export const ContentDescription = styled.div`
  align-self: stretch;
  color: var(--Night, #000);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const Description = styled.div`
  line-height: 20px;
  letter-spacing: 0.25px;
  font-size: 14px;
  height: 100%;
`;
