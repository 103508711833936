import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";

export const ModalContainer = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  z-index: 2;
  pointer-events: none;

  ${themeMinWidth("small")} {
    align-items: center;
  }
`;

export const ModalBox = styled.div`
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-grow: 1;
  position: relative;
  pointer-events: auto;

  ${themeMinWidth("small")} {
    flex-grow: 0;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: black;
  opacity: 0.4;
  z-index: 1;
`;

export const Close = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  .icon {
    color: ${themeColor("darkGrayText7")};
    :hover,
    :active {
      color: ${themeColor("darkGrayText4")};
    }
  }
`;
