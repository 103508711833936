import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";
import InactivityTimeout from "../../components/InactivityTimeout/InactivityTimeout";
import { ClosedScreenerStatus } from "../../api/queries/userFeatures";
import EligiblityFailPage from "../../pages/Screener/EligibilityFailPage/EligiblityFailPage";
import EligibilityPage from "../../pages/Screener/EligibilityPage/EligibilityPage";
import OrgNotFoundPage from "../../pages/Screener/OrgNotFoundPage/OrgNotFoundPage";
import PostSurveyPage from "../../pages/Screener/PostSurveyPage/PostSurveyPage";
import ScreenerAssessmentPage from "../../pages/Screener/ScreenerAssessmentPage/ScreenerAssessmentPage";
import ScreenerStatusPage from "../../pages/Screener/ScreenerStatusPage/ScreenerStatusPage";
import StudentExemptedPage from "../../pages/Screener/StudentExemptedPage/StudentExemptedPage";
import SurveyInstructionsPage from "../../pages/Screener/SurveyInstructionsPage/SurveyInstructionsPage";
import { RouteWith, RouteWithParams } from "./RouteWith";

export type ScreenerRoutesProps = {};

// Since all screener paths are prefixed with /organization/abc/screener and the
// various screener pages need to be able to navigate between eachother, here's
// a helper to get that path.
export const useOrgApiKeyParam = () => {
  return useParams<{ organizationApiKey: string }>().organizationApiKey;
};

export const useClosedScreenerStatusParam = () => {
  return useParams<{ closedScreenerStatus: ClosedScreenerStatus }>()
    .closedScreenerStatus;
};

const ScreenerRoutes = () => {
  const routes: { routeParams: RouteWithParams; component: ReactElement }[] = [
    {
      routeParams: {
        path: "/screener-404",
      },
      component: <OrgNotFoundPage />,
    },
    {
      routeParams: {
        path: "/organization/:organizationApiKey/screener/student-exempted",
      },
      component: <StudentExemptedPage />,
    },
    {
      routeParams: {
        path: "/organization/:organizationApiKey/screener/student-not-found",
      },
      component: <EligiblityFailPage />,
    },
    {
      routeParams: {
        path:
          "/organization/:organizationApiKey/screener/status/:closedScreenerStatus",
      },
      component: <ScreenerStatusPage />,
    },
    {
      routeParams: {
        path: "/organization/:organizationApiKey/screener/instructions",
      },
      component: <SurveyInstructionsPage />,
    },
    {
      routeParams: {
        path: [
          "/organization/:organizationApiKey/screener/assessment/:questionIndex",
          "/organization/:organizationApiKey/screener/assessment",
        ],
      },
      component: <ScreenerAssessmentPage />,
    },
    {
      routeParams: {
        path: "/organization/:organizationApiKey/screener/thank-you",
      },
      component: <PostSurveyPage />,
    },
    {
      routeParams: { path: "/organization/:organizationApiKey/screener" },
      component: <EligibilityPage />,
    },
  ];
  return routes.map(({ routeParams, component }, i) => (
    <RouteWith
      {...routeParams}
      key={typeof routeParams.path === "string" ? routeParams.path : i}
    >
      <>
        {/* Inject InactivityTimeout into all screener routes */}
        <InactivityTimeout />
        {component}
      </>
    </RouteWith>
  ));
};

export default ScreenerRoutes;
