import { createUseApiQuery, PageMetaTypeV1 } from "./queryHelpers";

// The features the frontend knows about
export type FeatureApiKey =
  | "organization_manual_user_management"
  | "parent_scheduled_referral"
  | "disable_direct_member_management"
  | "ssd_insurance_collection"
  | "family_therapy"
  | "pwp"
  | "consent_to_contact_flow"
  | "show_appointments_in_ssd"
  | "pwp_v1_flow"
  | "ssd_legacy_insurance_collection"
  | "organization_onsite_referral_form_link";

export type OrganizationFeature = {
  organizationId: string;
  featureId: string;
  active: boolean;
  featureApiKey: FeatureApiKey;
};

export const useGetOrganizationFeatures = createUseApiQuery<
  OrganizationFeature[],
  { organizationApiKey: string },
  PageMetaTypeV1
>({
  queryBuilder: ({ organizationApiKey }) => ({
    cacheKey: ["organizationFeatures", organizationApiKey],
    url: "/organization_features/v1",
    config: {
      params: { organization_api_key: organizationApiKey },
    },
  }),
  auth: false,
});
