import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";

export const Container = styled.div`
  padding: 16px;
`;
export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  ${themeMinWidth("small")} {
    flex-direction: row;
    justify-content: space-between;
  }
`;
export const PeriodSelectorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  margin-left: 16px;
  .icon {
    width: 16px;
    height: 16px;
    color: #666;
  }
  ${themeMinWidth("small")} {
    margin-top: 0;
  }
`;
export const PeriodSelect = styled.select`
  border: none;
  background-color: transparent;
  color: ${themeColor("black", { opacity: 0.6 })};
  font-size: 14px;
  font-weight: 500;
`;
export const ChartBoxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

export const ChartBoxContainer = styled.div`
  // padding: 8px;
  // width: 1000px;
`;
export const ChartBoxBody = styled.div`
  padding: 8px;

  ${themeMinWidth("small")} {
    padding: 16px 20px;
  }
`;
export const ChartBoxStatus = styled.div`
  display: flex;
  align-items: center;
`;
export const ChartBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 8px;
  ${themeMinWidth("small")} {
    padding: 16px;
  }
`;
export const ChartBoxTitle = styled.div`
  font-weight: 500;
  color: ${themeColor("darkGrayText7")};
  display: flex;
  gap: 4px;
  .icon {
    position: relative;
    top: 1px;
  }
`;
export const ChartBoxCurrentSection = styled.div`
  display: flex;
  align-items: center;
`;
export const ChartBoxCurrent = styled.div`
  color: ${themeColor("darkGrayText7")};
  font-size: 12px;
  font-weight: 500;
`;
export const ChartBoxDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: blue;
  margin-left: 16px;
`;
export const ChartBoxStatusText = styled.div`
  font-size: 12px;
  margin-left: 8px;
  font-weight: 500;
`;
export const ChartBoxDirection = styled.div`
  margin-left: 24px;
`;
export const ChartBoxStatusMobile = styled.div`
  display: flex;

  ${themeMinWidth("small")} {
    display: none;
  }
`;
export const ChartBoxStatusDesktop = styled.div`
  display: none;

  ${themeMinWidth("small")} {
    display: flex;
  }
`;

export const CardBoxChartContainer = styled.div`
  height: 164px;
  padding-top: 8px;
  ${themeMinWidth("small")} {
    height: 188px;
  }
`;

export const Disclaimer = styled.div`
  color: ${themeColor("darkGrayText7")};
  font-size: 14px;
  font-weight: 500;
  display: inline;
`;
export const DisclaimerLink = styled.a`
  margin-left: 8px;
  color: ${themeColor("teal")};
  :visited {
    color: ${themeColor("teal")};
  }
`;

export const WhereFromLink = styled.div`
  padding-left: 8px;
  position: relative;
  top: 2px;
  font-size: 14px;
  font-weight: normal;
`;
export const DesktopScoresLink = styled.div`
  display: none;
  ${themeMinWidth("small")} {
    display: block;
  }
`;
export const MobileScoresLink = styled.div`
  display: block;
  ${themeMinWidth("small")} {
    display: none;
  }
`;

export const EmptyChartBoxBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  ${themeMinWidth("small")} {
    padding: 24px 16px;
  }
`;
export const EmptyChartImage = styled.img`
  width: 50px;
`;
export const EmptyChartText = styled.div`
  margin-top: 16px;
  font-size: 14px;
  color: ${themeColor("darkGrayText7")};
  font-font-weight: 500;
`;
export const EmptyChartButton = styled.div`
  margin-top: 16px;
`;
