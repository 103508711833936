import { useFormik } from "formik";
import React from "react";
import styled from "styled-components/macro";
import { OrganizationSite } from "../../../api/queries/organizationSites";
import { themeColor } from "../../../app/theme";
import TealButton from "../../buttons/TealButton/TealButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  color: ${themeColor("darkGrayText6")};
  padding-bottom: 4px;
  border-bottom: 1px solid ${themeColor("borderGray5")};
  margin-top: 24px;
`;
const Form = styled.form`
  margin-top: 22px;
`;
const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 13px;
`;
const SiteOption = styled.div``;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  :not(:checked) {
    opacity: 0.2;
  }
`;
const CheckboxContainer = styled.div`
  display: flex;
`;
const CheckboxLabel = styled.label`
  margin-left: 12px;
`;
type FormCheckboxProps = {
  label: string;
} & React.InputHTMLAttributes<HTMLInputElement>;
const FormCheckbox: React.FC<FormCheckboxProps> = ({
  label,
  ...inputProps
}) => {
  return (
    <CheckboxContainer>
      <Checkbox {...inputProps} />
      <CheckboxLabel htmlFor={inputProps.id}>{label}</CheckboxLabel>
    </CheckboxContainer>
  );
};

export type StaffUserModalAccessProps = {
  onBack: () => void;
  onSubmit: (siteIds: string[]) => void;
  orgSites: OrganizationSite[];
  initialSelection?: OrganizationSite["id"][];
  mode: "edit" | "new";
};
const StaffUserModalAccess: React.FC<StaffUserModalAccessProps> = ({
  onBack,
  onSubmit,
  orgSites,
  initialSelection,
  mode,
}) => {
  const formik = useFormik({
    initialValues: { siteIds: initialSelection ?? [] },
    onSubmit: (values) => {
      onSubmit(values.siteIds);
    },
  });

  return (
    <Container>
      <Header>School access</Header>
      <Form onSubmit={formik.handleSubmit}>
        <Checkboxes>
          {orgSites.map((orgSite) => (
            <SiteOption key={orgSite.id}>
              <FormCheckbox
                label={orgSite.name}
                type="checkbox"
                id={orgSite.id}
                name="siteIds"
                value={orgSite.id}
                onChange={formik.handleChange}
                checked={formik.values.siteIds.includes(orgSite.id)}
              />
            </SiteOption>
          ))}
        </Checkboxes>
        <Buttons>
          <TealButton variant="secondary" onClick={onBack}>
            Back
          </TealButton>
          <TealButton variant="primary" type="submit">
            {mode === "new" ? "Invite" : "Save"}
          </TealButton>
        </Buttons>
      </Form>
    </Container>
  );
};

export default StaffUserModalAccess;
