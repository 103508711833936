import React, { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BaseModal, { BaseModalProps } from "../BaseModal/BaseModal";
import ChoiceMode from "./ChoiceMode";
import FormMode from "./FormMode";
import SearchMode from "./SearchMode";
import AddFormMode from "./AddFormMode";
import { useApi } from "../../../api/apiContext";
import { themeMinWidth } from "../../../app/theme";
import styled from "styled-components/macro";
import { CenteredLoading } from "../../Loading/Loading";
import { OrganizationSite } from "../../../api/queries/organizationSites";
import { useHasOrganizationFeature } from "../../../hooks/useHasOrganizationFeature";
import { trackEvent } from "client/amplitudeHelper";
import GeneralErrorPage from "../../../pages/GeneralErrorPage/GeneralErrorPage";

const Container = styled.div`
  padding: 24px;
  flex-grow: 1;
  ${themeMinWidth("small")} {
    min-width: 600px;
  }
`;
const LoadingContainer = styled.div`
  display: flex;
  min-height: 400px;
`;

export const useReferralModal = () => {
  const [referModalState, setReferModalState] = useState<{
    showing: boolean;
    selectedOrganizationMemberId: string | null;
  }>({
    showing: false,
    selectedOrganizationMemberId: null,
  });
  const showModal = (selectedOrganizationMemberId: string | null) => {
    setReferModalState({
      showing: true,
      selectedOrganizationMemberId,
    });
  };
  const hideModal = () => {
    setReferModalState({ showing: false, selectedOrganizationMemberId: null });
  };

  const setSelectedOrganizationMemberId = (
    selectedOrganizationMemberId: string | null
  ) => {
    setReferModalState({ ...referModalState, selectedOrganizationMemberId });
  };

  return {
    showing: referModalState.showing,
    selectedOrganizationMemberId: referModalState.selectedOrganizationMemberId,
    showModal,
    hideModal,
    setSelectedOrganizationMemberId,
  };
};

type ReferralModalInsertProps = {
  referralModalContext: {
    showing: boolean;
    selectedOrganizationMemberId: string | null;
    hideModal: () => void;
    showModal: (selectedOrganizationMemberId: string | null) => void;
    setSelectedOrganizationMemberId: (
      selectedOrganizationMemberId: string | null
    ) => void;
  };
};
export const ReferralModalInsert: React.FC<ReferralModalInsertProps> = ({
  referralModalContext,
}) => {
  if (!referralModalContext.showing) {
    return null;
  }
  return (
    <ReferAStudentModal
      selectedOrganizationMemberId={
        referralModalContext.selectedOrganizationMemberId
      }
      setSelectedOrganizationMemberId={
        referralModalContext.setSelectedOrganizationMemberId
      }
      closeModal={referralModalContext.hideModal}
    />
  );
};

export type ReferAStudentModalModes =
  | "choiceMode"
  | "searchMode"
  | "addMode"
  | "formMode";
export type ReferAStudentModalProps = {
  selectedOrganizationMemberId: string | null;
  setSelectedOrganizationMemberId: (
    organizationMemberId: string | null
  ) => void;
  /**
   * For testing purposes only; forces a specific mode in the modal
   */
  forceMode?: ReferAStudentModalModes;
} & BaseModalProps;
export type ReferAStudentModalWithDataProps = {
  sites: OrganizationSite[];
  directReferDisabled: boolean;
} & ReferAStudentModalProps;
const ReferAStudentModalWithData: React.FC<ReferAStudentModalWithDataProps> = ({
  selectedOrganizationMemberId,
  setSelectedOrganizationMemberId,
  forceMode,
  sites,
  directReferDisabled,
  ...baseModalProps
}) => {
  const history = useHistory();
  const [mode, setMode] = useState<ReferAStudentModalModes>(
    forceMode ?? "choiceMode"
  );
  const goToSearch = () => {
    setSelectedOrganizationMemberId(null);
    setMode("searchMode");
    trackEvent("SCD:loadSearchModeModal");
  };
  const goToAdd = () => {
    setMode("addMode");
    trackEvent("SCD:loadAddOrganizationMemberModeModal");
  };
  const goToChoiceMode = () => {
    setMode("choiceMode");
    trackEvent("SCD:loadChoiceModeModal");
  };
  const goToForm = (organizationMemberId: string) => {
    setSelectedOrganizationMemberId(organizationMemberId);
    trackEvent("SCD:studentInfoModalForm", {
      organizationMemberId,
    });
  };
  const finishModal = (organizationMemberId: string) => {
    history.push(`/school/scheduling/referral-form/${organizationMemberId}`);
  };

  const title = "Refer a student";
  let ModeComponent: ReactElement;
  switch (mode) {
    case "choiceMode":
      ModeComponent = (
        <ChoiceMode
          goToSearch={goToSearch}
          goToAdd={goToAdd}
          directReferDisabled={directReferDisabled}
        />
      );
      break;
    case "searchMode":
      ModeComponent = (
        <SearchMode
          title={title}
          onSelect={goToForm}
          searchParameters={{
            referral_status: [null, "referral_in_progress", "referral_queued"],
          }}
          goToAdd={directReferDisabled ? undefined : goToAdd}
        />
      );
      break;
    case "addMode":
      ModeComponent = (
        <AddFormMode
          title={title}
          goBack={goToChoiceMode}
          onCreate={finishModal}
          sites={sites}
        />
      );
      break;
    case "formMode":
      ModeComponent = (
        <FormMode
          organizationMemberId={selectedOrganizationMemberId}
          goToSearch={goToSearch}
          finishModal={() =>
            selectedOrganizationMemberId &&
            finishModal(selectedOrganizationMemberId)
          }
        />
      );
      break;
  }
  useEffect(() => {
    if (selectedOrganizationMemberId) {
      setMode("formMode");
    }
  }, [selectedOrganizationMemberId]);

  return <BaseModal {...baseModalProps}>{ModeComponent}</BaseModal>;
};

const ReferAStudentModal: React.FC<ReferAStudentModalProps> = ({
  selectedOrganizationMemberId,
  setSelectedOrganizationMemberId,
  forceMode,
  ...baseModalProps
}) => {
  const api = useApi();
  const { data: { data: myStaff } = {} } = api.useGetMyStaff();
  const { active: directReferDisabled } = useHasOrganizationFeature(
    "disable_direct_member_management"
  );

  const LoadingModal = () => (
    <BaseModal {...baseModalProps}>
      <Container>
        <LoadingContainer>
          <CenteredLoading />
        </LoadingContainer>
      </Container>
    </BaseModal>
  );

  const UnassignedSitesModal = () => (
    <BaseModal {...baseModalProps}>
      <Container>
        <GeneralErrorPage errorKey="noSitesAssignedError" showLogout={false} />
      </Container>
    </BaseModal>
  );

  if (!myStaff) {
    return <LoadingModal />;
  } else if (!myStaff.organizationSites) {
    return <UnassignedSitesModal />;
  }

  return (
    <ReferAStudentModalWithData
      selectedOrganizationMemberId={selectedOrganizationMemberId}
      setSelectedOrganizationMemberId={setSelectedOrganizationMemberId}
      forceMode={forceMode}
      sites={myStaff?.organizationSites}
      directReferDisabled={directReferDisabled}
      {...baseModalProps}
    />
  );
};

export default ReferAStudentModal;
