import React from "react";
import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../../app/theme";
import Icon from "../../Icon/Icon";
import Tooltip from "../Tooltip";

const Content = styled.div`
  padding: 8px 0;
`;
const Header = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${themeColor("darkGrayText3")};
`;
const Body = styled.div`
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  color: ${themeColor("darkGrayText4")};
  ${themeMinWidth("small")} {
    width: 400px;
  }
`;
export type UtilizedTooltipProps = { forceShow?: boolean };
const UtilizedTooltip: React.FC<UtilizedTooltipProps> = ({ forceShow }) => {
  return (
    <Tooltip
      tooltipContents={
        <Content>
          <Header>Utilized</Header>
          <Body>
            The total number of sessions that have or will be charged to your
            account - this is the total number of Attended, Late Cancels or No
            Show appointments
          </Body>
        </Content>
      }
      forceShow={forceShow}
    >
      <Icon name="info" />{" "}
    </Tooltip>
  );
};

export default UtilizedTooltip;
